import { useState, useEffect } from "react";
import { DiscountModel } from "../models/DiscountModel";
import { useHistory } from "react-router-dom";
import {
  getDiscounts,
  getDataDiscountById,
} from "../../../service/discount/Discount";

export const useGetAll = (loading: number) => {
  const [dataDiscount, setDataDiscount] = useState<DiscountModel[]>([]);
  const [count, setCount] = useState(1);
  const [loadingRequest, setLoadingRequest] = useState<boolean>(false);
  const history = useHistory();

  useEffect(() => {
    const fetchPost = async () => {
      setLoadingRequest(true);
      const data = await getDiscounts()
        .then()
        .catch((error) => {
          setLoadingRequest(false);

          history.push("/error/500");
        });
      if (data) {
        setDataDiscount(data.rows);
        setCount(data.count);
        setLoadingRequest(false);
      }
    };
    fetchPost();
  }, [loading]);
  return { dataDiscount, count, loadingRequest };
};

export const useGetOne = (id: number) => {
  const initModel = {
    id: 0,
    codigo: "",
    nombre: "",
    valor: 0,
    tipo_id: 0,
    unidad_moneda_id: 0,
  };
  const [initValues, setInitValues] = useState<DiscountModel>(initModel);

  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      const res = await getDataDiscountById(id)
        .then()
        .catch((error) => {
          history.push("/error/500");
        });
      if (res) {
        setInitValues(res);
      }
    };
    if (id) {
      fetchPost();
    } else {
      setInitValues(initModel);
    }
  }, [id]);

  return { initValues };
};
