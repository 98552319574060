import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { getAllComerciales, getProjectionsDashborad, getDataSelectMultiple, getAccountSelectMultiple } from '../../../service/dashboard/DashboardProjections'
import { VendedorModel } from '../../vendedor/models/VendedorModel'
import { SelectMultipleModal } from '../models/SelectModel'
import { ResultDataChartProjections } from '../models/ResultModel'

// OBTIENE LA INFORMACION DE LA GRAFICA
export const useGetProjections = (filter: any) => {

  const [loadingRequest, setLoadingRequest] = useState(0);
  const [dataChart, setDataChart] = useState<ResultDataChartProjections>();
  const history = useHistory();
  useEffect(() => {
      const fetchPost = async () => {
          setLoadingRequest(0);
          const res = await getProjectionsDashborad(filter)
              .then()
              .catch((error) => {
                  history.push('/error/500');
              });
          if (res) {
              setDataChart(res)
              setLoadingRequest(1);

          }
      };
      fetchPost();

  }, [filter]);
  return { dataChart, loadingRequest };
}

// ONTIENE EL LISTADO DE VENDEDORES
export const useGetAllSeller = () => {
    const [data, setData] = useState<VendedorModel[]>([]);
    const [loadingRequest, setLoadingRequest] = useState(0);
  
    const history = useHistory();
    useEffect(() => {
      setLoadingRequest(0);
      const fetchPost = async () => {
        const res = await getAllComerciales()
          .then()
          .catch((error) => {
            history.push('/error/500');
          });
        if (res) {

          setData(res);
          setLoadingRequest(1);
        }
      };
        fetchPost();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return { data, loadingRequest};
  };

  // ONTIENE EL CATALOGO POR CODIGO EN ESTE CASO TIPO OPORTUNIDAD (TOP)
export const useGetDataSelect = (code: string) => {
    const [data, setData] = useState<SelectMultipleModal[]>([]);
    const [loadingRequest, setLoadingRequest] = useState(0);
  
    const history = useHistory();
    useEffect(() => {
      setLoadingRequest(0);
      const fetchPost = async () => {
        const res = await getDataSelectMultiple(code)
          .then()
          .catch((error) => {
            history.push('/error/500');
          });
        if (res) {
          setData(res);
          setLoadingRequest(1);
        }
      };
        fetchPost();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [code]);
    return { data, loadingRequest};
  };
// OBTIENE EL LISTADO DE CUENTAS
export const useGetAccountSelect = () => {
    const [data, setData] = useState<SelectMultipleModal[]>([]);
    const [loadingRequest, setLoadingRequest] = useState(0);
  
    const history = useHistory();
    useEffect(() => {
      setLoadingRequest(0);
      const fetchPost = async () => {
        const res = await getAccountSelectMultiple()
          .then()
          .catch((error) => {
            history.push('/error/500');
          });
        if (res) {
          setData(res);
          setLoadingRequest(1);
        }
      };
        fetchPost();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return { data, loadingRequest};
  };