import axios from 'axios'
const API_URL = process.env.REACT_APP_API

export async function getSubCuentasEmail(cuentas: []) {

    let result: string = ''
    if (cuentas.length) {
        const values = cuentas.map((item: any) => item.value);
        result = values.join(', ');
    }


    // const response = await axios.get(`${API_URL}catalogos/subcuentas`);
    const response = await axios.get(`${API_URL}catalogos/subcuentas?cuenta_idOpIn=${result}`);

    const data = response.data.doc.data.rows;
    const count = response.data.doc.data.count;
    return {
        data: data,
        count: count
    };

}

export async function getAllOportunidadesByCuentaAndSubcuenta(subcuentas: []) {

    // aqui sin cuentas

    // let search_cuentas: string = '';
    let search_subcuentas: string = '';
    // let cuentaCollection: string = '';
    let subcuentaCollection: string = '';

    // if (cuentas.length) {
    //     const values = cuentas.map((item: any) => item.value);
    //     cuentaCollection = values.join(', ');
    // }
    if (subcuentas.length) {
        const values = subcuentas.map((item: any) => item.value);
        subcuentaCollection = values.join(', ');
    }

    // if (cuentaCollection) {
    //     search_cuentas = `&cuenta_idOpIn=${cuentaCollection}`;
    // }
    if (subcuentaCollection) {
        search_subcuentas = `sub_cuenta_idOpIn=${subcuentaCollection}`;
    }

    const response = await axios.get(`${API_URL}oportunidades?${search_subcuentas}`);

    const data = response.data.doc?.data?.rows;
    const count = response.data.doc?.data?.count;
    return {
        data: data,
        count: count
    };

}

export async function getAllContactosByCSO(cuentas: [], subcuentas: [], oportunidades_contacto: [], startIndex: number, endIndex: number) {


    let search_cuentas: string = '';
    let search_subcuentas: string = '';
    let search_oportunidades: string = '';
    let cuentaCollection: string = '';
    let subcuentaCollection: string = '';
    let oportunidadesCollection: string = '';

    if (cuentas.length) {
        const values = cuentas.map((item: any) => item.value);
        cuentaCollection = values.join(', ');
    }
    if (subcuentas.length) {
        const values = subcuentas.map((item: any) => item.value);
        subcuentaCollection = values.join(', ');
    }
    if (oportunidades_contacto.length) {
        const values = oportunidades_contacto.map((item: any) => item.value);
        oportunidadesCollection = values.join(', ');
    }

    if (cuentaCollection.length) {
        search_cuentas = `&cuenta_idOpIn=${cuentaCollection}`;
    }
    if (subcuentaCollection.length) {
        search_subcuentas = `&sub_cuenta_idOpIn=${subcuentaCollection}`;
    }
    if (oportunidades_contacto.length) {
        oportunidadesCollection = `&idOpIn=${subcuentaCollection}`;
    }

    const response = await axios.get(`${API_URL}catalogos/contactos?limit=${startIndex}&page=${endIndex}${search_cuentas}${search_subcuentas}${search_oportunidades}`);

    const data = response.data.doc?.data?.rows;
    const count = response.data.doc?.data?.count;
    return {
        data: data,
        count: count
    };

}

export async function sendEmail(body: any, plantillaId: any) {

    const { value } = plantillaId;

    const data = {
        listaCorreos: body,
        plantilla_id: value,
    }

    const response = await axios.post(`${API_URL}plantillas/correo`, data);
    return response;

}