import React, { useState, useEffect } from 'react';
import { KTSVG } from '../../../../_metronic/helpers';
import { Modal } from 'react-bootstrap-v5';
import { useFormik } from 'formik';
import { useGetOne } from '../hooks/PlantillaHook';
type Props = {
  show: boolean;
  handleClose: (updateGrid: boolean) => void;
  title: string;
  id_row: any;
};

const DetailModal: React.FC<Props> = ({ id_row, show, handleClose, title }) => {
  const { initValues } = useGetOne(id_row);

  const formPlantilla = useFormik({
    initialValues: initValues,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      setTimeout(() => {}, 1000);
    },
  });

  return (
    <Modal
      id="kt_modal_create"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog mw-900px"
      show={show}
      onHide={() => {
        handleClose(false);
      }}
    >
      <div className="modal-content">
        <div className="modal-header">
          <h2>{title}</h2>
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            onClick={() => {
              handleClose(false);
            }}
          >
            <KTSVG
              className="svg-icon-2"
              path="/media/icons/duotune/arrows/arr061.svg"
            />
          </div>
        </div>
        <div className="modal-body scroll-y mx-5">
          <form
            id="kt_modal_generic_form"
            className="form"
            action="#"
            onSubmit={formPlantilla.handleSubmit}
          >
            <div id="kt_app_content_container" className="app-container ">
              <div className="d-flex flex-column " style={{ padding: '0px' }}>
                <div className="flex-lg-row-fluid ms-lg-7 ms-xl-10">
                  <div className="card">
                    <div className="card-body p-0">
                      <div className="d-block">
                        <div className="border-bottom">
                          <input
                            className="form-control form-control-transparent border-0 px-8 min-h-45px"
                            {...formPlantilla.getFieldProps('nombre')}
                            name="nombre"
                            placeholder="Nombre"
                            readOnly
                          />
                        </div>
                        {/* </div> */}

                        <div className="border-bottom">
                          <input
                            className="form-control form-control-transparent border-0 px-8 min-h-45px"
                            {...formPlantilla.getFieldProps('encabezado')}
                            name="encabezado"
                            placeholder="Encabezado"
                            readOnly
                          />
                          {formPlantilla.touched.encabezado &&
                            formPlantilla.errors.encabezado && (
                              <div className="fv-plugins-message-container text-danger border-0 px-8">
                                <span role="alert">
                                  {formPlantilla.errors.encabezado}
                                </span>
                              </div>
                            )}
                        </div>
                        <div className="border-bottom">
                          <input
                            className="form-control form-control-transparent border-0 px-8 min-h-45px"
                            {...formPlantilla.getFieldProps('asunto')}
                            name="asunto"
                            placeholder="Asunto"
                            readOnly
                          />
                        </div>

                        <div className="mt-8">
                          <div
                            id="kt_inbox_form_editor"
                            className="  border-0 h-350px px-3 ql-container ql-snow border-0 px-8 min-h-45px"
                          >
                            <textarea
                              style={{
                                width: '100%',
                                height: '100%',
                                resize: 'none',
                                outline: 'none',
                                border: 'solid 1px #D6DBDF',
                              }}
                              {...formPlantilla.getFieldProps('cuerpo')}
                              name="cuerpo"
                              placeholder="Ingresa el texto de la plantilla aquí..."
                              readOnly
                            ></textarea>
                            {formPlantilla.touched.cuerpo &&
                              formPlantilla.errors.cuerpo && (
                                <div className="fv-plugins-message-container text-danger ">
                                  <span role="alert">
                                    {formPlantilla.errors.cuerpo}
                                  </span>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>

                      <div className="border-bottom mt-3">
                        {/* <input
                          className="form-control form-control-transparent border-0 px-8 min-h-45px"
                          {...formPlantilla.getFieldProps('firma')}
                          name="firma"
                          placeholder="Firma"
                          readOnly
                        /> */}
                        <div className="border-0  px-3 ql-container ql-snow border-0 px-8 min-h-45px mt-10">
                          {/* <input
                          className="form-control form-control-transparent border-0 px-8 min-h-45px"
                          {...formPlantilla.getFieldProps('firma')}
                          name="firma"
                          placeholder="Firma"
                        /> */}
                          <textarea
                            style={{
                              width: '100%',
                              resize: 'none',
                              outline: 'none',
                              border: 'solid 1px #D6DBDF',
                            }}
                            {...formPlantilla.getFieldProps('firma')}
                            // value={text}
                            name="firma"
                            placeholder="Ingresa la firma aquí..."
                            rows={10}
                            readOnly
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end mt-10">
              <button
                type="reset"
                className="btn btn-primary me-3"
                data-kt-users-modal-action="cancel"
                onClick={() => {
                  formPlantilla.resetForm();
                  handleClose(false);
                }}
              >
                Cerrar
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};
export { DetailModal };
