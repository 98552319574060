import React, { useState, useEffect } from 'react';
import { Pagination } from '../../../../../_metronic/helpers';
import { MenuComponent } from '../../../../../_metronic/assets/ts/components/MenuComponent';
import { shallowEqual, useSelector } from 'react-redux';
import { UserModel } from '../../../../modules/auth/models/UserModel';
import { RootState } from '../../../../../setup';
import { KTSVG } from '../../../../../_metronic/helpers';
import { Tooltip } from '@mui/material';
import { encryptId } from '../../../../../_metronic/helpers';
import { TableList } from '../../../../../_metronic/helpers/components/TableList';
import { EmptyResult } from '../../../../../_metronic/helpers/components/EmptyResult';

type Props = {
  data: any;
  count: number;
  currentPage: number;
  totalPages: number;
  valueFilter: string;
  onEditRegister: (row: any) => void;
  onRemoveRegister: (row: any) => void;
  onPageChange: (pageNumber: number) => void;
  loadingGrid: boolean;
  // code: string;
};

const ListGrid: React.FC<Props> = ({
  data,
  count,
  currentPage,
  totalPages,
  onEditRegister,
  onRemoveRegister,
  valueFilter,
  onPageChange,
  loadingGrid,
  // code,
}) => {
  const user: UserModel = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  ) as UserModel;
  const userHasRole = (roles: string[]) => {
    const luser: UserModel = user;
    let hasRole: boolean = false;

    roles.forEach((element) => {
      if (luser?.roles?.some((e) => e.code === element)) {
        hasRole = true;
      }
    });
    return hasRole;
  };
  const [isCatStage, setIsCatStage] = useState<boolean>(false);

  setTimeout(() => {
    MenuComponent.reinitialization();
  }, 1000);

  // useEffect(() => {
  //   setIsCatStage(false);
  //   if (code === 'ETP') {
  //     setIsCatStage(true);
  //   }
  // }, [code]);

  const columns: any = [
    {
      id: 'codigo',
      name: <div className="fw-bolder text-muted me-5">Código</div>,
      selector: (row: any) => row.codigo,
      cell: (row: any) => (
        <div className="large-text fw-bold text-primary">
          {row.codigo ? row.codigo : 'No registrado'}
        </div>
      ),
      sortable: true,
      width: '300px',
    },
    {
      id: 'nombre',
      name: <div className="fw-bolder text-muted me-5">Nombre</div>,
      selector: (row: any) => row.nombre,
      cell: (row: any) => (
        <div className="text-muted large-text">
          {row.nombre ? row.nombre : 'No registrado'}
        </div>
      ),
      sortable: true,
      width: '350px',
    },

    {
      id: 'orden',
      name: <div className="fw-bolder text-muted me-5">Orden</div>,
      selector: (row: any) => row.orden,
      cell: (row: any) => (
        <div className="text-muted large-text">
          {row.orden ? row.orden : 'No registrado'}
        </div>
      ),
      sortable: true,
      width: '300px',
    },
    {
      name: <div className="fw-bolder text-muted me-5">Acciones</div>,
      cell: (row: any) => dropdownRender(row),
      sortable: true,
      width: '200px',
    },
  ];

  const dropdownRender = (row: any) => {
    return (
      <>
        {/* {userHasRole(['ADMIN']) && code != 'TMO' && ( */}
        <div className="d-flex justify-content-end flex-shrink-0">
          <a
            href="#/"
            data-kt-menu="true"
            className="btn btn-light btn-active-light-primary btn-sm"
            data-kt-menu-trigger="click"
            data-kt-menu-placement="bottom-end"
          >
            Acciones
            <span className="svg-icon svg-icon-5 m-0">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="mh-50px"
              >
                <path
                  d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z"
                  fill="currentColor"
                ></path>
              </svg>
            </span>
          </a>
          <div
            className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4"
            data-kt-menu="true"
          >
            <div className="menu-item px-3">
              <a
                className="menu-link px-3"
                href="#/"
                onClick={() => {
                  onEditRegister(row.id);
                }}
              >
                Editar
              </a>
              <a
                className="menu-link px-3"
                data-kt-users-table-filter="delete_row"
                href="#/"
                onClick={() => {
                  onRemoveRegister(row);
                }}
              >
                Eliminar
              </a>
            </div>
          </div>
        </div>
        {/* )} */}
      </>
    );
  };

  return (
    <>
      <div className={`row gx-5 gx-xl-10`}>
        <div className="col-xl-12">
          <div className={`card mb-0 mb-xxl-8 h-100`}>
            {!loadingGrid ? (
              <TableList data={data} columns={columns}></TableList>
            ) : (
              <EmptyResult title="" loading={loadingGrid} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export { ListGrid };
