import { FC } from "react";
import { PageTitle, PageLink } from "../../../_metronic/layout/core";
import { useParams } from "react-router-dom";
import { decryptId } from "../../../_metronic/helpers";
import { View } from "./components/View";

const ServicesPage: FC = () => {
  const { id } = useParams<{ id: string }>();
  const serviceId = decryptId(id);

  const breadCrumbs: Array<PageLink> = [
    {
      title: "Cotizaciones",
      path: "/cotizaciones",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
    {
      title: "Servicios",
      path: `/cotizaciones/servicios`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
  ];
  return (
    <>
      <PageTitle breadcrumbs={breadCrumbs}>
        {serviceId ? "Editar servicio" : "Nuevo servicio"}
      </PageTitle>

      <View id={serviceId ? Number(serviceId) : 0} />
    </>
  );
};

export { ServicesPage };
