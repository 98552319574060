import React, { useState, useEffect, useRef } from 'react';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import Select from 'react-select';
import { KTSVG, SimpleAlert } from '../../../../_metronic/helpers';
import { useSelectGeneric } from '../../catalogos/generico/hooks/genericoHook';
import {
  useEditTask,
  useGetDocumentsByTaskId,
  useGetTaskByID,
  useGetUsersTasks,
  usePauseTask,
} from '../hooks/TaskHook';
import { TaskDoc } from './TaskDoc';
import {
  colorStatusOpportunity,
  colorStatusTask,
} from '../../../../_metronic/helpers/CustomElements';
// import { useGetAllVendedoresComercialesByUser } from '../../oportunidades/hooks/OportunidadHook';
import { MenuComponent } from '../../../../_metronic/assets/ts/components/MenuComponent';

type Props = {
  id: string;
  oportunityId: string;
  onRefreshHistory: () => void;
  onDisabledHistory: () => void;
};

const TaskForm: React.FC<Props> = ({
  id,
  oportunityId,
  onRefreshHistory,
  onDisabledHistory,
}) => {
  const [formIsEdit, setFormIsEdit] = useState<boolean>(false);

  const [reloadInfoCurrentTask, setReloadInfoCurrentTask] = useState(
    Math.random() * 40
  );
  const [reloadDocuments, setReloadDocuments] = useState(Math.random() * 40);
  const { taskInit } = useGetTaskByID(id, reloadInfoCurrentTask);
  const { dataDocuments, loadingDocuments } = useGetDocumentsByTaskId(
    id,
    reloadDocuments
  );
  const { catalog: etapa } = useSelectGeneric('ETAR');
  const { catalog: priority } = useSelectGeneric('PRIO');
  const { catalog: status } = useSelectGeneric('STST');
  const { editTask, loadingEditTask } = useEditTask();
  const { pauseTask, loadingPauseTask } = usePauseTask();

  const history = useHistory();
  const tabSection = useRef<HTMLDivElement>(null);
  const [tab, setTab] = useState<string | 'ACTIONS'>('ACTIONS');
  const { dataSelect } = useGetUsersTasks();

  const [loading, setLoading] = useState(false);
  let today = new Date().toISOString().split('T')[0];

  const taskSchema = Yup.object().shape({
    fecha_inicio: Yup.date()
      // .min(today, 'Debe ser igual o mayor a la fecha actual')
      .nullable()
      .required('La fecha es requerida'),
    fecha_fin: Yup.date()
      .min(Yup.ref('fecha_inicio'), 'No puede ser menor fecha inicio.')
      .required('La fecha de fin es requerida'),
    etapa_id: Yup.number()
      .min(1, 'Debe seleccionar una etapa.')
      .required('Etapa requerido.'),
    // user_asignado_id: Yup.number()
    //   .min(1, 'Debe seleccionar una vendedor.')
    //   .required('Vendedor requerido.'),
    prioridad_id: Yup.number()
      .min(1, 'Debe seleccionar una prioridad.')
      .required('Prioridad requerido.'),
    status_tarea_id: Yup.number()
      .min(1, 'Debe seleccionar un estatus.')
      .required('Estatus requerido.'),
  });
  const taskForm = useFormik({
    initialValues: {
      ...taskInit,
      fecha_inicio: taskInit.fecha_inicio || today,
      fecha_fin: taskInit.fecha_fin || today,
    },
    validationSchema: taskSchema,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      setLoading(true);
      editTask(values, Number(oportunityId));
    },
  });

  useEffect(() => {
    if (taskForm.values.etapa?.code === 'TCOMPL') {
      setFormIsEdit(true);
      onDisabledHistory();
    }
  }, [taskForm.values.etapa]);

  useEffect(() => {
    if (loadingEditTask) {
      history.goBack();
      SimpleAlert('Se actualizó la tarea exitosamente!', 3000, 'success');
      setLoading(false);
      setReloadInfoCurrentTask(Math.random() * 40);
      onRefreshHistory();
    }
  }, [loadingEditTask]);

  useEffect(() => {
    if (loadingPauseTask) {
      setReloadInfoCurrentTask(Math.random() * 40);
      onRefreshHistory();
    }
  }, [loadingPauseTask]);

  const userSelected = (selectedOption: any) => {
    const { value } = selectedOption;
    taskForm.setFieldValue('user_asignado_id', value);
  };

  const renderTaskStatus = (estatus: any, id: number) => {
    let renderContent = null;

    switch (estatus.code) {
      case 'TENCUR':
      case 'TRET':
        renderContent = (
          <div className="menu-item px-3">
            <a
              className="menu-link px-3"
              data-kt-users-table-filter="delete_row"
              onClick={() => {
                pauseTask(id.toString(), 0);
              }}
            >
              <KTSVG
                path="/media/icons/duotune/general/pause.svg"
                className="svg-icon-2 mr-05"
              />
              Pausar tarea
            </a>
          </div>
        );
        break;
      case 'TPAUSE':
        renderContent = (
          <div className="menu-item px-3">
            <a
              className="menu-link px-3"
              data-kt-users-table-filter="delete_row"
              onClick={() => {
                pauseTask(id.toString(), 1);
              }}
            >
              <KTSVG
                path="/media/icons/duotune/general/play.svg"
                className="svg-icon-2 mr-05"
              />
              Reanudar tarea
            </a>
          </div>
        );
        break;
      default:
        break;
    }
    return renderContent;
  };

  setTimeout(() => {
    MenuComponent.reinitialization();
  }, 1000);

  return (
    <div className="card mb-6 mb-xl-9">
      <div className="card-body">
        <form
          id="kt_account_form"
          className="form"
          action="#"
          noValidate
          onSubmit={taskForm.handleSubmit}
        >
          <div className="row">
            <div className="col-sm-10 col-md-10 col-lg-11 col-xl-11 col-xxl-11 p-0">
              <div className="w-100 pe-5">
                <input
                  disabled={formIsEdit}
                  className="form-control mb-2 w-100 border-0"
                  style={{
                    width: '100%',
                    padding: '15px',
                    fontSize: '24px',
                    boxSizing: 'border-box',
                    marginBottom: '20px',
                  }}
                  data-kt-element="input"
                  placeholder="Nombre *"
                  {...taskForm.getFieldProps('nombre')}
                ></input>
              </div>
            </div>
            <div className="col-sm-2 col-md-2 col-lg-1 col-xl-1 col-xxl-1 p-0">
              {(taskForm.values.statusTarea?.code === 'TENCUR' ||
                taskForm.values.statusTarea?.code === 'TRET' ||
                taskForm.values.statusTarea?.code === 'TPAUSE') && (
                <div className="d-flex mb-4 justify-content-end me-8">
                  <div className="d-flex justify-content-end flex-shrink-0">
                    <a
                      href="#/"
                      data-kt-menu="true"
                      className="btn btn-sm btn-icon btn-light-primary "
                      data-kt-menu-trigger="hover"
                      data-kt-menu-placement="bottom-end"
                      title="Opciones"
                    >
                      <KTSVG
                        path="/media/icons/duotune/general/gen052.svg"
                        className="svg-icon-5 svg-icon-gray-500 me-1"
                      />
                    </a>
                    <div
                      className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-200px py-4"
                      data-kt-menu="true"
                    >
                      {renderTaskStatus(
                        taskForm.values.statusTarea,
                        taskForm.values.id
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
              <div className="w-100 pe-5">
                <div className="w-100 d-flex">
                  <label className="required form-label w-auto">Etapa</label>
                </div>
                <select
                  disabled={formIsEdit}
                  className="form-select mb-3"
                  data-kt-select2="true"
                  data-placeholder="Select option"
                  data-allow-clear="true"
                  {...taskForm.getFieldProps('etapa_id')}
                >
                  <option value={0}>Seleccione</option>
                  {etapa.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
                {taskForm.touched.etapa_id && taskForm.errors.etapa_id && (
                  <div className="fv-plugins-message-container text-danger w-100 mb-3">
                    <span role="alert">{taskForm.errors.etapa_id}</span>
                  </div>
                )}
              </div>
            </div>
            {/* d-flex flex-column align-items-center justify-content-center */}
            <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 d-flex flex-column ">
              <div className="w-100 pe-5">
                <div className="w-100 d-flex">
                  <label className="required form-label w-auto">
                    Fecha inicio
                  </label>
                </div>
                <input
                  disabled={formIsEdit}
                  className={'form-control mb-3'}
                  data-kt-element="input"
                  type="date"
                  min={today}
                  name="fecha_inicio"
                  value={taskForm.values.fecha_inicio}
                  onChange={(e) => {
                    taskForm.setFieldValue('fecha_inicio', e.target.value);
                  }}
                />
                {taskForm.touched.fecha_inicio && taskForm.errors.fecha_inicio && (
                  <div className="fv-plugins-message-container text-danger w-100 mb-3">
                    <span role="alert">{taskForm.errors.fecha_inicio}</span>
                  </div>
                )}
              </div>
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
              <div className="w-100 pe-5">
                <div className="w-100 d-flex">
                  <label className="form-label w-auto">Fecha fin</label>
                </div>
                <input
                  disabled={formIsEdit}
                  className={'form-control mb-3'}
                  data-kt-element="input"
                  type="date"
                  min={taskForm.values.fecha_inicio}
                  name="fecha_fin"
                  value={taskForm.values.fecha_fin}
                  onChange={(e) => {
                    taskForm.setFieldValue('fecha_fin', e.target.value);
                  }}
                />
                {taskForm.touched.fecha_fin && taskForm.errors.fecha_fin && (
                  <div className="fv-plugins-message-container text-danger w-100 mb-3">
                    <span role="alert">{taskForm.errors.fecha_fin}</span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 d-flex flex-column">
              <label className="form-label w-auto ">Asignado a</label>
              <Select
                isDisabled={formIsEdit}
                options={dataSelect}
                value={
                  taskForm.values.user_asignado_id
                    ? dataSelect.find(
                        (option) =>
                          option.id === taskForm.values.user_asignado_id
                      )
                    : null
                }
                styles={{
                  placeholder: (provided: any, state: any) => ({
                    ...provided,
                    color: '#181c32',
                    textAlign: 'center',
                  }),
                  indicatorSeparator: (provided: any, state: any) => ({
                    ...provided,
                    display: 'none',
                  }),
                  dropdownIndicator: (provided: any, state: any) => ({
                    ...provided,
                    color: '#9096EC',
                    display: 'none',
                  }),
                  control: (provided: any, state: any) => ({
                    ...provided,
                    border: 'none',
                    borderRadius: '6px',
                    marginBottom: '3px',
                    padding: '3px',
                    fontSize: '1rem',
                    boxShadow: 'none',
                    backgroundColor: state.isDisabled ? '#EFF2F5' : 'white',
                  }),
                  singleValue: (provided: any, state: any) => ({
                    ...provided,
                    color: state.isDisabled ? '#181c32' : 'inherit',
                    fontWeight: 500,
                  }),
                  option: (provided: any, state: any) => ({
                    ...provided,
                    fontSize: '1.0rem',
                    textAlign: 'center',
                  }),
                }}
                formatOptionLabel={(option) => (
                  <div
                    className="d-flex"
                    style={{ alignItems: 'center', width: '100%' }}
                  >
                    <div className="symbol symbol-30px">
                      <span
                        className="symbol-label fs-7 fw-bold bg-light-primary text-primary fw-bold"
                        // title={option.label}
                      >
                        {option.data.first_name && option?.data.first_name[0]}
                        {option.data.father_last_name &&
                          option?.data.father_last_name[0]}
                      </span>
                    </div>
                    <span className="ms-3 ">
                      {option.data.first_name} {option.data.father_last_name}{' '}
                      {option.data?.mother_last_name}
                    </span>
                  </div>
                )}
                placeholder={
                  <span className="symbol symbol-30px cursor-pointer">
                    <div className="symbol-label">
                      <KTSVG
                        path="/media/icons/duotune/communication/com006.svg"
                        className="svg-icon-1"
                      />
                    </div>
                  </span>
                }
                isSearchable={true}
                noOptionsMessage={() => {
                  return <span>{'No se encontraron registros'}</span>;
                }}
                onChange={(event) => userSelected(event)}
              />
              {taskForm.touched.user_asignado_id &&
                taskForm.errors.user_asignado_id && (
                  <div className="fv-plugins-message-container text-danger w-100 mt-3 mb-3">
                    <span role="alert">{taskForm.errors.user_asignado_id}</span>
                  </div>
                )}
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
              <div className="w-100 pe-5">
                <div className="w-100 d-flex">
                  <label className="required form-label w-auto">
                    Prioridad
                  </label>
                </div>
                <select
                  disabled={formIsEdit}
                  className="form-select mb-3"
                  data-kt-select2="true"
                  data-placeholder="Select option"
                  data-allow-clear="true"
                  {...taskForm.getFieldProps('prioridad_id')}
                >
                  <option value={0}>Seleccione</option>
                  {priority.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
                {taskForm.touched.prioridad_id && taskForm.errors.prioridad_id && (
                  <div className="fv-plugins-message-container text-danger w-100 mb-3">
                    <span role="alert">{taskForm.errors.prioridad_id}</span>
                  </div>
                )}
              </div>
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
              <div className="w-100 pe-5">
                <div className="w-100 d-flex">
                  <label className="required form-label w-auto">Estatus</label>
                </div>
                <div
                  className={`mt-4 ${
                    taskInit.statusTarea
                      ? colorStatusTask(taskInit.statusTarea.code || '')
                      : ''
                  }`}
                >
                  {taskInit?.statusTarea ? taskInit.statusTarea.name : 'N/A'}{' '}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 ">
              <div className="w-100 pe-5">
                <div className="w-100 d-flex">
                  <label className="form-label w-auto">Descripción</label>
                </div>
                <textarea
                  disabled={formIsEdit}
                  className="form-control w-100"
                  data-kt-element="input"
                  rows={5}
                  style={{ resize: 'none' }}
                  placeholder=""
                  {...taskForm.getFieldProps('descripcion')}
                ></textarea>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end mt-10">
            <button
              type="reset"
              className="btn btn-light me-3"
              data-kt-users-modal-action="cancel"
              onClick={() => {
                history.goBack();
              }}
            >
              Cancelar
            </button>
            <button
              type="submit"
              className="btn btn-primary"
              data-kt-users-modal-action="submit"
              disabled={loading || formIsEdit}
            >
              {!loading && <span className="indicator-label">Guardar</span>}
              {loading && (
                <span
                  className="indicator-progress"
                  style={{ display: 'block' }}
                >
                  Espere por favor...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>
          </div>
          <div className="row g-6 g-xl-9" ref={tabSection}>
            <div className="col-12">
              <ul className="nav nav-line-tabs nav-line-tabs-2x border-transparent fs-7 fw-bolder flex-nowrap">
                <li className="nav-item cursor-pointer">
                  <a
                    className={`nav-link me-6 ${
                      tab === 'ACTIONS' ? 'text-active-primary active' : ''
                    } `}
                    onClick={() => {
                      setTab('ACTIONS');
                    }}
                  >
                    <span className="margin-left-05">Documentos</span>
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-12">
              {tab === 'ACTIONS' ? (
                <TaskDoc
                  documents={dataDocuments || []}
                  onRefreshData={() => {
                    setReloadDocuments(Math.random() * 100);
                    onRefreshHistory();
                  }}
                  task={taskInit.id}
                  isEdit={formIsEdit}
                ></TaskDoc>
              ) : null}
              {/* <Link
                    className="menu-link px-3"
                    to={`/oportunidades_tareas/${encryptId(
                      id_row.toString(),
                      secretKey
                    )}/edit/${encryptId(id_row.toString(), secretKey)}`}
                  >
                    Editar
                  </Link> */}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
export { TaskForm };
