import axios from 'axios';
const API_URL = process.env.REACT_APP_API;

export async function getTaxes() {
  const response = await axios.get(`${API_URL}catalogs/cotizaciones/impuestos`);
  const data = response.data.doc.data;
  // const count = response.data.doc.data.count;
  return data;
}

export async function addValueTaxe(body: any) {
  let porcentaje = body.porcentaje / 100;

  const response = await axios.post(
    `${API_URL}catalogs/cotizaciones/impuestos`,
    {
      siglas: body.siglas,
      descripcion: body.descripcion,
      porcentaje: porcentaje,
    }
  );
  return response;
}

export async function updValueTaxe(body: any) {
  let porcentaje = body.porcentaje / 100;

  const data = {
    siglas: body.siglas,
    descripcion: body.descripcion,
    porcentaje: porcentaje,
  };

  const response = await axios.patch(
    `${API_URL}catalogs/cotizaciones/impuestos/${body.id}`,
    data
  );
  return response;
}

export async function getDataTaxeById(id_row: number) {
  const response = await axios.get(
    `${API_URL}catalogs/cotizaciones/impuestos/${id_row}`
  );
  const data = response.data.doc.data;

  if (data.porcentaje !== undefined) {
    data.porcentaje *= 100;
  }
  return data;
}

export async function removeRegisterTaxe(id: number) {
  const response = await axios.delete(
    `${API_URL}catalogs/cotizaciones/impuestos/${id}`
  );

  return response;
}
