import React from 'react';
import { KTSVG } from '../../../../_metronic/helpers';
import { OportunidadModel } from '../../oportunidades/models/OportunidadModel';
import { DownloadExcel } from '../../../../_metronic/helpers/DownloadExcelHelper';
import { formatDate } from '../../../../_metronic/helpers/FormatDate';

type Props = {
  data: any;
};

const DownloadFileExcel: React.FC<Props> = ({ data }) => {
  const ScoredCard: Array<OportunidadModel> = data;

  const fileDownload = () => {
    let columnsName = [
      'Folio',
      'Cuenta',
      'Subcuenta',
      'Oportunidad',
      'Vendedor Comercial',
      // 'Vendedor Técnico',
      'Fecha Creación',
      'Fecha Aprobación Estimada',
      'Status',
      'Etapa',
      'Probabilidad de Éxito',
      'Tareas cerradas',
      'Tareas abiertas',
      'Riesgos y Dependencias',
      'Mitigación',
      'Siguientes Pasos',
    ];

    let bodyFile: any = ScoredCard.map((item) => {
      return {
        folio: item.id,
        cuenta: item.cuenta?.nombre,
        subcuenta: item.subCuenta ? item.subCuenta?.nombre : '',
        oportunidad: item.nombre,
        vendedorC: `${item.vendedorComercial?.nombre} ${item.vendedorComercial?.apellido_paterno} ${item.vendedorComercial?.apellido_materno}`,
        // vendedorT:
        //   item.vendedorTecnico?.nombre &&
        //     item.vendedorTecnico?.apellido_paterno &&
        //     item.vendedorTecnico?.apellido_materno
        //     ? `${item.vendedorTecnico?.nombre} ${item.vendedorTecnico?.apellido_paterno} ${item.vendedorTecnico?.apellido_materno}`
        //     : '',
        fechaCreacion: formatDate(
          new Date(item.created_at ? item.created_at : '')
        ),
        fechaAprobacion: formatDate(
          new Date(item.created_at ? item.created_at : '')
        ),
        status: item.status?.name,
        etapa: item.etapa?.name,
        probabilidad: `${item?.probabilidad}%`,
        tareas_cerradas: item?.tareasCerradas,
        tareas_abiertas: item?.tareasAbiertas,
        riesgos: item.riesgos_dependencias ? item.riesgos_dependencias : '',
        mitigacion: item.mitigaciones ? item.mitigaciones : '',
        sigPasos: item.siguentes_pasos ? item.siguentes_pasos : '',
      };
    });

    const response = {
      sheetName: 'ScoreCard',
      fileName: 'ScoreCard.xlsx',
      title: 'ScoreCard',
      columnsName: columnsName,
      columns: [
        {
          key: 'folio',
          width: 20,
        },
        {
          key: 'cuenta',
          width: 20,
        },
        {
          key: 'subcuenta',
          width: 20,
        },
        {
          key: 'oportunidad',
          width: 20,
        },
        {
          key: 'vendedorC',
          width: 20,
        },
        // {
        //   key: 'vendedorT',
        //   width: 20,
        // },
        {
          key: 'fechaCreacion',
          width: 20,
        },
        {
          key: 'fechaAprobacion',
          width: 20,
        },
        {
          key: 'status',
          width: 20,
        },
        {
          key: 'etapa',
          width: 20,
        },
        {
          key: 'probabilidad',
          width: 20,
        },
        {
          key: 'tareas_cerradas',
          width: 20,
        },
        {
          key: 'tareas_abiertas',
          width: 20,
        },
        {
          key: 'riesgos',
          width: 20,
        },
        {
          key: 'mitigacion',
          width: 20,
        },
        {
          key: 'sigPasos',
          width: 20,
        },
      ],
      data: bodyFile,
    };
    DownloadExcel(response);
  };

  return (
    <>
      <div
        className="card-toolbar"
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        data-bs-trigger="hover"
        title="Descargar excel"
      >
        <a
          href="#/"
          className="btn btn-sm btn-light-success ms-5"
          title="Descargar excel"
          onClick={fileDownload}
        >
          <KTSVG
            path="/media/icons/duotune/files/fil009.svg"
            className="svg-icon-5 svg-icon-gray-500 me-1"
          />
          Excel
        </a>
      </div>
    </>
  );
};

export { DownloadFileExcel };
