import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  createHistoryMessage,
  createMitigateRisk,
  getHistory,
  getRiskByID,
  getTasksByRiskID,
  updateRisk,
  getDocumentsByRiskId
} from '../../../service/risks/Risks';
import { RiskModel, RiskDocModel } from '../models/RiskModel';
import { HistoryModel } from '../../../../_metronic/helpers/models/HistoryModel';
import { MitigateModel } from '../../mitigate/models/MitigateModel';

export const useGetRiskByID = (id: string, reload: number) => {
  const risk: RiskModel = {
    id: 0,
    nombre: '',
    descripcion: '',
    fecha_inicio: new Date().toISOString().split('T')[0],
    fecha_fin: new Date().toISOString().split('T')[0],
    statusRiesgo: null,
    etapaRiesgo: null,
    prioridadRiesgo: null,
    oportunidad: null,
    vendedorComercial: {
      user_asignado: null,
    },
    user_creador: null,
    status_id: 0,
    etapa_id: 0,
    prioridad_id: 0,
    user_asignado_id: 0,
    documentos: null,
  };
  const [riskInit, setRiskInit] = useState<RiskModel>(risk);

  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      const res = await getRiskByID(id)
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        setRiskInit(res);
      }
    };
    if (id) {
      fetchPost();
    } else {
      setRiskInit(risk);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, reload]);

  return { riskInit };
};

export const useEditRisk = () => {
  const [loadingEditRisk, setLoadingEditRisk] = useState<boolean>(false);
  const history = useHistory();
  const editRisk = async (risk: RiskModel, oportunityId: number) => {
    setLoadingEditRisk(false);
    try {
      await updateRisk(risk, oportunityId);
      setLoadingEditRisk(true);
    } catch (error) {
      history.push('/error/500');
      setLoadingEditRisk(false);
    }
  };
  return { editRisk, loadingEditRisk };
};

export const useGetHistory = (reaload: number, search: string, id: string) => {
  const [data, setData] = useState<HistoryModel[]>([]);
  const [loadingRequest, setLoadingRequest] = useState(0);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      setLoadingRequest(0);
      const res = await getHistory(search, id)
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        setData(res);
        setLoadingRequest(1);
      }
    };
    if (reaload && Number(id) > 0) {
      fetchPost();
    }
  }, [reaload]);

  return { data, loadingRequest };
};

export const useInsertMessage = () => {
  const [loadingInsert, setLoadingInsert] = useState<boolean>(false);
  const history = useHistory();
  const insertData = async (message: string, id: string) => {
    setLoadingInsert(false);
    try {
      await createHistoryMessage(message, id);
      setLoadingInsert(true);
    } catch (error) {
      history.push('/error/500');
      setLoadingInsert(false);
    }
  };
  return { insertData, loadingInsert };
};

export const useGetMitigateByRiskID = (
  riskId: string,
  realoadMitigate: number
) => {
  const [mitigates, setMitigates] = useState<MitigateModel[]>([]);
  const [loadingMitigate, setLoadingMitigate] = useState<boolean>(false);
  const history = useHistory();
  useEffect(() => {
    setLoadingMitigate(false);
    const fetchPost = async () => {
      try {
        const res = await getTasksByRiskID(riskId);
        if (res) {
          setMitigates(res);
          setLoadingMitigate(true);
        }
      } catch (error) {
        history.push('/error/500');
        setLoadingMitigate(false);
      }
    };
    fetchPost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [riskId, realoadMitigate]);

  return { mitigates, loadingMitigate };
};
export const useInsertMitigateRiks = () => {
  const [loadingInsertMitigate, setLoadingInsertMitigate] =
    useState<boolean>(false);
  const history = useHistory();
  const insertMitigate = async (mitigateName: string, riskId: string) => {
    setLoadingInsertMitigate(false);
    try {
      await createMitigateRisk(mitigateName, riskId);
      setLoadingInsertMitigate(true);
    } catch (error) {
      history.push('/error/500');
      setLoadingInsertMitigate(false);
    }
  };
  return { insertMitigate, loadingInsertMitigate };
};

export const useGetDocumentsByRiskId = (riskId: string, reload: number) => {
  const [dataDocuments, setDataDocuments] = useState<RiskDocModel[]>([]);
  const [loadingDocuments, setLoadingDocuments] = useState<boolean>(false);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      setLoadingDocuments(false);
      const res = await getDocumentsByRiskId(riskId)
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        setDataDocuments(res);
        setLoadingDocuments(true);
      }
    };
    fetchPost();
  }, [reload]);

  return { dataDocuments, loadingDocuments };
};