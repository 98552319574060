import React, { useState, useEffect } from 'react';
import { PatternFormat } from 'react-number-format';
import { KTSVG } from '../../../../_metronic/helpers'; 
import { Modal } from 'react-bootstrap-v5';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { addContactoInAcc } from '../../../service/contacto/Contacto';
type Props = {
    show: boolean;
    cuenta: number | null;
    subcuenta: number | null;
    handleClose: (refresh: boolean) => void;
};

const ContactSchema = Yup.object().shape({
    nombre: Yup.string().required('Este campo es requerido.'),
    apellido_paterno: Yup.string().required('Este campo es requerido.'),
    email: Yup.string()
        .email('Formato de correo inválido.')
        .min(3, 'Mínimo 3 caracteres.'),
    telefono: Yup.string()
});

const CreateContactModal: React.FC<Props> = ({ show, handleClose, cuenta, subcuenta }) => {
    const [loading, setLoading] = useState(false);

    const formContact = useFormik({
        initialValues: {
            nombre: '',
            apellido_paterno: '',
            apellido_materno: '',
            email: '',
            telefono: '',
            cuenta_id: cuenta,
            sub_cuenta_id: subcuenta ? subcuenta : null

        },
        validationSchema: ContactSchema,
        enableReinitialize: true,
        onSubmit: (values, { setSubmitting }) => {
            setLoading(true);
            setTimeout(() => {
                addContactoInAcc(values)
                    .then((res) => {
                        const {
                            data: { message, doc: {
                                data: {
                                    id, nombre, apellido_paterno, email, telefono
                                }
                            } },
                        } = res;
                        toast.success(message, {
                            position: 'top-right',
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: 'light',
                        });
                        setLoading(false);
                        formContact.resetForm();
                        handleClose(true);
                    })
                    .catch((err) => {
                        let resMessageToast: string = '';
                        const {
                            data: { message, details = null },
                        } = err.response;
                        resMessageToast = `${message}`;
                        setLoading(false);
                        setSubmitting(false);
                        toast.error(resMessageToast, {
                            position: 'top-right',
                            autoClose: 4000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: 'light',
                        });
                    });

            }, 1000);
        },
    });

    return (
        <Modal
            id="kt_modal_create"
            tabIndex={-1}
            aria-hidden="true"
            backdrop="static"
            dialogClassName="modal-dialog mw-800px"
            show={show}
            onHide={() => {
                formContact.resetForm();
                handleClose(false);
            }}
        >
            <div className="modal-content">
                <div className="modal-header">
                    <h2 className='text-muted'>{'Datos del Contacto'}</h2>
                    <div
                        className="btn btn-sm btn-icon btn-active-color-primary"
                        onClick={() => {
                            formContact.resetForm();
                            handleClose(false);
                        }}
                    >
                        <KTSVG
                            className="svg-icon-2"
                            path="/media/icons/duotune/arrows/arr061.svg"
                        />
                    </div>
                </div>
                <div className="modal-body scroll-y mx-5 padding-top-00">
                    
                    <form
                        id="kt_modal_generic_form"
                        className="form"
                        action="#"
                        onSubmit={formContact.handleSubmit}
                    >

                        <div className="row wh-0100 mb-3">
                            <div className="col-xxl-12 col-xl-12 col-md-12 col-lg-12">
                                <input
                                    className="form-control mb-2 w-100 border-0 padd-left input-name-form"
                                    data-kt-element="input"
                                    placeholder="Nombre *"
                                    {...formContact.getFieldProps('nombre')}
                                ></input>
                                {formContact.touched.nombre &&
                                    formContact.errors.nombre && (
                                        <div className="fv-plugins-message-container text-danger w-100">
                                            <span role="alert">
                                                {formContact.errors.nombre}
                                            </span>
                                        </div>
                                    )}
                            </div>
                        </div>
                        <div className="d-flex flex-column flex-xl-row flex-row-fluid mb-3">
                            <div className="w-100 d-flex">
                                <div className="w-100 pe-5">
                                    <div className="w-100 d-flex">
                                        <label className="required fw-bold fs-6 mb-2 w-auto">
                                            Apellido Paterno
                                        </label>
                                    </div>
                                    <input
                                        className="form-control"
                                        data-kt-element="input"
                                        {...formContact.getFieldProps('apellido_paterno')}
                                    ></input>
                                    {formContact.touched.apellido_paterno &&
                                        formContact.errors.apellido_paterno && (
                                            <div className="fv-plugins-message-container text-danger w-100">
                                                <span role="alert">
                                                    {formContact.errors.apellido_paterno}
                                                </span>
                                            </div>
                                        )}
                                </div>
                                <div className="w-100 pe-5">
                                    <div className="w-100 d-flex">
                                        <label className="fw-bold fs-6 mb-2 w-auto">
                                            Apellido Materno
                                        </label>
                                    </div>
                                    <input
                                        className="form-control"
                                        data-kt-element="input"
                                        {...formContact.getFieldProps('apellido_materno')}
                                    ></input>
                                    {formContact.touched.apellido_materno &&
                                        formContact.errors.apellido_materno && (
                                            <div className="fv-plugins-message-container text-danger w-100">
                                                <span role="alert">
                                                    {formContact.errors.apellido_materno}
                                                </span>
                                            </div>
                                        )}
                                </div>
                            </div>
                        </div>
                        <div className="d-flex flex-column flex-xl-row flex-row-fluid">
                            <div className="w-100 d-flex">
                                <div className="w-100 pe-5">
                                    <div className="w-100 d-flex">
                                        <label className="fw-bold fs-6 mb-2 w-auto">
                                            Correo electrónico
                                        </label>
                                    </div>
                                    <input
                                        {...formContact.getFieldProps('email')}
                                        className="form-control"
                                        type="email"
                                        name="email"
                                    />
                                    {formContact.touched.email && formContact.errors.email && (
                                        <div className="fv-plugins-message-container text-danger w-100">
                                            <span role="alert">{formContact.errors.email}</span>
                                        </div>
                                    )}
                                </div>
                                <div className="w-100 pe-5">
                                    <div className="w-100 d-flex">
                                        <label className="fw-bold fs-6 mb-2 w-auto">
                                            Teléfono
                                        </label>
                                    </div>
                                    <PatternFormat
                                        {...formContact.getFieldProps('telefono')}
                                        className="form-control"
                                        format="## #### ####"
                                        name="telefono"
                                        onChange={(event) => {
                                            formContact.setFieldValue('telefono', event.target.value);
                                        }}
                                    />
                                    {formContact.touched.telefono &&
                                        formContact.errors.telefono && (
                                            <div className="fv-plugins-message-container text-danger w-100">
                                                <span role="alert">
                                                    {formContact.errors.telefono}
                                                </span>
                                            </div>
                                        )}
                                </div>
                            </div>
                        </div>

                        <div className="d-flex justify-content-end mt-10">
                            <button
                                type="reset"
                                className="btn btn-light me-3"
                                data-kt-users-modal-action="cancel"
                                onClick={() => {
                                    formContact.resetForm();
                                    handleClose(false);
                                }}
                            >
                                Cancelar
                            </button>
                            <button
                                type="submit"
                                className="btn btn-primary"
                                data-kt-users-modal-action="submit"
                                disabled={loading}
                            >
                                {!loading && (
                                    <span className="indicator-label">
                                        Guardar
                                    </span>
                                )}
                                {loading && (
                                    <span
                                        className="indicator-progress"
                                        style={{ display: 'block' }}
                                    >
                                        Espere por favor...
                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                )}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </Modal>
    );
};
export { CreateContactModal };
