import { FC } from 'react';
import { PageTitle } from '../../../_metronic/layout/core';
import { View } from './components/View';

const AccountPage: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Cuentas</PageTitle>
      <View />
    </>
  );
};

export { AccountPage };
