import React, { useState, useEffect } from 'react';
import { SubsubAccountForm } from './SubaccountForm';
import { History } from '../../../../_metronic/helpers/components/History';
import { useGetHistorySubaccount } from '../hooks/SubaccountHook';
import { useInsertMessageSubaccount } from '../hooks/SubaccountHook';
import { Toggle } from '../../../../_metronic/partials/layout/explore/Toggle';
// import {
//   useGetHistory,
//   useInsertMessageAccount,
// } from '../hooks/SubaccountHook';

type Props = {
  id: string;
  accountId: string;
};

const ViewAddEdit: React.FC<Props> = ({ id, accountId }) => {
  const [reloadGrid, setReloadGrid] = useState<number>(Math.random() * 40);
  const [search, setSearch] = useState<string>('');
  const { data } = useGetHistorySubaccount(reloadGrid, search, id);
  const { insertData, loadingInsert } = useInsertMessageSubaccount();
  const [showHistory, setShowHistory] = useState<boolean>(true);
  const [showTimerHistory, setShowTimerHistory] = useState<boolean>(true);

  const saveMessage = (message: string) => {
    insertData(message, id);
  };

  useEffect(() => {
    if (loadingInsert) {
      setReloadGrid(Math.random() * 40);
    }
  }, [loadingInsert]);

  const onRefreshData = () => {
    setReloadGrid(Math.random() * 40);
  };

  const historyStyle: React.CSSProperties = {
    transform: showHistory ? 'none' : 'translateX(110%)',
    transition: 'transform 0.4s ease-in-out',
  };

  useEffect(() => {
    if (!showHistory) {
      setTimeout(() => {
        setShowTimerHistory(false);
      }, 400);
    }
  }, [showHistory]);

  useEffect(() => {
    const historyMode = localStorage.getItem('isOpenHistory') || '';
    setShowHistory(historyMode ? JSON.parse(historyMode) : true);
    setShowTimerHistory(historyMode ? JSON.parse(historyMode) : true);
  }, []);

  return (
    <>
      <div className="d-flex flex-column flex-xl-row">
        <div className="flex-lg-row-fluid ">
          <div className="card mb-6 mb-xl-9">
            <div className="card-body">
              <SubsubAccountForm
                id={id}
                onRefreshHistory={onRefreshData}
                accountId={accountId}
              ></SubsubAccountForm>
            </div>
          </div>
        </div>

        <div style={historyStyle}>
          {Number(id) > 0 && showTimerHistory && (
            <>
              <div className="flex-column flex-lg-row-auto w-100 w-xl-400px ms-lg-10">
                <History
                  data={data}
                  onSaveMessage={saveMessage}
                  disabled={false}
                  onShow={() => {
                    setShowHistory(!showHistory);
                    const boolean = !showHistory;
                    localStorage.setItem('isOpenHistory', boolean.toString());
                  }}
                />
              </div>
            </>
          )}
        </div>
        {!showHistory && Number(id) > 0 && (
          <Toggle
            onShow={() => {
              setShowHistory(!showHistory);
              setShowTimerHistory(true);
              const boolean = !showHistory;
              localStorage.setItem('isOpenHistory', boolean.toString());
            }}
          />
        )}
      </div>
    </>
  );
};

export { ViewAddEdit };
