import axios from "axios";
import { CollaboratorModel } from "../../pages/collaborators/models/CollaboratorModel";

const API_URL = process.env.REACT_APP_API;
const codesToFilter: string[] = ["COLA_NOM", "COLA_PERF", "COLA_DEPTO"];

export async function getCatFieldsCollaborators() {
  const response = await axios.get(`${API_URL}catalogs/campos/TBL_COLA`);
  const data = response.data.doc.data;
  const dataFilter = data.filter((obj: any) =>
    codesToFilter.includes(obj.code)
  );
  return dataFilter;
}

export async function getAllCollaborators(filters: any = null, startIndex: number, endIndex: number) {
  let campo_id = null;
  let valor = null;
  if (filters) {
    if (filters.campo_id) {
      campo_id = filters.campo_id;
    }
    if (filters.valor) {
      valor = filters.valor;
    }
  }
  const response = await axios.post(`${API_URL}colaboradores/all?limit=${startIndex}&page=${endIndex}`, {
    campo_id,
    valor,
  });
  const data = response.data.doc.data.rows;
  return {
    data: data,
  };
}

export async function addUpdCollaborator(body: CollaboratorModel) {
  let response;
  const { id, fecha_nacimiento, fecha_ingreso, empleos} = body;

  const isNotEmptyFnac = !!fecha_nacimiento && fecha_nacimiento.trim() !== '';
  body.fecha_nacimiento = isNotEmptyFnac ? fecha_nacimiento: null;

  const isNotEmptyFecIng = !!fecha_ingreso && fecha_ingreso.trim() !== '';
  body.fecha_ingreso = isNotEmptyFecIng ? fecha_ingreso: new Date()
  .toISOString()
  .split('T')[0];

  let formatData;
    let empleosFormatedData = [];

    for(let job of empleos){

      formatData = {
        id: job.key > 0 ? 0 : job.id,
        fecha_inicio: job.fecha_inicio,
        fecha_termino: job.fecha_termino,
        puesto: {id: job.puesto?.id},
        departamento: {id: job.departamento?.id},
        tarifa_unidad_esfuerzo: job.tarifa_unidad_esfuerzo === '' ? null : job.tarifa_unidad_esfuerzo
      }

      empleosFormatedData.push(formatData)
      
    }

   body.empleos = empleosFormatedData;

  if (id) {
    response = await axios.put(`${API_URL}colaboradores/${id}`, body);
  }else{
  response = await axios.post(`${API_URL}colaboradores/`, body);
  }
  return response;
}

export async function getCollaboratorById(
  id: number
): Promise<CollaboratorModel | undefined> {
  try {
    const response = await axios.get(`${API_URL}colaboradores/${id}`);
    const data = response.data.doc.data;

    return data;
  } catch (error) {
    console.error("ERROR:", error);
    return undefined;
  }
}

export async function getDataFilterBySelectFiledCollaborator(code: string) {
  let response;
  let data;

  switch (code) {
    case codesToFilter[0]:
      response = await axios.post(`${API_URL}colaboradores/all`, {
        campo_id: null,
        valor: null,
      });
      data = response.data.doc.data.rows;
      break;
    case codesToFilter[1]:
      response = await axios.get(`${API_URL}catalogs/PASOC?sort=name`);
      data = response.data.doc.data.rows;
      break;
    case codesToFilter[2]:
      response = await axios.get(`${API_URL}catalogs/DEP?sort=name`);
      data = response.data.doc.data.rows;
      break;

    default:
      break;
  }
  return data;
}
