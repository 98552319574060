import axios from 'axios'
import { TaskModel } from '../../pages/tasks/models/TaskModel';
import { UserModel } from '../../modules/auth/models/UserModel';
import { SelectFormat } from '../../../_metronic/helpers/models/GenericModel';
const API_URL = process.env.REACT_APP_API

export const getTaskByID = async (id: string) => {

    const response = await axios.get(`${API_URL}tareas/${id}`);
    let task = response.data.doc.data;
    task = {
        ...task,
        etapa_id: task.etapa?.id | 0,
        prioridad_id:  task.prioridad?.id | 0,
        status_tarea_id:  task.statusTarea?.id | 0,
        user_asignado_id:  task.user_asignado?.id | 0,
        fecha_inicio: task.fecha_inicio ?  new Date(task.fecha_inicio).toISOString().split('T')[0] : new Date().toISOString().split('T')[0],
        fecha_fin: task.fecha_fin ?  new Date(task.fecha_fin).toISOString().split('T')[0] : new Date().toISOString().split('T')[0],
    };
    return task;

}
export async function updateTask(task: TaskModel, oportunidad_id: number) {
    const { id, nombre, prioridad_id, etapa_id, user_asignado_id, fecha_inicio, fecha_fin, descripcion } = task;
    const response = await axios.put(`${API_URL}tareas/${id}`, {
        nombre: nombre,
        oportunidad_id: oportunidad_id,
        etapa_id: etapa_id,
        prioridad_id: prioridad_id,
        user_id: (user_asignado_id && user_asignado_id > 0) ? user_asignado_id: null,
        fecha_inicio: fecha_inicio,
        fecha_fin: fecha_fin,
        descripcion: descripcion
    });
    return response;
}


export async function getUsersTasks() {
    const response = await axios.get(`${API_URL}tareas/getUser/byRole`);
    const data = response.data.doc.data;
    let select : SelectFormat[] = [];
    data.forEach((user: UserModel) => {
        const selectCustom = {
          id: Number(user.id),
          value: user.id.toString(),
          label: `${user.first_name} ${user.father_last_name} ${user?.mother_last_name}`,
          data: user
        };
        select.push(selectCustom);
    });
    return select;

}

export async function getDocumentsByTaskId(taskId: string) {
    const response = await axios.get(`${API_URL}opp/tarea/documento/${taskId}`);
    const data = response.data.doc.data;
    return data;

}

export async function updatePauseTask(taskId: string, isPause: number) {
    const response = await axios.put(`${API_URL}tareas/pausa/${taskId}`);
    return response;
}

