import React, { useState } from 'react';
import { KTSVG } from '../../../../../_metronic/helpers';
import { Modal } from 'react-bootstrap-v5';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { addCuenta } from '../../../../service/account/Account';
import Select from 'react-select';
import { styleSelect } from '../../../../../_metronic/helpers/SelectStyles';
import {
  useCatalogPerCode,
  useDataFormatForSelectComponent,
} from '../../hooks/OportunidadHook';
type Props = {
  show: boolean;
  handleClose: (isNew: boolean, newCuenta: any) => void;
  sellers: any;
};

const CuentaSchema = Yup.object().shape({
  nombre: Yup.string().required('Este campo es requerido.'),
  tipocuentaId: Yup.number()
    .min(1, 'Este campo es requerido.')
    .required('Este campo es requerido.'),
  vendedor_comercial_id: Yup.number()
    .min(1, 'Este campo es requerido.')
    .required('Este campo es requerido.'),
  regimen_id: Yup.number()
    .min(1, 'Este campo es requerido.')
    .required('Este campo es requerido.'),
});

const CreateCuentaModal: React.FC<Props> = ({ show, handleClose, sellers }) => {
  const [loading, setLoading] = useState(false);
  const [vcId, setVCId] = useState<any | null>(null);
  const [optionRegimen, setOptionRegimen] = useState<any | null>(null);
  const [typeAcc, setTypeAcc] = useState<any | null>(null);
  const { dataSelect: account } = useDataFormatForSelectComponent('TCA', 0);
  const { dataSelect: regimen } = useCatalogPerCode('TPREGF');

  const formCuenta = useFormik({
    initialValues: {
      nombre: '',
      tipocuentaId: 0,
      vendedor_comercial_id: 0,
      regimen_id: 0,
    },
    validationSchema: CuentaSchema,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      setLoading(true);
      setTimeout(() => {
        addCuenta(values)
          .then((res) => {
            const {
              data: {
                message,
                doc: {
                  data: { id, nombre },
                },
              },
            } = res;

            toast.success(message, {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light',
            });
            setLoading(false);
            formCuenta.resetForm();
            handleClose(true, {
              value: id,
              label: nombre,
            });
          })
          .catch((err) => {
            let resMessageToast: string = '';
            const {
              data: { message, details = null },
            } = err.response;
            resMessageToast = `${message}`;
            setLoading(false);
            setSubmitting(false);
            toast.error(resMessageToast, {
              position: 'top-right',
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light',
            });
          });
      }, 1000);
    },
  });

  const handleSelect = (selectedOption: any, selectId: string) => {
    const { value } = selectedOption;

    switch (selectId) {
      case 'CUENTA':
        setTypeAcc(selectedOption);
        formCuenta.setFieldValue('tipocuentaId', value);
        break;
      case 'VC':
        setVCId(selectedOption);
        formCuenta.setFieldValue('vendedor_comercial_id', value);
        break;
      case 'REGIMEN':
        setOptionRegimen(selectedOption);
        formCuenta.setFieldValue('regimen_id', value);
        break;
      default:
        break;
    }
  };

  return (
    <Modal
      id="kt_modal_create"
      tabIndex={-1}
      aria-hidden="true"
      backdrop="static"
      dialogClassName="modal-dialog mw-800px"
      show={show}
      onHide={() => {
        formCuenta.resetForm();
        handleClose(false, 0);
      }}
    >
      <div className="modal-content">
        <div className="modal-header">
          <h2 className="text-muted">{'Datos de la cuenta'}</h2>
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            onClick={() => {
              formCuenta.resetForm();
              handleClose(false, 0);
            }}
          >
            <KTSVG
              className="svg-icon-2"
              path="/media/icons/duotune/arrows/arr061.svg"
            />
          </div>
        </div>
        <div className="modal-body mx-5 padding-top-00">
          <form
            id="kt_modal_generic_form"
            className="form"
            action="#"
            onSubmit={formCuenta.handleSubmit}
          >
            <div className="row wh-0100 mb-3">
              <div className="col-xxl-12 col-xl-12 col-md-12 col-lg-12">
                <input
                  className="form-control mb-2 w-100 border-0 padd-left input-name-form"
                  data-kt-element="input"
                  placeholder="Nombre *"
                  {...formCuenta.getFieldProps('nombre')}
                ></input>
                {formCuenta.touched.nombre && formCuenta.errors.nombre && (
                  <div className="fv-plugins-message-container text-danger w-100">
                    <span role="alert">{formCuenta.errors.nombre}</span>
                  </div>
                )}
              </div>
            </div>
            <div className="d-flex flex-column flex-xl-row flex-row-fluid">
              <div className="w-100 d-flex">
                <div className="w-100 pe-5">
                  <div className="w-100 d-flex">
                    <label className="required fw-bold fs-6 mb-2 w-auto">
                      Tipo de Cuenta
                    </label>
                  </div>
                  <div>
                    <Select
                      options={account}
                      styles={styleSelect}
                      placeholder={'Seleccione'}
                      isSearchable={true}
                      noOptionsMessage={() => {
                        return <span>{'No se encontraron registros'}</span>;
                      }}
                      onChange={(event) => handleSelect(event, 'CUENTA')}
                      value={typeAcc}
                    />
                  </div>

                  {formCuenta.touched.tipocuentaId &&
                    formCuenta.errors.tipocuentaId && (
                      <div className="fv-plugins-message-container text-danger w-100">
                        <span role="alert">
                          {formCuenta.errors.tipocuentaId}
                        </span>
                      </div>
                    )}
                </div>
                <div className="w-100 pe-5">
                  <div className="w-100 d-flex">
                    <label className="fw-bold fs-6 mb-2 w-auto required">
                      Vendedor Comercial
                    </label>
                  </div>
                  <div>
                    <Select
                      options={sellers}
                      styles={{
                        placeholder: (provided: any, state: any) => ({
                          ...provided,
                          color: '#181c32',
                          textAlign: 'center',
                        }),
                        indicatorSeparator: (provided: any, state: any) => ({
                          ...provided,
                          display: 'none',
                        }),
                        dropdownIndicator: (provided: any, state: any) => ({
                          ...provided,
                          color: '#9096EC',
                          display: 'none',
                        }),
                        control: (provided: any, state: any) => ({
                          ...provided,
                          border: 'none',
                          borderRadius: '6px',
                          padding: '3px',
                          fontSize: '1rem',
                          boxShadow: 'none',
                          backgroundColor: state.isDisabled
                            ? '#EFF2F5'
                            : 'white',
                        }),
                        singleValue: (provided: any, state: any) => ({
                          ...provided,
                          color: state.isDisabled ? '#181c32' : 'inherit',
                          fontWeight: 500,
                        }),
                        option: (provided: any, state: any) => ({
                          ...provided,
                          fontSize: '1.0rem',
                          textAlign: 'center',
                        }),
                      }}
                      formatOptionLabel={(option) => (
                        <div
                          className="d-flex"
                          style={{ alignItems: 'center', width: '100%' }}
                        >
                          <div className="symbol symbol-30px">
                            <span
                              className="symbol-label fs-7 fw-bold bg-light-primary text-primary fw-bold"
                              title={option.label}
                            >
                              {option?.nombre[0]}
                              {option?.apellidoP[0]}
                            </span>
                          </div>
                          <span className="ms-3 ">
                            {option.nombre} {option.apellidoP}
                          </span>
                        </div>
                      )}
                      placeholder={
                        <span className="symbol symbol-30px cursor-pointer">
                          <div className="symbol-label">
                            <KTSVG
                              path="/media/icons/duotune/communication/com006.svg"
                              className="svg-icon-1"
                            />
                          </div>
                        </span>
                      }
                      isSearchable={false}
                      noOptionsMessage={() => {
                        return <span>{'No se encontraron registros'}</span>;
                      }}
                      onChange={(event) => handleSelect(event, 'VC')}
                      value={vcId}
                    />
                  </div>

                  {formCuenta.touched.vendedor_comercial_id &&
                    formCuenta.errors.vendedor_comercial_id && (
                      <div className="fv-plugins-message-container text-danger w-100">
                        <span role="alert">
                          {formCuenta.errors.vendedor_comercial_id}
                        </span>
                      </div>
                    )}
                </div>
              </div>
            </div>
            <div className="w-50 mt-5 d-flex">
              <div className="w-100 pe-5">
                <div className="w-100 d-flex">
                  <label className="required fw-bold fs-6 mb-2 w-auto">
                    Régimen
                  </label>
                </div>
                <div>
                  <Select
                    options={regimen}
                    styles={styleSelect}
                    placeholder={'Seleccione'}
                    isSearchable={true}
                    noOptionsMessage={() => {
                      return <span>{'No se encontraron registros'}</span>;
                    }}
                    onChange={(event) => handleSelect(event, 'REGIMEN')}
                    value={optionRegimen}
                  />
                </div>

                {formCuenta.touched.regimen_id && formCuenta.errors.regimen_id && (
                  <div className="fv-plugins-message-container text-danger w-100">
                    <span role="alert">{formCuenta.errors.regimen_id}</span>
                  </div>
                )}
              </div>
            </div>
            <div className="d-flex justify-content-end mt-10">
              <button
                type="reset"
                className="btn btn-light me-3"
                data-kt-users-modal-action="cancel"
                onClick={() => {
                  formCuenta.resetForm();
                  handleClose(false, 0);
                }}
              >
                Cancelar
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                data-kt-users-modal-action="submit"
                disabled={loading}
              >
                {!loading && <span className="indicator-label">Guardar</span>}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: 'block' }}
                  >
                    Espere por favor...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};
export { CreateCuentaModal };
