import React, { useRef } from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useGetOnePDF } from '../../cotizacion/hooks/QuotationHook';
import { dateFormatt } from '../../../../_metronic/helpers/FormatDate';
import { formattedCurrency } from '../../../../_metronic/helpers/FormatCurrency';

type Props = {
  id: number;
};

const View: React.FC<Props> = ({ id }) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const { initValues, loadingRequest } = useGetOnePDF(id);
  const generatePDF = () => {
    const content = contentRef.current;
    if (content) {
      html2canvas(content, { scrollY: -window.scrollY, useCORS: true }).then(
        (canvas) => {
          const imgData = canvas.toDataURL('image/png');

          const imgWidth = 208;
          const imgHeight = (canvas.height * imgWidth) / canvas.width;
          const pageHeight = 295;

          const pdf = new jsPDF('p', 'mm', 'a4');
          let position = 0;

          if (imgHeight > pageHeight) {
            let heightLeft = imgHeight;
            while (heightLeft > 0) {
              position = (imgHeight - heightLeft) % pageHeight;
              pdf.addImage(
                imgData,
                'PNG',
                0,
                position,
                imgWidth,
                Math.min(pageHeight, heightLeft)
              );
              heightLeft -= pageHeight;
              if (heightLeft > 0) {
                pdf.addPage();
              }
            }
          } else {
            pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
          }
          pdf.save('Documento.pdf');
        }
      );
    }
  };
  return (
    <>
      <div
        className="stepper stepper-pills stepper-column   d-flex flex-column flex-xl-row flex-row-fluid"
        id="kt_create_account_stepper"
      >
        <div className="d-flex flex-row-fluid flex-center bg-white rounded">
          <div className="w-100 h-100">
            <div ref={contentRef} className="p-0 ">
              <div className="mt-20 mb-5">
                <div className="d-flex justify-content-center mt-20 mb-5">
                  <div className="w-75">
                    <div className="d-flex justify-content-between align-items-center mt-20">
                      <div className="fs-1 fw-bolder text-primary">
                        {initValues.empresa_nombre}
                      </div>
                      <div>Fecha: {dateFormatt(initValues.fecha_creacion)}</div>
                    </div>
                  </div>
                </div>

                <div className="d-flex justify-content-center">
                  <div className="w-75">
                    <div className="d-flex justify-content-between align-items-center mb-9 mt-9">
                      <div className="fs-1 fw-bolder text-primary">
                        {initValues.nombre}
                      </div>
                      <div>
                        <div className="bg-secondary m-1 p-1 fs-5">
                          Folio: {initValues.id}
                        </div>
                        <div className="bg-secondary m-1 p-1 fs-5">
                          Versíon: V1
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-center">
                  <div className="w-75">
                    <div className="d-flex justify-content-between align-items-center mb-11">
                      <div>
                        <div className="fs-3 fw-bold">
                          {initValues.cuenta_nombre || 'sin cuenta'}
                        </div>
                        <div className="text-gray-600">
                          {' '}
                          {initValues.subcuenta_nombre || 'sin subcuenta'}
                        </div>
                        <div>
                          {/* Contacto:{' '} */}
                          {initValues.contacto_nombre || 'sin contacto'}{' '}
                        </div>
                        <div>
                          {initValues.contacto_correo || 'sin correo'} /{' '}
                          {initValues.contacto_telefono || 'sin teléfono'}
                        </div>
                      </div>
                      <div></div>
                    </div>
                  </div>
                </div>
                {/* <div className="d-flex justify-content-center">
                  <div className="w-75 ">Sección 1</div>
                </div> */}
                <div className=" d-flex justify-content-center ">
                  <table className="table gs-7 gy-7 gx-7 w-75">
                    <thead>
                      <tr className="fw-bold fs-6 text-gray-800 border-bottom border-gray-200 bg-secondary">
                        <th>No.</th>
                        <th>Descripción</th>
                        <th>UM</th>
                        <th>Vol.</th>
                        <th>Precio Unitario</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {initValues.concepts.map((item: any, index: number) => {
                        return (
                          <tr key={index}>
                            <td>{item.partida}</td>
                            <td>{item.nombre}</td>
                            <td>{item.unidad_medida_code}</td>
                            <td>{item.volumen}</td>
                            <td>{`${formattedCurrency(
                              item.precio_unitario
                            )}`}</td>
                            <td>{`${formattedCurrency(
                              item.importe_impuesto
                            )}`}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>

                <div className="d-flex justify-content-center mb-6">
                  <div className="w-75">
                    <div
                      style={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                      <div style={{ width: '35%' }}>
                        <div className="separator border-3 my-10"></div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-end align-items-center">
                      <div className="d-flex fs-6">
                        <div style={{ marginRight: '70px' }}>
                          <div className="bg-secondary m-1 p-1">Subtotal:</div>
                          <div className="bg-secondary m-1 p-1">Recargo:</div>
                          <div className="bg-secondary m-1 p-1">Descuento:</div>
                          <div className="bg-secondary m-1 p-1">Impuesto:</div>
                          <div className="bg-secondary m-1 p-1">Total:</div>
                        </div>
                        <div style={{ marginRight: '30px' }}>
                          <div className="m-1 p-1">
                            {`${formattedCurrency(initValues.subtotal)}`}{' '}
                          </div>
                          <div className="m-1 p-1">
                            {`${formattedCurrency(initValues.monto_recargo)}`}{' '}
                          </div>
                          <div className="m-1 p-1">
                            {`${formattedCurrency(initValues.monto_descuento)}`}{' '}
                          </div>
                          <div className="m-1 p-1">
                            {`${formattedCurrency(initValues.monto_impuestos)}`}{' '}
                          </div>
                          <div className="m-1 p-1">
                            {`${formattedCurrency(initValues.total)}`}{' '}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                      <div style={{ width: '35%' }}>
                        <div className="separator border-3 my-10"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-center">
                  <div className="w-75 bg-secondary fs-4 p-1 text-primary fw-bolder">
                    Especificaciones
                  </div>
                </div>
                <div className="d-flex justify-content-center mt-10 fs-5">
                  <div className="w-75 ">
                    <div>
                      {`${initValues.vendedor_nombre} ${initValues.vendedor_apellido_paterno}`}{' '}
                    </div>
                    <div className="text-gray-600 fs-6">
                      {initValues.vendedor_correo
                        ? initValues.vendedor_correo
                        : 'Sin correo'}
                      /{' '}
                      {initValues.vendedor_telefono
                        ? initValues.vendedor_telefono
                        : 'Sin telefono'}
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-center align-items-center">
                  <div className="w-75 fs-5 p-1 text-center text-primary fw-bolder">
                    Powered by {'  '}
                    <img
                      src={process.env.PUBLIC_URL + '/media/logos/logopper.png'}
                      alt="default"
                      className="w-150px pb-4"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                position: 'fixed',
                bottom: '20px',
                right: '20px',
                zIndex: '1000',
              }}
            >
              <button onClick={generatePDF} className="btn btn-primary mt-3">
                Generar PDF
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export { View };
