import React from 'react';
import { HomeCountByUserModel } from '../models/HomeModel';

type Props = {
  dataCount: HomeCountByUserModel;
};

const CardCount: React.FC<Props> = ({ dataCount }) => {
  const { tareas, oportunidades, cotizaciones, ventas } = dataCount;

  return (
    <>
      <div className="card-spacer mt-4">
        <div className="row m-0">
          <div className="col card px-6 py-8 rounded-xl mb-7 mr-15 br-15">
            <div className="center-items font-weight fs-020">Tareas</div>
            <div className="fs-030 center-items text-muted">{tareas}</div>
          </div>
          <div className="col card px-6 py-8 rounded-xl mb-7 br-15">
            <div className="center-items font-weight fs-020">Oportunidades</div>
            <div className="fs-030 center-items text-muted">
              {oportunidades}
            </div>
          </div>
        </div>
        <div className="row m-0">
          {/* <div className="col card px-6 py-8 rounded-xl mr-15 br-15">
                        <div className='center-items font-weight fs-020'>Cotizaciones</div>
                        <div className='fs-030 center-items text-muted '>{cotizaciones}</div>
                    </div> */}
          <div className="col card px-6 py-8 rounded-xl br-15">
            <div className="center-items font-weight fs-020">Ventas</div>
            <div className="fs-030 center-items text-muted ">{ventas}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export { CardCount };
