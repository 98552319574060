import { useEffect, useState } from "react";
import {
  getCountDetailByUser,
  getDataBoard,
  getDataTask,
  getDataFilterBySelectFiled,
} from "../../../service/home/Home";
import {
  HomeCountByUserModel,
  HomeBoardModel,
  HomeTaskModel,
  HomeFilterBoard,
} from "../models/HomeModel";
import { useHistory } from "react-router-dom";
import { formattedCurrency } from "../../../../_metronic/helpers/FormatCurrency";
import { GenericoModel } from "../../catalogos/generico/models/GenericoModel";
import { getCatFields } from "../../../service/catalog/Catalogs";

export const useGetDetailCountByUser = (filter: number, reload: number) => {
  const [dataCount, setDataCount] = useState<HomeCountByUserModel>({
    tareas: 0,
    oportunidades: 0,
    cotizaciones: 0,
    ventas: 0,
  });
  const [loadingRequest, setLoadingRequest] = useState(0);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      setLoadingRequest(0);
      const res = await getCountDetailByUser(filter)
        .then()
        .catch((error) => {
          history.push("/error/500");
        });
      if (res) {
        setDataCount(res.data);
        setLoadingRequest(1);
      }
    };
    fetchPost();
  }, [filter, reload]);

  return { dataCount, loadingRequest };
};

export const useGetDataBoard = (filter: HomeFilterBoard, reload: number) => {
  const [dataBoard, setDataBoard] = useState<HomeBoardModel[]>([]);
  const [loadingRequest, setLoadingRequest] = useState(0);
  const [boardFormatter, setBoardFormatter] = useState<any>([]);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      setLoadingRequest(0);
      const res = await getDataBoard(filter)
        .then()
        .catch((error) => {
          history.push("/error/500");
        });
      if (res) {
        if (res.data) {
          let formatter = res.data.map((item: any) => {
            let obj = {
              cards: item.items.map((element: any) => {
                return {
                  description: element.descripcion,
                  draggable: item.code == "CGA" ? false : true,
                  id: element.id,
                  label: "",
                  laneId: element.etapa_id,
                  title: element.nombre,
                  nombre_vendedor: element.vendedorComercial.nombre,
                  apellido_paterno_vendedor:
                    element.vendedorComercial.apellido_paterno,
                  apellido_materno_endedor:
                    element.vendedorComercial.apellido_materno,
                  cuenta: element.cuenta.nombre,
                  oportunidad: element.nombre,
                  photo: element.vendedorComercial.user.profile_photo,
                  cantidad: formattedCurrency(element.cantidad_estimada),
                  porcentaje: element.probabilidad,
                  estatus: element.status.name,
                  code: item.code,
                  style: "border:solid 1px red",
                };
              }),
              currentPage: 1,
              disallowAddingCard: true,
              id: item.code,
              label: item.items.length,
              style: {
                width: 330,
                // height: 600
              },
              title: item.nombre,
              color_etapa: item.color,
              etapa_id: item.etapaId,
              codigo: item.code,
            };
            return obj;
          });
          setBoardFormatter(formatter);
        }

        setDataBoard(res.data);
        setLoadingRequest(1);
      }
    };
    fetchPost();
  }, [reload]);

  return { dataBoard, loadingRequest, boardFormatter };
};

export const useGetDataTask = () => {
  const [dataTask, setDataTask] = useState<HomeTaskModel[]>([]);
  const [loadingRequest, setLoadingRequest] = useState<boolean>(false);

  const history = useHistory();

  useEffect(() => {
    const fetchPost = async () => {
      setLoadingRequest(true);
      const res = await getDataTask()
        .then()
        .catch((error) => {
          history.push("/error/500");
        });
      if (res) {
        setDataTask(res.data);
        setLoadingRequest(false);
      }
    };
    fetchPost();
  }, []);

  return {
    dataTask,
    loadingRequest,
  };
};

export const useGetFieldsOpportunity = () => {
  const [listFields, setlistFields] = useState<GenericoModel[]>([]);
  const [loadingRequest, setLoadingRequest] = useState(0);

  const history = useHistory();

  useEffect(() => {
    const fetchPost = async () => {
      setLoadingRequest(0);
      const res = await getCatFields()
        .then()
        .catch((error) => {
          history.push("/error/500");
        });
      if (res) {
        // res.unshift({
        //   id: 0,
        //   name: "Todas",
        //   code: "ALL",
        // });
        const filedsFilter = res.filter(
          (item: any) => item.code != "UNI_ID" && item.code != "MON_ID"
        );

        const selectOptions = filedsFilter.map((option: any) => ({
          value: option.id,
          label: `${option.name} `,
          code: option.code,
        }));
        setlistFields(selectOptions);
        setLoadingRequest(1);
      }
    };
    fetchPost();
  }, []);

  return {
    listFields,
    loadingRequest,
  };
};

export const useGetDataSelectedByField = (code: string) => {
  const [dataOptionSelected, setDataOptionSelected] = useState<any[]>([]);
  const [loadingRequest, setLoadingRequest] = useState(0);

  const history = useHistory();

  useEffect(() => {
    const fetchPost = async () => {
      setLoadingRequest(0);
      const res = await getDataFilterBySelectFiled(code)
        .then()
        .catch((error) => {
          history.push("/error/500");
        });
      if (res) {
        const selectOptions = res.map((option: any) => ({
          value: option.id,
          label: `${
            option?.name
              ? option?.name
              : code === "VEN_COM_ID" || code === "VEN_TEC_ID"
              ? option?.alias
              : option?.nombre
          } `,
        }));
        setDataOptionSelected(selectOptions);
        setLoadingRequest(1);
      }
    };

    if (code) {
      fetchPost();
    }
  }, [code]);

  return {
    dataOptionSelected,
    loadingRequest,
  };
};
