import React, { useLayoutEffect, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Pagination,
  encryptId,
  maskPhoneNumber,
  KTSVG,
} from '../../../../_metronic/helpers';
import { MenuComponent } from '../../../../_metronic/assets/ts/components/MenuComponent';
import { AccountModel } from '../models/AccountModel';
import { TableList } from '../../../../_metronic/helpers/components/TableList';
import { EmptyResult } from '../../../../_metronic/helpers/components/EmptyResult';

type Props = {
  data: AccountModel[];
  count: number;
  currentPage: number;
  totalPages: number;
  onRemoveRegister: (row: any) => void;
  onPageChange: (pageNumber: number) => void;
  onEditRegister: (row: any) => void;
  loadingGrid: boolean;
};

const ListGrid: React.FC<Props> = ({
  data,
  count,
  currentPage,
  totalPages,
  onRemoveRegister,
  onPageChange,
  onEditRegister,
  loadingGrid,
}) => {
  MenuComponent.reinitialization();

  const columns: any = [
    {
      id: 'nombre',
      name: <div className="fw-bolder text-muted me-5">Nombre</div>,
      selector: (row: any) => row.nombre,
      cell: (row: any) => (
        <Link to={`/clientes/cuentas/edit/${encryptId(row.id.toString())}`}>
          {`${row.nombre}`}
        </Link>
      ),
      sortable: true,
    },
    {
      id: 'vendedorComercial',
      name: (
        <div className="fw-bolder text-muted me-5 text-center w-100">
          Vendedor comercial
        </div>
      ),
      cell: (row: any) => (
        <div
          className="symbol symbol-30px text-center"
          style={{ width: '150px' }}
        >
          <div className="symbol symbol-30px tooltip-soft">
            {row.vendedorComercial && (
              <>
                {row.vendedorComercial.user_asignado.profile_photo ? (
                  <>
                    <img
                      src={`${process.env.REACT_APP_PUBLIC}users/${row.vendedorComercial.user_asignado.profile_photo}`}
                      crossOrigin="anonymous"
                      className="symbol-label fs-8 "
                      alt="user"
                    />
                    <span className="tooltiptext">{`${row.vendedorComercial.nombre} ${row.vendedorComercial.apellido_paterno} ${row.vendedorComercial.apellido_materno}`}</span>
                  </>
                ) : (
                  <>
                    <span className="symbol-label fs-8 fw-bold bg-light-primary text-primary fw-bold">
                      {`${row.vendedorComercial.nombre[0]}${row.vendedorComercial.apellido_paterno[0]}`}
                    </span>
                    <span className="tooltiptext">{`${row.vendedorComercial.nombre} ${row.vendedorComercial.apellido_paterno} ${row.vendedorComercial.apellido_materno}`}</span>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      ),
      sortable: true,
    },
    {
      id: 'tipoCuenta',
      name: <div className="fw-bolder text-muted me-5">Tipo de cuenta</div>,
      selector: (row: any) => row.tipoCuenta?.name,
      cell: (row: any) => (
        <div className="text-gray-700">
          {row.tipoCuenta ? row.tipoCuenta?.name : 'No registrado'}
        </div>
      ),
      sortable: true,
    },
    {
      id: 'sitio_web',
      name: <div className="fw-bolder text-muted me-5">Sitio web</div>,
      selector: (row: any) => row.sitio_web,
      cell: (row: any) =>
        row.sitio_web ? (
          // <div className="fw-bolder text-info">{row.sitio_web}</div>
          <a
            href={row.sitio_web}
            target="_blank"
            rel="noopener noreferrer"
            className="text-info"
          >
            {row.sitio_web}
          </a>
        ) : (
          <div className="text-gray-700">{'Sin sitio Web'}</div>
        ),
      sortable: true,
    },
    {
      id: 'tamano',
      name: (
        <div className="fw-bolder text-muted me-5 text-center w-100">
          No. empleados
        </div>
      ),
      selector: (row: any) => row.num_empleados,
      cell: (row: any) =>
        // <div className="text-center text-muted" style={{ width: '150px' }}>
        //   {row.num_empleados ? row.num_empleados : 'No registrado'}
        // </div>
        row.num_empleados ? (
          // <div className="fw-bolder text-info">{row.sitio_web}</div>
          <div className="text-gray-700 fw-bolder">{row.num_empleados}</div>
        ) : (
          <div className="text-gray-700">{'Sin número'}</div>
        ),
      sortable: true,
    },
    {
      name: <div className="fw-bolder text-muted me-5">Acciones</div>,
      cell: (row: any) => dropdownRender(row),
      sortable: true,
    },
  ];

  const dropdownRender = (row: any) => {
    return (
      <>
        <div className="d-flex justify-content-end flex-shrink-0">
          <a
            href="#/"
            data-kt-menu="true"
            className="btn btn-light btn-active-light-primary btn-sm"
            data-kt-menu-trigger="click"
            data-kt-menu-placement="bottom-end"
          >
            Acciones
            <span className="svg-icon svg-icon-5 m-0">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="mh-50px"
              >
                <path
                  d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z"
                  fill="currentColor"
                ></path>
              </svg>
            </span>
          </a>

          <div
            className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-150px py-4"
            data-kt-menu="true"
          >
            <div className="menu-item px-3">
              <Link
                className="menu-link px-3"
                to={`/clientes/cuentas/edit/${encryptId(row.id.toString())}`}
              >
                <KTSVG
                  path="/media/icons/duotune/abstract/abs011.svg"
                  className="svg-icon-5 svg-icon-gray-500 "
                />
                <span className="ms-1">Subcuenta</span>
              </Link>
              <Link
                className="menu-link px-3"
                to={`/clientes/cuentas/edit/${encryptId(row.id.toString())}`}
              >
                <KTSVG
                  path="/media/icons/duotune/files/fil029.svg"
                  className="svg-icon-5"
                />
                <span className="ms-1">Documentos</span>
              </Link>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className={`row`}>
        <div className="col-xl-12">
          <div className={`card mb-0 mb-xxl-8 h-100 w-100`}>
            {!loadingGrid ? (
              <TableList data={data} columns={columns}></TableList>
            ) : (
              <EmptyResult title="" loading={loadingGrid} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export { ListGrid };
