import React, { useState, useEffect } from 'react';
import { GenericoModel } from "../../catalogos/generico/models/GenericoModel";
import { useHistory } from "react-router-dom";
import { getCatFieldsProd } from '../../../service/catalog/Catalogs';
import { getDataFilterBySelectFiledProducts } from '../../../service/home/Home';


export const useGetFieldsPorducts = () => {
    const [listFields, setlistFields] = useState<GenericoModel[]>([]);
    const [loadingRequest, setLoadingRequest] = useState(0);
  
    const history = useHistory();
  
    useEffect(() => {
      const fetchPost = async () => {
        setLoadingRequest(0);
        const res = await getCatFieldsProd()
          .then()
          .catch((error) => {
            history.push("/error/500");
          });
        if (res) {
        //   res.unshift({
        //     id: 0,
        //     name: "Todas",
        //     code: "ALL",
        //   });
  
          const selectOptions = res.map((option: any) => ({
            value: option.id,
            label: `${option.name} `,
            code: option.code,
          }));
          setlistFields(selectOptions);
          setLoadingRequest(1);
        }
      };
      fetchPost();
    }, []);

    return {
      listFields,
      loadingRequest,
    };
  };


export const useGetDataSelectedByFieldProducts = (code: string) => {
  const [dataOptionSelected, setDataOptionSelected] = useState<any[]>([]);
  const [loadingRequest, setLoadingRequest] = useState(0);

  const history = useHistory();

  useEffect(() => {
    const fetchPost = async () => {
      setLoadingRequest(0);
      const res = await getDataFilterBySelectFiledProducts(code)
        .then()
        .catch((error) => {
          history.push("/error/500");
        });
      if (res) {
        const selectOptions = res.map((option: any) => ({
          value: option.id,
          label: (option?.name) ? option?.name:  option?.nombre
        }));

        setDataOptionSelected(selectOptions);
        setLoadingRequest(1);
      }
    };

    if (code) {
      fetchPost();
    }
  }, [code]);

  return {
    dataOptionSelected,
    loadingRequest,
  };
};

  