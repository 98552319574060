import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { NumericFormat } from 'react-number-format';
import {
  KTSVG,
  SimpleAlert,
  formatPrice,
  formatPriceNew,
} from '../../../../../_metronic/helpers';
import { useSelectProducts } from '../../hooks/PackagesHook';
import { styleSelect } from '../../../../../_metronic/helpers/SelectStyles';
import { FormikProps } from 'formik';
import {
  dividir,
  multiplicar,
  restar,
  sumar,
} from '../../../../../_metronic/helpers/PriceCalculations';

type Props = {
  data: any[];
  onChangePriceProducts: (
    subtotal: number,
    impuestos: number,
    total: number,
    products: any
  ) => void;
  codeCurrency: string;
  exchangeRate: number;
  updateCostProductos: number;
  packageId: number;
  formik: FormikProps<any>;
};

const ProductsList: React.FC<Props> = ({
  data,
  onChangePriceProducts,
  codeCurrency,
  exchangeRate,
  updateCostProductos,
  packageId,
  formik,
}) => {
  const [updateNewData, setUpdateNewData] = useState<number>(0);
  const { catalog: products, loadingRequest: loadingPROD } =
    useSelectProducts();
  const [dataMemo, setDataMemo] = useState<any[]>(data);
  const AddRow = () => {
    if (codeCurrency === '') {
      SimpleAlert('Favor seleccione un tipo de moneda!', 3000, 'error');
      return false;
    }
    const cleanedData = dataMemo.filter((item) => item.id !== 0);
    const newRow: any = {
      id: 0,
      sku: 'N/A',
      nombre: 'Nuevo producto',
      id_unidad_medida: 0,
      name_unidad_medida: 'N/A',
      code_unidad_moneda: 'N/A',
      volumen: 1,
      // precio_unitario: 0,
      // precio_unitario_real: 0,
      // importe: 0,
      // monto_impuesto_precio_venta: 0,
      // precio_impuesto_real: 0,
      // monto_impuesto_precio_venta_total: 0,

      precio_venta_sin_impuestos_real: Number(0),
      precio_impuesto_real: Number(0),
      precio_venta_real: Number(0),

      precio_venta_sin_impuestos_conversion: Number(0),
      precio_impuesto_conversion: Number(0),
      precio_venta_conversion: Number(0),

      subtotal_conversion: Number(0),
      impuestos_conversion: Number(0),
      total_conversion: Number(0),

      categoria: 'N/A',
    };
    setDataMemo([...cleanedData, newRow]);
  };

  const addProduct = (selectedOption: any) => {
    if (selectedOption) {
      const {
        data: {
          id,
          sku,
          nombre,
          unidadMedidaVenta,
          unidadMonedaVenta,
          precio_venta,
          precio_fijo_venta,
          precio_objetivo_calculado_venta,
          aplica_impuesto_precio_venta,
          monto_impuesto_precio_venta,
          tipoPrecioVenta,
          categoria,
        },
      } = selectedOption;
      if (dataMemo.length && packageId > 0) {
        const storageData = JSON.parse(localStorage.getItem('memo') ?? '');
        const exist = storageData.filter((item: any) => item.id === id);

        if (exist.length) {
          setUpdateNewData(1);
        }
      }
      const cleanedData = dataMemo.filter((item) => item.id !== 0);
      const existingIndex = dataMemo.findIndex((item) => item.id === id);
      if (existingIndex === -1) {
        let impuesto_aplica = monto_impuesto_precio_venta;
        // let impuesto_aplica = aplica_impuesto_precio_venta
        //   ? monto_impuesto_precio_venta
        //   : 0;
        const precio_tipo =
          tipoPrecioVenta.code === 'TPVO'
            ? precio_objetivo_calculado_venta
            : precio_fijo_venta;

        const precio_unitario_sin_impuestos_conversion = calculateExchangeRate(
          unidadMonedaVenta ? unidadMonedaVenta.code : '',
          codeCurrency,
          precio_tipo
        );

        const precio_unitario = calculateExchangeRate(
          unidadMonedaVenta ? unidadMonedaVenta.code : '',
          codeCurrency,
          precio_venta
        );

        const precio_unitario_tax = calculateExchangeRateTax(
          unidadMonedaVenta ? unidadMonedaVenta.code : '',
          codeCurrency,
          impuesto_aplica
        );

        const updateRow = {
          id: id,
          sku: sku ? sku : 'N/A',
          nombre: nombre,
          id_unidad_medida: unidadMedidaVenta ? unidadMedidaVenta.id : 0,
          name_unidad_medida: unidadMedidaVenta
            ? unidadMedidaVenta.name
            : 'N/A',
          volumen: 1,
          code_unidad_moneda: unidadMonedaVenta
            ? unidadMonedaVenta.code
            : 'N/A',

          precio_venta_sin_impuestos_real: Number(precio_tipo),
          precio_impuesto_real: Number(impuesto_aplica),
          precio_venta_real: Number(precio_venta),

          precio_venta_sin_impuestos_conversion: Number(
            precio_unitario_sin_impuestos_conversion
          ),
          precio_impuesto_conversion: Number(precio_unitario_tax),
          precio_venta_conversion: Number(precio_unitario),

          subtotal_conversion: Number(precio_unitario_sin_impuestos_conversion),
          impuestos_conversion: Number(precio_unitario_tax),
          total_conversion: Number(precio_unitario),

          categoria: categoria ? categoria.nombre : 'N/A',
        };
        setDataMemo((prevDataMemo) => {
          const updatedDataMemo = [...prevDataMemo, updateRow];
          sumAmount(updatedDataMemo);
          return updatedDataMemo;
        });
        setDataMemo([...cleanedData, updateRow]);
      } else {
        setDataMemo([...cleanedData]);
        SimpleAlert(
          'Ya existe el producto en el paquete actual.',
          3000,
          'error'
        );
      }
    }
  };

  const calculateExchangeRate = (
    unidadMoneda: string,
    codeCurrency: string,
    precio_venta: number
  ) => {
    let costCalculate: number = 0;
    switch (unidadMoneda) {
      case 'MXN':
        if (codeCurrency === 'USD') {
          costCalculate = dividir(precio_venta, Number(exchangeRate));
        } else if (codeCurrency === 'MXN') {
          costCalculate = precio_venta;
        }
        break;
      case 'USD':
        if (codeCurrency === 'USD') {
          costCalculate = precio_venta;
        } else if (codeCurrency === 'MXN') {
          costCalculate = multiplicar(precio_venta, Number(exchangeRate));
        }
        break;
    }
    return costCalculate;
  };

  const calculateExchangeRateTax = (
    unidadMoneda: string,
    codeCurrency: string,
    monto_impuesto_precio_venta: number
  ) => {
    let taxCalculate: number = 0;
    switch (unidadMoneda) {
      case 'MXN':
        if (codeCurrency === 'USD') {
          taxCalculate = dividir(
            monto_impuesto_precio_venta,
            Number(exchangeRate)
          );
        } else if (codeCurrency === 'MXN') {
          taxCalculate = monto_impuesto_precio_venta;
        }
        break;
      case 'USD':
        if (codeCurrency === 'USD') {
          taxCalculate = monto_impuesto_precio_venta;
        } else if (codeCurrency === 'MXN') {
          taxCalculate = multiplicar(
            monto_impuesto_precio_venta,
            Number(exchangeRate)
          );
        }
        break;
    }
    return taxCalculate;
  };

  const sumAmount = (updatedDataMemo: any) => {
    const subtotal = updatedDataMemo.reduce((subtotal: number, item: any) => {
      return sumar(subtotal, Number(item.subtotal_conversion));
    }, 0);

    const impuestos = updatedDataMemo.reduce((impuestos: number, item: any) => {
      return sumar(impuestos, Number(item.impuestos_conversion));
    }, 0);

    const total = updatedDataMemo.reduce((total: number, item: any) => {
      return sumar(total, Number(item.total_conversion));
    }, 0);
    onChangePriceProducts(subtotal, impuestos, total, updatedDataMemo);
  };

  const deleteRowById = (productId: number) => {
    setDataMemo((prevDataMemo) => {
      const updatedDataMemo = prevDataMemo.filter(
        (item) => item.id !== productId
      );
      sumAmount(updatedDataMemo);
      return updatedDataMemo;
    });
  };

  const volumeChange = (newVolumen: number, productId: number) => {
    setDataMemo((prevDataMemo) => {
      const updatedDataMemo = prevDataMemo.map((item) => {
        if (item.id === productId) {
          let subtotal_conversion = multiplicar(
            item.precio_venta_sin_impuestos_conversion,
            newVolumen
          );
          let impuestos_conversion = multiplicar(
            item.precio_impuesto_conversion,
            newVolumen
          );
          let total_conversion = sumar(
            subtotal_conversion,
            impuestos_conversion
          );

          return {
            ...item,
            volumen: newVolumen,
            subtotal_conversion: subtotal_conversion,
            impuestos_conversion: impuestos_conversion,
            total_conversion: total_conversion,
          };
        }
        return item;
      });
      sumAmount(updatedDataMemo);
      return updatedDataMemo;
    });
  };

  useEffect(() => {
    setDataMemo((prevDataMemo) => {
      const updatedDataMemo = prevDataMemo.map((item: any) => {
        const precio_unitario_sin_impuestos_conversion = calculateExchangeRate(
          item.code_unidad_moneda,
          codeCurrency,
          item.precio_venta_sin_impuestos_real
        );

        const precio_unitario_tax = calculateExchangeRateTax(
          item.code_unidad_moneda,
          codeCurrency,
          item.precio_impuesto_real
        );

        const precio_unitario = calculateExchangeRate(
          item.code_unidad_moneda,
          codeCurrency,
          item.precio_venta_real
        );

        let subtotal_conversion = multiplicar(
          precio_unitario_sin_impuestos_conversion,
          item.volumen
        );
        let impuestos_conversion = multiplicar(
          precio_unitario_tax,
          item.volumen
        );
        let total_conversion = sumar(subtotal_conversion, impuestos_conversion);

        return {
          ...item,
          precio_venta_sin_impuestos_conversion: Number(
            precio_unitario_sin_impuestos_conversion
          ),
          precio_impuesto_conversion: Number(precio_unitario_tax),
          precio_venta_conversion: Number(precio_unitario),
          subtotal_conversion: subtotal_conversion,
          impuestos_conversion: impuestos_conversion,
          total_conversion: total_conversion,
        };
      });
      sumAmount(updatedDataMemo);
      return updatedDataMemo;
    });
  }, [updateCostProductos]);

  useEffect(() => {
    if (data.length) {
      const cleanedData = data.filter((item) => item.id !== 0);
      setDataMemo(cleanedData);
    }
  }, [data]);

  useEffect(() => {
    if (data && packageId > 0) {
      localStorage.setItem('memo', JSON.stringify(data));
    }
  }, [data]);

  useEffect(() => {
    formik.setFieldValue('update_products', updateNewData);
  }, [updateNewData]);

  useEffect(() => {
    return () => {
      localStorage.removeItem('memo');
      setDataMemo([]);
    };
  }, []);

  return (
    <>
      <div className="table-responsive min-h-300px me-n5 pe-5 scroll-y">
        <table className="table table-border table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 me-n5 pe-5">
          <thead>
            <tr className="text-gray-800 fs-7">
              <th className="w-100px">SKU</th>
              <th className="w-200px">Producto</th>
              <th className="w-200px">Categoría</th>
              <th className="w-200px">Unidad de medida</th>
              <th className="w-200px">Unidad de moneda</th>
              <th className="w-150px">Volumen</th>
              <th className="w-200px">Impuesto Unitario</th>
              <th className="w-200px">Precio Unitario</th>
              <th className="w-200px">Impuesto total</th>
              <th className="w-200px">Subtotal</th>
              <th className="w-200px">Importe</th>
              <th className="w-100px"></th>
            </tr>
          </thead>
          <tbody>
            {dataMemo.length > 0 ? (
              dataMemo.map((row, index) => (
                <tr key={index}>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="d-flex justify-content-start flex-column fs-012 text-gray-600">
                        {row.sku}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="d-flex justify-content-start w-100 flex-column fs-012 font-weight-mormal text-gray-700 fw-bold ">
                        {row.id === 0 ? (
                          <Select
                            key={row.id}
                            options={products}
                            styles={styleSelect}
                            placeholder={'Seleccione'}
                            isSearchable={true}
                            menuPortalTarget={document.body}
                            menuPosition={'fixed'}
                            noOptionsMessage={() => {
                              return (
                                <span>{'No se encontraron registros'}</span>
                              );
                            }}
                            onChange={addProduct}
                            isLoading={loadingPROD}
                            backspaceRemovesValue
                          />
                        ) : (
                          <>{row.nombre}</>
                        )}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="d-flex justify-content-start w-100 flex-column fs-012 font-weight-mormal text-gray-600">
                        <>{row.categoria}</>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="d-flex justify-content-start flex-column fs-012 text-gray-600">
                        {row.name_unidad_medida}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="d-flex justify-content-start flex-column fs-012 text-gray-700 fw-bold">
                        {row.code_unidad_moneda}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="d-flex justify-content-start flex-column fs-012 text-gray-600">
                        {row.id !== 0 ? (
                          <NumericFormat
                            className="form-control w-100px"
                            thousandSeparator={false}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                e.preventDefault();
                                e.stopPropagation();
                              }
                            }}
                            allowNegative={false}
                            decimalScale={2}
                            value={row.volumen}
                            onBlur={(e) => {
                              const value = e.target.value;
                              if (!value) {
                                e.target.value = '1';
                              }
                            }}
                            isAllowed={(values: any) => {
                              const { floatValue } = values;
                              // Verificar que la parte entera no tenga más de 11 dígitos
                              const integerPart = Math.floor(floatValue);
                              return integerPart.toString().length <= 7;
                            }}
                            suffix=""
                            allowLeadingZeros={false}
                            onValueChange={(values) => {
                              const { floatValue } = values;
                              volumeChange(floatValue ? floatValue : 0, row.id);
                            }}
                          />
                        ) : (
                          <>{'1'}</>
                        )}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="d-flex justify-content-start flex-column fs-012 text-gray-700 fw-bold">
                        {`${formatPriceNew(
                          row.precio_impuesto_conversion ?? 0,
                          'es-MX',
                          'MXN',
                          2,
                          2
                        )}`}
                        / {codeCurrency} 
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="d-flex justify-content-start flex-column fs-012 text-gray-700 fw-bold">
                        {`${formatPriceNew(
                          row.precio_venta_sin_impuestos_conversion,
                          'es-MX',
                          'MXN',
                          2,
                          2
                        )}`}
                        / {codeCurrency}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="d-flex justify-content-start flex-column fs-012 text-gray-700 fw-bold">
                        {`${formatPriceNew(
                          Number(row.impuestos_conversion),
                          'es-MX',
                          'MXN',
                          2,
                          2
                        )}`}
                        / {codeCurrency}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="d-flex justify-content-start flex-column fs-012 text-gray-700 fw-bold">
                        {`${formatPriceNew(
                          Number(row.subtotal_conversion),
                          'es-MX',
                          'MXN',
                          2,
                          2
                        )}`}
                        / {codeCurrency}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="d-flex justify-content-start flex-column fs-012 text-gray-700 fw-bold">
                        {`${formatPriceNew(
                          row.total_conversion,
                          'es-MX',
                          'MXN',
                          2,
                          2
                        )}`}{' '}
                        / {codeCurrency}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="d-flex justify-content-start flex-column fs-012 text-gray-600">
                        <a
                          className="badge badge-light-danger  text-hover-danger fw-bold px-4 py-3 cursor-pointer"
                          title="Eliminar producto"
                          onClick={async (event) => {
                            deleteRowById(row.id);
                          }}
                        >
                          <KTSVG
                            path="/media/icons/duotune/general/gen027.svg"
                            className="svg-icon-4"
                          />
                        </a>
                      </div>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={9} className="text-center text-muted">
                  Sin productos
                </td>
              </tr>
            )}

            <tr>
              <td colSpan={9}>
                <div className="d-flex align-items-center">
                  <div
                    className="btn btn-light-primary btn-sm mb-10"
                    onClick={AddRow}
                  >
                    <KTSVG
                      path="/media/icons/duotune/arrows/arr075.svg"
                      className="svg-icon-5"
                    />
                    Agregar Producto
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export { ProductsList };
