import { FC } from "react";
import { PageTitle, PageLink } from "../../../_metronic/layout/core";
import { ViewAddEdit } from "./components/ViewAddEdit";
import { useParams } from "react-router-dom";
import { decryptId } from "../../../_metronic/helpers";

const OpportunityCreateEditPage: FC = () => {
  const { id, section, etapa } =
    useParams<{ id: string; section?: string; etapa?: string }>();
  const idRegister = decryptId(id);
  const stageId = decryptId(etapa ? etapa : "");
  const breadCrumbs: Array<PageLink> = [
    {
      title: "Oportunidades",
      path: "/oportunidades/",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
  ];
  return (
    <>
      <PageTitle breadcrumbs={breadCrumbs}>
        {idRegister ? "Editar oportunidad" : "Nueva oportunidad"}
      </PageTitle>
      <ViewAddEdit
        id={idRegister ? idRegister : "0"}
        section={section ? section : ""}
        etapa={stageId ? stageId : ""}
      />
    </>
  );
};

export { OpportunityCreateEditPage };
