import { FC } from 'react';
import { PageTitle } from '../../../_metronic/layout/core';
import { View } from './components/View';

const ScoreCardPage: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>ScoreCard</PageTitle>
      <View />
    </>
  );
};

export { ScoreCardPage };
