import React, { useState, useEffect } from "react";
import { KTSVG, SimpleAlert } from "../../../../_metronic/helpers";
import { Modal } from "react-bootstrap-v5";
import * as Yup from "yup";
import { useFormik } from "formik";
import { UpdProductsModel, UpdServiceModel } from "../models/QuotationModel";
import { useUPdateProductShortcut } from "../hooks/ProductsHook";
import { useUpdateServiceShortcut } from "../hooks/ServicesHook";

type Props = {
  show: boolean;
  handleClose: (refresh?: boolean) => void;
  itemSelected: number;
  typeProveedor: number;
};

const NoteSchema = Yup.object().shape({
  nombre_proveedor: Yup.string().required("Este campo es requerido."),
});

const CreateSupplierModal: React.FC<Props> = ({
  show,
  handleClose,
  itemSelected,
  typeProveedor,
}) => {
  const [loading, setLoading] = useState(false);
  const { updateProductShort, loadingUpdateProdShort, isCorrectUpdateP } =
    useUPdateProductShortcut();
  const { updateServiceShort, loadingUpdateServShort, isCorrectUpdateS } =
    useUpdateServiceShortcut();
  const [modelProveedor, setModelProveedor] = useState<number>(typeProveedor);

  const initValues: UpdProductsModel = {
    id: itemSelected,
    nombre_proveedor: "",
  };

  const supplierForm = useFormik({
    initialValues: initValues,
    validationSchema: NoteSchema,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      setLoading(true);
      setTimeout(() => {
        try {
          if (modelProveedor === 1) {
            updateProductShort(values).then(() => {
              setLoading(false);
              supplierForm.resetForm();
              handleClose(true);
            });
          }
          if (modelProveedor === 2) {
            updateServiceShort(values).then(() => {
              setLoading(false);
              supplierForm.resetForm();
              handleClose(true);
            });
          }
        } catch (error) {
          setLoading(false);
          SimpleAlert(
            "Hubo un problema al crear el proveedor, intentalo más tarde",
            3000,
            "error"
          );
          handleClose();
        }
      }, 1000);
    },
  });

  return (
    <Modal
      id="kt_modal_create"
      tabIndex={-1}
      aria-hidden="true"
      backdrop="static"
      dialogClassName="modal-dialog mw-800px"
      show={show}
      onHide={() => {
        supplierForm.resetForm();
        handleClose();
      }}
    >
      <div className="modal-content">
        <div className="modal-header">
          <h2 className="text-muted">Nuevo proveedor</h2>
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            onClick={() => {
              supplierForm.resetForm();
              handleClose();
            }}
          >
            <KTSVG
              className="svg-icon-2"
              path="/media/icons/duotune/arrows/arr061.svg"
            />
          </div>
        </div>
        <div className="modal-body mx-5 padding-top-00">
          <form
            id="kt_modal_generic_form"
            className="form"
            action="#"
            onSubmit={supplierForm.handleSubmit}
          >
            <div className="row wh-0100 mb-3">
              <div className="col-xxl-12 col-xl-12 col-md-12 col-lg-12">
                <input
                  className="form-control mb-2 w-100 border-0 padd-left input-name-form"
                  data-kt-element="input"
                  placeholder="Nombre *"
                  {...supplierForm.getFieldProps("nombre_proveedor")}
                ></input>
                {supplierForm.touched.nombre_proveedor &&
                  supplierForm.errors.nombre_proveedor && (
                    <div className="fv-plugins-message-container text-danger w-100">
                      <span role="alert">
                        {supplierForm.errors.nombre_proveedor}
                      </span>
                    </div>
                  )}
              </div>
            </div>

            <div className="d-flex justify-content-end mt-10">
              <button
                type="reset"
                className="btn btn-light me-3"
                data-kt-users-modal-action="cancel"
                onClick={() => {
                  supplierForm.resetForm();
                  handleClose();
                }}
              >
                Cancelar
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                data-kt-users-modal-action="submit"
                disabled={loading}
              >
                {!loading && <span className="indicator-label">Guardar</span>}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: "block" }}
                  >
                    Espere por favor...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};
export { CreateSupplierModal };
