import { format } from 'date-fns';

export const formatDate = (date: Date): string => {

    return format(date, 'dd/MM/yyyy');
}

export const formatDateTime = (date: Date): string => {

    return format(date, 'dd-MM-yy HH:mm');
}

export const formatHour = (date: Date): string => {

    return format(date, 'HH:mm');
}

export const dateFormatt = (date: string) => {
    const now = new Date(date);
    const year = now.getUTCFullYear();
    const month = String(now.getUTCMonth() + 1).padStart(2, '0');
    const day = String(now.getUTCDate()).padStart(2, '0');
    const formattedDate = `${day}/${month}/${year}`;

    return formattedDate;
};

export const dateFormattTime = (date: string) => {
    const now = new Date(date);
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}`;
    return formattedDate;
};

export const dateFormattYYYYMMDD = (date: string) => {
    const now = new Date(date);
    const year = now.getUTCFullYear();
    const month = String(now.getUTCMonth() + 1).padStart(2, '0');
    const day = String(now.getUTCDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
};