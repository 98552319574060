import React, { useState, useEffect } from "react";
import { KTSVG, SimpleAlert } from "../../../../../_metronic/helpers";
import { Modal } from "react-bootstrap-v5";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useCreateSubcategoryProducts } from "../../../sub_categories/products/hooks/ProductsHook";

type Props = {
  show: boolean;
  handleClose: (isNew: boolean) => void;
  category: any;
};

const SubcategorySchema = Yup.object().shape({
  nombre: Yup.string().required("Nombre de la subcategoría es requerido."),
});

const SubcategoryModal: React.FC<Props> = ({ show, handleClose, category }) => {
  const [loading, setLoading] = useState(false);
  const [nameCat, setNameCat] = useState<string>("");
  const {
    create: createSubcategory,
    loading: loadingSubcategory,
    errorRequest,
  } = useCreateSubcategoryProducts();

  const SubcategoryForm = useFormik({
    initialValues: {
      nombre: "",
    },
    validationSchema: SubcategorySchema,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      setLoading(true);
      createSubcategory(values, Number(category.id));
    },
  });

  useEffect(() => {
    if (show && category) {
      const { label } = category;
      setNameCat(label);
    } else {
      setNameCat("");
    }
  }, [category, show]);

  useEffect(() => {
    if (loadingSubcategory) {
      if (errorRequest) {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      } else {
        setTimeout(() => {
          setLoading(false);
          handleClose(true);
          SubcategoryForm.resetForm();
        }, 1000);
      }
    }
  }, [loadingSubcategory]);

  return (
    <Modal
      id="kt_modal_create"
      tabIndex={-1}
      aria-hidden="true"
      backdrop="static"
      dialogClassName="modal-dialog mw-600px"
      show={show}
      onHide={() => {
        SubcategoryForm.resetForm();
        handleClose(false);
      }}
    >
      <div className="modal-content">
        <div className="modal-header">
          <h2 className="text-muted">{"Nueva subcategoría"}</h2>
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            onClick={() => {
              SubcategoryForm.resetForm();
              handleClose(false);
            }}
          >
            <KTSVG
              className="svg-icon-2"
              path="/media/icons/duotune/arrows/arr061.svg"
            />
          </div>
        </div>
        <div className="modal-body mx-5 padding-top-00">
          {nameCat ? (
            <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed min-w-lg-400px flex-shrink-0 p-6 mb-2">
              <KTSVG
                path="/media/icons/duotune/general/gen044.svg"
                className="svg-icon-2tx svg-icon-primary me-4"
              />
              <div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
                <div className="mb-3 mb-md-0 fw-semibold">
                  <h4 className="fs-6 text-gray-700 pe-7">
                    {`Estas por crear un subcategoría ligada a la categoría `}
                    <span className="text-primary">{nameCat}</span>
                  </h4>
                </div>
              </div>
            </div>
          ) : null}
          <form
            id="kt_modal_generic_form"
            className="form"
            action="#"
            onSubmit={SubcategoryForm.handleSubmit}
          >
            <div className="row wh-0100 mb-3">
              <div className="col-xxl-12 col-xl-12 col-md-12 col-lg-12 mt-5">
                <input
                  className="form-control mb-2 w-100 border-0 padd-left input-name-form"
                  data-kt-element="input"
                  placeholder="Nombre *"
                  {...SubcategoryForm.getFieldProps("nombre")}
                ></input>
                {SubcategoryForm.touched.nombre &&
                  SubcategoryForm.errors.nombre && (
                    <div className="fv-plugins-message-container text-danger w-100">
                      <span role="alert">{SubcategoryForm.errors.nombre}</span>
                    </div>
                  )}
              </div>
            </div>
            <div className="d-flex justify-content-end mt-10">
              <button
                type="reset"
                className="btn btn-light me-3"
                data-kt-users-modal-action="cancel"
                onClick={() => {
                  SubcategoryForm.resetForm();
                  handleClose(false);
                }}
              >
                Cancelar
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                data-kt-users-modal-action="submit"
                disabled={loading}
              >
                {!loading && <span className="indicator-label">Guardar</span>}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: "block" }}
                  >
                    Espere por favor...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};
export { SubcategoryModal };
