import { useState, useEffect } from 'react';
import { TaxesModel } from '../models/GenericModel';
import { useHistory } from 'react-router-dom';
import { getTaxes, getDataTaxeById } from '../../../service/taxes/Taxes';

export const useGetAll = (loading: number) => {
  const [dataTaxes, setDataTaxes] = useState<TaxesModel[]>([]);
  const [loadingRequest, setLoadingRequest] = useState<boolean>(false);
  const history = useHistory();

  useEffect(() => {
    const fetchPost = async () => {
      setLoadingRequest(true);
      const data = await getTaxes()
        .then()
        .catch((error) => {
          setLoadingRequest(false);

          history.push('/error/500');
        });
      if (data) {
        setDataTaxes(data);
        setLoadingRequest(false);
      }
    };
    fetchPost();
  }, [loading]);
  return { dataTaxes, loadingRequest };
};

export const useGetOne = (id: number) => {
  const initModel = {
    id: 0,
    siglas: '',
    descripcion: '',
    porcentaje: 0,
  };
  const [initValues, setInitValues] = useState<TaxesModel>(initModel);

  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      const res = await getDataTaxeById(id)
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        setInitValues(res);
      }
    };
    if (id) {
      fetchPost();
    } else {
      setInitValues(initModel);
    }
  }, [id]);

  return { initValues };
};
