import axios from 'axios'
import { RiskModel } from '../../pages/risks/models/RiskModel';
const API_URL = process.env.REACT_APP_API

export const getRiskByID = async (id: string) => {

    const response = await axios.get(`${API_URL}oportunidad/riesgo/${id}`);
    let risk = response.data.doc.data;
    risk = {
        ...risk,
        descripcion: risk.descripcion || "",
        etapa_id: risk.etapaRiesgo?.id | 0,
        prioridad_id: risk.prioridadRiesgo?.id | 0,
        status_id: risk.statusRiesgo?.id | 0,
        user_asignado_id: risk.vendedorComercial?.id | 0,
        fecha_inicio: risk.fecha_inicio ?  new Date(risk.fecha_inicio).toISOString().split('T')[0] : new Date().toISOString().split('T')[0],
        fecha_fin: risk.fecha_fin ?  new Date(risk.fecha_fin).toISOString().split('T')[0] : new Date().toISOString().split('T')[0],
    };
    return risk;

}

export async function updateRisk(risk: RiskModel, oportunidad_id: number) {
    const { id, nombre, prioridad_id, etapa_id, user_asignado_id, fecha_inicio, fecha_fin, descripcion } = risk;
    const response = await axios.put(`${API_URL}oportunidad/riesgo/upd/${id}`, {
        nombre: nombre,
        descripcion: descripcion,
        oportunidad_id: oportunidad_id,
        etapa_id: Number(etapa_id),
        prioridad_id: prioridad_id,
        // estatus_id: status_id,
        vendedor_comercial_id: user_asignado_id,
        fecha_inicio: fecha_inicio,
        fecha_fin: fecha_fin,
    });
    return response;
}

export async function getHistory(search: string, id: string) {
    const response = await axios.get(`${API_URL}opp/riesgo/historial/${id}`);
    const data = response.data.doc.data;
    return data;
}

export async function createHistoryMessage(message: string, id: string) {
    const response = await axios.post(`${API_URL}opp/riesgo/historial/comentario`, {
        riskId: id,
        comentario: message
    });
    return response;
}
export const uploadFileRisk = async (file: any, risk: string) => {

    const formData = new FormData();
    formData.append('file', file);
    formData.append('riskId', risk);

    const response = await axios.post(`${API_URL}opp/riesgo/documento`, formData);
    return response;

}

export const removeFileRisk = async (fileId: number) => {

    const response = await axios.delete(`${API_URL}opp/riesgo/documento/${fileId}`);
    return response;

}

export async function getTasksByRiskID(riskId: string) {

    const response = await axios.get(`${API_URL}riesgo/mitigacion/risk/${riskId}`);
    const data = response.data.doc.data;
    return data;

}
export async function createMitigateRisk(mitigateName: string, riskId: string) {
    const response = await axios.post(`${API_URL}/riesgo/mitigacion`, {
        riesgo_id: riskId,
        nombre: mitigateName
    });
    return response;
}

export async function getDocumentsByRiskId(riskId: string) {
    const response = await axios.get(`${API_URL}opp/riesgo/documento/${riskId}`);
    const data = response.data.doc.data;
    return data;

}