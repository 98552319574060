import React, { useState, useEffect, useRef } from 'react';
import { MitigateModel } from '../../mitigate/models/MitigateModel';
import { KTSVG, encryptId } from '../../../../_metronic/helpers';
import { Link } from 'react-router-dom';
import {
  dateFormatt,
  dateFormattTime,
  formatDateTime,
} from '../../../../_metronic/helpers/FormatDate';

type Props = {
  oportunityId: string;
  mitigates: MitigateModel[];
  onSaveMitigate: (message: string) => void;
  active: boolean;
  isEdit: boolean;
};

const MitigationList: React.FC<Props> = ({
  oportunityId,
  mitigates,
  onSaveMitigate,
  active,
  isEdit,
}) => {
  const [mitigateMemo, setMitigateMemo] = useState<MitigateModel[]>(mitigates);
  const [mitigateName, setMitigateName] = useState<string>('');
  const inputRef = useRef<HTMLInputElement>(null);
  const AddMitigateRow = () => {
    const newMitigate: MitigateModel = {
      id: 0,
      nombre: 'Nuevo mitigación',
    };
    setMitigateMemo((prevMitigate) => [...prevMitigate, newMitigate]);
  };

  const sendTask = () => {
    if (mitigateName.trim() !== '') {
      onSaveMitigate(mitigateName);
      setMitigateName('');
    }
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [mitigateMemo]);

  useEffect(() => {
    setMitigateMemo(mitigates);
  }, [mitigates]);

  useEffect(() => {
    if (active) {
      AddMitigateRow();
    }
  }, [active]);

  const onEnterPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      sendTask();
    }
  };
  const handleInputBlur = () => {
    setMitigateMemo((prevMitigate) =>
      prevMitigate.filter((mitigate) => mitigate.id !== 0)
    );
    setMitigateName('');
  };

  return (
    <>
      <div className="table-responsive min-h-200px me-n5 pe-5 scroll-y">
        <table className="table table-border table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 me-n5 pe-5">
          <thead>
            <tr className="text-gray-400 fs-7">
              <th className="w-200px">Nombre</th>
              <th className="w-100px">Asignado a</th>
              <th className="w-100px">Fecha inicial</th>
              <th className="w-100px">Fecha final</th>
              <th className="w-100px">Etapa</th>
            </tr>
          </thead>
          <tbody className="">
            {mitigateMemo.map((row, index) => (
              <tr key={row.id}>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start w-100 flex-column fs-012 font-weight-mormal text-muted">
                      {row.id === 0 ? (
                        <input
                          type="text"
                          value={mitigateName}
                          className="w-100 border-0"
                          ref={
                            index === mitigateMemo.length - 1 ? inputRef : null
                          }
                          onChange={(e) => setMitigateName(e.target.value)}
                          onKeyDown={onEnterPress}
                          style={{ outline: 'none' }}
                          placeholder={row.nombre}
                          onBlur={handleInputBlur}
                        ></input>
                      ) : (
                        <>
                          {!isEdit ? (
                            <Link
                              className="text-primary"
                              to={`/oportunidades_mitigaciones/${encryptId(
                                oportunityId
                              )}/riesgo/${encryptId(
                                row.riesgo ? row.riesgo.id.toString() : '0'
                              )}/edit/${encryptId(row.id.toString())}`}
                            >
                              {row.nombre}
                            </Link>
                          ) : (
                            row.nombre
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column fs-012">
                      <div className="symbol symbol-25px tooltip-soft">
                        {row.vendedorComercial?.user_asignado && (
                          <>
                            {row.vendedorComercial.user_asignado
                              .profile_photo ? (
                              <>
                                <img
                                  src={`${process.env.REACT_APP_PUBLIC}users/${row.vendedorComercial.user_asignado.profile_photo}`}
                                  crossOrigin="anonymous"
                                  className="symbol-label fs-7 "
                                  alt="user"
                                />
                                <span className="tooltiptext">{`${row.vendedorComercial.user_asignado.first_name} ${row.vendedorComercial.user_asignado.father_last_name} ${row.vendedorComercial.user_asignado.mother_last_name}`}</span>
                              </>
                            ) : (
                              <>
                                <span className="symbol-label fs-7 fw-bold bg-light-primary text-primary fw-bold">
                                  {row.vendedorComercial.user_asignado
                                    .first_name &&
                                  row.vendedorComercial.user_asignado
                                    .father_last_name ? (
                                    <>{`${row.vendedorComercial.user_asignado.first_name[0]}${row.vendedorComercial.user_asignado.father_last_name[0]}`}</>
                                  ) : (
                                    <></>
                                  )}
                                </span>
                                <span className="tooltiptext">{`${row.vendedorComercial.user_asignado.first_name} ${row.vendedorComercial.user_asignado.father_last_name} ${row.vendedorComercial.user_asignado.mother_last_name}`}</span>
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column fs-012  text-muted">
                      {row.fecha_inicio
                        ? dateFormatt(row.fecha_inicio)
                        : row.fecha_inicio}
                    </div>
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column fs-012  text-muted">
                      {row.fecha_fin
                        ? dateFormatt(row.fecha_fin)
                        : row.fecha_fin}
                    </div>
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column fs-012 text-muted">
                      {row.etapaMitigacion && row.etapaMitigacion.name}
                    </div>
                  </div>
                </td>
              </tr>
            ))}
            {!isEdit && (
              <tr>
                <td colSpan={5}>
                  <div className="d-flex align-items-center">
                    <div
                      className="btn btn-light-primary btn-sm mb-10"
                      onClick={AddMitigateRow}
                    >
                      <KTSVG
                        path="/media/icons/duotune/arrows/arr075.svg"
                        className="svg-icon-5"
                      />
                      Agregar mitigación
                    </div>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export { MitigationList };
