import axios from 'axios'
import { toAbsoluteUrl } from '../../../_metronic/helpers'

const API_URL = process.env.REACT_APP_API
const API_URL_IMAGE_PUBLIC = `${process.env.REACT_APP_PUBLIC}users/`


export async function getUsers(startIndex: number, endIndex: number) {

    const response = await axios.get(`${API_URL}users?limit=${startIndex}&page=${endIndex}`);
    const data = response.data.doc.data.rows;
    const count = response.data.doc.data.count;

    return {
        data: data,
        count: count
    };

}
export async function getUser(id: number) {

    const response = await axios.get(`${API_URL}users?id=${id}`);
    const data = response.data.doc.data.rows[0];
    return data;

}

export async function addUser(body: any) {

    const { email, first_name, father_last_name, mother_last_name, roles } = body;
    const data = {
        email: email,
        first_name: first_name,
        father_last_name: father_last_name,
        mother_last_name: mother_last_name,
        role_id: roles[0].id
    }
    const response = await axios.post(`${API_URL}users/`, data);
    return response;

}
export async function updateUser(body: any) {

    const { id, first_name, father_last_name, mother_last_name } = body;
    const data = {
        first_name: first_name,
        father_last_name: father_last_name,
        mother_last_name: mother_last_name,
    }
    const response = await axios.put(`${API_URL}users/${id}`, data);
    return response;

}
export async function activeUser(body: any) {

    const { id, active } = body;
    const data = {
        active: active
    }
    const response = await axios.put(`${API_URL}users/${id}`, data);
    return response;

}
export async function getRoles() {

    const response = await axios.get(`${API_URL}users/roles`);
    const data = response.data.doc.data.rows;

    const filter = data.filter((item: any) => {
        return item.code === 'ADMIN' || item.code === 'SOP' || item.code === 'RH' || item.code === 'COMP';
    });

    return filter;
}

export async function getMe() {

    const response = await axios.get(`${API_URL}users/me`);
    const data = response.data.doc.data;
    const random = Math.random() * 40;

    const user = {
        id: data.id,
        alias: data.alias || '',
        company: data.company || { id: 0, name: '', code: '' },
        email: data.email,
        father_last_name: data.father_last_name,
        first_name: data.first_name,
        mother_last_name: data.mother_last_name,
        phone: data.phone || '',
        roles: data.roles || [],
        active: data.active,
        profile_photo: data.profile_photo ? `${API_URL_IMAGE_PUBLIC}${data.profile_photo}?${random}` : toAbsoluteUrl('/media/avatars/blank.png'),
    }
    return user;

}
export async function updateMe(body: any) {

    const { mother_last_name, phone, alias, avatar } = body;
    let formData = new FormData();
    formData.append('alias', alias);
    formData.append('mother_last_name', mother_last_name);
    formData.append('phone', phone);
    formData.append('profile_photo', avatar);

    const response = await axios({
        method: "put",
        url: `${API_URL}users/me`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
    });
    return response;

}

export async function resetPassword(body: any) {
    const { currentPassword: current_password, newPassword: password, passwordConfirmation: confirm_password } = body;
    const data = {
        password,
        confirm_password,
        current_password
    }
    const response = await axios.put(`${API_URL}users/pwd`, data);
    return response;
}

export async function addRol(body: any) {

    const { roleId, id } = body;
    const response = await axios.post(`${API_URL}users/${id}/roles/${roleId}`);
    return response;

}
export async function removeRol(body: any) {

    const { roleId, id } = body;
    const response = await axios.delete(`${API_URL}users/${id}/roles/${roleId}`);
    return response;

}
export async function getSelectUsuarios() {

    const response = await axios.get(`${API_URL}users`);
    const data = response.data.doc.data;
    let dataSelect: any = data.rows;
    dataSelect.forEach((row: any) => {
        row.label = `${row.first_name} ${row.father_last_name}`
        row.value = row.id;
    });
    return dataSelect;

}