import axios from "axios";
import { NoteModel } from "../../pages/notes/models/NoteModel";
import { NoteOppModel } from "../../pages/notes_opp/models/NoteOppModel";
const API_URL = process.env.REACT_APP_API;

export async function getHistoryNotes(search: string, id: string) {
  const response = await axios.get(
    `${API_URL}catalogos/cuentas/notas/historial/${id}`
  );
  const data = response.data.doc.data;
  return data;
}
export async function createHistoryMessageNotes(message: string, id: string) {
  const response = await axios.post(
    `${API_URL}catalogos/cuentas/notas/historial/comentario`,
    {
      nota_id: id,
      comentario: message,
    }
  );
  return response;
}

export const getNoteByID = async (id: string) => {
  const response = await axios.get(`${API_URL}catalogos/cuentas/notas/${id}`);
  let note = response.data.doc.data;
  note = {
    ...note,
    cuenta_id: note.cuenta?.id | 0,
    created_at: note.created_at
      ? new Date(note.created_at).toISOString().split("T")[0]
      : new Date().toISOString().split("T")[0],
    updated_at: note.updated_at
      ? new Date(note.updated_at).toISOString().split("T")[0]
      : new Date().toISOString().split("T")[0],
  };
  return note;
};
export async function updateNote(note: NoteModel) {
  const { id, nombre, descripcion } = note;
  const response = await axios.put(`${API_URL}catalogos/cuentas/notas/${id}`, {
    nombre: nombre,
    descripcion: descripcion,
  });
  return response;
}

export async function getNotesByOpoId(id: string) {
  const response = await axios.get(`${API_URL}oportunidad/notas/opp/${id}`);
  const data = response.data.doc.data;
  return data;
}

export async function insertNoteOpp(note: NoteModel, oportunityId: number) {
  const response = await axios.post(`${API_URL}oportunidad/notas/add`, {
    nombre: note.nombre,
    descripcion: note.descripcion,
    oportunidad_id: Number(oportunityId),
  });
  return response;
}
export async function getHistoryNotesOpp(search: string, id: string) {
  const response = await axios.get(
    `${API_URL}oportunidad/notas/historial/${id}`
  );
  const data = response.data.doc.data;
  return data;
}
export async function createHistoryMessageNotesOpp(
  message: string,
  id: string
) {
  const response = await axios.post(
    `${API_URL}oportunidad/notas/historial/comentario`,
    {
      nota_opp_id: id,
      comentario: message,
    }
  );
  return response;
}
export const getNoteOppByID = async (id: string) => {
  const response = await axios.get(`${API_URL}oportunidad/notas/${id}`);
  let note = response.data.doc.data;
  note = {
    ...note,
    oportunidad_id: note.oportunidad?.id | 0,
    created_at: note.created_at
      ? new Date(note.created_at).toISOString().split("T")[0]
      : new Date().toISOString().split("T")[0],
    updated_at: note.updated_at
      ? new Date(note.updated_at).toISOString().split("T")[0]
      : new Date().toISOString().split("T")[0],
  };
  return note;
};
export async function updateNoteOpp(note: NoteOppModel) {
  const { id, nombre, descripcion } = note;
  const response = await axios.put(`${API_URL}oportunidad/notas/${id}`, {
    nombre: nombre,
    descripcion: descripcion,
  });
  return response;
}

export const getNotePackageByID = async (id: string) => {
  const response = await axios.get(`${API_URL}cotizacion/paquete/notas/${id}`);
  let note = response.data.doc.data;
  note = {
    ...note,
    cuenta_id: note.cuenta?.id | 0,
    created_at: note.created_at
      ? new Date(note.created_at).toISOString().split("T")[0]
      : new Date().toISOString().split("T")[0],
    updated_at: note.updated_at
      ? new Date(note.updated_at).toISOString().split("T")[0]
      : new Date().toISOString().split("T")[0],
  };
  return note;
};
export async function updateNotePackage(note: NoteModel) {
  const { id, nombre, descripcion } = note;
  const response = await axios.patch(
    `${API_URL}cotizacion/paquete/notas/${id}`,
    {
      nombre: nombre,
      descripcion: descripcion,
    }
  );
  return response;
}

export const getNoteProductByID = async (id: string) => {
  const response = await axios.get(`${API_URL}cotizacion/producto/notas/${id}`);
  let note = response.data.doc.data;
  note = {
    ...note,
    created_at: note.created_at
      ? new Date(note.created_at).toISOString().split("T")[0]
      : new Date().toISOString().split("T")[0],
    updated_at: note.updated_at
      ? new Date(note.updated_at).toISOString().split("T")[0]
      : new Date().toISOString().split("T")[0],
  };
  return note;
};

export async function updateNoteProduct(note: NoteModel) {
  const { id, nombre, descripcion } = note;
  const response = await axios.put(
    `${API_URL}cotizacion/producto/notas/${id}`,
    {
      nombre: nombre,
      descripcion: descripcion,
    }
  );
  return response;
}

export const getNoteServiceById = async (id: string) => {
  const response = await axios.get(`${API_URL}servicios/notas/${id}`);
  let note = response.data.doc.data;
  note = {
    ...note,
    created_at: note.created_at
      ? new Date(note.created_at).toISOString().split("T")[0]
      : new Date().toISOString().split("T")[0],
    updated_at: note.updated_at
      ? new Date(note.updated_at).toISOString().split("T")[0]
      : new Date().toISOString().split("T")[0],
  };
  return note;
};

export async function updateNoteService(note: NoteModel) {
  const { id, nombre, descripcion } = note;
  const response = await axios.put(`${API_URL}servicios/notas/${id}`, {
    nombre: nombre,
    descripcion: descripcion,
  });
  return response;
}
