import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ConfigModel } from '../models/ScoreCard';
import { getConfigScoreCard } from '../../../service/scorecard/ScoreCard';

export const useGetConfigScoredCard = (
  realoadGrid: number,
  execute: boolean
) => {
  const [dataConfig, setDataConfig] = useState<ConfigModel>({
    id: 0,
    dia: 0,
    hora: 0,
    minuto: 0,
    vendedor_id: 0,
    created_by: 0,
    updated_by: 0,
    createdAt: new Date(),
    updatedAt: new Date(),
  });
  const [loadingRequest, setLoadingRequest] = useState(0);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      setLoadingRequest(0);
      const res = await getConfigScoreCard()
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        setDataConfig(res.data);
        setLoadingRequest(1);
      }
    };
    if (realoadGrid && execute) {
      fetchPost();
    }
  }, [realoadGrid, execute]);

  return { dataConfig, loadingRequest };
};
