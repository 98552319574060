import React, { useState, useEffect } from 'react';
import { KTSVG } from '../../../../_metronic/helpers';
import { Modal } from 'react-bootstrap-v5';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { useGetConfigScoredCard } from '../hook/ScoreCardHook';
import {
  updConfigScoreCard,
  addConfigScoreCard,
} from '../../../service/scorecard/ScoreCard';

type Props = {
  show: boolean;
  handleClose: (updateGrid: boolean) => void;
  title: string;
  id_row: number;
};

const ConfigModal: React.FC<Props> = ({ id_row, show, handleClose, title }) => {
  const [loading, setLoading] = useState(false);
  const [reload, setReloadGrid] = useState(Math.random() * 40);
  const [execute, setExecute] = useState<boolean>(false);
  const [selectedDay, setSelectedDay] = useState<number | null>(null);
  const [idRecord, setIdRecord] = useState<number>(0);
  const { dataConfig } = useGetConfigScoredCard(reload, execute);

  const handleDayChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value, 10);
    setSelectedDay(value);
  };

  useEffect(() => {
    setExecute(show);
  }, [show]);

  useEffect(() => {
    if (dataConfig) {
      const { id, dia } = dataConfig;
      setIdRecord(id);
      formConfig.setFieldValue('record_id', id);
      setSelectedDay(dia);
    }
  }, [dataConfig]);

  useEffect(() => {
    formConfig.setFieldValue('day', selectedDay);
  }, [selectedDay]);

  const initValues = {
    day: '',
    record_id: 0,
  };
  const formConfig = useFormik({
    initialValues: initValues,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      setLoading(true);
      setTimeout(() => {
        if (idRecord > 0) {
          updConfigScoreCard(values)
            .then((res) => {
              const {
                data: { message },
              } = res;
              toast.success(message, {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
              });
              // formTarea.resetForm();
              setLoading(false);
              setReloadGrid(Math.random() * 40);
            })
            .catch((err) => {
              let resMessageToast: string = '';
              const {
                data: { message, details = null },
              } = err.response;
              resMessageToast = `${message}`;
              setLoading(false);
              setSubmitting(false);
              toast.error(resMessageToast, {
                position: 'top-right',
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
              });
            });
        } else {
          addConfigScoreCard(values)
            .then((res) => {
              const {
                data: { message },
              } = res;
              toast.success(message, {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
              });
              // formTarea.resetForm();
              setLoading(false);
              setReloadGrid(Math.random() * 40);
            })
            .catch((err) => {
              let resMessageToast: string = '';
              const {
                data: { message, details = null },
              } = err.response;
              resMessageToast = `${message}`;
              setLoading(false);
              setSubmitting(false);
              toast.error(resMessageToast, {
                position: 'top-right',
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
              });
            });
        }
      }, 1000);
    },
  });

  return (
    <Modal
      id="kt_modal_create"
      tabIndex={-1}
      aria-hidden="true"
      backdrop="static"
      dialogClassName="modal-dialog mw-800px"
      show={show}
      onHide={() => {
        // formConfig.resetForm();
        handleClose(false);
      }}
    >
      <div className="modal-content">
        <div className="modal-header">
          <h2>{title}</h2>
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            onClick={() => {
              //   formTarea.resetForm();
              handleClose(false);
            }}
          >
            <KTSVG
              className="svg-icon-2"
              path="/media/icons/duotune/arrows/arr061.svg"
            />
          </div>
        </div>
        <div className="modal-body scroll-y mx-5">
          <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed mb-9 p-6">
            <KTSVG
              path="/media/icons/duotune/general/gen044.svg"
              className="svg-icon-2tx svg-icon-warning me-4"
            />

            <div className="d-flex flex-stack flex-grow-1">
              <div className="fw-bold">
                <h4 className="text-gray-800 fw-bolder">
                  Configuración de envío automatico
                </h4>
                <div className="fs-6 text-gray-600">
                  Al guardar la información se estará enviando automáticamente
                  el ScoreCard a tu correo electrónico en el día de la semana
                  seleccionado.
                  <br />
                </div>
              </div>
            </div>
          </div>
          <form
            id="kt_modal_generic_form"
            className="form"
            action="#"
            onSubmit={formConfig.handleSubmit}
          >
            <div className="d-flex flex-column flex-xl-row flex-row-fluid mt-10">
              <div className="w-100 d-flex">
                <div className="w-100 pe-5">
                  <div className="d-flex fv-row">
                    <div className="form-check form-check-custom form-check-solid">
                      <input
                        className="form-check-input me-3"
                        type="radio"
                        value={1}
                        name="dias"
                        checked={selectedDay === 1}
                        onChange={handleDayChange}
                      />
                      <label className="form-check-label">
                        <div className="fw-bolder text-gray-800">Lunes</div>
                      </label>
                    </div>

                    <div
                      className="form-check form-check-custom form-check-solid"
                      style={{ paddingLeft: '15px' }}
                    >
                      <input
                        className="form-check-input me-3"
                        type="radio"
                        value={2}
                        name="dias"
                        checked={selectedDay === 2}
                        onChange={handleDayChange}
                      />
                      <label className="form-check-label">
                        <div className="fw-bolder text-gray-800">Martes</div>
                      </label>
                    </div>

                    <div
                      className="form-check form-check-custom form-check-solid"
                      style={{ paddingLeft: '15px' }}
                    >
                      <input
                        className="form-check-input me-3"
                        type="radio"
                        value={3}
                        name="dias"
                        checked={selectedDay === 3}
                        onChange={handleDayChange}
                      />
                      <label className="form-check-label">
                        <div className="fw-bolder text-gray-800">Miércoles</div>
                      </label>
                    </div>
                    <div
                      className="form-check form-check-custom form-check-solid"
                      style={{ paddingLeft: '15px' }}
                    >
                      <input
                        className="form-check-input me-3"
                        type="radio"
                        value={4}
                        name="dias"
                        checked={selectedDay === 4}
                        onChange={handleDayChange}
                      />
                      <label className="form-check-label">
                        <div className="fw-bolder text-gray-800">Jueves</div>
                      </label>
                    </div>
                    <div
                      className="form-check form-check-custom form-check-solid"
                      style={{ paddingLeft: '15px' }}
                    >
                      <input
                        className="form-check-input me-3"
                        type="radio"
                        value={5}
                        name="dias"
                        checked={selectedDay === 5}
                        onChange={handleDayChange}
                      />
                      <label className="form-check-label">
                        <div className="fw-bolder text-gray-800">Viernes</div>
                      </label>
                    </div>
                    <div
                      className="form-check form-check-custom form-check-solid"
                      style={{ paddingLeft: '15px' }}
                    >
                      <input
                        className="form-check-input me-3"
                        type="radio"
                        value={6}
                        name="dias"
                        checked={selectedDay === 6}
                        onChange={handleDayChange}
                      />
                      <label className="form-check-label">
                        <div className="fw-bolder text-gray-800">Sábado</div>
                      </label>
                    </div>
                    <div
                      className="form-check form-check-custom form-check-solid"
                      style={{ paddingLeft: '15px' }}
                    >
                      <input
                        className="form-check-input me-3"
                        type="radio"
                        value={0}
                        name="dias"
                        checked={selectedDay === 0}
                        onChange={handleDayChange}
                      />
                      <label className="form-check-label">
                        <div className="fw-bolder text-gray-800">Domingo</div>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-end mt-10">
              <button
                type="reset"
                className="btn btn-light me-3"
                data-kt-users-modal-action="cancel"
                onClick={() => {
                  //   formTarea.resetForm();
                  handleClose(false);
                }}
              >
                Cancelar
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                data-kt-users-modal-action="submit"
                disabled={loading}
              >
                {!loading && (
                  <span className="indicator-label">
                    {/* {id_row > 0 ? 'Guardar' : 'Crear'} */}
                    Guardar
                  </span>
                )}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: 'block' }}
                  >
                    Espere por favor...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};
export { ConfigModal };
