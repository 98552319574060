import React, { useState, useEffect } from 'react';
import { KTSVG } from '../../../../../_metronic/helpers';
import { Modal } from 'react-bootstrap-v5';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { addSubCuenta } from '../../../../service/subcuenta/Subcuenta';
import { AccountModel } from '../../../account/models/AccountModel';
import { styleSelect } from '../../../../../_metronic/helpers/SelectStyles';
import Select from 'react-select';

type Props = {
  show: boolean;
  handleClose: (isNew: boolean, newSubcuenta: any) => void;
  cuentaList: AccountModel[];
  cuentaSelected: number;
  cuentaObj: any;
};

const SubCuentaSchema = Yup.object().shape({
  nombre: Yup.string().required('Este campo es requerido.'),
  cuenta_id: Yup.number()
    .min(1, 'Este campo es requerido.')
    .required('Este campo es requerido.'),
});

const CreateSubCuentaModal: React.FC<Props> = ({
  show,
  handleClose,
  cuentaList,
  cuentaSelected,
  cuentaObj,
}) => {
  const [loading, setLoading] = useState(false);
  const [account, setAccount] = useState<any | null>(null);
  const [accFilt, setAccFilt] = useState<AccountModel[]>();

  useEffect(() => {

    if (cuentaList) {
      const filteredAccs = cuentaList.filter((option: any) => option.value !== 'new');
      setAccFilt(filteredAccs)
    }
  }, [cuentaList]);

  useEffect(() => {
    if (cuentaObj) {
      setAccount(cuentaObj);
    }else {
      setAccount(0);
    }
  }, [cuentaObj]);

  const formSubCuenta = useFormik({
    initialValues: {
      nombre: '',
      cuenta_id: account ? account.value : 0,
    },
    validationSchema: SubCuentaSchema,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      setLoading(true);
      setTimeout(() => {
        addSubCuenta(values)
          .then((res) => {
            const {
              data: {
                message,
                doc: {
                  data: { id, nombre },
                },
              },
            } = res;
            toast.success(message, {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light',
            });
            setLoading(false);
            formSubCuenta.resetForm();
            handleClose(true, {
              value: id,
              label: nombre,
            });
          })
          .catch((err) => {
            let resMessageToast: string = '';
            const {
              data: { message, details = null },
            } = err.response;
            resMessageToast = `${message}`;
            setLoading(false);
            setSubmitting(false);
            toast.error(resMessageToast, {
              position: 'top-right',
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light',
            });
          });
      }, 1000);
    },
  });

  const handleSelect = (selectedOption: any, selectId: string) => {
    const { value } = selectedOption;

    switch (selectId) {
      case 'CUENTA':
        setAccount(selectedOption);
        formSubCuenta.setFieldValue('cuenta_id', value);
        break;
      default:
        break;
    }
  };

  return (
    <Modal
      id="kt_modal_create"
      tabIndex={-1}
      aria-hidden="true"
      backdrop="static"
      dialogClassName="modal-dialog mw-600px"
      show={show}
      onHide={() => {
        formSubCuenta.resetForm();
        handleClose(false, 0);
      }}
    >
      <div className="modal-content">
        <div className="modal-header">
          <h2 className="text-muted">{'Datos de la Subcuenta'}</h2>
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            onClick={() => {
              formSubCuenta.resetForm();
              handleClose(false, 0);
            }}
          >
            <KTSVG
              className="svg-icon-2"
              path="/media/icons/duotune/arrows/arr061.svg"
            />
          </div>
        </div>
        <div className="modal-body mx-5 padding-top-00">
          <form
            id="kt_modal_generic_form"
            className="form"
            action="#"
            onSubmit={formSubCuenta.handleSubmit}
          >
            <div className="row wh-0100 mb-3">
              <div className="col-xxl-12 col-xl-12 col-md-12 col-lg-12">
                <input
                  className="form-control mb-2 w-100 border-0 padd-left input-name-form"
                  data-kt-element="input"
                  placeholder="Nombre *"
                  {...formSubCuenta.getFieldProps('nombre')}
                ></input>
                {formSubCuenta.touched.nombre && formSubCuenta.errors.nombre && (
                  <div className="fv-plugins-message-container text-danger w-100">
                    <span role="alert">{formSubCuenta.errors.nombre}</span>
                  </div>
                )}
              </div>
            </div>
            <div className="d-flex flex-column flex-xl-row flex-row-fluid">
              <div className="w-100 d-flex">
                <div className="w-100 pe-5">
                  <div className="w-100 d-flex">
                    <label className="required fw-bold fs-6 mb-2 w-auto">
                      Cuenta
                    </label>
                  </div>
                  <Select
                    options={accFilt}
                    isDisabled
                    styles={styleSelect}
                    placeholder={'Seleccione'}
                    isSearchable={true}
                    noOptionsMessage={() => {
                      return <span>{'No se encontraron registros'}</span>;
                    }}
                    onChange={(event) => handleSelect(event, 'CUENTA')}
                    value={account}
                  />
                  {formSubCuenta.touched.cuenta_id &&
                    formSubCuenta.errors.cuenta_id && (
                      <div className="fv-plugins-message-container text-danger w-100">
                        <span role="alert">
                          {formSubCuenta.errors.cuenta_id}
                        </span>
                      </div>
                    )}
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-end mt-10">
              <button
                type="reset"
                className="btn btn-light me-3"
                data-kt-users-modal-action="cancel"
                onClick={() => {
                  formSubCuenta.resetForm();
                  handleClose(false, 0);
                }}
              >
                Cancelar
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                data-kt-users-modal-action="submit"
                disabled={loading}
              >
                {!loading && <span className="indicator-label">Guardar</span>}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: 'block' }}
                  >
                    Espere por favor...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};
export { CreateSubCuentaModal };
