// import { TareaModel, TaskDocModel } from '../models/TaskModel';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  createHistoryMessageNotesOpp,
  getHistoryNotesOpp,
  getNoteOppByID,
  updateNoteOpp,
} from '../../../service/notes/Notes';
import { HistoryModel } from '../../../../_metronic/helpers/models/HistoryModel';
import { NoteOppModel } from '../models/NoteOppModel';

export const useGetNoteByID = (id: string, reload: number) => {
  const note: NoteOppModel = {
    id: 0,
    nombre: '',
    descripcion: '',
  };
  const [noteInit, setNoteInit] = useState<NoteOppModel>(note);

  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      const res = await getNoteOppByID(id)
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        setNoteInit(res);
      }
    };
    if (id) {
      fetchPost();
    } else {
      setNoteInit(note);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, reload]);

  return { noteInit };
};

export const useGetHistoryNotes = (
  reaload: number,
  search: string,
  id: string
) => {
  const [data, setData] = useState<HistoryModel[]>([]);
  const [loadingRequest, setLoadingRequest] = useState(0);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      setLoadingRequest(0);
      const res = await getHistoryNotesOpp(search, id)
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        setData(res);
        setLoadingRequest(1);
      }
    };
    if (reaload && Number(id) > 0) {
      fetchPost();
    }
  }, [reaload]);

  return { data, loadingRequest };
};
export const useInsertMessageNotes = () => {
  const [loadingInsert, setLoadingInsert] = useState<boolean>(false);
  const history = useHistory();
  const insertData = async (message: string, id: string) => {
    setLoadingInsert(false);
    try {
      await createHistoryMessageNotesOpp(message, id);
      setLoadingInsert(true);
    } catch (error) {
      history.push('/error/500');
      setLoadingInsert(false);
    }
  };
  return { insertData, loadingInsert };
};

export const useEditNote = () => {
  const [loadingEditNote, setLoadingEditNote] = useState<boolean>(false);
  const history = useHistory();
  const editNote = async (note: NoteOppModel) => {
    setLoadingEditNote(false);
    try {
      await updateNoteOpp(note);
      setLoadingEditNote(true);
    } catch (error) {
      history.push('/error/500');
      setLoadingEditNote(false);
    }
  };
  return { editNote, loadingEditNote };
};
