import axios from 'axios'
import { MitigateModel } from '../../pages/mitigate/models/MitigateModel'; 
const API_URL = process.env.REACT_APP_API

export const getMitigateByID = async (id: string) => {

    const response = await axios.get(`${API_URL}riesgo/mitigacion/${id}`);
    let mitigate = response.data.doc.data;
    mitigate = {
        ...mitigate,
        descripcion: mitigate.descripcion || "",
        etapa_id: mitigate.etapaMitigacion?.id | 0,
        prioridad_id: mitigate.prioridadMitigacion?.id | 0,
        status_id: mitigate.statusMitigacion?.id | 0,
        user_asignado_id: mitigate.vendedorComercial?.id | 0,
        fecha_inicio: mitigate.fecha_inicio ?  new Date(mitigate.fecha_inicio).toISOString().split('T')[0] : new Date().toISOString().split('T')[0],
        fecha_fin: mitigate.fecha_fin ?  new Date(mitigate.fecha_fin).toISOString().split('T')[0] : new Date().toISOString().split('T')[0],
    };
    return mitigate;

}
export async function updateMitigate(mitigate: MitigateModel, riesgo_id: number) {
    const { id, nombre, prioridad_id, etapa_id, user_asignado_id, fecha_inicio, fecha_fin, descripcion, status_id } = mitigate;
    const response = await axios.put(`${API_URL}riesgo/mitigacion/upd/${id}`, {
        nombre: nombre,
        descripcion: (descripcion !== "") ? descripcion : null,
        riesgo_id: riesgo_id,
        etapa_id: etapa_id,
        prioridad_id: prioridad_id,
        // estatus_id: status_id,
        vendedor_comercial_id: user_asignado_id,
        fecha_inicio: fecha_inicio,
        fecha_fin: fecha_fin,
    });
    return response;
}

export async function getHistory(search: string, id: string) {
    const response = await axios.get(`${API_URL}riesgo/mitigacion/historial/${id}`);
    const data = response.data.doc.data;
    return data;
}

export async function createHistoryMessage(message: string, id: string) {
    const response = await axios.post(`${API_URL}riesgo/mitigacion/historial/comentario`, {
        mitId: id,
        comentario: message
    });
    return response;
}
export const uploadFileMitigate = async (file: any, mitigate: string) => {

    const formData = new FormData();
    formData.append('file', file);
    formData.append('mitigationId', mitigate);

    const response = await axios.post(`${API_URL}riesgo/mitigacion/documento`, formData);
    return response;

}

export const removeFileMitigate = async (fileId: number) => {

    const response = await axios.delete(`${API_URL}riesgo/mitigacion/documento/${fileId}`);
    return response;

}

export async function getDocumentsByMitigateId(mitigateId: string) {
    const response = await axios.get(`${API_URL}riesgo/mitigacion/documento/${mitigateId}`);
    const data = response.data.doc.data;
    return data;

}