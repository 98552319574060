import axios from 'axios'
const API_URL = process.env.REACT_APP_API


export async function getConfigScoreCard() {
    const response = await axios.get(`${API_URL}configCorreoVendedor/getByUser`);
    const data = response.data.doc.data;
    return {
        data: data,
    };
}

export async function updConfigScoreCard(body: any) {

    const { record_id, day } = body;
    const data = {
        dia: day,
    }

    const response = await axios.put(`${API_URL}configCorreoVendedor/${record_id}`, data);
    return response;

}
export async function addConfigScoreCard(body: any) {

    const { day } = body;
    const data = {
        dia: day,
        hora: 1,
        minuto: 1
    }

    const response = await axios.post(`${API_URL}configCorreoVendedor`, data);
    return response;

}

