import axios from 'axios'
const API_URL = process.env.REACT_APP_API

const fieldToInt = (field: any) => {
    let parse: any = field
    if (field) {
        parse = parseInt(field)
    }
    return parse;
}
export async function getOportunidades(startIndex: number, endIndex: number, filters: any) {

    let search: string = '';
    let nombre: string = '';
    let fecha: string = '';
    let cuenta: string = '';
    let subcuenta: string = '';
    let vendedorC: string = '';
    let vendedorT: string = '';
    let status: string = '';
    let etapa: string = '';

    if (filters.nombre) {

        nombre = `&id=${filters.nombre}`;
    }

    if (filters.fecha_inicio && filters.fecha_fin) {
        fecha = `&created_atOpBetween=${filters.fecha_inicio},${filters.fecha_fin} 23:00`;
    }

    if (filters.cuenta) {
        cuenta = `&cuenta_id=${filters.cuenta}`;
    }
    if (filters.subcuenta) {
        subcuenta = `&sub_cuenta_id=${filters.subcuenta}`;

    }
    if (filters.vendedorC) {
        vendedorC = `&vendedor_comercial_id=${filters.vendedorC}`;

    }
    if (filters.vendedorT) {
        vendedorT = `&vendedor_tecnico_id=${filters.vendedorT}`;

    }
    if (filters.status) {
        status = `&status_id=${filters.status}`;

    }
    if (filters.etapa) {
        etapa = `&etapa_id=${filters.etapa}`;

    }

    search = `${nombre}${fecha.replace(/ /g, '%20')}${cuenta}${subcuenta}${vendedorC}${vendedorT}${etapa}${status}`;

    const response = await axios.get(`${API_URL}oportunidades?limit=${startIndex}&page=${endIndex}${search}`);

    const data = response.data.doc?.data?.rows;
    const count = response.data.doc?.data?.count;
    return {
        data: data,
        count: count
    };

}
export async function addOportunidad(body: any) {

    const { nombre, nombre_contacto, cantidad_estimada, fecha_aprobacion_estimada, fecha_cierre_real, probabilidad, riesgos_dependencias, mitigaciones, descripcion, siguentes_pasos,
        cuenta_id, sub_cuenta_id, tipo_oportunidad_id, etapa_id, vendedor_comercial_id, vendedor_tecnico_id, departamento_id, detalles, moneda_id, status_id, unidad_negocio_id } = body;

    const data = {
        nombre: nombre,
        contacto_id: nombre_contacto ? fieldToInt(nombre_contacto) : null,
        cantidad_estimada: cantidad_estimada,
        fecha_aprobacion_estimada: fecha_aprobacion_estimada === '' ? null : fecha_aprobacion_estimada,
        fecha_cierre_real: fecha_cierre_real === '' ? null : fecha_cierre_real,
        probabilidad: probabilidad,
        riesgos_dependencias: riesgos_dependencias,
        mitigaciones: mitigaciones,
        descripcion: descripcion,
        siguentes_pasos: siguentes_pasos,
        cuenta_id: fieldToInt(cuenta_id),
        sub_cuenta_id: sub_cuenta_id ? fieldToInt(sub_cuenta_id) : null,
        tipo_id: tipo_oportunidad_id ? fieldToInt(tipo_oportunidad_id) : null,
        etapa_id: fieldToInt(etapa_id),
        status_id: fieldToInt(status_id),
        vendedor_comercial_id: vendedor_comercial_id,
        vendedor_tecnico_id: vendedor_tecnico_id ? vendedor_tecnico_id : null,
        departamento_id: departamento_id ? fieldToInt(departamento_id) : null,
        detalles: detalles,
        moneda_id: moneda_id ? moneda_id : null,
        unidad_negocio_id: unidad_negocio_id ? unidad_negocio_id : null,
    }

    const response = await axios.post(`${API_URL}oportunidades/`, data);
    return response;
}
export async function getOportunidadById(id_row: number) {

    const response = await axios.get(`${API_URL}oportunidades/${id_row}`);
    const { id, nombre, cantidad_estimada, fecha_aprobacion_estimada, fecha_cierre_real, probabilidad, mitigaciones,
        cuenta, subCuenta, tipoOportunidad, etapa, status, vendedorComercial, created_at, departamento, contacto, company_id, moneda, documentos, detalles, unidad } = response.data.doc.data;

    return {
        id: id,
        nombre: nombre,
        nombre_contacto: contacto?.id,
        cantidad_estimada: cantidad_estimada,
        fecha_aprobacion_estimada: fecha_aprobacion_estimada ? fecha_aprobacion_estimada : '',
        fecha_cierre_real: fecha_cierre_real ? fecha_cierre_real : '',
        created_at: created_at,
        probabilidad: probabilidad,
        mitigaciones: mitigaciones,
        detalles: detalles,
        cuenta: {
            id: cuenta.id,
            nombre: cuenta.nombre,
        },
        subCuenta: {
            id: subCuenta ? subCuenta.id : '',
            nombre: subCuenta ? subCuenta.nombre : '',
        },
        tipoOportunidad: {
            id: tipoOportunidad?.id,
            name: tipoOportunidad?.name,
            code: tipoOportunidad?.code,
        },
        etapa: {
            id: etapa?.id,
            name: etapa?.name,
            code: etapa?.code,
        },
        unidad: {
            id: unidad?.id,
            name: unidad?.name,
            code: unidad?.code,
        },
        status: {
            id: status?.id,
            name: status?.name,
            code: status?.code,
        },
        vendedorComercial: {
            id: vendedorComercial?.id,
            nombre: vendedorComercial?.nombre,
            apellido_paterno: vendedorComercial?.apellido_paterno,
            apellido_materno: vendedorComercial?.apellido_materno,
            email: vendedorComercial?.email,
        },
        contacto: {
            id: contacto?.id ?? '',
            nombre: contacto?.nombre ?? '',
            apellido_paterno: contacto?.apellido_paterno ?? '',
            apellido_materno: contacto?.apellido_materno ?? '',
            email: contacto?.email ?? '',
        },
        departamento: {
            id: departamento?.id,
            name: departamento?.name,
            code: departamento?.code
        },
        cuenta_id: cuenta?.id,
        sub_cuenta_id: subCuenta?.id,
        tipo_oportunidad_id: tipoOportunidad?.id,
        etapa_id: etapa?.id,
        estatus_id: status?.id,
        vendedor_comercial_id: vendedorComercial?.id,
        departamento_id: departamento?.id,
        tareasAbiertas: 0,
        tareasCerradas: 0,
        status_id: status?.id,
        moneda: {
            id: moneda?.id,
            name: moneda?.name,
            code: moneda?.code
        },
        documentos: documentos,
        company_id: company_id,
        unidad_negocio_id: unidad?.id,
        moneda_id: moneda?.id
    };


}
export async function updateOportunidad(body: any) {

    const { id, nombre, nombre_contacto, cantidad_estimada, fecha_aprobacion_estimada, fecha_cierre_real, probabilidad, riesgos_dependencias, mitigaciones, descripcion, siguentes_pasos,
        cuenta_id, sub_cuenta_id, tipo_oportunidad_id, etapa_id, vendedor_comercial_id, vendedor_tecnico_id, departamento_id, detalles, moneda_id, status_id, unidad_negocio_id } = body;
    const data = {
        nombre: nombre,
        contacto_id: nombre_contacto ? fieldToInt(nombre_contacto) : null,
        cantidad_estimada: cantidad_estimada,
        fecha_aprobacion_estimada: fecha_aprobacion_estimada === '' ? null : fecha_aprobacion_estimada,
        fecha_cierre_real: fecha_cierre_real === '' ? null : fecha_cierre_real,
        probabilidad: probabilidad,
        riesgos_dependencias: riesgos_dependencias,
        mitigaciones: mitigaciones,
        descripcion: descripcion,
        siguentes_pasos: siguentes_pasos,
        cuenta_id: fieldToInt(cuenta_id),
        sub_cuenta_id: sub_cuenta_id ? fieldToInt(sub_cuenta_id) : null,
        tipo_id: tipo_oportunidad_id ? fieldToInt(tipo_oportunidad_id) : null,
        etapa_id: fieldToInt(etapa_id),
        status_id: fieldToInt(status_id),
        vendedor_comercial_id: vendedor_comercial_id,
        vendedor_tecnico_id: vendedor_tecnico_id === '' ? null : vendedor_tecnico_id,
        departamento_id: departamento_id === '' ? null : departamento_id,
        detalles: detalles,
        moneda_id: moneda_id ? fieldToInt(moneda_id) : null,
        unidad_negocio_id: unidad_negocio_id ? fieldToInt(unidad_negocio_id) : null
    }

    const response = await axios.put(`${API_URL}/oportunidades/${id}`, data);
    return response;
}
export async function getAllOportunidades() {

    const response = await axios.get(`${API_URL}oportunidades`);

    const data = response.data.doc?.data?.rows;
    const count = response.data.doc?.data?.count;
    return {
        data: data,
        count: count
    };

}
export async function getAllOportunidadesTareas(cuentaId: number, subcuentaId: number) {

    let cuenta: string = '';
    let subcuenta: string = '';

    if (cuentaId) {
        cuenta = `&cuenta_id=${cuentaId}`;
    }
    if (subcuentaId) {
        subcuenta = `&sub_cuenta_id=${subcuentaId}`;
    }

    const response = await axios.get(`${API_URL}tareas/getAll/oportunidades?${cuenta}${subcuenta}`);

    const data = response.data.doc?.data?.rows;
    const count = response.data.doc?.data?.count;
    return {
        data: data,
        count: count
    };

}
export async function getAllVendedoresComerciales() {

    const response = await axios.get(`${API_URL}catalogos/vendedores/list/comerciales`);

    const data = response.data?.doc?.data?.rows;
    const count = response.data?.doc?.data?.count;
    return {
        data: data,
        count: count
    };

}
export async function getAllVendedoresTecnicos() {

    const response = await axios.get(`${API_URL}catalogos/vendedores/list/tecnicos`);

    const data = response.data?.doc?.data?.rows;
    const count = response.data?.doc?.data?.count;
    return {
        data: data,
        count: count
    };

}
export async function updateOpportunityStage(values: any) {

    const { oportunidadId, etapaCode } = values;

    let data = {
        oportunidadId,
        codeStage: etapaCode
    }

    const response = await axios.put(`${API_URL}oportunidades/cambiar/etapa`, data);
    return response;
}
export const uploadFileToOpportunity = async (file: any, opportunity: string) => {

    const formData = new FormData();
    formData.append('file', file);
    formData.append('oportunidadId', opportunity);

    const response = await axios.post(`${API_URL}oportunidad/documento`, formData);
    return response;

}
export const removeFileOfOpportunity = async (oppId: number) => {

    const response = await axios.delete(`${API_URL}oportunidad/documento/${oppId}`);
    return response;

}
export async function getHistory(search: string, id: string) {

    const response = await axios.get(`${API_URL}oportunidad/historial/${id}`);
    const data = response.data.doc.data;
    return data;

}
export async function createHistoryMessage(message: string, id: string) {

    const response = await axios.post(`${API_URL}oportunidad/historial/comentario`, {
        oportunidadId: id,
        comentario: message
    });
    return response;

}
export async function createTaskOportunity(taskName: string, oportunityId: string) {
    const response = await axios.post(`${API_URL}/tareas/`, {
        oportunidad_id: oportunityId,
        nombre: taskName
    });
    return response;
}
export async function getTasksByIdOportunity(oportunityId: string) {

    const response = await axios.post(`${API_URL}tareas/oportunidad/`, {
        oportunidad_id: oportunityId
    });
    const data = response.data.doc.data;
    return data;

}
export async function getRisksByIdOportunity(oportunityId: string) {

    const response = await axios.get(`${API_URL}oportunidad/riesgo/opp/${oportunityId}`);
    const data = response.data.doc.data;
    return data;

}
export async function createRiskOportunity(riskName: string, oportunityId: string) {
    const response = await axios.post(`${API_URL}oportunidad/riesgo/add`, {
        oportunidad_id: oportunityId,
        nombre: riskName
    });
    return response;
}
export async function createDptoOportunity(body: any) {
    const { name } = body;
    const data = {
        name
    }

    const response = await axios.post(`${API_URL}/catalogs/add/dpto/`, data);
    return response;
}

export async function getDocumentsByOportunityId(OportunityId: number) {
    const response = await axios.get(`${API_URL}oportunidad/documento/${OportunityId}`);
    const data = response.data.doc.data;
    return data;

}

export async function createBusinessOportunity(body: any) {
    const { name } = body;
    const data = {
        name
    }
    const response = await axios.post(`${API_URL}/catalogs/add/unit/`, data);
    return response;
}

export async function getQuotationsByIdOportunity(oportunityId: string) {

    const response = await axios.get(`${API_URL}cotizaciones/oportunidad/${oportunityId}`);
    const data = response.data.doc.data;
    return data;

}