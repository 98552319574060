import React, { useState, useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { UserModel } from '../../../modules/auth/models/UserModel';
import { RootState } from '../../../../setup';
import { KTSVG, userHasRole } from '../../../../_metronic/helpers';
import { useFormik } from 'formik';
import Select from 'react-select';

type Props = {
  initFilterValues: any;
  onCleanFilter: () => void;
  onSearchFilter: (values: any) => void;
  filterSellers: any;
  filterTypes: any;
  filterAccounts: any;
};

const Filter: React.FC<Props> = ({
  initFilterValues,
  onSearchFilter,
  onCleanFilter,
  filterSellers,
  filterTypes,
  filterAccounts,
}) => {
  // OBTENEMOS EL USUARIO PARA ACCESAR A LOS PERMISOS
  const user: UserModel = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  ) as UserModel;
  const { roles: userRoles } = user;

  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOptionType, setSelectedOptionType] = useState(null);
  const [selectedOptionAccounts, setSelectedOptionAccounts] = useState(null);
  // INICIALIZA EL FORMULARIO CON LOS FILTROS
  const [filterValues, setFilterValues] = useState(initFilterValues);
  useEffect(() => {
    if (initFilterValues) {
      setFilterValues(initFilterValues);
      if (!initFilterValues.agente) {
        setSelectedOption(null);
      }
      if (!initFilterValues.tipo_oportunidad) {
        setSelectedOptionType(null);
      }
      if (!initFilterValues.cuenta) {
        setSelectedOptionAccounts(null);
      }
    }
  }, [initFilterValues]);

  const styleSelect = {
    placeholder: (provided: any, state: any) => ({
      ...provided,
      color: '#181c32',
    }),
    indicatorSeparator: (provided: any, state: any) => ({
      ...provided,
      display: 'none',
    }),
    dropdownIndicator: (provided: any, state: any) => ({
      ...provided,
      color: '#7E8199',
    }),
    control: (provided: any, state: any) => ({
      ...provided,
      border: '1px solid #e4e6ef',
      borderRadius: '6px',
      padding: '3px',
      fontSize: '1.1rem',
      boxShadow: 'none',
      backgroundColor: state.isDisabled ? '#EFF2F5' : 'white',
    }),
    singleValue: (provided: any, state: any) => ({
      ...provided,
      color: state.isDisabled ? '#181c32' : 'inherit',
      fontWeight: 500,
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      fontSize: '1.1rem',
    }),
  };

  const formFilter = useFormik({
    initialValues: filterValues,
    enableReinitialize: true,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      onSearchFilter({ ...values, filter: 1 });
    },
  });

  // SE CREA EL MANEJADOR DEL SELECT DE LOS VENDEDORES
  const handleSelect = (selectedOption: any) => {
    const { value, label } = selectedOption;
    setSelectedOption(selectedOption);
    formFilter.setFieldValue('agente', value);
    formFilter.setFieldValue('label_agente', label);
  };
  // SE CREA EL MANEJADOR DEL SELECT DE TIPO DE OPORTUNIDADES
  const handleSelectType = (selectedOption: any) => {
    const { value, label } = selectedOption;
    setSelectedOptionType(selectedOption);
    formFilter.setFieldValue('tipo_oportunidad', value);
    formFilter.setFieldValue('label_oportunidad', label);
  };
  // SE CREA EL MANEJADOR DEL SELECT DE CUENTAS
  const handleSelectAccount = (selectedOption: any) => {
    const { value, label } = selectedOption;
    setSelectedOptionAccounts(selectedOption);
    formFilter.setFieldValue('cuenta', value);
    formFilter.setFieldValue('label_cuenta', label);
  };

  return (
    <>
      <div className="card-title d-flex justify-content-end mb-3">
        <a
          href="#/"
          className={
            formFilter.values.filter !== 0
              ? ' btn btn-sm btn-primary'
              : 'btn btn-sm btn-light-primary'
          }
          data-kt-menu-trigger="click"
          data-kt-menu-placement="bottom-end"
          data-kt-menu-flip="top-end"
        >
          <KTSVG
            path="/media/icons/duotune/general/gen031.svg"
            className="svg-icon-5 svg-icon-gray-500 me-1"
          />
          Filtrar
        </a>
        <div
          className="menu menu-sub menu-sub-dropdown w-250px w-md-300px"
          data-kt-menu="true"
        >
          <div className="px-7 py-5">
            <div className="fs-5 text-dark fw-bolder">Opciones de filtro</div>
          </div>

          <form
            id="kt_modal_generic_form"
            className="form"
            action="#"
            onSubmit={formFilter.handleSubmit}
          >
            <div className="px-7 py-5">
              <div className="mb-5">
                <label className="form-label fw-bold">
                  Líneas de Negocio:{' '}
                </label>
                <div onClick={(e) => e.stopPropagation()}>
                  <Select
                    options={filterTypes}
                    placeholder={'Seleccione'}
                    onChange={handleSelectType}
                    value={selectedOptionType}
                    noOptionsMessage={() => {
                      return <span>No se encontraron registros</span>;
                    }}
                    styles={styleSelect}
                  />
                </div>
              </div>
              <div className="mb-5">
                <label className="form-label fw-bold">Cuenta:</label>
                <div onClick={(e) => e.stopPropagation()}>
                  <Select
                    options={filterAccounts}
                    placeholder={'Seleccione'}
                    onChange={handleSelectAccount}
                    value={selectedOptionAccounts}
                    noOptionsMessage={() => {
                      return <span>No se encontraron registros</span>;
                    }}
                    styles={styleSelect}
                  />
                </div>
              </div>

              {userHasRole(['ADMIN', 'DIR'], userRoles) && (
                <div className="mb-5">
                  <label className="form-label fw-bold">Agentes: </label>
                  <div onClick={(e) => e.stopPropagation()}>
                    <Select
                      options={filterSellers}
                      placeholder={'Seleccione'}
                      onChange={handleSelect}
                      value={selectedOption}
                      noOptionsMessage={() => {
                        return <span>No se encontraron registros</span>;
                      }}
                      styles={styleSelect}
                    />
                  </div>
                </div>
              )}

              <div className="d-flex justify-content-end ">
                <button
                  type="reset"
                  className="btn btn-sm btn-white btn-active-light-primary me-2"
                  data-kt-menu-dismiss="true"
                  onClick={() => {
                    formFilter.resetForm();
                    onCleanFilter();
                    setSelectedOption(null);
                    setSelectedOptionType(null);
                    setSelectedOptionAccounts(null);
                  }}
                >
                  Limpiar
                </button>

                <button
                  type="submit"
                  className="btn btn-sm btn-primary"
                  data-kt-menu-dismiss="true"
                >
                  Buscar
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export { Filter };
