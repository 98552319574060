import React from 'react';

const PreloadView: React.FC = () => {
  return (
    <>
      <div className="col-xl-12">
        <div className={`card card-xxl-stretch mb-xl-12`}>
          <div className="card-body py-3 h-700px">
            <div className="w-100 d-flex justify-content-center align-items-center h-100">
              <span
                className="indicator-progress text-primary"
                style={{ display: 'block' }}
              >
                Cargando...
                <span className="spinner-border spinner-border align-middle ms-5 text-primary"></span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { PreloadView };
