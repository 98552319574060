import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { encryptId } from '../../../../_metronic/helpers';
import DataTable, { TableColumn } from 'react-data-table-component';
import { formattedCurrency } from '../../../../_metronic/helpers/FormatCurrency';
import { dateFormatt } from '../../../../_metronic/helpers/FormatDate';
import { colorStatusOpportunity } from '../../../../_metronic/helpers/CustomElements';
import { KTSVG } from '../../../../_metronic/helpers';
import { MenuComponent } from '../../../../_metronic/assets/ts/components/MenuComponent';
import { uploadFile } from '../../oportunidades/helpers/FileOpportunity';
type Props = {
  listData: any;
};

interface RowDataList {
  code: string;
  color: string;
  etapaId: number;
  nombre: string;
  items: any[];
}

const CardList: React.FC<Props> = ({ listData }) => {
  const data: RowDataList[] = listData;
  const fileRef = useRef<HTMLInputElement>(null);
  const [realod, setReload] = useState(Math.random() * 150);
  const history = useHistory();
  const onOpenFile = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    fileRef?.current?.click();
  };

  const columns: TableColumn<RowDataList>[] = [
    {
      name: '',
      selector: (row: RowDataList) => row.nombre,
      cell: (row: any) => (
        <>
          <span
            className="badge fs-7 fw-bold mr-15 min-w-150px"
            style={{
              color: '#fff',
              backgroundColor: row.color ? row.color : '#9096EC',
            }}
          >
            {row.nombre}
          </span>
          <div
            className="badge badge-light-primary fs-7 fw-bold mr-15"
            title="Total de oportunidades"
          >
            {row.items.length}
          </div>
          {row.code !== 'CPE' && row.code !== 'CGA' ? (
            <a
              className="cursor-pointer badge badge-light-primary"
              title="Agregar oportunidad"
              onClick={() => {
                // history.push(`/oportunidades_add/${row.etapaId}`);
                history.push(
                  `/oportunidades_add/${encryptId(row.etapaId.toString())}`
                );
              }}
            >
              + Agregar oportunidad
            </a>
          ) : null}
        </>
      ),
      sortable: false,
      width: '250px',
    },
  ];

  const dropdownRender = (opportunity: any) => {
    return (
      <>
        <div className="space-items-between d-flex flex-shrink-0" key={realod}>
          <div className="mr-05">
            <div
              data-kt-menu-trigger="click"
              data-kt-menu-attach="parent"
              data-kt-menu-placement="bottom-end"
              data-kt-menu-flip="bottom"
              data-kt-menu="true"
              className="btn btn-sm btn-icon btn-active-light-primary btn-light-primary center-items"
            >
              <KTSVG
                path="/media/icons/duotune/abstract/abs011.svg"
                className="svg-icon-8"
              />
            </div>
            <div
              className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-150px py-4"
              data-kt-menu="true"
            >
              <div className="menu-item px-3 center-items">
                <a
                  className="menu-link px-3 fs-8 "
                  href="#/"
                  onClick={(event: any) => {
                    history.push(
                      `/oportunidades_edit/${encryptId(
                        opportunity.toString()
                        // secretKey
                      )}/RISKS`
                    );
                  }}
                >
                  <KTSVG
                    path="/media/icons/duotune/abstract/abs011.svg"
                    className="svg-icon-8 mr-05"
                  />
                  <span> Agregar riesgo</span>
                </a>
              </div>
              <div className="menu-item px-3 center-items">
                <a
                  className="menu-link px-3 fs-8"
                  href="#/"
                  onClick={(event: any) => {
                    history.push(
                      `/oportunidades_edit/${encryptId(
                        opportunity.toString()
                        // secretKey
                      )}/TASK`
                    );
                  }}
                >
                  <KTSVG
                    path="/media/icons/duotune/abstract/abs011.svg"
                    className="svg-icon-8 mr-05 "
                  />
                  Agregar tarea
                </a>
              </div>
            </div>
          </div>
          <div>
            <input
              style={{ display: 'none' }}
              ref={fileRef}
              type="file"
              name="documentos"
              // accept=".xls, .xlsx"
              onChange={async (event: any) => {
                if (event.currentTarget.files) {
                  let files = event.currentTarget.files;
                  if (files.length) {
                    await uploadFile(files, opportunity);
                  }
                }
              }}
            />
            <a
              href="#/"
              className="btn btn-sm btn-icon btn-active-light-primary btn-light-primary"
              onClick={onOpenFile}
              title="Subir archivo"
            >
              <KTSVG
                path="/media/icons/duotune/files/fil029.svg"
                className="svg-icon-6"
              />
            </a>
          </div>
        </div>
      </>
    );
  };

  const expandedComponent = (d: any) => {
    const columns_section: any = [
      {
        id: 'nombre',
        name: <div className="fw-bolder text-muted me-5 ">Nombre</div>,
        selector: (row: any) => row.nombre,
        cell: (row: any) => (
          <Link to={`/oportunidades_edit/${encryptId(row.id.toString())}`}>
            {`${row.nombre}`}
          </Link>
        ),
        sortable: false,
      },
      {
        id: 'vc',
        name: <div className="fw-bolder text-muted">Vendedor Comercial</div>,
        selector: (row: any) => row?.vendedorComercial?.nombre,
        cell: (row: any) => (
          <>
            <div className="symbol symbol-30px tooltip-soft">
              {row.vendedorComercial && (
                <>
                  {row.vendedorComercial.user.profile_photo ? (
                    <>
                      <img
                        src={`${process.env.REACT_APP_PUBLIC}users/${row.vendedorComercial.user.profile_photo}`}
                        crossOrigin="anonymous"
                        className="symbol-label fs-8 "
                        alt="user"
                      />
                      <span className="tooltiptext">{`${row.vendedorComercial.nombre} ${row.vendedorComercial.apellido_paterno} ${row.vendedorComercial.apellido_materno}`}</span>
                    </>
                  ) : (
                    <>
                      <span className="symbol-label fs-8 fw-bold bg-light-primary text-primary fw-bold">
                        {`${row?.vendedorComercial?.nombre[0]}${row.vendedorComercial.apellido_paterno[0]}`}
                      </span>
                      <span className="tooltiptext">{`${row.vendedorComercial.nombre} ${row.vendedorComercial.apellido_paterno} ${row.vendedorComercial.apellido_materno}`}</span>
                    </>
                  )}
                </>
              )}
            </div>
          </>
        ),
        sortable: false,
        center: true,
      },
      {
        id: 'cuenta',
        name: <div className="fw-bolder text-muted me-5">Cuenta</div>,
        selector: (row: any) => (
          <div className="text-gray-700">{row?.cuenta?.nombre}</div>
        ),
        sortable: false,
        center: true,
      },
      {
        id: 'subcuenta',
        name: <div className="fw-bolder text-muted me-5">Subcuenta</div>,
        selector: (row: any) => (
          <div className="text-gray-700">
            {' '}
            {row?.subCuenta?.nombre || 'Sin subcuenta'}
          </div>
        ),
        sortable: false,
        center: true,
      },
      {
        id: 'monto',
        name: <div className="fw-bolder text-muted">Monto estimado</div>,
        selector: (row: any) => row?.cantidad_estimada,
        cell: (row: any) => (
          <div className="fw-bolder text-gray-700">
            {formattedCurrency(row.cantidad_estimada)}
          </div>
        ),
        sortable: false,
        center: true,
      },
      {
        id: 'fe',
        name: <div className="fw-bolder text-muted me-5 ">Fecha estimada</div>,
        selector: (row: any) => row?.fecha_aprobacion_estimada,
        cell: (row: any) => (
          <>
            <div className="text-gray-700">
              {row.fecha_aprobacion_estimada
                ? dateFormatt(row.fecha_aprobacion_estimada)
                : 'N/A'}
            </div>
          </>
        ),
        sortable: false,
        center: true,
      },
      {
        id: 'sts',
        name: <div className="fw-bolder text-muted me-5">Estatus</div>,
        selector: (row: any) => row?.status?.name,
        cell: (row: any) => (
          <div
            className={`${
              row.status ? colorStatusOpportunity(row.status.code) : ''
            }`}
          >
            {row?.status ? row.status.name : 'N/A'}{' '}
          </div>
        ),
        sortable: false,
        center: true,
      },
      {
        id: 'acc',
        name: <div className="fw-bolder text-muted me-5">Acciones</div>,
        cell: (row: any) => {
          if (row?.etapa?.code != 'CGA') {
            return <>{<div>{dropdownRender(row.id)}</div>}</>;
          } else {
            return (
              <>
                {
                  <div>
                    <KTSVG
                      path="/media/icons/duotune/general/gen026.svg"
                      className="svg-icon-1 mr-05"
                    />
                  </div>
                }
              </>
            );
          }
        },
        sortable: false,
        center: true,
      },
    ];

    return (
      <>
        <DataTable
          className="mb-5"
          columns={columns_section}
          data={d.data.items}
          customStyles={{
            table: {
              style: {
                width: '900px',
                minWidth: '100%',
              },
            },
          }}
          responsive
          noDataComponent={
            <>
              <div className="d-flex align-items-center ">
                <div className="row">
                  <div className="col-xxl-12 col-xl-12 col-md-12 collg-12 text-center wh-0100">
                    <div className="text-muted fs-6 w-100 m-5">
                      Sin registros
                    </div>
                  </div>
                </div>
              </div>
            </>
          }
        />
      </>
    );
  };

  setTimeout(() => {
    MenuComponent.reinitialization();
  }, 1000);

  return (
    <>
      <DataTable
        columns={columns}
        data={data}
        responsive
        expandableRows
        expandableRowsComponent={expandedComponent}
        noDataComponent={
          <>
            <div className="d-flex align-items-center">
              <div className="text-muted text-center fs-6 w-100 m-5">
                Sin registros
              </div>
            </div>
          </>
        }
        onRowExpandToggled={(event) => {
          if (event) setReload(Math.random() * 100);
        }}
      />
    </>
  );
};

export default CardList;
