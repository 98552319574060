import React, { useState, useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { UserModel } from '../../../modules/auth/models/UserModel';
import { RootState } from '../../../../setup';
import { KTSVG, userHasRole } from '../../../../_metronic/helpers';
import { useFormik } from 'formik';
import Select from 'react-select';
import { useGetYearsOpportunities } from '../hooks/DashboardHook';

type Props = {
  initFilterValues: any;
  onCleanFilter: () => void;
  onSearchFilter: (values: any) => void;
  data: any;
  filterTypes: any;
  filterStages: any;
  flterCuenta: any;
};

const Filter: React.FC<Props> = ({
  initFilterValues,
  onSearchFilter,
  onCleanFilter,
  data,
  filterTypes,
  filterStages,
  flterCuenta,
}) => {
  // OBTENEMOS EL USUARIO PARA ACCESAR A LOS PERMISOS
  const user: UserModel = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  ) as UserModel;
  const { roles: userRoles } = user;

  const [selectedTipoOportunidad, setSelectedTipoOportunidad] = useState(null);
  const [selectedCuenta, setSelectedCuenta] = useState(null);
  const [selectedVendedor, setSelectedVendedor] = useState(null);
  const [selectedAnio, setSelectedAnio] = useState(null);
  const [selectedEstatus, setSelectedEstatus] = useState(null);
  const [filterValues, setFilterValues] = useState(initFilterValues);
  const [etapaId, setEtapaId] = useState(null);
  const { years: anios } = useGetYearsOpportunities(etapaId);

  const [vendedores, setVendedores] = useState<
    { value: any; label: string }[] | undefined
  >();
  const [opptunitiesType, setOpptunitiesType] = useState<
    { value: any; label: string }[] | undefined
  >();
  const [opptunitiesStage, setOpptunitiesStage] = useState<
    { value: any; label: string }[] | undefined
  >();
  const [cuentas, setCuentas] = useState<
    { value: any; label: string }[] | undefined
  >();
  const [years, setYears] = useState<
    { value: any; label: string }[] | undefined
  >();

  const styleSelect = {
    placeholder: (provided: any, state: any) => ({
      ...provided,
      color: '#181c32',
    }),
    indicatorSeparator: (provided: any, state: any) => ({
      ...provided,
      display: 'none',
    }),
    dropdownIndicator: (provided: any, state: any) => ({
      ...provided,
      color: '#7E8199',
    }),
    control: (provided: any, state: any) => ({
      ...provided,
      border: '1px solid #e4e6ef',
      borderRadius: '6px',
      padding: '3px',
      fontSize: '1.1rem',
      boxShadow: 'none',
      backgroundColor: state.isDisabled ? '#EFF2F5' : 'white',
    }),
    singleValue: (provided: any, state: any) => ({
      ...provided,
      color: state.isDisabled ? '#181c32' : 'inherit',
      fontWeight: 500,
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      fontSize: '1.1rem',
    }),
  };

  useEffect(() => {
    if (initFilterValues) {
      setFilterValues(initFilterValues);
      if (!initFilterValues.agente) {
        setSelectedVendedor(null);
      }
      if (!initFilterValues.tipo_oportunidad) {
        setSelectedTipoOportunidad(null);
      }
      if (!initFilterValues.cuenta) {
        setSelectedCuenta(null);
      }
      if (!initFilterValues.anio) {
        setSelectedAnio(null);
      }
      if (!initFilterValues.estatus) {
        setSelectedEstatus(null);
      }
    }
  }, [initFilterValues]);

  useEffect(() => {
    if (data) {
      const selectOptions = data.map((option: any, index: any) => ({
        key: index + option.id,
        value: option.id,
        label: `${option.nombre} ${option.apellido_paterno} ${option.apellido_materno}`,
      }));
      setVendedores(selectOptions);
    }
  }, [data]);

  useEffect(() => {
    if (filterTypes) {
      const selectOptions = filterTypes.map((option: any, index: any) => ({
        key: index + option.id,
        value: option.id,
        label: `${option.name}`,
      }));
      setOpptunitiesType(selectOptions);
    }
  }, [filterTypes]);

  useEffect(() => {
    if (filterStages) {
      let arrayFilter = [] as any;

      let satges = filterStages.map((stage: any, index: any) => {
        if (stage.code === 'CPE' || stage.code === 'CGA') {
          let obj = {
            key: index + stage.code,
            value: stage.id,
            label: stage.code === 'CPE' ? 'Ventas Perdida' : 'Ventas',
          };
          arrayFilter.push(obj);
        }
      });
      setOpptunitiesStage(arrayFilter);
    }
  }, [filterStages]);

  useEffect(() => {
    if (flterCuenta) {
      const selectOptions = flterCuenta.map((option: any, index: any) => ({
        key: index + option.id,
        value: option.id,
        label: `${option.nombre}`,
      }));
      setCuentas(selectOptions);
    }
  }, [flterCuenta]);

  useEffect(() => {
    if (anios.length) {
      const selectOptions = anios.map((option: any, index: any) => ({
        key: index + option.anio,
        value: option.anio,
        label: `${option.anio}`,
      }));
      setYears(selectOptions);
    }
  }, [anios]);

  const formFilter = useFormik({
    initialValues: filterValues,
    enableReinitialize: true,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      onSearchFilter({ ...values, filter: 1 });
    },
  });

  const handleSelect = (selectedOption: any, code: any) => {
    const { value, label } = selectedOption;

    switch (code) {
      case 'TIPOPP':
        setSelectedTipoOportunidad(selectedOption);
        formFilter.setFieldValue('tipo_oportunidad', value);
        formFilter.setFieldValue('label_oportunidad', label);
        break;
      case 'CUENTA':
        setSelectedCuenta(selectedOption);
        formFilter.setFieldValue('cuenta', value);
        formFilter.setFieldValue('label_cuenta', label);
        break;
      case 'VENDEDOR':
        setSelectedVendedor(selectedOption);
        formFilter.setFieldValue('agente', value);
        formFilter.setFieldValue('label_agente', label);
        break;
      case 'ANIO':
        setSelectedAnio(selectedOption);
        formFilter.setFieldValue('anio', value);
        break;
      case 'ESTATUS':
        setSelectedEstatus(selectedOption);
        formFilter.setFieldValue('estatus', value);
        formFilter.setFieldValue('label_estatus', label);
        setEtapaId(value);
        setSelectedAnio(null);
        formFilter.setFieldValue('anio', null);
        break;

      default:
        break;
    }
  };

  return (
    <>
      <div className="card-title d-flex justify-content-end mb-3">
        <a
          href="#/"
          className={
            formFilter.values.filter !== 0
              ? ' btn btn-sm btn-primary'
              : 'btn btn-sm btn-light-primary'
          }
          data-kt-menu-trigger="click"
          data-kt-menu-placement="bottom-end"
          data-kt-menu-flip="top-end"
        >
          <KTSVG
            path="/media/icons/duotune/general/gen031.svg"
            className="svg-icon-5 svg-icon-gray-500 me-1"
          />
          Filtrar
        </a>
        <div
          className="menu menu-sub menu-sub-dropdown w-250px w-md-300px"
          data-kt-menu="true"
        >
          <div className="px-7 py-5">
            <div className="fs-5 text-dark fw-bolder">Opciones de filtro</div>
          </div>

          <form
            id="kt_modal_generic_form"
            className="form"
            action="#"
            onSubmit={formFilter.handleSubmit}
          >
            <div className="px-7 py-5">
              <div className="mb-5">
                <label className="form-label fw-bold">
                  Líneas de Negocio:{' '}
                </label>
                <div onClick={(e) => e.stopPropagation()}>
                  <Select
                    options={opptunitiesType}
                    placeholder={'Seleccione'}
                    onChange={(event) => {
                      handleSelect(event, 'TIPOPP');
                    }}
                    value={selectedTipoOportunidad}
                    noOptionsMessage={() => {
                      return <span>No se encontraron registros</span>;
                    }}
                    styles={styleSelect}
                  />
                </div>
              </div>
              <div className="mb-5">
                <label className="form-label fw-bold">Cuenta:</label>
                <div onClick={(e) => e.stopPropagation()}>
                  <Select
                    options={cuentas}
                    placeholder={'Seleccione'}
                    onChange={(event) => {
                      handleSelect(event, 'CUENTA');
                    }}
                    value={selectedCuenta}
                    noOptionsMessage={() => {
                      return <span>No se encontraron registros</span>;
                    }}
                    styles={styleSelect}
                  />
                </div>
              </div>
              {userHasRole(['ADMIN', 'DIR'], userRoles) && (
                <div className="mb-5">
                  <label className="form-label fw-bold">Agentes: </label>
                  <div onClick={(e) => e.stopPropagation()}>
                    <Select
                      options={vendedores}
                      placeholder={'Seleccione'}
                      onChange={(event) => {
                        handleSelect(event, 'VENDEDOR');
                      }}
                      value={selectedVendedor}
                      noOptionsMessage={() => {
                        return <span>No se encontraron registros</span>;
                      }}
                      styles={styleSelect}
                    />
                  </div>
                </div>
              )}

              <div className="mb-5">
                <label className="form-label fw-bold">Año: </label>
                <div onClick={(e) => e.stopPropagation()}>
                  <Select
                    options={years}
                    placeholder={'Seleccione'}
                    onChange={(event) => {
                      handleSelect(event, 'ANIO');
                    }}
                    value={selectedAnio}
                    noOptionsMessage={() => {
                      return <span>No se encontraron registros</span>;
                    }}
                    styles={styleSelect}
                  />
                </div>
              </div>
              <div className="mb-5">
                <label className="form-label fw-bold">Etapa: </label>
                <div onClick={(e) => e.stopPropagation()}>
                  <Select
                    options={opptunitiesStage}
                    placeholder={'Seleccione'}
                    onChange={(event) => {
                      handleSelect(event, 'ESTATUS');
                    }}
                    value={selectedEstatus}
                    noOptionsMessage={() => {
                      return <span>No se encontraron registros</span>;
                    }}
                    styles={styleSelect}
                  />
                </div>
              </div>

              <div className="d-flex justify-content-end ">
                <button
                  type="reset"
                  className="btn btn-sm btn-white btn-active-light-primary me-2"
                  data-kt-menu-dismiss="true"
                  onClick={() => {
                    formFilter.resetForm();
                    onCleanFilter();
                    setSelectedTipoOportunidad(null);
                    setSelectedCuenta(null);
                    setSelectedVendedor(null);
                    setSelectedAnio(null);
                    setSelectedEstatus(null);
                    setEtapaId(null);
                  }}
                >
                  Limpiar
                </button>

                <button
                  type="submit"
                  className="btn btn-sm btn-primary"
                  data-kt-menu-dismiss="true"
                >
                  Buscar
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export { Filter };
