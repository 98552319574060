import { useState, useEffect } from "react";
import {
  getCatalog,
  getCatalogGrid,
  getCatalogDisabledGrid,
  getDataCatalogById,
  getCatalogSelect,
  getCatalogCurrencyPackages,
} from "../../../../service/catalog/Catalogs";
import { GenericoModel, RecordDisabledCat } from "../models/GenericoModel";
import { useHistory } from "react-router-dom";
import { SelectFormat } from "../../../../../_metronic/helpers/models/GenericModel";

export const useGetGenerico = (code: string) => {
  const [title, setTitle] = useState("");
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      const data = await getCatalog(code)
        .then()
        .catch((error) => {
          history.push("/error/500");
        });
      if (data) {
        setTitle(data.info.name);
      }
    };
    fetchPost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);
  return { title };
};
export const useGetAll = (
  code: string,
  realoadGrid: number,
  startIndex: number,
  endIndex: number,
  filters: string
) => {
  const [catalog, setCatalog] = useState<GenericoModel[]>([]);
  const [count, setCount] = useState(1);
  const [loadingRequest, setLoadingRequest] = useState(0);
  const history = useHistory();

  useEffect(() => {
    const fetchPost = async () => {
      setLoadingRequest(0);
      const data = await getCatalogGrid(code, startIndex, endIndex, filters)
        .then()
        .catch((error) => {
          history.push("/error/500");
        });
      if (data) {
        setCatalog(data.rows);
        setCount(data.count);
        setLoadingRequest(1);
      }
    };
    if (code) {
      fetchPost();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code, endIndex, realoadGrid]);
  return { catalog, count, loadingRequest };
};

export const useSelectGeneric = (code: string) => {
  const [catalog, setCatalog] = useState<GenericoModel[]>([]);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      const data = await getCatalog(code)
        .then()
        .catch((error) => {
          history.push("/error/500");
        });
      if (data) {
        setCatalog(data.rows);
      }
    };
    fetchPost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  return { catalog };
};

export const useGetAllDisabled = (
  code: string,
  realoadGrid: number,
  startIndex: number,
  endIndex: number,
  filters: string
) => {
  const [catalogDisabled, setCatalogDisabled] = useState<RecordDisabledCat[]>(
    []
  );
  const [countDisabled, setCountDisbled] = useState(1);
  const [loadingRequestDisabled, setLoadingRequestDisabled] = useState(0);
  const history = useHistory();

  useEffect(() => {
    const fetchPost = async () => {
      setLoadingRequestDisabled(0);
      const data = await getCatalogDisabledGrid(
        code,
        startIndex,
        endIndex,
        filters
      )
        .then()
        .catch((error) => {
          history.push("/error/500");
        });
      if (data) {
        setCatalogDisabled(data.rows);
        setCountDisbled(data.count);
        setLoadingRequestDisabled(1);
      }
    };
    if (code) {
      fetchPost();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code, endIndex, realoadGrid]);
  return { catalogDisabled, countDisabled, loadingRequestDisabled };
};

export const useGetOne = (id: number, code: string) => {
  const initModel = {
    id: 0,
    code: "",
    name: "",
    order: 0,
    color: "#000000",
  };
  const [initValues, setInitValues] = useState<GenericoModel>(initModel);

  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      const res = await getDataCatalogById(id, code)
        .then()
        .catch((error) => {
          history.push("/error/500");
        });
      if (res) {
        setInitValues(res);
      }
    };
    if (id) {
      fetchPost();
    } else {
      setInitValues(initModel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return { initValues };
};

export const useExtensions = () => {
  const [extension, setExtension] = useState<any[]>([]);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      const data = await getCatalog("EXTNS")
        .then()
        .catch((error) => {
          history.push("/error/500");
        });
      if (data) {
        let arrEx: any[] = [];
        let extns = data.rows.map((item: any) => {
          arrEx.push(`.${item.code.toLowerCase().trim()}`);
        });
        setExtension(arrEx);
      }
    };
    fetchPost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { extension };
};

export const useSelectGenericPlus = (code: string, reload?: number) => {
  const [catalog, setCatalog] = useState<SelectFormat[]>([]);
  const [loadingRequest, setLoadingRequest] = useState(true);

  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      const data = await getCatalogSelect(code)
        .then()
        .catch((error) => {
          history.push("/error/500");
        });
      if (data) {
        setCatalog(data);
        setLoadingRequest(false);
      }
    };
    fetchPost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code, reload]);

  return { catalog, loadingRequest };
};
export const useSelectCurrencyPackages = (code: string) => {
  const [catalog, setCatalog] = useState<SelectFormat[]>([]);
  const [loadingRequest, setLoadingRequest] = useState(true);

  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      const data = await getCatalogCurrencyPackages(code)
        .then()
        .catch((error) => {
          history.push("/error/500");
        });
      if (data) {
        setCatalog(data);
        setLoadingRequest(false);
      }
    };
    fetchPost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  return { catalog, loadingRequest };
};

export const useSelectComponentFormat = (code: string, execute?: boolean) => {
  const [optionsSelect, setOptionSelect] = useState<any[]>([]);

  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      const data = await getCatalog(code)
        .then()
        .catch((error) => {
          history.push("/error/500");
        });
      if (data) {
        const selectOptions = data.rows.map((option: any) => ({
          value: option.id,
          label:
            code === "TCOIN" ? option.name : `${option.code}-${option.name} `,
          code: option.code,
          data: option,
        }));
        setOptionSelect((prevState) => [...selectOptions]);
      }
    };

    if (execute) {
      fetchPost();
    }
  }, [execute]);

  return { optionsSelect };
};

export const useSelectSimpleFormat = (code: string, execute: boolean) => {
  const [optionsSelect, setOptionSelect] = useState<any[]>([]);

  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      const data = await getCatalog(code)
        .then()
        .catch((error) => {
          history.push("/error/500");
        });
      if (data) {
        const selectOptions = data.rows.map((option: any) => ({
          value: option.id,
          label: option.name,
          code: option.code,
        }));
        setOptionSelect((prevState) => [...selectOptions]);
      }
    };

    if (execute) {
      fetchPost();
    }
  }, [execute]);

  return { optionsSelect };
};
