import React, { useState, useEffect, useRef } from 'react';
import { SubAccountModel } from '../../../subaccount/models/SubAccountModel';
import {
  KTSVG,
  encryptId,
  maskPhoneNumber,
} from '../../../../../_metronic/helpers';
import { Link } from 'react-router-dom';

type Props = {
  subaccounts: SubAccountModel[];
  onSaveSubaccount: (message: string) => void;
  active: boolean;
};

const SubAccountList: React.FC<Props> = ({
  subaccounts,
  onSaveSubaccount,
  active,
}) => {
  const [subaccountMemo, setSubaccountMemo] =
    useState<SubAccountModel[]>(subaccounts);

  const [subaccountName, setSubaccountName] = useState<string>('');
  const inputRef = useRef<HTMLInputElement>(null);
  const AddSubaccountRow = () => {
    const newSubaccount: SubAccountModel = {
      id: 0,
      nombre: 'Nueva Subcuenta',
    };
    setSubaccountMemo((prev) => [...prev, newSubaccount]);
  };

  const sendSubaccount = () => {
    if (subaccountName.trim() !== '') {
      onSaveSubaccount(subaccountName);
      setSubaccountName('');
    }
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [subaccountMemo]);

  useEffect(() => {
    setSubaccountMemo(subaccounts);
  }, [subaccounts]);

  useEffect(() => {
    if (active) {
      AddSubaccountRow();
    }
  }, [active]);

  const onEnterPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      sendSubaccount();
    }
  };
  const handleInputBlur = () => {
    setSubaccountMemo((prev) => prev.filter((sub) => sub.id !== 0));
    setSubaccountName('');
  };

  return (
    <>
      <div className="table-responsive min-h-200px me-n5 pe-5 scroll-y">
        <table className="table table-border table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 me-n5 pe-5">
          <thead>
            <tr className="text-gray-400 fs-7">
              <th className="w-300px">Nombre</th>
              <th className="w-200px">Teléfono</th>
            </tr>
          </thead>
          <tbody className="">
            {subaccountMemo.map((row, index) => (
              <tr key={row.id}>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start w-100 flex-column fs-012 font-weight-mormal text-muted">
                      {row.id === 0 ? (
                        <input
                          type="text"
                          value={subaccountName}
                          className="w-100 border-0"
                          ref={
                            index === subaccountMemo.length - 1
                              ? inputRef
                              : null
                          }
                          onChange={(e) => setSubaccountName(e.target.value)}
                          onKeyDown={onEnterPress}
                          style={{ outline: 'none' }}
                          placeholder={row.nombre ? row.nombre : ''}
                          onBlur={handleInputBlur}
                        ></input>
                      ) : (
                        <>
                          <Link
                            className="text-primary"
                            to={`/clientes/cuentas/${
                              row.cuenta?.id
                                ? encryptId(row.cuenta.id.toString())
                                : ''
                            }/subcuentas/${encryptId(row.id.toString())}`}
                          >
                            {row.nombre}
                          </Link>
                        </>
                      )}
                    </div>
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column fs-012 text-muted">
                      {row.telefono ? maskPhoneNumber(row.telefono) : ''}
                    </div>
                  </div>
                </td>
              </tr>
            ))}
            <tr>
              <td colSpan={2}>
                <div className="d-flex align-items-center">
                  <div
                    className="btn btn-light-primary btn-sm mb-10"
                    onClick={AddSubaccountRow}
                  >
                    <KTSVG
                      path="/media/icons/duotune/arrows/arr075.svg"
                      className="svg-icon-5"
                    />
                    Agregar Subcuenta
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export { SubAccountList };
