import React, { useState, useEffect } from "react";
import { KTSVG } from "../../../../_metronic/helpers";
import Select from "react-select";
import { useFormik } from "formik";
import { useGetDataSelectedByField } from "../../home/hooks/HomeHook";
import { styleSelect } from "../../../../_metronic/helpers/SelectStyles";
import {
  useGetDataSelectedByFieldProducts,
  useGetFieldsPorducts,
} from "../hooks/ProductsFilterHook";
import { NumericFormat } from "react-number-format";
import { MODULS } from "./View";

type Props = {
  initFilterValues: any;
  onCleanFilter: () => void;
  onSearchFilter: (values: any) => void;
  module: number;
};

const FilterBoard: React.FC<Props> = ({
  initFilterValues,
  onSearchFilter,
  onCleanFilter,
  module,
}) => {
  const [filterValues, setFilterValues] = useState(initFilterValues);
  const { listFields } = useGetFieldsPorducts();
  const [selectedOption, setSelectedOption] = useState(null);
  const [code, setCode] = useState<string>("");
  const { dataOptionSelected } = useGetDataSelectedByFieldProducts(code);
  const [secondOption, setSecondOption] = useState(null);
  const [isInput, setIsInput] = useState<boolean>(false);
  const [isFetch, setIsFetch] = useState<boolean>(false);
  //   const [currentDate, setCurrentDate] = useState<string>('');

  const CodesForInput = ["PROD_NOM", "PROD_UNEG", "PROD_TIPO"];
  const CodesForPrice = ["PROD_PV"];

  //   const CodesForDatePicker = ['FEC_APR_EST', 'FEC_CIE_REAL', 'FEC_ACT'];

  useEffect(() => {
    if (initFilterValues) {
      setFilterValues(initFilterValues);
    }
  }, [initFilterValues]);

  useEffect(() => {
    if (code) {
      if (CodesForInput.includes(code)) {
        setIsFetch(true);
        setIsInput(false);
      } else if (CodesForPrice.includes(code)) {
        setIsInput(true);
        setIsFetch(false);
      }
    }
  }, [code]);

  const handleSelect = (selectedOption: any, select: string) => {
    const { code, value } = selectedOption;
    switch (select) {
      case "SELECT-FIELDS":
        setSelectedOption(selectedOption);
        setSecondOption(null);
        formFilter.setFieldValue("campo_id", value);
        break;
      case "SELECT-FILTER":
        setSecondOption(selectedOption);
        formFilter.setFieldValue("valor", value);
        break;
      default:
        break;
    }

    setCode(code);
  };

  const formFilter = useFormik({
    initialValues: filterValues,
    enableReinitialize: true,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      if (module === MODULS.PRODUCTOS) {
        onSearchFilter(values);
      }
    },
  });

  return (
    <>
      <div className="card-title align-items-start flex-column">
        <a
          href="#/"
          className={"btn btn-sm btn-light-primary"}
          data-kt-menu-trigger="click"
          data-kt-menu-placement="bottom-end"
          data-kt-menu-flip="top-end"
        >
          <KTSVG
            path="/media/icons/duotune/general/gen031.svg"
            className="svg-icon-5 svg-icon-gray-500 me-1"
          />
          Filtrar
        </a>
        <div
          className="menu menu-sub menu-sub-dropdown w-250px w-md-350px"
          data-kt-menu="true"
        >
          <div className="px-7 py-5">
            <div className="fs-5 text-dark fw-bolder">Opciones de filtro</div>
          </div>
          <form
            id="kt_modal_generic_form"
            className="form"
            action="#"
            onSubmit={formFilter.handleSubmit}
          >
            <div className="px-7 py-5">
              <div className="mb-5">
                <label className="form-label fw-bold">
                  Selecciona una opción:{" "}
                </label>
                <div onClick={(e) => e.stopPropagation()}>
                  <Select
                    options={listFields}
                    placeholder={"Seleccione"}
                    onChange={(event) => {
                      handleSelect(event, "SELECT-FIELDS");
                    }}
                    value={selectedOption}
                    noOptionsMessage={() => {
                      return <span>No se encontraron registros</span>;
                    }}
                    styles={styleSelect}
                  />
                </div>
              </div>
              {isFetch ? (
                <div className="mb-5">
                  <label className="form-label fw-bold">
                    Selecciona una opción:{" "}
                  </label>
                  <div onClick={(e) => e.stopPropagation()}>
                    <Select
                      options={dataOptionSelected}
                      placeholder={"Seleccione"}
                      onChange={(event) => {
                        handleSelect(event, "SELECT-FILTER");
                      }}
                      value={secondOption}
                      noOptionsMessage={() => {
                        return <span>No se encontraron registros</span>;
                      }}
                      styles={styleSelect}
                    />
                  </div>
                </div>
              ) : null}
              {isInput ? (
                <div className="mb-5">
                  <label className="form-label fw-bold">
                    Ingrese el precio máximo de busqueda:{" "}
                  </label>
                  <NumericFormat
                    className="form-control fs-4"
                    thousandSeparator={true}
                    prefix="$"
                    decimalScale={2}
                    fixedDecimalScale={true}
                    allowNegative={false}
                    allowLeadingZeros={false}
                    isAllowed={(values: any) => {
                      const { floatValue } = values;
                      const integerPart = Math.floor(floatValue);
                      return integerPart.toString().length <= 9;
                    }}
                    onValueChange={(values: any) => {
                      const { floatValue } = values;
                      formFilter.setFieldValue("valor", floatValue);
                    }}
                  />
                  {/* <input
                    type="text"
                    className="form-control"
                    onChange={(e) => {
                      if (e.target.value) {
                        formFilter.setFieldValue('valor', e.target.value);
                      }
                    }}
                  ></input> */}
                </div>
              ) : null}

              <div className="d-flex justify-content-end ">
                <button
                  type="reset"
                  className="btn btn-sm btn-white btn-active-light-primary me-2"
                  data-kt-menu-dismiss="true"
                  onClick={() => {
                    formFilter.resetForm();
                    onCleanFilter();
                    setSelectedOption(null);
                    setSecondOption(null);
                    setIsFetch(false);
                    setIsInput(false);
                    setCode("");
                  }}
                >
                  Limpiar
                </button>

                <button
                  type="submit"
                  className="btn btn-sm btn-primary"
                  data-kt-menu-dismiss="true"
                >
                  Buscar
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export { FilterBoard };
