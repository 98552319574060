import axios from 'axios'
const API_URL = process.env.REACT_APP_API


export async function getTemplate(startIndex: number, endIndex: number, filters: any) {

    let search: string = '';

    if (filters.nombre) {
        search = `&id=${filters.nombre}`;
    }

    const response = await axios.get(`${API_URL}plantillas?limit=${startIndex}&page=${endIndex}${search}`);

    const data = response.data.doc.data.rows;
    const count = response.data.doc.data.count;
    return {
        data: data,
        count: count
    };

}
export async function addTemplate(body: any) {

    const { nombre, encabezado, cuerpo, firma, asunto } = body;

    const data = {
        nombre: nombre,
        encabezado: encabezado,
        asunto: asunto,
        cuerpo: cuerpo,
        firma: firma,

    }

    const response = await axios.post(`${API_URL}plantillas`, data);
    return response;

}
export async function getTemplateById(id_row: number) {

    const response = await axios.get(`${API_URL}plantillas/${id_row}`);
    const { id, nombre, encabezado, cuerpo, firma, asunto } = response.data.doc.data;
    return {
        id: id,
        nombre: nombre,
        encabezado: encabezado,
        cuerpo: cuerpo,
        firma: firma,
        asunto: asunto,
    };


}
export async function updateTemplate(body: any) {
    const { id, nombre, encabezado, cuerpo, firma, asunto } = body;

    const data = {
        nombre: nombre,
        encabezado: encabezado,
        asunto: asunto,
        cuerpo: cuerpo,
        firma: firma,

    }

    const response = await axios.put(`${API_URL}plantillas/${id}`, data);
    return response;
}
export async function removeTemplate(id: number) {
    const response = await axios.delete(
        `${API_URL}plantillas/${id}`
    );
    return response;
}

export async function getTemplateFilter() {

    const response = await axios.get(`${API_URL}plantillas`);

    const data = response.data.doc.data.rows;
    const count = response.data.doc.data.count;
    return {
        data: data,
        count: count
    };

}