import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { NumericFormat } from 'react-number-format';
import {
  KTSVG,
  SimpleAlert,
  formatPriceNew,
} from '../../../../../_metronic/helpers';
import {
  useSelectPackages,
  useSelectProductsCotizacion,
  useSelectServicesCotizacion,
} from '../../../packages/hooks/PackagesHook';
import { styleSelect } from '../../../../../_metronic/helpers/SelectStyles';
import { Currency } from '../QuotationForm';
import {
  dividir,
  multiplicar,
  sumar,
} from '../../../../../_metronic/helpers/PriceCalculations';

type Props = {
  indexSection: number;
  data: any[];
  onChangePriceConcepts: (
    subtotal: number,
    subtotal_utilidad: number,
    subtotal_indirectos: number,
    impuestos: number,
    total: number,
    concepts: any,
    indexSection: number
  ) => void;
  codeCurrency: string;
  exchangeRate: number;
  updateCostConcepts: number;
  costoIndirectoPorcentaje: number;
  utilidadPorcentaje: number;
};
const ConceptsList: React.FC<Props> = ({
  indexSection,
  data,
  onChangePriceConcepts,
  codeCurrency,
  exchangeRate,
  updateCostConcepts,
  costoIndirectoPorcentaje,
  utilidadPorcentaje,
}) => {
  const { catalog: products, loadingRequest: loadingPROD } =
    useSelectProductsCotizacion();
  const { catalog: services, loadingRequest: loadingSER } =
    useSelectServicesCotizacion();
  const { catalog: packages, loadingRequest: loadingPAC } = useSelectPackages();

  const [dataMemo, setDataMemo] = useState<any[]>(data);
  const [conceptTypes] = useState<any[]>([
    {
      data: {},
      id: 1,
      label: 'Producto',
      value: 1,
    },
    {
      data: {},
      id: 2,
      label: 'Servicio',
      value: 2,
    },
    {
      data: {},
      id: 3,
      label: 'Paquete',
      value: 3,
    },
  ]);

  const AddRow = () => {
    if (codeCurrency === '') {
      SimpleAlert('Favor seleccione un tipo de moneda!', 3000, 'error');
      return false;
    }
    const cleanedData = dataMemo.filter((item) => item.id !== 0);
    const newRow: any = {
      id: 0,
      tipo: 0,
      partida: 'N/A',
      nombre: 'Nuevo concepto',
      nombre_categoria: 'N/A',
      id_unidad_medida: 0,
      name_unidad_medida: 'N/A',
      code_unidad_moneda: 'N/A',

      volumen: 1,
      precio_sin_impuesto: 0,
      impuesto: 0,

      precio_sin_impuesto_conversion: 0,
      precio_sin_impuesto_utilidad_conversion: 0,
      precio_sin_impuesto_indirecto_conversion: 0,

      impuesto_conversion: 0,
      impuesto_utilidad_conversion: 0,
      impuesto_indirecto_conversion: 0,

      total_utilidad_conversion: 0,
      total_indirectos_conversion: 0,

      subtotal_conversion: 0,
      impuestos_conversion: 0,
      total_conversion: 0,
    };
    setDataMemo([...cleanedData, newRow]);
  };

  const loadConcepts = (selectedOption: any) => {
    if (selectedOption) {
      const { value } = selectedOption;
      setDataMemo((prevDataMemo) => {
        const updatedDataMemo = prevDataMemo.map((item) => {
          if (item.id === 0) {
            return {
              ...item,
              tipo: value,
            };
          }
          return item;
        });
        return updatedDataMemo;
      });
    }
  };

  const addConcept = (selectedOption: any, tipo: number) => {
    if (selectedOption) {
      let dataConcept = {
        id: 0,
        nombre: '',
        nombre_categoria: '',
        unidadMonedaVenta: {
          code: '',
          id: 0,
          name: '',
        },
        unidadMedidaVenta: {
          code: '',
          id: 0,
          name: '',
        },
        precio_fijo_venta: 0,
        monto_impuesto_precio_venta: 0,
      };
      if (tipo === conceptTypes[0].id || tipo === conceptTypes[1].id) {
        const {
          data: {
            id,
            nombre,
            unidadMedidaVenta,
            categoria,
            unidadMonedaVenta,
            precio_fijo_venta,
            monto_impuesto_precio_venta,
          },
        } = selectedOption;

        dataConcept = {
          id: id,
          nombre: nombre,
          unidadMonedaVenta: unidadMonedaVenta,
          unidadMedidaVenta: unidadMedidaVenta,
          nombre_categoria: categoria.nombre,
          precio_fijo_venta: Number(precio_fijo_venta),
          monto_impuesto_precio_venta: Number(monto_impuesto_precio_venta),
        };
      } else {
        const {
          data: {
            id,
            nombre,
            categoria,
            moneda,
            precio_sin_impuestos,
            impuestos,
          },
        } = selectedOption;
        dataConcept = {
          id: id,
          nombre: nombre,
          unidadMonedaVenta: moneda,
          unidadMedidaVenta: {
            code: '',
            id: 0,
            name: 'N/A',
          },
          nombre_categoria: categoria.nombre,
          precio_fijo_venta: Number(precio_sin_impuestos),
          monto_impuesto_precio_venta: Number(impuestos),
        };
      }
      const {
        id,
        nombre,
        unidadMedidaVenta,
        unidadMonedaVenta,
        precio_fijo_venta,
        monto_impuesto_precio_venta,
        nombre_categoria,
      } = dataConcept;

      const cleanedData = dataMemo.filter((item) => item.id !== 0);
      const existingIndex = dataMemo.findIndex(
        (item) => item.id === id && item.tipo === tipo
      );

      if (existingIndex === -1) {
        // CONVERSIÓN DE PRECIO E IMPUESTOS
        const precio_unitario = calculateExchangeRate(
          unidadMonedaVenta ? unidadMonedaVenta.code : '',
          codeCurrency,
          Number(precio_fijo_venta)
        );
        const precio_unitario_tax = calculateExchangeRateTax(
          unidadMonedaVenta ? unidadMonedaVenta.code : '',
          codeCurrency,
          Number(monto_impuesto_precio_venta)
        );

        // CÁLCULA COSTO E IMPUESTO INDIRECTO UNITARIO
        let precio_sin_impuesto_indirecto_conversion = multiplicar(
          precio_unitario,
          costoIndirectoPorcentaje
        );
        let impuesto_indirecto_conversion = multiplicar(
          precio_unitario_tax,
          costoIndirectoPorcentaje
        );
        // CÁLCULA UTILIDAD
        let precio_sin_impuesto_utilidad_conversion = multiplicar(
          precio_unitario,
          utilidadPorcentaje
        );
        let impuesto_utilidad_conversion = multiplicar(
          precio_unitario_tax,
          utilidadPorcentaje
        );
        // CALCULA EL TOTAL IMPUESTOS POR VOLUMEN
        let impuestos_conversion = calularConceptos(
          precio_unitario_tax,
          impuesto_utilidad_conversion,
          impuesto_indirecto_conversion,
          1
        );
        // CALCULA EL SUBTOTAL DE LA UTILIDAD
        let subtotal_utilidad_conversion = calularConceptos(
          0,
          precio_sin_impuesto_utilidad_conversion,
          0,
          1
        );
        // CALCULA EL SUBTOTAL DEL COSTO INDIRECTO
        let subtotal_indirectos_conversion = calularConceptos(
          0,
          precio_sin_impuesto_indirecto_conversion,
          0,
          1
        );

        const updateRow = {
          id: id,
          tipo: tipo,
          partida: indexSection + 1,
          nombre: nombre,
          nombre_categoria: nombre_categoria,
          id_unidad_medida: unidadMedidaVenta ? unidadMedidaVenta.id : 0,
          name_unidad_medida: unidadMedidaVenta
            ? unidadMedidaVenta.name
            : 'N/A',
          code_unidad_moneda: unidadMonedaVenta
            ? unidadMonedaVenta.code
            : 'N/A',

          volumen: 1,
          precio_sin_impuesto: precio_fijo_venta,
          impuesto: monto_impuesto_precio_venta,

          precio_sin_impuesto_conversion: precio_unitario,
          precio_sin_impuesto_utilidad_conversion:
            precio_sin_impuesto_utilidad_conversion,
          precio_sin_impuesto_indirecto_conversion:
            precio_sin_impuesto_indirecto_conversion,

          impuesto_conversion: precio_unitario_tax,
          impuesto_utilidad_conversion: impuesto_utilidad_conversion,
          impuesto_indirecto_conversion: impuesto_indirecto_conversion,

          subtotal_utilidad_conversion: subtotal_utilidad_conversion,
          subtotal_indirectos_conversion: subtotal_indirectos_conversion,

          subtotal_conversion: precio_unitario,
          impuestos_conversion: impuestos_conversion,
          total_conversion: 0,
        };

        setDataMemo((prevDataMemo) => {
          const updatedDataMemo = [...cleanedData, updateRow];
          sumAmount(updatedDataMemo);
          return updatedDataMemo;
        });
        // setDataMemo([...cleanedData, updateRow]);
      } else {
        setDataMemo([...cleanedData]);
        SimpleAlert('Ya existe el concepto en esta sección.', 3000, 'error');
      }
    }
  };

  const calularConceptos = (
    unitario: number,
    utilidad: number,
    indirecto: number,
    volumen: number
  ): number => {
    let total = 0;
    let c_unitario = multiplicar(unitario, volumen);
    let c_utilidad = multiplicar(utilidad, volumen);
    let c_indirecto = multiplicar(indirecto, volumen);
    total = sumar(sumar(c_unitario, c_utilidad), c_indirecto);
    return total;
  };

  const calculateExchangeRate = (
    unidadMoneda: string,
    codeCurrency: string,
    precio_venta: number
  ): number => {
    let costCalculate = Number(0);
    switch (unidadMoneda) {
      case Currency.MXN:
        if (codeCurrency === Currency.USD) {
          costCalculate = dividir(Number(precio_venta), Number(exchangeRate));
        } else if (codeCurrency === Currency.MXN) {
          costCalculate = Number(precio_venta);
        }
        break;
      case Currency.USD:
        if (codeCurrency === Currency.USD) {
          costCalculate = Number(precio_venta);
        } else if (codeCurrency === Currency.MXN) {
          costCalculate = multiplicar(
            Number(precio_venta),
            Number(exchangeRate)
          );
        }
        break;
    }
    return costCalculate;
  };
  const calculateExchangeRateTax = (
    unidadMoneda: string,
    codeCurrency: string,
    monto_impuesto_precio_venta: number
  ): number => {
    let taxCalculate = Number(0);

    switch (unidadMoneda) {
      case Currency.MXN:
        if (codeCurrency === Currency.USD) {
          taxCalculate = dividir(
            Number(monto_impuesto_precio_venta),
            Number(exchangeRate)
          );
        } else if (codeCurrency === Currency.MXN) {
          taxCalculate = Number(monto_impuesto_precio_venta);
        }
        break;
      case Currency.USD:
        if (codeCurrency === Currency.USD) {
          taxCalculate = Number(monto_impuesto_precio_venta);
        } else if (codeCurrency === Currency.MXN) {
          taxCalculate = multiplicar(
            Number(monto_impuesto_precio_venta),
            Number(exchangeRate)
          );
        }
        break;
    }

    return taxCalculate;
  };

  const sumAmount = (updatedDataMemo: any) => {
    const subtotal = updatedDataMemo.reduce((subtotal: number, item: any) => {
      return sumar(subtotal, Number(item.subtotal_conversion));
    }, 0);

    const subtotal_utilidad = updatedDataMemo.reduce(
      (subtotal_utilidad: number, item: any) => {
        return sumar(
          subtotal_utilidad,
          Number(item.subtotal_utilidad_conversion)
        );
      },
      0
    );

    const subtotal_indirectos = updatedDataMemo.reduce(
      (subtotal_indirectos: number, item: any) => {
        return sumar(
          subtotal_indirectos,
          Number(item.subtotal_indirectos_conversion)
        );
      },
      0
    );

    const impuestos = updatedDataMemo.reduce((impuestos: number, item: any) => {
      return sumar(impuestos, Number(item.impuestos_conversion));
    }, 0);

    const total = updatedDataMemo.reduce((total: number, item: any) => {
      return sumar(total, Number(item.total_conversion));
    }, 0);

    onChangePriceConcepts(
      subtotal,
      subtotal_utilidad,
      subtotal_indirectos,
      impuestos,
      total,
      updatedDataMemo,
      indexSection
    );
  };

  const deleteRowById = (productId: number, tipo: number) => {
    setDataMemo((prevDataMemo) => {
      const updatedDataMemo = prevDataMemo.filter(
        (item) => item.id !== productId || item.tipo !== tipo
      );
      sumAmount(updatedDataMemo);
      return updatedDataMemo;
    });
  };

  const volumeChange = (
    newVolumen: number,
    conceptId: number,
    tipo: number
  ) => {
    setDataMemo((prevDataMemo) => {
      const updatedDataMemo = prevDataMemo.map((item) => {
        if (item.id === conceptId && item.tipo === tipo) {
          // CALCULA EL TOTAL IMPUESTOS POR VOLUMEN
          let impuestos_conversion = calularConceptos(
            item.impuesto_conversion,
            item.impuesto_utilidad_conversion,
            item.impuesto_indirecto_conversion,
            newVolumen
          );
          // CALCULA EL SUBTOTAL DE PRECIOS POR VOLUMEN
          let subtotal_conversion = calularConceptos(
            item.precio_sin_impuesto_conversion,
            0,
            0,
            newVolumen
          );
          // CALCULA EL SUBTOTAL DE LA UTILIDAD
          let subtotal_utilidad_conversion = calularConceptos(
            0,
            item.precio_sin_impuesto_utilidad_conversion,
            0,
            newVolumen
          );
          // CALCULA EL SUBTOTAL DEL COSTO INDIRECTO
          let subtotal_indirectos_conversion = calularConceptos(
            0,
            item.precio_sin_impuesto_indirecto_conversion,
            0,
            newVolumen
          );
          return {
            ...item,
            volumen: newVolumen,

            subtotal_utilidad_conversion: subtotal_utilidad_conversion,
            subtotal_indirectos_conversion: subtotal_indirectos_conversion,

            subtotal_conversion: subtotal_conversion,
            impuestos_conversion: impuestos_conversion,
            total_conversion: 0,
          };
        }
        return item;
      });
      sumAmount(updatedDataMemo);
      return updatedDataMemo;
    });
  };

  useEffect(() => {
    setDataMemo((prevDataMemo) => {
      const updatedDataMemo = prevDataMemo.map((item: any) => {
        const precio_sin_impuesto_conversion = calculateExchangeRate(
          item.code_unidad_moneda,
          codeCurrency,
          item.precio_sin_impuesto
        );
        const impuesto_conversion = calculateExchangeRateTax(
          item.code_unidad_moneda,
          codeCurrency,
          item.impuesto
        );

        let precio_sin_impuesto_utilidad_conversion = multiplicar(
          precio_sin_impuesto_conversion,
          utilidadPorcentaje
        );

        let precio_sin_impuesto_indirecto_conversion = multiplicar(
          precio_sin_impuesto_conversion,
          costoIndirectoPorcentaje
        );

        let impuesto_utilidad_conversion = multiplicar(
          impuesto_conversion,
          utilidadPorcentaje
        );
        let impuesto_indirecto_conversion = multiplicar(
          impuesto_conversion,
          costoIndirectoPorcentaje
        );

        let subtotal_conversion = calularConceptos(
          precio_sin_impuesto_conversion,
          0,
          0,
          item.volumen
        );
        let impuestos_conversion = calularConceptos(
          impuesto_conversion,
          impuesto_utilidad_conversion,
          impuesto_indirecto_conversion,
          item.volumen
        );

        let subtotal_utilidad_conversion = calularConceptos(
          0,
          precio_sin_impuesto_utilidad_conversion,
          0,
          item.volumen
        );
        // CALCULA EL SUBTOTAL DEL COSTO INDIRECTO
        let subtotal_indirectos_conversion = calularConceptos(
          0,
          precio_sin_impuesto_indirecto_conversion,
          0,
          item.volumen
        );

        return {
          ...item,
          precio_sin_impuesto_conversion: precio_sin_impuesto_conversion,
          precio_sin_impuesto_utilidad_conversion:
            precio_sin_impuesto_utilidad_conversion,
          precio_sin_impuesto_indirecto_conversion:
            precio_sin_impuesto_indirecto_conversion,

          impuesto_conversion: impuesto_conversion,
          impuesto_utilidad_conversion: impuesto_utilidad_conversion,
          impuesto_indirecto_conversion: impuesto_indirecto_conversion,

          subtotal_utilidad_conversion: subtotal_utilidad_conversion,
          subtotal_indirectos_conversion: subtotal_indirectos_conversion,

          subtotal_conversion: subtotal_conversion,
          impuestos_conversion: impuestos_conversion,
          total_conversion: 0,
        };
      });
      sumAmount(updatedDataMemo);
      return updatedDataMemo;
    });
  }, [updateCostConcepts]);

  useEffect(() => {
    const cleanedData = data.filter((item) => item.id !== 0);
    setDataMemo(cleanedData);
  }, [data]);

  useEffect(() => {
    return () => {
      setDataMemo([]);
    };
  }, []);
  return (
    <>
      <div className="table-responsive min-h-300px me-n5 pe-5 scroll-y">
        <table className="table table-border table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 me-n5 pe-5">
          <thead>
            <tr className="text-gray-800 fs-7">
              <th className="w-100px">Partida</th>
              <th className="w-150px">Tipo</th>
              <th className="w-150px">Concepto</th>
              <th className="w-100px">Categoría</th>
              <th className="w-100px">U/M</th>
              <th className="w-150px">Vol.</th>
              <th className="w-150px">Precio /u</th>
              <th className="w-150px">Indirecto /u</th>
              <th className="w-150px">Utilidad /u</th>
              {/* <th className="w-150px">Impuesto /u</th>
              <th className="w-150px">Imp. Indirecto /u</th>
              <th className="w-150px">Imp. Utilidad /u</th>
              <th className="w-150px">Impuesto real</th>
              <th className="w-150px">Impuesto subtotal</th> */}
              <th className="w-150px">Precio s/imp u</th>
              <th className="w-150px">Subtotal s/imp</th>

              {/* <th className="w-150px">total s/imp</th> */}
              <th className="w-100px"></th>
            </tr>
          </thead>
          <tbody className="">
            {dataMemo.length > 0 ? (
              dataMemo.map((row, index) => (
                <tr key={row.id}>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="d-flex justify-content-start flex-column fs-012 text-gray-600">
                        {row.partida} - {index + 1}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="d-flex justify-content-start w-100 flex-column fs-012 font-weight-mormal text-gray-600">
                        {row.id === 0 ? (
                          <Select
                            key={row.id}
                            options={conceptTypes}
                            menuPortalTarget={document.body}
                            menuPosition={'fixed'}
                            styles={styleSelect}
                            placeholder={'Seleccione'}
                            isSearchable={true}
                            noOptionsMessage={() => {
                              return (
                                <span>{'No se encontraron registros'}</span>
                              );
                            }}
                            onChange={loadConcepts}
                            backspaceRemovesValue
                          />
                        ) : (
                          <>
                            {row.tipo === conceptTypes[0].id && (
                              <>
                                <div
                                  className={`mt-2 badge badge-light-success`}
                                >
                                  Producto
                                </div>
                              </>
                            )}
                            {row.tipo === conceptTypes[1].id && (
                              <>
                                <div className={`mt-2 badge badge-light-info`}>
                                  Servicio
                                </div>
                              </>
                            )}
                            {row.tipo === conceptTypes[2].id && (
                              <>
                                <div
                                  className={`mt-2 badge badge-light-primary`}
                                >
                                  Paquete
                                </div>
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="d-flex justify-content-start w-100 flex-column fs-012 font-weight-mormal text-gray-600">
                        {row.tipo === conceptTypes[0].id &&
                          (row.id === 0 ? (
                            <Select
                              key={row.id}
                              options={products}
                              styles={styleSelect}
                              menuPortalTarget={document.body}
                              menuPosition={'fixed'}
                              placeholder={'Seleccione'}
                              isSearchable={true}
                              noOptionsMessage={() => {
                                return (
                                  <span>{'No se encontraron registros'}</span>
                                );
                              }}
                              onChange={(e) => {
                                addConcept(e, row.tipo);
                              }}
                              isLoading={loadingPROD}
                              backspaceRemovesValue
                            />
                          ) : (
                            <>{row.nombre}</>
                          ))}
                        {row.tipo === conceptTypes[1].id &&
                          (row.id === 0 ? (
                            <Select
                              key={row.id}
                              options={services}
                              styles={styleSelect}
                              menuPortalTarget={document.body}
                              menuPosition={'fixed'}
                              placeholder={'Seleccione'}
                              isSearchable={true}
                              noOptionsMessage={() => {
                                return (
                                  <span>{'No se encontraron registros'}</span>
                                );
                              }}
                              onChange={(e) => {
                                addConcept(e, row.tipo);
                              }}
                              isLoading={loadingPROD}
                              backspaceRemovesValue
                            />
                          ) : (
                            <>{row.nombre}</>
                          ))}
                        {row.tipo === conceptTypes[2].id &&
                          (row.id === 0 ? (
                            <Select
                              key={row.id}
                              options={packages}
                              styles={styleSelect}
                              menuPortalTarget={document.body}
                              menuPosition={'fixed'}
                              placeholder={'Seleccione'}
                              isSearchable={true}
                              noOptionsMessage={() => {
                                return (
                                  <span>{'No se encontraron registros'}</span>
                                );
                              }}
                              onChange={(e) => {
                                addConcept(e, row.tipo);
                              }}
                              isLoading={loadingPROD}
                              backspaceRemovesValue
                            />
                          ) : (
                            <>{row.nombre}</>
                          ))}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="d-flex justify-content-start flex-column fs-012 text-gray-600">
                        {row.nombre_categoria}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="d-flex justify-content-start flex-column fs-012 text-gray-600">
                        {/* {row.tipo === 3 ? '' : row.code_unidad_moneda} */}
                        {row.name_unidad_medida}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="d-flex justify-content-start flex-column fs-012 text-gray-600">
                        {row.id !== 0 ? (
                          <NumericFormat
                            className="form-control w-100px"
                            thousandSeparator={false}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                e.preventDefault();
                                e.stopPropagation();
                              }
                            }}
                            allowNegative={false}
                            decimalScale={2}
                            value={row.volumen}
                            onBlur={(e) => {
                              const value = e.target.value;
                              if (!value) {
                                e.target.value = '1';
                              }
                            }}
                            isAllowed={(values: any) => {
                              const { floatValue } = values;
                              const integerPart = Math.floor(floatValue);
                              return integerPart.toString().length <= 7;
                            }}
                            suffix=""
                            allowLeadingZeros={false}
                            onValueChange={(values) => {
                              const { floatValue } = values;
                              if (floatValue) {
                                if (floatValue > 0) {
                                  volumeChange(floatValue, row.id, row.tipo);
                                }
                              } else {
                                volumeChange(1, row.id, row.tipo);
                              }
                            }}
                          />
                        ) : (
                          <>{'1'}</>
                        )}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="d-flex justify-content-start flex-column fs-012 text-gray-600">
                        {`${formatPriceNew(
                          row.precio_sin_impuesto_conversion,
                          'es-MX',
                          'MXN',
                          2,
                          2
                        )}`}
                        / {codeCurrency}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="d-flex justify-content-start flex-column fs-012 text-gray-600">
                        {`${formatPriceNew(
                          row.precio_sin_impuesto_indirecto_conversion,
                          'es-MX',
                          'MXN',
                          2,
                          2
                        )}`}
                        / {codeCurrency}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="d-flex justify-content-start flex-column fs-012 text-gray-600">
                        {`${formatPriceNew(
                          row.precio_sin_impuesto_utilidad_conversion,
                          'es-MX',
                          'MXN',
                          2,
                          2
                        )}`}
                        / {codeCurrency}
                      </div>
                    </div>
                  </td>

                  {/* precio_sin_impuesto_conversion: precio_sin_impuesto_conversion,
          precio_sin_impuesto_utilidad_conversion:
            precio_sin_impuesto_utilidad_conversion,
          precio_sin_impuesto_indirecto_conversion:
            precio_sin_impuesto_indirecto_conversion,

          impuesto_conversion: impuesto_conversion,
          impuesto_utilidad_conversion: impuesto_utilidad_conversion,
          impuesto_indirecto_conversion: impuesto_indirecto_conversion,

          subtotal_utilidad_conversion: subtotal_utilidad_conversion,
          subtotal_indirectos_conversion: subtotal_indirectos_conversion,

          subtotal_conversion: subtotal_conversion,
          impuestos_conversion: impuestos_conversion,
          total_conversion: 0, */}

                  {/* <td>
                    <div className="d-flex align-items-center">
                      <div className="d-flex justify-content-start flex-column fs-012 text-gray-600">
                        {`${formatPriceNew(
                          row.impuesto_conversion,
                          'es-MX',
                          'MXN',
                          2,
                          2
                        )}`}
                        / {codeCurrency}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="d-flex justify-content-start flex-column fs-012 text-gray-600">
                        {`${formatPriceNew(
                          row.impuesto_utilidad_conversion,
                          'es-MX',
                          'MXN',
                          2,
                          2
                        )}`}
                        / {codeCurrency}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="d-flex justify-content-start flex-column fs-012 text-gray-600">
                        {`${formatPriceNew(
                          row.impuesto_indirecto_conversion,
                          'es-MX',
                          'MXN',
                          2,
                          2
                        )}`}
                        / {codeCurrency}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="d-flex justify-content-start flex-column fs-012 text-gray-600">
                        {`${formatPriceNew(
                          sumar(
                            Number(row.impuesto_conversion),
                            sumar(
                              Number(row.impuesto_utilidad_conversion),
                              Number(row.impuesto_indirecto_conversion)
                            )
                          ),
                          'es-MX',
                          'MXN',
                          2,
                          2
                        )}`}
                        / {codeCurrency}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="d-flex justify-content-start flex-column fs-012 text-gray-600">
                        {`${formatPriceNew(
                          row.impuestos_conversion,
                          'es-MX',
                          'MXN',
                          2,
                          2
                        )}`}
                        / {codeCurrency}
                      </div>
                    </div>
                  </td> */}

                  <td>
                    <div className="d-flex align-items-center">
                      <div className="d-flex justify-content-start flex-column fs-012 text-gray-600">
                        {`${formatPriceNew(
                          sumar(
                            Number(row.precio_sin_impuesto_conversion),
                            sumar(
                              Number(
                                row.precio_sin_impuesto_indirecto_conversion
                              ),
                              Number(
                                row.precio_sin_impuesto_utilidad_conversion
                              )
                            )
                          ),
                          'es-MX',
                          'MXN',
                          2,
                          2
                        )}`}
                        / {codeCurrency}
                      </div>
                    </div>
                  </td>

                  <td>
                    <div className="d-flex align-items-center">
                      <div className="d-flex justify-content-start flex-column fs-012 text-gray-600">
                        {`${formatPriceNew(
                          sumar(
                            Number(row.subtotal_conversion),
                            sumar(
                              Number(row.subtotal_indirectos_conversion),
                              Number(row.subtotal_utilidad_conversion)
                            )
                          ),
                          'es-MX',
                          'MXN',
                          2,
                          2
                        )}`}
                        / {codeCurrency}
                      </div>
                    </div>
                  </td>

                  <td>
                    <div className="d-flex align-items-center">
                      <div className="d-flex justify-content-start flex-column fs-012 text-gray-600">
                        <a
                          className="badge badge-light-danger  text-hover-danger fw-bold px-4 py-3 cursor-pointer"
                          title="Eliminar concepto"
                          onClick={async (event) => {
                            deleteRowById(row.id, row.tipo);
                          }}
                        >
                          <KTSVG
                            path="/media/icons/duotune/general/gen027.svg"
                            className="svg-icon-4"
                          />
                        </a>
                      </div>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={9} className="text-center text-muted">
                  Sin conceptos
                </td>
              </tr>
            )}

            <tr>
              <td colSpan={9}>
                <div className="d-flex align-items-center">
                  <div
                    className="btn btn-light-primary btn-sm mb-10"
                    onClick={AddRow}
                  >
                    <KTSVG
                      path="/media/icons/duotune/arrows/arr075.svg"
                      className="svg-icon-5"
                    />
                    Agregar Concepto
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export { ConceptsList };
