import React, { useState } from 'react';

interface Props {
  shortText: string;
  fullText: string;
}

const VerMasTexto: React.FC<Props> = ({ shortText, fullText }) => {
  const [mostrarCompleto, setMostrarCompleto] = useState(false);

  const toggleMostrarCompleto = () => {
    setMostrarCompleto(!mostrarCompleto);
  };

  return (
    <div className="texto-contenedor">
      <p className={`texto ${mostrarCompleto ? 'oculto' : ''}`}>{shortText}</p>
      <p
        className={`texto ${mostrarCompleto ? '' : 'oculto'}`}
        style={{ textAlign: 'justify', wordWrap: 'break-word' }}
      >
        {fullText}
      </p>
      <a className="ver-mas-btn" onClick={toggleMostrarCompleto}>
        {mostrarCompleto ? 'Ver menos' : 'Ver más'}
      </a>
    </div>
  );
};

export { VerMasTexto };
