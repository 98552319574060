import axios from 'axios'
const API_URL = process.env.REACT_APP_API


export async function getAllTareas(startIndex: number, endIndex: number, filters: any, oportunidad_id: number) {

    let search: string = '';
    let fecha: string = '';
    let prioridad: string = '';
    let oportunidad: string = '';
    let status: string = '';

    if (filters.prioridad_id) {
        prioridad = `&prioridad_id=${filters.prioridad_id}`;
    }

    if (filters.fecha_inicio && filters.fecha_fin) {
        fecha = `&created_atOpBetween=${filters.fecha_inicio},${filters.fecha_fin} 23:00`;
    }

    if (filters.status_id) {
        status = `&status_id=${filters.status_id}`;
    }

    if (filters.oportunidad_id) {
        oportunidad = `&oportunidad_id=${filters.oportunidad_id}`;
    } else {
        oportunidad = `&oportunidad_id=${oportunidad_id}`
    }

    search = `${prioridad}${fecha.replace(/ /g, '%20')}${status}`;

    const response = await axios.get(`${API_URL}tareas?limit=${startIndex}&page=${endIndex}${oportunidad}${search}`);
    // const response = await axios.get(`${API_URL}tareas?limit=${startIndex}&page=${endIndex}&oportunidad_id=${oportunidad_id}${search}`);

    const data = response.data.doc?.data?.rows;
    const count = response.data.doc?.data?.count;
    return {
        data: data,
        count: count
    };

}

export async function getTareaById(op_id: number, tarea_id: number) {

    const response = await axios.get(`${API_URL}tareas?oportunidad_id=${op_id}&id=${tarea_id}`);
    const { id, nombre, descripcion, fecha_inicio, fecha_fin, statusTarea, prioridad, oportunidad, user, } = response.data.doc.data.rows[0];
    return {
        id: id,
        nombre: nombre,
        descripcion: descripcion,
        fecha_inicio: fecha_inicio,
        fecha_fin: fecha_fin,
        statusTarea: {
            id: statusTarea?.id,
            name: statusTarea?.name,
            code: statusTarea?.code
        },
        prioridad: {
            id: prioridad?.id,
            name: prioridad?.name,
            code: prioridad?.code
        },
        oportunidad: {
            id: oportunidad?.id,
            nombre: oportunidad?.nombre
        },
        user: {
            id: user.id,
            alias: user.alias,
            email: user.email,
            first_name: user.first_name,
            father_last_name: user.father_last_name,
            mother_last_name: user.mother_last_name,
            phone: user.phone
        },
        vendedor_id: user?.id,
        prioridad_id: prioridad.id,
        status_id: statusTarea.id,
    };
}

export async function getTareaSByOportunidad(op_id: number) {

    const response = await axios.get(`${API_URL}tareas?oportunidad_id=${op_id}`);
    const data = response.data.doc.data.rows;
    const count = response.data.doc.data.count;
    return {
        data: data,
        count: count
    };
}


export async function addTarea(body: any, oportunidadId: number) {

    const { fecha_inicio, fecha_fin, nombre, descripcion, vendedor_id, prioridad_id, status_id } = body;

    const data = {
        nombre: nombre,
        descripcion: descripcion,
        status_id: status_id,
        prioridad_id: prioridad_id,
        oportunidad_id: oportunidadId,
        user_id: vendedor_id,
        fecha_inicio: fecha_inicio,
        fecha_fin: fecha_fin,

    }
    const response = await axios.post(`${API_URL}tareas/`, data);
    return response;

}
export async function updTarea(body: any) {

    const { id, fecha_inicio, fecha_fin, nombre, descripcion, vendedor_id, prioridad_id, status_id, oportunidad_id } = body;

    const data = {
        nombre: nombre,
        descripcion: descripcion,
        status_id: status_id,
        prioridad_id: prioridad_id,
        oportunidad_id: oportunidad_id,
        user_id: vendedor_id,
        fecha_inicio: fecha_inicio,
        fecha_fin: fecha_fin,

    }

    const response = await axios.put(`${API_URL}tareas/${id}`, data);
    return response;

}

export async function getAllUsers() {


    const response = await axios.get(`${API_URL}tareas/getUser/byRole`);

    const data = response.data.doc.data;
    // const count = response.data.doc.data.count;
    return {
        data: data,
        // count: count
    };

}

export async function getHistory(search: string, id: string) {

    const response = await axios.get(`${API_URL}opp/tarea/historial/${id}`);
    const data = response.data.doc.data;
    return data;

}

export async function createHistoryMessage(message: string, id: string) {
    const response = await axios.post(`${API_URL}opp/tarea/historial/comentario`, {
        taskId: id,
        comentario: message
    });
    return response;

}

export const uploadFileToTask = async (file: any, task: string) => {

    const formData = new FormData();
    formData.append('file', file);
    formData.append('taskId', task);

    const response = await axios.post(`${API_URL}opp/tarea/documento`, formData);
    return response;

}

export const removeFileOfOTask = async (taskId: number) => {

    const response = await axios.delete(`${API_URL}opp/tarea/documento/${taskId}`);
    return response;

}