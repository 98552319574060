import React, { useState, useEffect } from 'react';
import { KTSVG } from '../../../../_metronic/helpers';
import Select from 'react-select';
import { useFormik } from 'formik';
import { useGetAllCuentas } from '../../contacto/hooks/ContactoHook';
import {
  useGetAllVendedoresTecnicos,
  useGetAllVendedoresComerciales,
} from '../../oportunidades/hooks/OportunidadHook';
import { useGetAllSubCuentas } from '../../contacto/hooks/ContactoHook';
import { useSelectGeneric } from '../../catalogos/generico/hooks/genericoHook';

type Props = {
  initFilterValues: any;
  onCleanFilter: () => void;
  onSearchFilter: (values: any) => void;
};

const Filter: React.FC<Props> = ({
  initFilterValues,
  onSearchFilter,
  onCleanFilter,
}) => {
  const [filterValues, setFilterValues] = useState(initFilterValues);
  const [selectedOptionCuenta, setSelectedOptionCuenta] = useState(null);
  const [selectedOptionSubCuenta, setSelectedOptionSubCuenta] = useState(null);
  const [selectedOptionVComercial, setSelectedOptionVComercial] =
    useState(null);
  const [selectedOptionVTecnico, setSelectedOptionVTecnico] = useState(null);
  const [selectedOptionEstatus, setSelectedOptionEstatus] = useState(null);
  const [selectedOptionEtapa, setSelectedOptionEtapa] = useState(null);
  const [cuentaId, setcuentaId] = useState<number>(0);

  const { dataSub } = useGetAllSubCuentas(cuentaId, 1);
  const { dataCuenta } = useGetAllCuentas(1);
  const { vTecnicos } = useGetAllVendedoresTecnicos();
  const { vComerciales } = useGetAllVendedoresComerciales();
  const { catalog: etapa } = useSelectGeneric('ETP');
  const { catalog: estatus } = useSelectGeneric('STS');

  const [cuentasData, setCuentasData] = useState<
    { value: any; label: string }[] | undefined
  >();
  const [subCuentasData, setSubCuentasData] = useState<
    { value: any; label: string }[] | undefined
  >();

  const [vTecData, setVTecData] = useState<
    { value: any; label: string }[] | undefined
  >();

  const [vComData, setVComData] = useState<
    { value: any; label: string }[] | undefined
  >();
  const [etapaData, setEtapaData] = useState<
    { value: any; label: string }[] | undefined
  >();
  const [status, setStatus] = useState<
    { value: any; label: string }[] | undefined
  >();

  useEffect(() => {
    if (initFilterValues) {
      setFilterValues(initFilterValues);
    }
  }, [initFilterValues]);

  useEffect(() => {
    if (dataCuenta) {
      const selectOptions = dataCuenta.map((option: any) => ({
        value: option.id,
        label: `${option.nombre} `,
      }));
      setCuentasData(selectOptions);
    }
  }, [dataCuenta]);

  useEffect(() => {
    if (dataSub) {
      const selectOptions = dataSub.map((option: any) => ({
        value: option.id,
        label: `${option.nombre} `,
      }));
      setSubCuentasData(selectOptions);
    }
  }, [dataSub]);

  useEffect(() => {
    if (vTecnicos) {
      const selectOptions = vTecnicos.map((option: any) => ({
        value: option.id,
        label: `${option.nombre} `,
      }));
      setVTecData(selectOptions);
    }
  }, [vTecnicos]);

  useEffect(() => {
    if (vComerciales) {
      const selectOptions = vComerciales.map((option: any) => ({
        value: option.id,
        label: `${option.nombre} `,
      }));
      setVComData(selectOptions);
    }
  }, [vComerciales]);

  useEffect(() => {
    if (etapa) {
      const selectOptions = etapa.map((option: any) => ({
        value: option.id,
        label: `${option.name} `,
      }));
      setEtapaData(selectOptions);
    }
  }, [etapa]);

  useEffect(() => {
    if (estatus) {
      const selectOptions = estatus.map((option: any) => ({
        value: option.id,
        label: `${option.name} `,
      }));
      setStatus(selectOptions);
    }
  }, [estatus]);

  const handleSelect = (selectedOption: any, code: string) => {
    const { value } = selectedOption;

    switch (code) {
      case 'CUE':
        setSelectedOptionCuenta(selectedOption);
        formFilter.setFieldValue('cuenta', value);
        setcuentaId(value);
        break;
      case 'SUB':
        setSelectedOptionSubCuenta(selectedOption);
        formFilter.setFieldValue('subcuenta', value);
        break;
      case 'VC':
        setSelectedOptionVComercial(selectedOption);
        formFilter.setFieldValue('vendedorC', value);
        break;
      case 'VT':
        setSelectedOptionVTecnico(selectedOption);
        formFilter.setFieldValue('vendedorT', value);
        break;
      case 'STS':
        setSelectedOptionEstatus(selectedOption);
        formFilter.setFieldValue('status', value);
        break;
      case 'ETP':
        setSelectedOptionEtapa(selectedOption);
        formFilter.setFieldValue('etapa', value);
        break;
      default:
        break;
    }
  };

  const formFilter = useFormik({
    initialValues: filterValues,
    enableReinitialize: true,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      onSearchFilter(values);
    },
  });

  return (
    <>
      <div className="mt-5" style={{ width: '100%' }}>
        <form
          style={{ width: '100%' }}
          id="kt_modal_generic_form"
          className="form"
          action="#"
          onSubmit={formFilter.handleSubmit}
        >
          <div className="row">
            <div className="col-md-3">
              <label className="form-label fw-bold">Fecha inicial </label>
              <div>
                <input
                  type="date"
                  value={
                    new Date(formFilter.values.fecha_inicio)
                      .toISOString()
                      .split('T')[0]
                  }
                  className="form-control"
                  onChange={(e) => {
                    if (e.target.value) {
                      const fecha = new Date(e.target.value)
                        .toISOString()
                        .split('T')[0];
                      formFilter.setFieldValue('fecha_inicio', fecha);
                    }
                  }}
                ></input>
              </div>
            </div>
            <div className="col-md-3">
              <label className="form-label fw-bold">Fecha final </label>
              <div>
                <input
                  type="date"
                  value={
                    new Date(formFilter.values.fecha_fin)
                      .toISOString()
                      .split('T')[0]
                  }
                  className="form-control"
                  onChange={(e) => {
                    if (e.target.value) {
                      const fecha = new Date(e.target.value)
                        .toISOString()
                        .split('T')[0];
                      formFilter.setFieldValue('fecha_fin', fecha);
                    }
                  }}
                ></input>
              </div>
            </div>
            <div className="col-md-3">
              <label className="form-label fw-bold">Cuenta </label>
              <div>
                <Select
                  options={cuentasData}
                  placeholder={'Seleccione'}
                  // onChange={handleSelect}
                  onChange={(event) => {
                    handleSelect(event, 'CUE');
                  }}
                  value={selectedOptionCuenta}
                  // closeMenuOnSelect={false}
                  noOptionsMessage={() => {
                    return <span>No hay cuentas</span>;
                  }}
                  styles={{
                    placeholder: (provided, state) => ({
                      ...provided,
                      color: '#181c32',
                    }),
                    indicatorSeparator: (provided, state) => ({
                      ...provided,
                      display: 'none',
                    }),
                    dropdownIndicator: (provided, state) => ({
                      ...provided,
                      color: '#7E8199',
                    }),
                    control: (provided, state) => ({
                      ...provided,
                      border: '1px solid #e4e6ef',
                      borderRadius: '6px',
                      padding: '3px',
                      fontSize: '1.1rem',
                      boxShadow: 'none',
                      backgroundColor: state.isDisabled ? '#EFF2F5' : 'white',
                    }),
                    singleValue: (provided, state) => ({
                      ...provided,
                      color: state.isDisabled ? '#181c32' : 'inherit',
                      fontWeight: 500,
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      fontSize: '1.1rem',
                    }),
                  }}
                />
              </div>
            </div>
            <div className="col-md-3">
              <label className="form-label fw-bold">Subcuenta </label>
              <div>
                <Select
                  options={subCuentasData}
                  placeholder={'Seleccione'}
                  // onChange={handleSelect}

                  onChange={(event) => {
                    handleSelect(event, 'SUB');
                  }}
                  value={selectedOptionSubCuenta}
                  // closeMenuOnSelect={false}
                  noOptionsMessage={() => {
                    return <span>No hay subcuentas</span>;
                  }}
                  styles={{
                    placeholder: (provided, state) => ({
                      ...provided,
                      color: '#181c32',
                    }),
                    indicatorSeparator: (provided, state) => ({
                      ...provided,
                      display: 'none',
                    }),
                    dropdownIndicator: (provided, state) => ({
                      ...provided,
                      color: '#7E8199',
                    }),
                    control: (provided, state) => ({
                      ...provided,
                      border: '1px solid #e4e6ef',
                      borderRadius: '6px',
                      padding: '3px',
                      fontSize: '1.1rem',
                      boxShadow: 'none',
                      backgroundColor: state.isDisabled ? '#EFF2F5' : 'white',
                    }),
                    singleValue: (provided, state) => ({
                      ...provided,
                      color: state.isDisabled ? '#181c32' : 'inherit',
                      fontWeight: 500,
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      fontSize: '1.1rem',
                    }),
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-md-3">
              <label className="form-label fw-bold">Vendedor comercial</label>
              <div>
                <Select
                  options={vComData}
                  placeholder={'Seleccione'}
                  onChange={(event) => {
                    handleSelect(event, 'VC');
                  }}
                  value={selectedOptionVComercial}
                  // closeMenuOnSelect={false}
                  noOptionsMessage={() => {
                    return <span>No hay vendedores</span>;
                  }}
                  styles={{
                    placeholder: (provided, state) => ({
                      ...provided,
                      color: '#181c32',
                    }),
                    indicatorSeparator: (provided, state) => ({
                      ...provided,
                      display: 'none',
                    }),
                    dropdownIndicator: (provided, state) => ({
                      ...provided,
                      color: '#7E8199',
                    }),
                    control: (provided, state) => ({
                      ...provided,
                      border: '1px solid #e4e6ef',
                      borderRadius: '6px',
                      padding: '3px',
                      fontSize: '1.1rem',
                      boxShadow: 'none',
                      backgroundColor: state.isDisabled ? '#EFF2F5' : 'white',
                    }),
                    singleValue: (provided, state) => ({
                      ...provided,
                      color: state.isDisabled ? '#181c32' : 'inherit',
                      fontWeight: 500,
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      fontSize: '1.1rem',
                    }),
                  }}
                />
              </div>
            </div>
            <div className="col-md-3">
              <label className="form-label fw-bold">Vendedor técnico</label>
              <div>
                <Select
                  options={vTecData}
                  placeholder={'Seleccione'}
                  // onChange={handleSelect}
                  onChange={(event) => {
                    handleSelect(event, 'VT');
                  }}
                  value={selectedOptionVTecnico}
                  // closeMenuOnSelect={false}
                  noOptionsMessage={() => {
                    return <span>No hay vendedores</span>;
                  }}
                  styles={{
                    placeholder: (provided, state) => ({
                      ...provided,
                      color: '#181c32',
                    }),
                    indicatorSeparator: (provided, state) => ({
                      ...provided,
                      display: 'none',
                    }),
                    dropdownIndicator: (provided, state) => ({
                      ...provided,
                      color: '#7E8199',
                    }),
                    control: (provided, state) => ({
                      ...provided,
                      border: '1px solid #e4e6ef',
                      borderRadius: '6px',
                      padding: '3px',
                      fontSize: '1.1rem',
                      boxShadow: 'none',
                      backgroundColor: state.isDisabled ? '#EFF2F5' : 'white',
                    }),
                    singleValue: (provided, state) => ({
                      ...provided,
                      color: state.isDisabled ? '#181c32' : 'inherit',
                      fontWeight: 500,
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      fontSize: '1.1rem',
                    }),
                  }}
                />
              </div>
            </div>
            <div className="col-md-3">
              <label className="form-label fw-bold">Estatus </label>
              <div>
                <Select
                  options={status}
                  placeholder={'Seleccione'}
                  // onChange={handleSelect}
                  onChange={(event) => {
                    handleSelect(event, 'STS');
                  }}
                  value={selectedOptionEstatus}
                  // closeMenuOnSelect={false}
                  noOptionsMessage={() => {
                    return <span>No hay estatus</span>;
                  }}
                  styles={{
                    placeholder: (provided, state) => ({
                      ...provided,
                      color: '#181c32',
                    }),
                    indicatorSeparator: (provided, state) => ({
                      ...provided,
                      display: 'none',
                    }),
                    dropdownIndicator: (provided, state) => ({
                      ...provided,
                      color: '#7E8199',
                    }),
                    control: (provided, state) => ({
                      ...provided,
                      border: '1px solid #e4e6ef',
                      borderRadius: '6px',
                      padding: '3px',
                      fontSize: '1.1rem',
                      boxShadow: 'none',
                      backgroundColor: state.isDisabled ? '#EFF2F5' : 'white',
                    }),
                    singleValue: (provided, state) => ({
                      ...provided,
                      color: state.isDisabled ? '#181c32' : 'inherit',
                      fontWeight: 500,
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      fontSize: '1.1rem',
                    }),
                  }}
                />
              </div>
            </div>
            <div className="col-md-3">
              <label className="form-label fw-bold">Etapa </label>
              <div>
                <Select
                  options={etapaData}
                  placeholder={'Seleccione'}
                  // onChange={handleSelect}
                  onChange={(event) => {
                    handleSelect(event, 'ETP');
                  }}
                  value={selectedOptionEtapa}
                  // closeMenuOnSelect={false}
                  noOptionsMessage={() => {
                    return <span>No hay etapas</span>;
                  }}
                  styles={{
                    placeholder: (provided, state) => ({
                      ...provided,
                      color: '#181c32',
                    }),
                    indicatorSeparator: (provided, state) => ({
                      ...provided,
                      display: 'none',
                    }),
                    dropdownIndicator: (provided, state) => ({
                      ...provided,
                      color: '#7E8199',
                    }),
                    control: (provided, state) => ({
                      ...provided,
                      border: '1px solid #e4e6ef',
                      borderRadius: '6px',
                      padding: '3px',
                      fontSize: '1.1rem',
                      boxShadow: 'none',
                      backgroundColor: state.isDisabled ? '#EFF2F5' : 'white',
                    }),
                    singleValue: (provided, state) => ({
                      ...provided,
                      color: state.isDisabled ? '#181c32' : 'inherit',
                      fontWeight: 500,
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      fontSize: '1.1rem',
                    }),
                  }}
                />
              </div>
            </div>

            <div className="d-flex justify-content-end mt-10">
              <button
                type="reset"
                className="btn btn-sm btn-white btn-active-light-primary me-2"
                data-kt-menu-dismiss="true"
                onClick={() => {
                  formFilter.resetForm();
                  onCleanFilter();
                  setSelectedOptionCuenta(null);
                  setSelectedOptionSubCuenta(null);
                  setSelectedOptionVComercial(null);
                  setSelectedOptionVTecnico(null);
                  setSelectedOptionEtapa(null);
                  setSelectedOptionEstatus(null);
                }}
              >
                Limpiar
              </button>

              <button
                type="submit"
                className="btn btn-sm btn-primary"
                data-kt-menu-dismiss="true"
              >
                Buscar
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export { Filter };
