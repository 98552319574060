import React, { FC, useState } from "react";
import { useHistory } from "react-router-dom";
import { encryptId } from "../../../../_metronic/helpers";

type Props = {
  board: any;
};

const CardOpportunities: FC<Props> = ({ board }) => {
  const history = useHistory();
  const itemsPerPage = 9;
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(board.length / itemsPerPage);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, board.length);

  const onPageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  return (
    <>
      <div className="container">
        <div className="row">
          {board.slice(startIndex, endIndex).map((item: any, index: number) => {
            return (
              <div
                key={index}
                className="col-md-6 col-lg-6 col-xxl-4 col-xl-6 mb-4 mt-4"
              >
                <div
                  className="card borders-card-board h-100 cursor-pointer"
                  onClick={() =>
                    history.push(
                      `/clientes/contactos/edit/${encryptId(
                        item.id.toString()
                      )}`
                    )
                  }
                >
                  <div
                    className="card-body  p-9"
                    style={{ overflow: "hidden" }}
                  >
                    <div className="d-flex justify-content-between w-100">
                      <div>
                        <div className="fs-4 large-text text-hover-primary fw-bolder mb-0">
                          {`${item.nombre
                            .charAt(0)
                            .toUpperCase()}${item.nombre.slice(
                            1
                          )} ${item.apellido_paterno
                            .charAt(0)
                            .toUpperCase()}${item.apellido_paterno.slice(1)}`}
                        </div>
                      </div>

                      <div className="text-center">
                        <div className="symbol symbol-40px">
                          {item.nombre ? (
                            <span
                              className={`symbol-label bg-light-primary fs-5 text-primary fw-bolder`}
                            >
                              {`${item.nombre.substring(
                                0,
                                1
                              )}${item.apellido_paterno.substring(0, 1)}`}
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="d-flex w-100 mb-2">
                      <div className="fs-6 fw-bold text-primary">
                        <span className="text-gray-600 fw-bold fs-5 me-2">
                          Teléfono:
                        </span>
                        {item.telefono ? item.telefono : "No registrado"}
                      </div>
                    </div>
                    <div className="d-flex w-100">
                      <div className="fs-6 fw-normal text-primary">
                        <span className="text-gray-600 fw-bold fs-5 me-2">
                          Email:
                        </span>
                        {item.email ? item.email : "No registrado"}
                      </div>
                      <span className="position-absolute opacity-15 bottom-0 start-0 border-4 border-primary border-bottom w-100"></span>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="row justify-content-center">
          <nav aria-label="Navegación de página">
            <ul className="pagination">
              {Array.from({ length: totalPages }, (_, i) => (
                <li
                  key={i}
                  className={`page-item ${
                    currentPage === i + 1 ? "active" : ""
                  }`}
                >
                  <button
                    className="page-link"
                    onClick={() => onPageChange(i + 1)}
                  >
                    {i + 1}
                  </button>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
};

export { CardOpportunities };
