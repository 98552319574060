import React, { useState, useEffect } from "react";
import { KTSVG, encryptId } from "../../../../_metronic/helpers";
import { Modal } from "react-bootstrap-v5";
import { Link } from "react-router-dom";
import { dateFormatt } from "../../../../_metronic/helpers/FormatDate";
import { NoteQuoModel } from "../../notes_quo/models/NoteModel";

type Props = {
  show: boolean;
  handleClose: (refresh: boolean) => void;
  data: NoteQuoModel[];
  id: number;
};

const CreateNoteDetailModal: React.FC<Props> = ({
  show,
  handleClose,
  data,
  id,
}) => {
  return (
    <Modal
      id="kt_modal_create"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog min-w-1000px"
      show={show}
      onHide={() => {
        handleClose(false);
      }}
    >
      <div className="modal-content">
        <div className="modal-header">
          <h2 className="text-muted">Notas</h2>
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            onClick={() => {
              handleClose(false);
            }}
          >
            <KTSVG
              className="svg-icon-2"
              path="/media/icons/duotune/arrows/arr061.svg"
            />
          </div>
        </div>
        <div className="modal-body mx-5 padding-top-00">
          <div
            className="pe-6 me-n6 mt-5 position-relative scroll-y max-h-200px"
            style={{ maxHeight: "600px" }}
          >
            {data.length > 0 ? (
              data.map((note, index) => (
                <div
                  className="border border-dashed border-gray-300 rounded px-7 py-3 mb-6"
                  // key={index}
                >
                  <div className="d-flex flex-stack mt-3">
                    <div className="me-3 fs-5 fw-bolder">{note.nombre}</div>
                    <div className=" fw-bolder">
                      <Link
                        className="btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary"
                        to={`/cotizaciones/${
                          id ? encryptId(id.toString()) : ""
                        }/notas/${encryptId(note.id.toString())}`}
                      >
                        <KTSVG
                          path="/media/icons/duotune/general/gen055.svg"
                          className="svg-icon-3"
                        />
                      </Link>
                    </div>
                  </div>
                  <div className="d-flex flex-stack">
                    <span className="text-gray-500 fw-bold">
                      <div className="w-100 pe-5">
                        <div className="w-100 d-flex mt-5">
                          <label className="form-label w-auto">
                            {index <= 0 ? "Creado por:" : "Se agregó nota por:"}
                          </label>
                        </div>
                        <div
                          className="d-flex"
                          style={{ alignItems: "center", width: "100%" }}
                        >
                          <div className="symbol symbol-30px">
                            <span className="symbol-label fs-7 fw-bold bg-light-primary text-primary fw-bold">
                              {note.user_creador?.first_name &&
                                note.user_creador?.first_name[0]}
                              {note.user_creador?.father_last_name &&
                                note.user_creador?.father_last_name[0]}
                            </span>
                          </div>
                          <span className="ms-3 ">
                            {note.user_creador?.first_name &&
                              note.user_creador?.first_name}{" "}
                            {note.user_creador?.father_last_name &&
                              note.user_creador?.father_last_name}
                          </span>
                        </div>
                      </div>
                    </span>
                    <span>
                      <div className="w-100 pe-5 text-gray-500">
                        <div className="w-100 d-flex mt-5">
                          <label className=" form-label w-auto">
                            Fecha de creación:
                          </label>
                        </div>
                        <span>
                          {note.created_at
                            ? dateFormatt(note.created_at)
                            : note.created_at}
                        </span>
                      </div>
                    </span>
                    <span>
                      <div className="w-100 pe-5 text-gray-500">
                        <div className="w-100 d-flex mt-5">
                          <label className="form-label w-auto">
                            Fecha de modificación:
                          </label>
                        </div>
                        <span>
                          {note.updated_at
                            ? dateFormatt(note.updated_at)
                            : note.updated_at}
                        </span>
                      </div>
                    </span>
                  </div>
                  <div className="d-flex flex-stack mb-3">
                    <div className="w-100 pe-5 text-gray-500">
                      <div className="w-100 d-flex mt-5">
                        <label className="form-label w-auto">
                          Descripción:
                        </label>
                      </div>
                      <span className="break-word-text-large">
                        {note.descripcion}
                      </span>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="text-center mt-5 fs-5 text-gray-700">
                Sin notas
              </div>
            )}
          </div>

          <div className="d-flex justify-content-end mt-10">
            <button
              type="reset"
              className="btn btn-light me-3"
              data-kt-users-modal-action="cancel"
              onClick={() => {
                handleClose(false);
              }}
            >
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export { CreateNoteDetailModal };
