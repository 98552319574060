import { FC } from 'react';
import { PageTitle, PageLink } from '../../../_metronic/layout/core';
import { View } from './components/View';
// import { ViewAddEdit } from './components/ViewAddEdit';
import { useParams } from 'react-router-dom';
import { decryptId } from '../../../_metronic/helpers';

const NoteOppPage: FC = () => {
  const { id_oportunity, id } =
    useParams<{ id_oportunity: string; id: string }>();

  const noteId = decryptId(id);
  const oportunityId = decryptId(id_oportunity);
  const breadCrumbs: Array<PageLink> = [
    {
      title: 'Oportunidades',
      path: '/oportunidades/',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
    {
      title: 'Editar',
      path: `/oportunidades_edit/${id_oportunity}`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
    {
      title: 'Notas',
      path: `/oportunidades_edit/${id_oportunity}`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ];
  return (
    <>
      <PageTitle breadcrumbs={breadCrumbs}>
        {noteId ? 'Editar nota' : 'Nueva nota'}
      </PageTitle>

      <View
        id={noteId ? noteId : '0'}
        oportunityId={oportunityId ? oportunityId : '0'}
      />
    </>
  );
};

export { NoteOppPage };
