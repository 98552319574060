import React, { useState, useEffect } from "react";
import {
  CategoryModel,
  UpdProductsModel,
  QuotationModel,
} from "../models/QuotationModel";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import {
  getCategories,
  insertCategoryProducts,
  createProductShorcut,
  updProducts,
  getFilterSupplier,
  getTaxes,
  uploadImageProduct,
  uploadDocProduct,
  removeDocProduct,
  getAllProducts,
  getAllQuotation,
  removeProduct,
} from "../../../service/products/Products";
import { SimpleAlert } from "../../../../_metronic/helpers";
import { MODULS } from "../components/View";

export const useGetCategoryProd = (
  realoadGrid: number,
  // startIndex: number,
  // endIndex: number,
  filters: any,
  module: number
) => {
  const [dataCatProd, setDataCatProd] = useState<CategoryModel[]>([]);
  const [loadingRequest, setLoadingRequest] = useState<boolean>(false);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      setLoadingRequest(true);
      const res = await getCategories(filters)
        .then()
        .catch((error) => {
          history.push("/error/500");
        });
      if (res) {
        setDataCatProd(res.data);
        setLoadingRequest(false);
      }
    };

    if (module === MODULS.PRODUCTOS) {
      fetchPost();
    }
  }, [realoadGrid, filters, module]);

  return { dataCatProd, loadingRequest };
};

export const useCreateCategoryProducts = () => {
  const [loadingCreateCategory, setLoadingCreateCategory] =
    useState<boolean>(false);
  const [isCorrectInsertCP, setIsCorrectInsertCP] = useState<boolean>(false);
  const createCategoryProduct = async (category: CategoryModel) => {
    setLoadingCreateCategory(true);
    try {
      await insertCategoryProducts(category).then((response) => {
        const {
          data: { message },
        } = response;

        SimpleAlert(message, 3000, "success");

        setLoadingCreateCategory(false);
        setIsCorrectInsertCP(true);
      });
    } catch (err: any) {
      setIsCorrectInsertCP(false);
      let resMessageToast: string = "";
      const {
        data: { message },
      } = err.response;
      resMessageToast = `${message}`;
      toast.error(resMessageToast, {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setLoadingCreateCategory(false);
      throw resMessageToast;
    }
  };
  return { createCategoryProduct, loadingCreateCategory, isCorrectInsertCP };
};

export const useInsertProductShortcut = () => {
  const [loadingInsertProductShort, setLoadingProductShort] =
    useState<boolean>(false);
  const [isInsertedCorrect, setIsInsertedCorrect] = useState<boolean>(false);

  const insertProductShort = async (
    productName: string,
    categoryId: number
  ) => {
    setLoadingProductShort(true);
    try {
      await createProductShorcut(productName, categoryId).then(() => {
        setLoadingProductShort(false);
        setIsInsertedCorrect(true);
      });
    } catch (err: any) {
      setIsInsertedCorrect(false);

      let resMessageToast: string = "";
      const {
        data: { message },
      } = err.response;
      resMessageToast = `${message}`;
      toast.error(resMessageToast, {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setLoadingProductShort(false);
    }
  };
  return { insertProductShort, loadingInsertProductShort, isInsertedCorrect };
};

export const useUPdateProductShortcut = () => {
  const [loadingUpdateProdShort, setLoadingUpdateProdShort] =
    useState<boolean>(false);
  const [isCorrectUpdateP, setIsCorrectUpdateP] = useState<boolean>(false);
  const errorResponse = {
    error: true,
  };

  const updateProductShort = async (body: UpdProductsModel) => {
    setLoadingUpdateProdShort(true);
    try {
      await updProducts(body).then(() => {
        setIsCorrectUpdateP(true);
        setLoadingUpdateProdShort(false);
      });
    } catch (err: any) {
      setIsCorrectUpdateP(false);
      let resMessageToast: string = "";
      const {
        data: { message },
      } = err.response;
      resMessageToast = `${message}`;
      toast.error(resMessageToast, {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      setLoadingUpdateProdShort(false);
      throw errorResponse;
    }
  };
  return { updateProductShort, loadingUpdateProdShort, isCorrectUpdateP };
};

const formmatedDataToSelect = (data: any) => {
  const selectOptions = data.map((option: any) => ({
    value: option.id,
    label: option.nombre,
  }));

  return selectOptions;
};

export const useGetProveedorFilter = (execute: boolean, refresh: number) => {
  const [suppDataSelect, setSuppDataSelect] = useState<any[]>([
    {
      value: "new",
      label: "Agregar Proveedor",
    },
  ]);
  const [loadingRequest, setLoadingRequest] = useState<boolean>(false);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      setLoadingRequest(true);
      const res = await getFilterSupplier()
        .then()
        .catch((error) => {
          history.push("/error/500");
        });
      if (res) {
        setLoadingRequest(false);
        let options: any = [];

        options = formmatedDataToSelect(res.data);
        setSuppDataSelect((prevState) => [
          {
            value: "new",
            label: "Agregar Proveedor",
          },
          ...options,
        ]);
        setLoadingRequest(true);
      }
    };
    if (execute || refresh) {
      fetchPost();
    }
  }, [execute, refresh]);

  return { suppDataSelect, loadingRequest };
};

export const useGetCatTaxes = (execute: boolean) => {
  const [loadingGetTaxes, setLoadingGetTaxes] = useState<boolean>(false);
  const [optionsSelectTaxes, setOptionsSelectTaxes] = useState<any[]>([]);

  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      setLoadingGetTaxes(false);
      const res = await getTaxes()
        .then()
        .catch((error) => {
          history.push("/error/500");
        });
      if (res) {
        let formatLabel: string;
        const selectOptions = res.map(
          (option: any) => (
            (formatLabel = `${option.siglas} ${option.porcentaje * 100}%`),
            {
              value: option.id,
              label: formatLabel,
            }
          )
        );
        setOptionsSelectTaxes(selectOptions);
        setLoadingGetTaxes(false);
      }
    };
    if (execute) {
      fetchPost();
    }
  }, [execute]);

  return { loadingGetTaxes, optionsSelectTaxes };
};

export const useUploadImageProd = () => {
  const [loadingUploadImg, setLoadingUploadImg] = useState<boolean>(false);
  const [uploadImgCorrect, setUploadImgCorrect] = useState<boolean>(false);

  const insertImageproduct = async (file: any, id: any) => {
    const _file = file[0];
    setLoadingUploadImg(true);
    try {
      await uploadImageProduct(id, _file);
      setLoadingUploadImg(false);
      setUploadImgCorrect(true);
    } catch (err: any) {
      setUploadImgCorrect(false);
      let resMessageToast: string = "";
      const {
        data: { message },
      } = err.response;
      resMessageToast = `${message}`;
      toast.error(resMessageToast, {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setLoadingUploadImg(false);
    }
  };
  return { insertImageproduct, loadingUploadImg, uploadImgCorrect };
};

export const useUploadDocProd = () => {
  const [loadingUploadDoc, setLoadingUploadDoc] = useState<boolean>(false);
  const history = useHistory();
  const insertDocproduct = async (file: any, id: any) => {
    const _file = file[0];
    setLoadingUploadDoc(false);
    try {
      await uploadDocProduct(id, _file);
      setLoadingUploadDoc(false);
    } catch (err: any) {
      let resMessageToast: string = "";
      const {
        data: { message },
      } = err.response;
      resMessageToast = `${message}`;
      toast.error(resMessageToast, {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setLoadingUploadDoc(false);
    }
  };
  return { insertDocproduct, loadingUploadDoc };
};

export const removeFile = async (fileId: number, event: any) => {
  event.preventDefault();
  event.stopPropagation();

  await removeDocProduct(fileId)
    .then((res) => {
      toast.success(`${res.data.message}`, {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    })
    .catch((err) => {
      let resMessageToast: string = "";
      const {
        data: { message },
      } = err.response;
      resMessageToast = `${message}`;
      // setUploadLoading(false);
      toast.error(resMessageToast, {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    });
};

export const useGetAllProd = (
  realoadGrid: number,
  // startIndex: number,
  // endIndex: number,
  filters: any,
  module: number
) => {
  const [dataProducts, setDataProducts] = useState<CategoryModel[]>([]);
  const [loadingRequestProducts, setLoadingRequestProducts] =
    useState<boolean>(false);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      setLoadingRequestProducts(true);
      const res = await getAllProducts(filters)
        .then()
        .catch((error) => {
          history.push("/error/500");
        });
      if (res) {
        setDataProducts(res.data);
        setLoadingRequestProducts(false);
      }
    };

    if (module === MODULS.PRODUCTOS) {
      fetchPost();
    }
  }, [realoadGrid, filters, module]);

  return { dataProducts, loadingRequestProducts };
};

export const useGetAllQuo = (
  realoadGrid: number,
  // startIndex: number,
  // endIndex: number,
  // filters: any
  filters: any,
  module: number
) => {
  const [dataQuotations, setDataQuotations] = useState<QuotationModel[]>([]);
  const [loadingRequestQuotations, setLoadingRequestQuotations] =
    useState<boolean>(true);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      const res = await getAllQuotation(filters)
        .then()
        .catch((error) => {
          history.push("/error/500");
        });
      if (res) {
        setDataQuotations(res.data);
        setLoadingRequestQuotations(false);
      }
    };
    if (module === MODULS.COTIZACIONES) {
      fetchPost();
    }
  }, [realoadGrid, filters, module]);

  return { dataQuotations, loadingRequestQuotations };
};

export const useRemoveProduct = () => {
  const [isDeletedCorrect, setIsDeletedCorrect] = useState<boolean>(false);

  const removeProducts = async (product: number) => {
    try {
      await removeProduct(product)
        .then((res) => {
          setIsDeletedCorrect(true);
          toast.success(`${res.data.message}`, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        })
        .catch((err) => {
          setIsDeletedCorrect(false);
          let resMessageToast: string = "";
          const {
            data: { message },
          } = err.response;
          resMessageToast = `${message}`;
          toast.error(resMessageToast, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        });
    } catch (err: any) {
      setIsDeletedCorrect(false);
      toast.error(err.message, {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  return { removeProducts, isDeletedCorrect };
};
