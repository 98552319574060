import axios from 'axios'
const API_URL = process.env.REACT_APP_API


export async function getAllVendedoresActivos() {

    const response = await axios.get(`${API_URL}catalogos/vendedores`);
    // const response = await axios.get(`${API_URL}dashboard/vendedores/activos`);

    const data = response.data.doc.data.rows;
    const count = response.data.doc.data.count;
    return {
        data: data,
        count: count
    };

}
export async function getAllComerciales() {

    const response = await axios.get(`${API_URL}catalogos/vendedores/list/comerciales`);
    const data = response.data.doc.data.rows;
    const count = response.data.doc.data.count;
    return {
        data: data,
        count: count
    };

}

export async function getOpportunitiesByStage(filters: any) {

    let search: string = '';

    if (filters.vendedor) {
        search = `?user=${filters.vendedor}`
    }

    const response = await axios.get(`${API_URL}dashboard/oportunidades${search}`)
    const data = response.data.doc.data;
    return data;

}

export async function getSalesDashborad(body: any) {

    const { tipo_oportunidad, cuenta, agente, anio, estatus } = body;

    const filter = {
        year: anio,
        tipoOportunidadId: tipo_oportunidad,
        vendedorId: agente,
        etapaId: estatus,
        cuentaId: cuenta,
    }
    const response = await axios.post(`${API_URL}dashboard/ventas`, filter)
    const data = response.data.doc.data;
    return data;
}

export async function getYearsOpportunities(etapa: any) {

    const filter = {
        etapaId: etapa
    }
    const response = await axios.post(`${API_URL}dashboard/anios/list`, filter)
    const data = response.data.doc.data;
    return data;
}