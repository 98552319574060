import { FC } from "react";
import { PageTitle, PageLink } from "../../../_metronic/layout/core";
import { View } from "./components/View";
import { useParams } from "react-router-dom";
import { decryptId } from "../../../_metronic/helpers";

const NoteProductPage: FC = () => {
  const { id_product, id } = useParams<{ id_product: string; id: string }>();

  const noteId = decryptId(id);
  const productId = decryptId(id_product);
  const breadCrumbs: Array<PageLink> = [
    {
      title: "Productos",
      path: "/cotizaciones/productos/",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
    {
      title: "Editar",
      path: `/cotizaciones/productos/edit/${id_product}`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
    {
      title: 'Notas',
      path: `/cotizaciones/productos/edit/${id_product}`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
  ];
  return (
    <>
      <PageTitle breadcrumbs={breadCrumbs}>
        {noteId ? "Editar nota" : "Nueva nota"}
      </PageTitle>

      <View id={noteId ? noteId : "0"} />
    </>
  );
};

export { NoteProductPage };
