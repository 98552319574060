import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  getAllCollaborators,
  getCatFieldsCollaborators,
  getCollaboratorById,
  getDataFilterBySelectFiledCollaborator,
} from "../../../service/collaborator/Collaborator";
import { getCountriesSelect } from "../../../service/country/Country";
import { CollaboratorModel } from "../models/CollaboratorModel";
import { GenericCatalogModel, SelectFormat } from "../../../../_metronic/helpers/models/GenericModel";
import { getCatalog } from "../../../service/catalog/Catalogs";
import { GenericoModel } from "../../catalogos/generico/models/GenericoModel";

export const useGetAllCollaborators = (realoadGrid: number, filters: any, startIndex:number, endIndex:number) => {
  const [collaboartorsData, setCollaboratorsData] = useState<
    CollaboratorModel[]
  >([]);
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      setLoading(true);
      const res = await getAllCollaborators(filters, startIndex, endIndex)
        .then()
        .catch((error) => {
          history.push("/error/500");
        });
      if (res) {
        setCollaboratorsData(res.data);
        setLoading(false);
      }
    };
    if (realoadGrid) {
      fetchPost();
    }
  }, [realoadGrid, filters, module]);

  return { collaboartorsData, loading};
};

export const useGetCollaborator = (id: number) => {
  const initModel: CollaboratorModel = {
    id: 0,
    nombre: "",
    numero_empleado: "",
    apellido_paterno: "",
    apellido_materno: "",
    telefono: "",
    email: "",
    telefono_movil: "",
    fecha_nacimiento: "",
    fecha_ingreso: "",
    contacto: {
      id: 0,
      nombre: "",
      apellido_paterno: "",
      apellido_materno: "",
    },
    perfil: {
      id: 0,
      name: "",
      code: "",
    },
    genero: {
      id: 0,
      name: "",
      code: "",
    },
    pais: {
      id: 0,
      nombre: "",
    },
    empleos: [],
  };

  const [initValues, setInitValues] = useState<any>(initModel);
  const [loadingRequest, setLoadingRequest] = useState<boolean>(true);
  const history = useHistory();

  useEffect(() => {
    const fetchPost = async () => {
      await getCollaboratorById(id)
        .then((res) => {
          if (res) {
            setInitValues(res);
            setLoadingRequest(false);
          }
        })
        .catch((error) => {
          history.push("/error/500");
        });
    };
    if (id > 0) {
      fetchPost();
    } else {
      setInitValues(initModel);
      setLoadingRequest(false);
    }
  }, [id]);

  return { initValues, loadingRequest };
};

export const useGetFieldsCollaborators = () => {
  const [listFields, setlistFields] = useState<GenericCatalogModel[]>([]);
  const [loadingRequest, setLoadingRequest] = useState(0);

  const history = useHistory();

  useEffect(() => {
    const fetchPost = async () => {
      setLoadingRequest(0);
      const res = await getCatFieldsCollaborators()
        .then()
        .catch((error) => {
          history.push("/error/500");
        });
      if (res) {
        const selectOptions = res.map((option: any) => ({
          value: option.id,
          label: `${option.name} `,
          code: option.code,
        }));
        setlistFields(selectOptions);
        setLoadingRequest(1);
      }
    };
    fetchPost();
  }, []);

  return {
    listFields,
    loadingRequest,
  };
};

export const useGetDataSelectedByFieldCollaborator = (code: string) => {
  const [dataOptionSelected, setDataOptionSelected] = useState<any[]>([]);
  const [loadingRequest, setLoadingRequest] = useState(0);

  const history = useHistory();

  useEffect(() => {
    const fetchPost = async () => {
      setLoadingRequest(0);
      const res = await getDataFilterBySelectFiledCollaborator(code)
        .then()
        .catch((error) => {
          history.push("/error/500");
        });
      if (res) {
        const selectOptions = res.map((option: any) => ({
          value: option.id,
          label: option?.name ? option?.name : `${option?.nombre} ${option?.apellido_paterno} ${option?.apellido_materno}`,
        }));

        setDataOptionSelected(selectOptions);
        setLoadingRequest(1);
      }
    };

    if (code) {
      fetchPost();
    }
  }, [code]);

  return {
    dataOptionSelected,
    loadingRequest,
  };
};

export const useSelectCountry = () => {
  const [countries, setCountries] = useState<SelectFormat[]>([]);
  const [loadingRequest, setLoadingRequest] = useState(true);

  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      const data = await getCountriesSelect()
        .then()
        .catch((error) => {
          history.push("/error/500");
        });
      if (data) {
        setCountries(data);
        setLoadingRequest(false);
      }
    };
    fetchPost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {countries, loadingRequest}
}

  const formmatedDataToSelect = (data: any) => {
    const selectOptions = data.map((option: any) => ({
      value: option.id,
      label: option?.name,
    }));
  
    return selectOptions;
  };

export const useGetProfileSelect = (
  code : string,  
  realoadGrid?: number,
  ) => {
    const [dataPerfiles, setDataPerfiles] = useState<GenericoModel[]>([]);
    const [profileDataSelect, setProfileDataSelect] = useState<any[]>([
      {
        value: 'new',
        label: 'Agregar Perfil',
      },
    ]);
    const [profile, setProfile] = useState(1);
    const [loadingRequest, setLoadingRequest] = useState(0);
    const history = useHistory();
    useEffect(() => {
      const fetchPost = async () => {
        setLoadingRequest(0);
        const res = await getCatalog(code)
          .then()
          .catch((error) => {
            history.push('/error/500');
          });
        if (res) {
          let options: any = [];
          setDataPerfiles(res.rows);
          options = formmatedDataToSelect(res.rows);
          setProfileDataSelect((prevState) => [
            {
              value: 'new',
              label: 'Agregar Perfil',
            },
            ...options,
          ]);
          setProfile(res);
          setLoadingRequest(1);
        }
      };
  
      
      fetchPost();
    }, [realoadGrid]);
  
    return { dataPerfiles, profileDataSelect };
  };

  export const useGetPositionSelect = (
    code : string,  
    realoadGrid?: number,
    ) => {
      const [dataPuestos, setDataPuestos] = useState<GenericoModel[]>([]);
      const [positionDataSelect, setPositionDataSelect] = useState<any[]>([
        {
          value: 'new',
          label: 'Agregar puesto',
        },
      ]);
      const [position, setPosition] = useState(1);
      const [loadingRequest, setLoadingRequest] = useState(0);
      const history = useHistory();
      useEffect(() => {
        const fetchPost = async () => {
          setLoadingRequest(0);
          const res = await getCatalog(code)
            .then()
            .catch((error) => {
              history.push('/error/500');
            });
          if (res) {
            let options: any = [];
            setDataPuestos(res.rows);
            options = formmatedDataToSelect(res.rows);
            setPositionDataSelect((prevState) => [
              {
                value: 'new',
                label: 'Agregar puesto',
              },
              ...options,
            ]);
            setPosition(res);
            setLoadingRequest(1);
          }
        };
    
        
        fetchPost();
      }, [realoadGrid]);
    
      return { dataPuestos, positionDataSelect };
    };

    export const useGetDepartmentSelect = (
      code : string,  
      realoadGrid?: number,
      ) => {
        const [dataDepartamentos, setDataDepartamentos] = useState<GenericoModel[]>([]);
        const [departmentDataSelect, setDepartmentDataSelect] = useState<any[]>([]);
  
        const [department, setDepartment] = useState(1);
        const [loadingRequest, setLoadingRequest] = useState(0);
        const history = useHistory();
        useEffect(() => {
          const fetchPost = async () => {
            setLoadingRequest(0);
            const res = await getCatalog(code)
              .then()
              .catch((error) => {
                history.push('/error/500');
              });
            if (res) {
              let options: any = [];
              setDataDepartamentos(res.rows);
              options = formmatedDataToSelect(res.rows);
              setDepartmentDataSelect((prevState) => [
                ...options,
              ]);
              setDepartment(res);
              setLoadingRequest(1);
            }
          };
      
          
          fetchPost();
        }, [realoadGrid]);
      
        return { dataDepartamentos, departmentDataSelect };
      };
  
  
