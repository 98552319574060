import { FC } from "react";
import { PageTitle, PageLink } from "../../../_metronic/layout/core";
import { View } from "./components/View";
import { useLocation } from "react-router-dom";

const CollaboratorPage: FC = () => {
  let location = useLocation();

  // esta es la pantalla principal que contiene el grid  y las cards
  // MIGAJAS
  const breadCrumbs: Array<PageLink> = [
    {
      title: "Recursos Humanos",
      path: location.pathname,
      isSeparator: false,
      isActive: true,
    },
    {
      title: "Colaboradores",
      path: "",
      isSeparator: true,
      isActive: false,
    },
  ];

  return (
    <>
      {/* este componente es para las migagas */}
      <PageTitle breadcrumbs={breadCrumbs}>Colaboradores</PageTitle>

      {/* esta es el componente que contiene el grid y las cards */}
      <View />
    </>
  );
};

export { CollaboratorPage };
