import axios from 'axios'
const API_URL = process.env.REACT_APP_API

export async function getCuentasSb() {
    const response = await axios.get(`${API_URL}catalogos/cuentas`);
    const data = response.data.doc.data.rows;
    const count = response.data.doc.data.count;
    return {
        data: data,
        count: count
    };

}
export async function getSubCuentas(startIndex: number, endIndex: number, filters: any) {

    let search: string = '';

    if (filters.nombre) {
        search = `&id=${filters.nombre}`;
    }

    const response = await axios.get(`${API_URL}catalogos/subcuentas?limit=${startIndex}&page=${endIndex}${search}`);

    const data = response.data.doc.data.rows;
    const count = response.data.doc.data.count;
    return {
        data: data,
        count: count
    };

}
export async function getSubCuentasFilter() {

    const response = await axios.get(`${API_URL}catalogos/subcuentas`);

    const data = response.data.doc.data.rows;
    const count = response.data.doc.data.count;
    return {
        data: data,
        count: count
    };

}

export async function addSubCuenta(body: any) {

    const response = await axios.post(`${API_URL}catalogos/subcuentas/add`, body);
    return response;

}

export async function getSubCuentaById(id_row: number) {

    const response = await axios.get(`${API_URL}catalogos/subcuentas?id=${id_row}`);
    const { id, nombre, telefono, sitio_web, cuenta, industria, tamanio, direcciones, created_at, referencia, referencia_comentario } = response.data.doc.data.rows[0];

    let obtenerDP;
    let obtenerDE;

    if (direcciones) {
        obtenerDP = direcciones.filter((item: any) => item?.tipoDireccion?.code === 'PRI')
        obtenerDE = direcciones.filter((item: any) => item?.tipoDireccion?.code === 'ENT')
    }
    return {
        id: id,
        nombre: nombre,
        telefono: telefono === '52' || telefono === '(+52)' || telefono === '(+ )' || telefono === '(+  )' || telefono === '' ? '52' : telefono,
        sitio_web: sitio_web,
        created_at: created_at,
        cuenta: {
            id: cuenta.id,
            nombre: cuenta.nombre,
        },
        industria: {
            id: industria.id,
            name: industria.name,
            code: industria.code,

        },
        tamanio: {
            id: tamanio.id,
            name: tamanio.name,
            code: tamanio.code,

        },
        referencia: {
            id: referencia?.id,
            name: referencia?.name,
            code: referencia?.code
        },
        direcciones: null,
        industriaId: industria.id,
        tipocuentaId: cuenta.id,
        cuentaId: cuenta.id,
        tamanioId: tamanio.id,

        // DIRECCION PRINCIPAL
        calle: obtenerDP.length ? obtenerDP[0].calle : '',
        codigo_postal: obtenerDP.length ? obtenerDP[0].codigo_postal : '',
        numero_interior: obtenerDP.length ? obtenerDP[0].numero_interior : '',
        numero_exterior: obtenerDP.length ? obtenerDP[0].numero_exterior : '',
        colonia_p: obtenerDP.length ? obtenerDP[0].colonia : '',
        pais: obtenerDP.length ? obtenerDP[0].pais : '',
        municipioId: obtenerDP.length ? obtenerDP[0].municipio : '',
        estadoId: obtenerDP.length ? obtenerDP[0].estado : '',
        tipo_direccion_id_principal: obtenerDP.length ? obtenerDP[0].tipoDireccion.id : '',

        // DIRECCION ENTREGA
        codigo_postal_e: obtenerDE.length ? obtenerDE[0].codigo_postal : '',
        numero_interio_e: obtenerDE.length ? obtenerDE[0].numero_interior : '',
        numero_exterio_e: obtenerDE.length ? obtenerDE[0].numero_exterior : '',
        calle_e: obtenerDE.length ? obtenerDE[0].calle : '',
        pais_e: obtenerDE.length ? obtenerDE[0].pais : '',
        municipioId_e: obtenerDE.length ? obtenerDE[0].municipio : '',
        estadoId_e: obtenerDE.length ? obtenerDE[0].estado : '',
        colonia_e: obtenerDE.length ? obtenerDE[0].colonia : '',
        tipo_direccion_id_entrega: obtenerDE.length ? obtenerDE[0].tipoDireccion.id : '',
        referenciaId: referencia?.id,
        motivo: referencia_comentario,
    };

}

export async function updateSubCuenta(body: any) {
    let direcciones_collection = [];
    const { id, nombre, telefono, calle, numero_interior, numero_exterior, municipioId, estadoId, codigo_postal, pais, sitio_web, industriaId, cuentaId, tamanioId, calle_e, numero_interio_e,
        numero_exterio_e, municipioId_e, estadoId_e, pais_e, colonia_p,
        colonia_e, codigo_postal_e, tipo_direccion_id_principal, tipo_direccion_id_entrega, referenciaId, motivo } = body;

    const direccion_principal = {

        calle: calle,
        numero_exterior: numero_exterior,
        numero_interior: numero_interior,
        colonia: colonia_p,
        codigo_postal: codigo_postal,
        pais: pais,
        municipio: municipioId,
        estado: estadoId,
        tipo_direccion_id: tipo_direccion_id_principal // principal
    }
    const direccion_entrega = {

        calle: calle_e,
        numero_exterior: numero_exterio_e,
        numero_interior: numero_interio_e,
        colonia: colonia_e,
        codigo_postal: codigo_postal_e,
        pais: pais_e,
        municipio: municipioId_e,
        estado: estadoId_e,
        tipo_direccion_id: tipo_direccion_id_entrega
    }

    direcciones_collection.push(direccion_entrega)
    direcciones_collection.push(direccion_principal)

    const data = {
        nombre: nombre,
        telefono: telefono === '52' || telefono === '(+52)' || telefono === '(+ )' || telefono === '(+  )' ? '' : telefono,
        cuenta_id: cuentaId,
        tamanio_id: tamanioId,
        sitio_web: sitio_web,
        industria_id: industriaId,
        direcciones: direcciones_collection,
        referencia_id: referenciaId === '' ? null : referenciaId,
        referencia_comentario: referenciaId ? motivo : '',
    }

    const response = await axios.put(`${API_URL}catalogos/subcuentas/${id}`, data);
    return response;
}

export async function removeSubCuenta(id: number) {
    const response = await axios.delete(
        `${API_URL}catalogos/subcuentas/${id}`
    );
    return response;
}