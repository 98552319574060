import CryptoJS from 'crypto-js';

const secretKey = process.env.REACT_APP_SECRET_KEY || '2024OppertimeSoftnet';

export const encryptId = (id: string): string => {
    const encrypted = CryptoJS.AES.encrypt(id, secretKey).toString();
    const encodedEncrypted = encodeURIComponent(encrypted);
    return encodedEncrypted;
};
export const decryptId = (encodedEncryptedId: string): string => {
    const decodedEncryptedId = decodeURIComponent(encodedEncryptedId);
    const bytes = CryptoJS.AES.decrypt(decodedEncryptedId, secretKey);
    const decrypted = bytes.toString(CryptoJS.enc.Utf8);
    return decrypted;
};