import React, { useState, useEffect } from 'react';
import { RiskForm } from './RiskForm';
import { History } from '../../../../_metronic/helpers/components/History';
import { useGetHistory, useInsertMessage } from '../hooks/RiskHook';
import { Toggle } from '../../../../_metronic/partials/layout/explore/Toggle';

type Props = {
  id: string;
  oportunityId: string;
};

const View: React.FC<Props> = ({ id, oportunityId }) => {
  const [reloadGrid, setReloadGrid] = useState<number>(Math.random() * 40);
  const [search, setSearch] = useState<string>('');
  const { data } = useGetHistory(reloadGrid, search, id);
  const { insertData, loadingInsert } = useInsertMessage();
  const [historyIsEdit, setHistoryFormIsEdit] = useState<boolean>(false);
  const [showHistory, setShowHistory] = useState<boolean>(true);
  const [showTimerHistory, setShowTimerHistory] = useState<boolean>(true);

  const saveMessage = (message: string) => {
    insertData(message, id);
  };

  useEffect(() => {
    if (loadingInsert) {
      setReloadGrid(Math.random() * 40);
    }
  }, [loadingInsert]);

  const onRefreshData = () => {
    setReloadGrid(Math.random() * 40);
  };

  const onDisabledHistory = () => {
    setHistoryFormIsEdit(true);
  };

  const historyStyle: React.CSSProperties = {
    transform: showHistory ? 'none' : 'translateX(110%)',
    transition: 'transform 0.4s ease-in-out',
  };

  useEffect(() => {
    if (!showHistory) {
      setTimeout(() => {
        setShowTimerHistory(false);
      }, 400);
    }
  }, [showHistory]);

  useEffect(() => {
    const historyMode = localStorage.getItem('isOpenHistory') || '';
    setShowHistory(historyMode ? JSON.parse(historyMode) : true);
    setShowTimerHistory(historyMode ? JSON.parse(historyMode) : true);
  }, []);

  return (
    <>
      <div className="d-flex flex-column flex-xl-row">
        <div className="flex-lg-row-fluid ">
          <RiskForm
            id={id}
            oportunityId={oportunityId}
            onRefreshHistory={onRefreshData}
            onDisabledHistory={onDisabledHistory}
          ></RiskForm>
        </div>
        <div style={historyStyle}>
          {Number(id) > 0 && showTimerHistory && (
            <>
              <div className="flex-column flex-lg-row-auto w-100 w-xl-400px ms-lg-10">
                <History
                  data={data}
                  onSaveMessage={saveMessage}
                  disabled={historyIsEdit}
                  onShow={() => {
                    setShowHistory(!showHistory);
                    const boolean = !showHistory;
                    localStorage.setItem('isOpenHistory', boolean.toString());
                  }}
                />
              </div>
            </>
          )}
        </div>
        {!showHistory && Number(id) > 0 && (
          <Toggle
            onShow={() => {
              setShowHistory(!showHistory);
              setShowTimerHistory(true);
              const boolean = !showHistory;
              localStorage.setItem('isOpenHistory', boolean.toString());
            }}
          />
        )}
      </div>
    </>
  );
};

export { View };
