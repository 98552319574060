import React, { useState, useEffect, useRef } from 'react';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import Select from 'react-select';
import { KTSVG, SimpleAlert } from '../../../../_metronic/helpers';
import { useSelectGeneric } from '../../catalogos/generico/hooks/genericoHook';
import {
  useEditMitigate,
  useGetMitigateByID,
  useGetDocumentsByTaskId,
} from '../hooks/MitigateHook';
import { useGetAllVendedoresComerciales } from '../../oportunidades/hooks/OportunidadHook';
import { MitigateDoc } from './MitigateDoc';
import { colorStatusMit } from '../../../../_metronic/helpers/CustomElements';

type Props = {
  id: string;
  riskId: string;
  onRefreshHistory: () => void;
  onDisabledHistory: () => void;
};

const today = new Date().toISOString().split('T')[0];

const mitigateSchema = Yup.object().shape({
  fecha_inicio: Yup.date()
    .min(today, 'Debe ser igual o mayor a la fecha actual')
    .nullable()
    .required('La fecha es requerida'),
  fecha_fin: Yup.date()
    .min(Yup.ref('fecha_inicio'), 'No puede ser menor fecha inicio.')
    .required('La fecha de fin es requerida'),
  etapa_id: Yup.number()
    .min(1, 'Debe seleccionar una etapa.')
    .required('Etapa requerido.'),
  user_asignado_id: Yup.number()
    .min(1, 'Debe seleccionar una vendedor.')
    .required('Vendedor requerido.'),
  prioridad_id: Yup.number()
    .min(1, 'Debe seleccionar una prioridad.')
    .required('Prioridad requerido.'),
  status_id: Yup.number()
    .min(1, 'Debe seleccionar un estatus.')
    .required('Estatus requerido.'),
});

const MitigateForm: React.FC<Props> = ({
  id,
  riskId,
  onRefreshHistory,
  onDisabledHistory,
}) => {
  const [reloadRisk, setReloadRisk] = useState<number>(Math.random() * 40);
  const { mitigateInit } = useGetMitigateByID(id, reloadRisk);
  const { catalog: etapa } = useSelectGeneric('ETPMI');
  const { catalog: priority } = useSelectGeneric('PRIMI');
  const { catalog: status } = useSelectGeneric('STSMI');
  const { editMitigate, loadingEditMitigate } = useEditMitigate();
  const [formIsEdit, setFormIsEdit] = useState<boolean>(false);

  const history = useHistory();
  const tabSection = useRef<HTMLDivElement>(null);
  const [tab] = useState<string | 'ACTIONS'>('ACTIONS');
  const { sellerData } = useGetAllVendedoresComerciales();
  const [reloadDocuments, setReloadDocuments] = useState(Math.random() * 40);

  const { dataDocuments, loadingDocuments } = useGetDocumentsByTaskId(
    id,
    reloadDocuments
  );
  const [loading, setLoading] = useState(false);
  const mitigateForm = useFormik({
    initialValues: {
      ...mitigateInit,
      fecha_inicio: mitigateInit.fecha_inicio || today,
      fecha_fin: mitigateInit.fecha_fin || today,
    },
    validationSchema: mitigateSchema,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      setLoading(true);
      editMitigate(values, Number(riskId));
    },
  });

  useEffect(() => {
    if (loadingEditMitigate) {
      history.goBack();
      SimpleAlert('Se actualizó la mitigación exitosamente!', 3000, 'success');
      setLoading(false);
    }
  }, [loadingEditMitigate]);

  const userSelected = (selectedOption: any) => {
    const { value } = selectedOption;
    mitigateForm.setFieldValue('user_asignado_id', value);
  };

  useEffect(() => {
    if (mitigateForm.values.etapaMitigacion?.code === 'ETPCOMM') {
      setFormIsEdit(true);
      onDisabledHistory();
    }
  }, [mitigateForm.values.etapaMitigacion]);

  return (
    <div className="card mb-6 mb-xl-9">
      <div className="card-body">
        <form
          id="kt_account_form"
          className="form"
          action="#"
          noValidate
          onSubmit={mitigateForm.handleSubmit}
        >
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 p-0">
              <div className="w-100 pe-5">
                <input
                  className="form-control mb-2 w-100 border-0"
                  disabled={formIsEdit}
                  style={{
                    width: '100%',
                    padding: '15px',
                    fontSize: '24px',
                    boxSizing: 'border-box',
                    marginBottom: '20px',
                  }}
                  data-kt-element="input"
                  placeholder="Nombre *"
                  {...mitigateForm.getFieldProps('nombre')}
                ></input>
              </div>
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
              <div className="w-100 pe-5">
                <div className="w-100 d-flex">
                  <label className="required form-label w-auto">Etapa</label>
                </div>
                <select
                  disabled={formIsEdit}
                  className="form-select mb-3"
                  data-kt-select2="true"
                  data-placeholder="Select option"
                  data-allow-clear="true"
                  {...mitigateForm.getFieldProps('etapa_id')}
                >
                  {etapa.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
                {mitigateForm.touched.etapa_id && mitigateForm.errors.etapa_id && (
                  <div className="fv-plugins-message-container text-danger w-100 mb-3">
                    <span role="alert">{mitigateForm.errors.etapa_id}</span>
                  </div>
                )}
              </div>
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 d-flex flex-column ">
              <div className="w-100 pe-5">
                <div className="w-100 d-flex">
                  <label className="required form-label w-auto">
                    Fecha inicio
                  </label>
                </div>
                <input
                  disabled={formIsEdit}
                  className={'form-control mb-3'}
                  data-kt-element="input"
                  type="date"
                  min={today}
                  name="fecha_inicio"
                  value={mitigateForm.values.fecha_inicio}
                  onChange={(e) => {
                    mitigateForm.setFieldValue('fecha_inicio', e.target.value);
                  }}
                />
                {mitigateForm.touched.fecha_inicio &&
                  mitigateForm.errors.fecha_inicio && (
                    <div className="fv-plugins-message-container text-danger w-100 mb-3">
                      <span role="alert">
                        {mitigateForm.errors.fecha_inicio}
                      </span>
                    </div>
                  )}
              </div>
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
              <div className="w-100 pe-5">
                <div className="w-100 d-flex">
                  <label className="required form-label w-auto">
                    Fecha fin
                  </label>
                </div>
                <input
                  disabled={formIsEdit}
                  className={'form-control mb-3'}
                  data-kt-element="input"
                  type="date"
                  min={mitigateForm.values.fecha_inicio}
                  name="fecha_fin"
                  value={mitigateForm.values.fecha_fin}
                  onChange={(e) => {
                    mitigateForm.setFieldValue('fecha_fin', e.target.value);
                  }}
                />
                {mitigateForm.touched.fecha_fin &&
                  mitigateForm.errors.fecha_fin && (
                    <div className="fv-plugins-message-container text-danger w-100 mb-3">
                      <span role="alert">{mitigateForm.errors.fecha_fin}</span>
                    </div>
                  )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 d-flex flex-column">
              <label className="required form-label w-auto ">Asignado a</label>
              <Select
                isDisabled={formIsEdit}
                options={sellerData}
                value={
                  mitigateForm.values.user_asignado_id
                    ? sellerData.find(
                        (option) =>
                          option.value === mitigateForm.values.user_asignado_id
                      )
                    : null
                }
                styles={{
                  placeholder: (provided: any, state: any) => ({
                    ...provided,
                    color: '#181c32',
                    textAlign: 'center',
                  }),
                  indicatorSeparator: (provided: any, state: any) => ({
                    ...provided,
                    display: 'none',
                  }),
                  dropdownIndicator: (provided: any, state: any) => ({
                    ...provided,
                    color: '#9096EC',
                    display: 'none',
                  }),
                  control: (provided: any, state: any) => ({
                    ...provided,
                    border: 'none',
                    borderRadius: '6px',
                    marginBottom: '3px',
                    padding: '3px',
                    fontSize: '1rem',
                    boxShadow: 'none',
                    backgroundColor: state.isDisabled ? '#EFF2F5' : 'white',
                  }),
                  singleValue: (provided: any, state: any) => ({
                    ...provided,
                    color: state.isDisabled ? '#181c32' : 'inherit',
                    fontWeight: 500,
                  }),
                  option: (provided: any, state: any) => ({
                    ...provided,
                    fontSize: '1.0rem',
                    textAlign: 'center',
                  }),
                }}
                formatOptionLabel={(option) => (
                  <div
                    className="d-flex"
                    style={{ alignItems: 'center', width: '100%' }}
                  >
                    <div className="symbol symbol-30px">
                      <span
                        className="symbol-label fs-7 fw-bold bg-light-primary text-primary fw-bold"
                        title={option.label}
                      >
                        {option?.nombre[0]}
                        {option?.apellidoP[0]}
                      </span>
                    </div>
                    <span className="ms-3 ">
                      {option.nombre} {option.apellidoP}
                    </span>
                  </div>
                )}
                placeholder={
                  <span className="symbol symbol-30px cursor-pointer">
                    <div className="symbol-label">
                      <KTSVG
                        path="/media/icons/duotune/communication/com006.svg"
                        className="svg-icon-1"
                      />
                    </div>
                  </span>
                }
                isSearchable={true}
                noOptionsMessage={() => {
                  return <span>{'No se encontraron registros'}</span>;
                }}
                onChange={(event) => userSelected(event)}
              />
              {mitigateForm.touched.user_asignado_id &&
                mitigateForm.errors.user_asignado_id && (
                  <div className="fv-plugins-message-container text-danger w-100 mt-3 mb-3">
                    <span role="alert">
                      {mitigateForm.errors.user_asignado_id}
                    </span>
                  </div>
                )}
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
              <div className="w-100 pe-5">
                <div className="w-100 d-flex">
                  <label className="required form-label w-auto">
                    Prioridad
                  </label>
                </div>
                <select
                  disabled={formIsEdit}
                  className="form-select mb-3"
                  data-kt-select2="true"
                  data-placeholder="Select option"
                  data-allow-clear="true"
                  {...mitigateForm.getFieldProps('prioridad_id')}
                >
                  <option value={0}>Seleccione</option>
                  {priority.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
                {mitigateForm.touched.prioridad_id &&
                  mitigateForm.errors.prioridad_id && (
                    <div className="fv-plugins-message-container text-danger w-100 mb-3">
                      <span role="alert">
                        {mitigateForm.errors.prioridad_id}
                      </span>
                    </div>
                  )}
              </div>
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
              <div className="w-100 pe-5">
                <div className="w-100 d-flex">
                  <label className="required form-label w-auto">Estatus</label>
                </div>
                <div
                  className={`mt-4 ${
                    mitigateInit.statusMitigacion
                      ? colorStatusMit(mitigateInit.statusMitigacion.code || '')
                      : ''
                  }`}
                >
                  {mitigateInit?.statusMitigacion
                    ? mitigateInit.statusMitigacion.name
                    : 'N/A'}{' '}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 ">
              <div className="w-100 pe-5">
                <div className="w-100 d-flex">
                  <label className="form-label w-auto">Descripción</label>
                </div>
                <textarea
                  disabled={formIsEdit}
                  className="form-control w-100"
                  data-kt-element="input"
                  rows={5}
                  style={{ resize: 'none' }}
                  placeholder=""
                  {...mitigateForm.getFieldProps('descripcion')}
                ></textarea>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end mt-10">
            <button
              type="reset"
              className="btn btn-light me-3"
              data-kt-users-modal-action="cancel"
              onClick={() => {
                history.goBack();
              }}
            >
              Cancelar
            </button>
            <button
              type="submit"
              className="btn btn-primary"
              data-kt-users-modal-action="submit"
              disabled={loading || formIsEdit}
            >
              {!loading && <span className="indicator-label">Guardar</span>}
              {loading && (
                <span
                  className="indicator-progress"
                  style={{ display: 'block' }}
                >
                  Espere por favor...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>
          </div>
          <div className="row g-6 g-xl-9" ref={tabSection}>
            <div className="col-12">
              <ul className="nav nav-line-tabs nav-line-tabs-2x border-transparent fs-7 fw-bolder flex-nowrap">
                <li className="nav-item cursor-pointer">
                  <a
                    className={`nav-link me-6 ${
                      tab === 'ACTIONS' ? 'text-active-primary active' : ''
                    } `}
                    onClick={() => {}}
                  >
                    <span className="margin-left-05">Documentos</span>
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-12">
              {tab === 'ACTIONS' ? (
                <MitigateDoc
                  documents={dataDocuments || []}
                  onRefreshData={() => {
                    setReloadDocuments(Math.random() * 100);
                    onRefreshHistory();
                  }}
                  risk={mitigateInit.id}
                  isEdit={formIsEdit}
                ></MitigateDoc>
              ) : null}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
export { MitigateForm };
