import React, { useState, useEffect } from 'react';
import { History } from '../../../../_metronic/helpers/components/History';
import { PreloadView } from '../../../../_metronic/helpers';
import { useGetHistoryNotes, useInsertMessageNotes } from '../hooks/NoteHook';
import { NoteForm } from './NoteForm';

type Props = {
  id: string;
  oportunityId: string;
};

const View: React.FC<Props> = ({ id, oportunityId }) => {
  const [reloadGrid, setReloadGrid] = useState<number>(Math.random() * 40);
  const [search, setSearch] = useState<string>('');
  const { data } = useGetHistoryNotes(reloadGrid, search, id);
  const { insertData, loadingInsert } = useInsertMessageNotes();
  const saveMessage = (message: string) => {
    insertData(message, id);
  };
  useEffect(() => {
    if (loadingInsert) {
      setReloadGrid(Math.random() * 40);
    }
  }, [loadingInsert]);

  const onRefreshData = () => {
    setReloadGrid(Math.random() * 40);
  };

  return (
    <>
      {data ? (
        <div className="d-flex flex-column flex-xl-row">
          <div className="flex-lg-row-fluid ">
            <NoteForm
              id={id}
              oportunityId={oportunityId}
              onRefreshHistory={onRefreshData}
            />
          </div>

          {/* {Number(id) > 0 && (
            <div className="flex-column flex-lg-row-auto w-100 w-xl-400px ms-lg-10">
              <History data={data} onSaveMessage={saveMessage} />
            </div>
          )} */}
        </div>
      ) : (
        <PreloadView></PreloadView>
      )}
    </>
  );
};

export { View };
