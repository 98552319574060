import { FC } from 'react';
import { PageTitle, PageLink } from '../../../_metronic/layout/core';
import { View } from './components/View';
import { useParams } from 'react-router-dom';
import { decryptId } from '../../../_metronic/helpers';

const MitigatePage: FC = () => {
  const { id_oportunidad, id, id_riesgo } =
    useParams<{ id_oportunidad: string; id: string; id_riesgo: string }>();

  const mitigateId = decryptId(id);
  const oportunityId = decryptId(id_oportunidad);
  const riskId = decryptId(id_riesgo);

  const breadCrumbs: Array<PageLink> = [
    {
      title: 'Oportunidades',
      path: '/oportunidades/',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
    {
      title: 'Editar',
      path: `/oportunidades_edit/${id_oportunidad}`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
    {
      title: 'Riesgos',
      path: '#/',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
    {
      title: 'Editar Riesgo',
      path: `/oportunidades_riesgos/${id_oportunidad}/edit/${id_riesgo}`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ];
  return (
    <>
      <PageTitle breadcrumbs={breadCrumbs}>
        {mitigateId ? 'Editar mitigación' : 'Nueva mitigación'}
      </PageTitle>

      <View
        id={mitigateId ? mitigateId : '0'}
        riskId={riskId ? riskId : '0'}
        oportunityId={oportunityId ? oportunityId : '0'}
      />
    </>
  );
};

export { MitigatePage };
