import { useState, useEffect } from 'react';
import { getNotificaciones } from '../../../service/notificaciones/Notificaciones';
import { NotificacionModel } from '../models/NotificacionModel';
import { useHistory } from 'react-router-dom';

export const useGetAll = (
  realoadGrid: number,
  startIndex: number,
  endIndex: number
  //   filters: any
) => {
  const [data, setData] = useState<NotificacionModel[]>([]);
  const [count, setCount] = useState(1);
  const [loadingRequest, setLoadingRequest] = useState(0);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      setLoadingRequest(0);
      const res = await getNotificaciones(startIndex, endIndex)
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        setData(res.data);
        setCount(res.count);
        setLoadingRequest(1);
      }
    };
    // fetchPost();
    if (realoadGrid) {
      fetchPost();
    }
  }, [startIndex, realoadGrid]);

  return { data, count, loadingRequest };
};
