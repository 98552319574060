import React from 'react';
import { Pagination } from '../../../../_metronic/helpers';
import { MenuComponent } from '../../../../_metronic/assets/ts/components/MenuComponent';
import { TemplateModel } from '../models/PlantillaModel';
import { KTSVG } from '../../../../_metronic/helpers';

type Props = {
  data: any;
  count: number;
  currentPage: number;
  totalPages: number;
  onRemoveRegister: (row: any) => void;
  onPageChange: (pageNumber: number) => void;
  onEditRegister: (row: any) => void;
  onDetail: (row: any) => void;
  loadingGrid: boolean;
};

const ListGrid: React.FC<Props> = ({
  data,
  count,
  currentPage,
  totalPages,
  onRemoveRegister,
  onPageChange,
  onEditRegister,
  onDetail,
  loadingGrid,
}) => {
  const Template: Array<TemplateModel> = data;

  setTimeout(() => {
    MenuComponent.reinitialization();
  }, 1000);

  return (
    <>
      <div className="card-body py-3">
        <div className="card-body py-3 mt-5">
          <div className=" min-h-600px">
            <div className="tab-content">
              <div
                id="kt_project_targets_card_pane"
                className="tab-pane fade show active"
                role="tabpanel"
              >
                <div className="row g-9">
                  {loadingGrid ? (
                    <div className="d-flex align-items-center">
                      <div className="text-muted text-center fs-6 w-100 m-5">
                        <span
                          className="indicator-progress"
                          style={{ display: 'block' }}
                        >
                          Espere por favor...
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      </div>
                    </div>
                  ) : count <= 0 ? (
                    <div className="d-flex align-items-center">
                      <div className="text-muted text-center fs-6 w-100 m-5">
                        Sin datos
                      </div>
                    </div>
                  ) : (
                    Template.map((row: any) => (
                      <div className="col-md-4 col-lg-12 col-xl-4" key={row.id}>
                        <div
                          className="card mb-4"
                          style={{
                            border: 'solid 1px #D7DBDD',
                            // height: '95%',
                          }}
                        >
                          <div className="card-body">
                            <div
                              className="mb-2"
                              style={{
                                width: '100%',
                              }}
                            >
                              <div
                                className="fs-4 fw-bold mb-1 text-gray-900 text-hover-primary"
                                style={{
                                  overflow: 'hidden',
                                  whiteSpace: 'nowrap',
                                  textOverflow: 'ellipsis',
                                }}
                              >
                                {row.nombre ? row?.nombre : 'Sin Nombre'}
                              </div>
                            </div>

                            <div
                              className="fs-6 fw-semibold text-gray-600 mb-5"
                              style={{
                                // height: '55%',
                                width: '100%',
                              }}
                            >
                              {`${row.cuerpo.slice(0, 127)}...`}
                            </div>
                            <div className="d-flex flex-stack flex-wrapr">
                              <div className="symbol-group symbol-hover my-1">
                                <div
                                  className="symbol symbol-35px symbol-circle"
                                  title={`Número de plantilla: ${row.id}`}
                                >
                                  <span className="symbol-label bg-primary text-inverse-primary fw-bold">
                                    {row.id}
                                  </span>
                                </div>
                              </div>

                              <div className="d-flex my-1">
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                  }}
                                >
                                  <a
                                    type="button"
                                    className="btn btn-sm btn-icon btn-color-light-dark btn-active-light-primary"
                                    title="Ver detalle"
                                    onClick={() => {
                                      onDetail(row.id);
                                    }}
                                  >
                                    <KTSVG
                                      path="/media/icons/duotune/general/gen045.svg"
                                      className="svg-icon-5 svg-icon-gray-500 me-1"
                                    />
                                  </a>
                                  <a
                                    type="button"
                                    className="btn btn-sm btn-icon btn-color-light-dark btn-active-light-primary"
                                    title="Editar"
                                    onClick={() => {
                                      onEditRegister(row.id);
                                    }}
                                  >
                                    <KTSVG
                                      path="/media/icons/duotune/general/gen055.svg"
                                      className="svg-icon-5 svg-icon-gray-500 me-1"
                                    />
                                  </a>
                                  <a
                                    type="button"
                                    className="btn btn-sm btn-icon btn-color-light-dark btn-active-light-primary"
                                    title="Eliminar"
                                    onClick={() => {
                                      onRemoveRegister(row.id);
                                    }}
                                  >
                                    <KTSVG
                                      path="/media/icons/duotune/general/gen040.svg"
                                      className="svg-icon-5 svg-icon-gray-500 me-1"
                                    />
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex flex-stack flex-wrap pt-10">
          <div className="fs-6 text-muted">Total de registros: {count}</div>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={onPageChange}
          ></Pagination>
        </div>
      </div>
    </>
  );
};

export { ListGrid };
