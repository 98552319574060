import axios from "axios";
const API_URL = process.env.REACT_APP_API;

export async function getSurcharges() {
  const response = await axios.get(`${API_URL}cotizacion/recargo`);
  const data = response.data.doc.data;
  return data;
}

export async function getDataCategoryById(id_row: number) {
  const response = await axios.get(`${API_URL}categoria/productos/${id_row}`);
  const data = response.data.doc.data;
  return data;
}

export async function getDataSurchargeById(id_row: number) {
  const response = await axios.get(`${API_URL}cotizacion/recargo/${id_row}`);
  const data = response.data.doc.data;
  data.unidad_moneda_id = data.unidadMoneda ? data.unidadMoneda?.id : 0;
  if (data.tipo && data.tipo?.code === "TCPOR") {
    data.valor *= 100;
  }

  return data;
}

export async function addValueSurcharge(body: any) {
  const response = await axios.post(`${API_URL}cotizacion/recargo`, {
    codigo: body.codigo,
    nombre: body.nombre,
    tipo_id: body.tipo_id,
    valor: body.valor,
    unidad_moneda_id: body.unidad_moneda_id,
  });
  return response;
}

export async function updValueSurcharge(body: any) {
  const data = {
    codigo: body.codigo,
    nombre: body.nombre,
    tipo_id: body.tipo_id,
    valor: body.valor,
    unidad_moneda_id: body.unidad_moneda_id,
  };

  const response = await axios.patch(
    `${API_URL}cotizacion/recargo/${body.id}`,
    data
  );
  return response;
}

export async function removeRegisterSurcharge(id: number) {
  const response = await axios.delete(`${API_URL}cotizacion/recargo/${id}`);

  return response;
}
