import React, { useState } from "react";
import { KTSVG } from "../../../../../_metronic/helpers";
import { Modal } from "react-bootstrap-v5";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  addValueCategory,
  updValueCategory,
} from "../../../../service/packages/Packages";
import { toast } from "react-toastify";
import { useGetOne } from "../hooks/PackagesHook";

type Props = {
  show: boolean;
  handleClose: (updateGrid: boolean) => void;
  title: string;
  id_row: number;
};

const CreateModal: React.FC<Props> = ({ id_row, show, handleClose, title }) => {
  const [loading, setLoading] = useState(false);
  const { initValues } = useGetOne(id_row);

  const genericSchema = Yup.object().shape({
    nombre: Yup.string().required("Nombre es requerido."),
    orden: Yup.number()
      .required("Orden es requerido.")
      .min(1, "Orden no puede ser 0"),
  });

  const formGeneric = useFormik({
    initialValues: initValues,
    validationSchema: genericSchema,
    enableReinitialize: true,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      setTimeout(() => {
        if (values.id > 0) {
          updValueCategory(values)
            .then((res) => {
              const {
                data: { message },
              } = res;
              toast.success(message, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
              formGeneric.resetForm();
              setLoading(false);
              handleClose(true);
            })
            .catch((err) => {
              let resMessageToast: string = "";
              const {
                data: { message, details = null },
              } = err.response;
              resMessageToast = `${message}`;
              setLoading(false);
              setSubmitting(false);
              toast.error(resMessageToast, {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            });
        } else {
          addValueCategory(values)
            .then((res) => {
              formGeneric.resetForm();
              const {
                data: { message },
              } = res;
              toast.success(message, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
              setLoading(false);
              handleClose(true);
            })
            .catch((err) => {
              let resMessageToast: string = "";
              const {
                data: { message, details = null },
              } = err.response;
              resMessageToast = `${message}`;
              if (details.length) {
                setStatus(`${details[0]?.message}`);
              }
              setLoading(false);
              setSubmitting(false);
              toast.error(resMessageToast, {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            });
        }
      }, 1000);
    },
  });

  const handleNumericChange = (e: any) => {
    const value = e.target.value.slice(0, 3); // Limita a 3 caracteres
    formGeneric.setFieldValue("orden", value.replace(/[^0-9]/g, "")); // Asegura que solo sean números
  };

  return (
    <Modal
      id="kt_modal_create"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-650px"
      show={show}
      onHide={() => {
        formGeneric.resetForm();
        handleClose(false);
      }}
    >
      <div className="modal-content">
        <div className="modal-header">
          <h2>{title}</h2>
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            onClick={() => {
              // formik.resetForm()
              handleClose(false);
            }}
          >
            <KTSVG
              className="svg-icon-2"
              path="/media/icons/duotune/arrows/arr061.svg"
            />
          </div>
        </div>
        <div className="modal-body scroll-y mx-5 ">
          <form
            id="kt_modal_generic_form"
            className="form"
            action="#"
            onSubmit={formGeneric.handleSubmit}
          >
            <div
              className="d-flex flex-column scroll-y pe-7"
              id="kt_modal_add_user_scroll"
              data-kt-scroll="true"
              data-kt-scroll-activate="{ default: false, lg: true }"
              data-kt-scroll-max-height="auto"
              data-kt-scroll-dependencies="#kt_modal_add_user_header"
              data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
              data-kt-scroll-offset="300px"
            >
              {formGeneric.status && (
                <div className="alert alert-danger">
                  <div className="alert-text font-weight-bold">
                    {formGeneric.status}
                  </div>
                </div>
              )}
              <div className="fv-row mb-7">
                <div className="w-100 d-flex">
                  <label className=" fw-bold fs-6 mb-2">Código</label>
                </div>
                <input
                  disabled
                  placeholder="Código"
                  {...formGeneric.getFieldProps("codigo")}
                  className="form-control mb-3 mb-lg-0"
                  type="text"
                  name="codigo"
                  autoComplete="off"
                />
              </div>
              <div className="fv-row mb-7">
                <div className="w-100 d-flex">
                  <label className="required fw-bold fs-6 mb-2">Nombre</label>
                  {formGeneric.touched.nombre && formGeneric.errors.nombre && (
                    <div className="fv-plugins-message-container text-danger w-100 ms-5">
                      <span role="alert">{formGeneric.errors.nombre}</span>
                    </div>
                  )}
                </div>
                <input
                  placeholder="Nombre"
                  {...formGeneric.getFieldProps("nombre")}
                  className="form-control mb-3 mb-lg-0"
                  type="text"
                  name="nombre"
                  autoComplete="off"
                />
              </div>
              <div className="fv-row mb-7">
                <div className="w-100 d-flex">
                  <label className="required fw-bold fs-6 mb-2">Orden</label>
                  {formGeneric.errors.orden && (
                    <div className="fv-plugins-message-container text-danger w-100 ms-5">
                      <span role="alert">{formGeneric.errors.orden}</span>
                    </div>
                  )}
                </div>
                <input
                  placeholder="orden"
                  {...formGeneric.getFieldProps("orden")}
                  className="form-control mb-3 mb-lg-0"
                  type="text"
                  name="orden"
                  autoComplete="off"
                  onChange={handleNumericChange}
                />
              </div>
            </div>
            <div className="text-center pt-15">
              <button
                type="reset"
                className="btn btn-light me-3"
                data-kt-users-modal-action="cancel"
                onClick={() => {
                  formGeneric.resetForm();
                  handleClose(false);
                }}
              >
                Cancelar
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                data-kt-users-modal-action="submit"
                disabled={loading}
              >
                {!loading && (
                  <span className="indicator-label">
                    {id_row > 0 ? "Actualizar" : "Guardar"}
                  </span>
                )}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: "block" }}
                  >
                    Espere por favor...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};
export { CreateModal };
