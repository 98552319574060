import { useState, useEffect } from 'react';
import { ContactoModel } from '../../contacto/models/ContactoModel';
import { OportunidadModel } from '../../oportunidades/models/OportunidadModel';
import { useHistory } from 'react-router-dom';
import { SubAccountModel } from '../../subaccount/models/SubAccountModel'; 
import { getSubCuentasEmail, getAllOportunidadesByCuentaAndSubcuenta, getAllContactosByCSO } from '../../../service/email/Email';

export const useGetAllSubCuentas = (cuentas: []) => {
    const [dataSub, setDataSub] = useState<SubAccountModel[]>([]);
    const [count, setCount] = useState(1);
    const [loadingRequest, setLoadingRequest] = useState(0);
    const history = useHistory();
    useEffect(() => {
        const fetchPost = async () => {
            setLoadingRequest(0);
            const res = await getSubCuentasEmail(cuentas)
                .then()
                .catch((error) => {
                    history.push('/error/500');
                });
            if (res) {
                setDataSub(res.data);
                setCount(res.count);
                setLoadingRequest(1);
            }
        };
        if (cuentas.length) {
            fetchPost();
        } else {
            setDataSub([]);
        }

    }, [cuentas]);

    return { dataSub, count, loadingRequest };
};

export const useGetAllOportunidades = (
    realoadGrid: number,
    subcuenta: []
) => {
    const [dataOportunidades, setDataOportunidades] = useState<
        OportunidadModel[]
    >([]);
    const [count, setCount] = useState(1);
    const [loadingRequest, setLoadingRequest] = useState(0);
    const history = useHistory();
    useEffect(() => {
        const fetchPost = async () => {
            setLoadingRequest(0);
            const res = await getAllOportunidadesByCuentaAndSubcuenta(subcuenta)
                .then()
                .catch((error) => {
                    history.push('/error/500');
                });
            if (res) {
                setDataOportunidades(res.data);
                setCount(res.count);
                setLoadingRequest(1);
            }
        };
        // fetchPost();
        if (subcuenta.length) {
            fetchPost();
        } else {
            setDataOportunidades([]);

        }
    }, [subcuenta]);

    return { dataOportunidades, count, loadingRequest };
};

export const useGetAllContactos = (
    realoadGrid: number,
    startIndex: number,
    endIndex: number,
    cuenta: [],
    subcuenta: [],
    contactos_op: [],
    indicador: boolean
) => {
    const [dataContactos, setDataContactos] = useState<
        ContactoModel[]
    >([]);

    const [count, setCount] = useState(0);
    const [loadingRequest, setLoadingRequest] = useState(1);
    const history = useHistory();
    useEffect(() => {
        const fetchPost = async () => {
            setLoadingRequest(0);
            const res = await getAllContactosByCSO(cuenta, subcuenta, contactos_op, startIndex, endIndex)
                .then()
                .catch((error) => {
                    history.push('/error/500');
                });
            if (res) {
                setDataContactos(res.data);
                setCount(res.count);
                setLoadingRequest(1);
            }
        };
        // fetchPost();
        if (cuenta.length || subcuenta.length || contactos_op.length) {
            if (indicador) {
                fetchPost();

            } else {
                setLoadingRequest(1);

                setDataContactos([])
                setCount(0);
            }
        } else {
            setDataContactos([])
            setCount(0);

        }
    }, [cuenta, subcuenta, contactos_op]);

    return { dataContactos, count, loadingRequest };
};