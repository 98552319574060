import { FC } from 'react';
import { PageTitle, PageLink } from '../../../_metronic/layout/core';
import { View } from './components/View';
import { useParams } from 'react-router-dom';
import { decryptId } from '../../../_metronic/helpers';

const PackagesPage: FC = () => {
  const { id } = useParams<{ id: string }>();
  const packageId = decryptId(id);

  const breadCrumbs: Array<PageLink> = [
    {
      title: 'Cotizaciones',
      path: '/cotizaciones',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
    {
      title: 'Paquetes',
      path: `/cotizaciones/paquetes`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ];
  return (
    <>
      <PageTitle breadcrumbs={breadCrumbs}>
        {packageId ? 'Editar paquete' : 'Nuevo paquete'}
      </PageTitle>

      <View id={packageId ? Number(packageId) : 0} />
    </>
  );
};

export { PackagesPage };
