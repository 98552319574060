import React, { useState, useEffect } from 'react';
import { KTSVG } from '../../../../_metronic/helpers';
import { Modal } from 'react-bootstrap-v5';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { addValueTaxe, updValueTaxe } from '../../../service/taxes/Taxes';
import { toast } from 'react-toastify';
import { useGetOne } from '../hooks/taxesHook';
import { NumericFormat } from 'react-number-format';
type Props = {
  show: boolean;
  handleClose: (updateGrid: boolean) => void;
  title: string;
  id_row: number;
};

const CreateModal: React.FC<Props> = ({ id_row, show, handleClose, title }) => {
  const [loading, setLoading] = useState(false);
  const [contId, setContId] = useState<any | null>(null);
  const { initValues } = useGetOne(id_row);
  const [porcentaje, setPorcentaje] = useState<number>(0);

  const genericSchema = Yup.object().shape({
    siglas: Yup.string().required('Siglas es requerido.'),
    descripcion: Yup.string().required('Descripción es requerida.'),
    porcentaje: Yup.number()
      .min(1, 'Este campo es requerido.')
      .required('Este campo es requerido.'),
  });

  const formGeneric = useFormik({
    initialValues: initValues,
    validationSchema: genericSchema,
    enableReinitialize: true,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      setTimeout(() => {
        if (values.id > 0) {
          updValueTaxe(values)
            .then((res) => {
              const {
                data: { message },
              } = res;
              toast.success(message, {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
              });
              formGeneric.resetForm();
              setLoading(false);
              handleClose(true);
            })
            .catch((err) => {
              let resMessageToast: string = '';
              const {
                data: { message, details = null },
              } = err.response;
              resMessageToast = `${message}`;
              setLoading(false);
              setSubmitting(false);
              toast.error(resMessageToast, {
                position: 'top-right',
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
              });
            });
        } else {
          addValueTaxe(values)
            .then((res) => {
              formGeneric.resetForm();
              const {
                data: { message },
              } = res;
              toast.success(message, {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
              });
              setLoading(false);
              handleClose(true);
            })
            .catch((err) => {
              let resMessageToast: string = '';
              const {
                data: { message, details = null },
              } = err.response;
              resMessageToast = `${message}`;
              if (details.length) {
                setStatus(`${details[0]?.message}`);
              }
              setLoading(false);
              setSubmitting(false);
              toast.error(resMessageToast, {
                position: 'top-right',
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
              });
            });
        }
      }, 1000);
    },
  });
  const { setFieldValue } = formGeneric;
  const handleSelect = (selectedOption: any, selectId: string) => {
    if (selectedOption) {
      const { value } = selectedOption;
      if (selectId) {
        setFieldValue('contId', selectedOption);
        setFieldValue('categoria_id', value);
      }
    } else {
      setFieldValue('contId', null);
      setFieldValue('categoria_id', null);
    }
  };
  const handleNumericChange = (e: any) => {
    const value = e.target.value.slice(0, 3); // Limita a 3 caracteres
    formGeneric.setFieldValue('orden', value.replace(/[^0-9]/g, '')); // Asegura que solo sean números
  };

  return (
    <Modal
      id="kt_modal_create"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-650px"
      show={show}
      onHide={() => {
        formGeneric.resetForm();
        handleClose(false);
      }}
    >
      <div className="modal-content">
        <div className="modal-header">
          <h2>{title}</h2>
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            onClick={() => {
              // formik.resetForm()
              handleClose(false);
            }}
          >
            <KTSVG
              className="svg-icon-2"
              path="/media/icons/duotune/arrows/arr061.svg"
            />
          </div>
        </div>
        <div className="modal-body scroll-y mx-5 ">
          <form
            id="kt_modal_generic_form"
            className="form"
            action="#"
            onSubmit={formGeneric.handleSubmit}
          >
            <div
              className="d-flex flex-column scroll-y pe-7"
              id="kt_modal_add_user_scroll"
              data-kt-scroll="true"
              data-kt-scroll-activate="{ default: false, lg: true }"
              data-kt-scroll-max-height="auto"
              data-kt-scroll-dependencies="#kt_modal_add_user_header"
              data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
              data-kt-scroll-offset="300px"
            >
              {formGeneric.status && (
                <div className="alert alert-danger">
                  <div className="alert-text font-weight-bold">
                    {formGeneric.status}
                  </div>
                </div>
              )}

              <div className="fv-row mb-7">
                <div className="w-100 d-flex">
                  <label className="required fw-bold fs-6 mb-2">Siglas</label>
                  {formGeneric.touched.siglas && formGeneric.errors.siglas && (
                    <div className="fv-plugins-message-container text-danger w-100 ms-5">
                      <span role="alert">{formGeneric.errors.siglas}</span>
                    </div>
                  )}
                </div>
                <input
                  placeholder="Siglas"
                  {...formGeneric.getFieldProps('siglas')}
                  className="form-control mb-3 mb-lg-0"
                  type="text"
                  name="siglas"
                  autoComplete="off"
                />
              </div>
              <div className="fv-row mb-7">
                <div className="w-100 d-flex">
                  <label className="required fw-bold fs-6 mb-2">
                    Descripción
                  </label>
                  {formGeneric.errors.descripcion && (
                    <div className="fv-plugins-message-container text-danger w-100 ms-5">
                      <span role="alert">{formGeneric.errors.descripcion}</span>
                    </div>
                  )}
                </div>
                <input
                  placeholder="Descripción"
                  {...formGeneric.getFieldProps('descripcion')}
                  className="form-control mb-3 mb-lg-0"
                  type="text"
                  name="descripcion"
                  autoComplete="off"
                  // onChange={handleNumericChange}
                />
              </div>
              <div className="fv-row mb-7">
                <div className="w-100 d-flex">
                  <label className="required fw-bold fs-6 mb-2">
                    Porcentaje
                  </label>
                  {formGeneric.touched.porcentaje &&
                    formGeneric.errors.porcentaje && (
                      <div className="fv-plugins-message-container text-danger w-100 ms-5">
                        <span role="alert">
                          {formGeneric.errors.porcentaje}
                        </span>
                      </div>
                    )}
                </div>
                <NumericFormat
                  className="form-control fs-7"
                  value={formGeneric.values.porcentaje}
                  thousandSeparator={false}
                  allowNegative={false}
                  suffix="%"
                  allowLeadingZeros={false}
                  isAllowed={({ value }) => {
                    if (parseInt(value) < 0 || parseInt(value) > 100) {
                      return false;
                    }
                    const integerValue = parseInt(value);
                    if (integerValue < 10 && value.length > 1) {
                      return false;
                    }
                    if (integerValue < 100 && value.length > 2) {
                      return false;
                    }
                    return true;
                  }}
                  onValueChange={(values) => {
                    const { floatValue } = values;
                    formGeneric.setFieldValue('porcentaje', floatValue || 0);
                  }}
                />
              </div>
            </div>
            <div className="text-center pt-15">
              <button
                type="reset"
                className="btn btn-light me-3"
                data-kt-users-modal-action="cancel"
                onClick={() => {
                  formGeneric.resetForm();
                  handleClose(false);
                }}
              >
                Cancelar
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                data-kt-users-modal-action="submit"
                disabled={loading}
              >
                {!loading && (
                  <span className="indicator-label">
                    {id_row > 0 ? 'Actualizar' : 'Guardar'}
                  </span>
                )}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: 'block' }}
                  >
                    Espere por favor...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};
export { CreateModal };
