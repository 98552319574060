import React, { useState, useEffect } from "react";
import { KTSVG } from "../../../../_metronic/helpers";
import { Modal } from "react-bootstrap-v5";
import * as Yup from "yup";
import { useFormik } from "formik";
// import { useCreateNoteOpp } from '../hooks/OportunidadHook';
import { NoteOppModel } from "../../notes_opp/models/NoteOppModel";
import { CategoryModel } from "../models/QuotationModel";
import { useCreateCategoryProducts } from "../hooks/ProductsHook";
import { useCreateCategoryServices } from "../hooks/ServicesHook";
import { useCreateCategoryPackage } from "../hooks/PackagesHook";

type Props = {
  show: boolean;
  handleClose: (refresh: boolean) => void;
  sectionId: number;
};

const NoteSchema = Yup.object().shape({
  nombre: Yup.string().required("Este campo es requerido."),
});

const CreateCategoryModal: React.FC<Props> = ({
  show,
  handleClose,
  sectionId,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { createCategoryProduct, loadingCreateCategory, isCorrectInsertCP } =
    useCreateCategoryProducts();
  const {
    createCategoryService,
    loadingCreateCategoryServ,
    isCorrectInsertCS,
  } = useCreateCategoryServices();
  const {
    createCategoryPackage,
    loadingCreateCategoryPack,
    isCorrectInsertCPA,
  } = useCreateCategoryPackage();

  const initValues: CategoryModel = {
    id: 0,
    nombre: "",
    // descripcion: '',
  };

  const categoryQuotationForm = useFormik({
    initialValues: initValues,
    validationSchema: NoteSchema,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      setLoading(true);
      setTimeout(() => {
        let promise;
        switch (sectionId) {
          case 1:
            promise = createCategoryProduct(values);
            break;
          case 2:
            promise = createCategoryService(values);
            break;
          case 3:
            promise = createCategoryPackage(values);
        }
        if (promise) {
          promise
            .then(() => {
              setSubmitting(false);
              setLoading(false);
              categoryQuotationForm.resetForm();
              handleClose(true);
            })
            .catch((error) => {
              console.error("Error processing the form:", error);
              setLoading(false);
              setSubmitting(false);
            });
        }
      }, 1000);
    },
  });

  return (
    <Modal
      id="kt_modal_create"
      tabIndex={-1}
      aria-hidden="true"
      backdrop="static"
      dialogClassName="modal-dialog mw-800px"
      show={show}
      onHide={() => {
        categoryQuotationForm.resetForm();
        handleClose(false);
      }}
    >
      <div className="modal-content">
        <div className="modal-header">
          <h2 className="text-muted">Nueva categoría</h2>
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            onClick={() => {
              categoryQuotationForm.resetForm();
              handleClose(false);
            }}
          >
            <KTSVG
              className="svg-icon-2"
              path="/media/icons/duotune/arrows/arr061.svg"
            />
          </div>
        </div>
        <div className="modal-body mx-5 padding-top-00">
          <form
            id="kt_modal_generic_form"
            className="form"
            action="#"
            onSubmit={categoryQuotationForm.handleSubmit}
          >
            <div className="row wh-0100 mb-3">
              <div className="col-xxl-12 col-xl-12 col-md-12 col-lg-12">
                <input
                  className="form-control mb-2 w-100 border-0 padd-left input-name-form"
                  data-kt-element="input"
                  placeholder="Nombre *"
                  {...categoryQuotationForm.getFieldProps("nombre")}
                ></input>
                {categoryQuotationForm.touched.nombre &&
                  categoryQuotationForm.errors.nombre && (
                    <div className="fv-plugins-message-container text-danger w-100">
                      <span role="alert">
                        {categoryQuotationForm.errors.nombre}
                      </span>
                    </div>
                  )}
              </div>
            </div>

            <div className="d-flex justify-content-end mt-10">
              <button
                type="reset"
                className="btn btn-light me-3"
                data-kt-users-modal-action="cancel"
                onClick={() => {
                  categoryQuotationForm.resetForm();
                  handleClose(false);
                }}
              >
                Cancelar
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                data-kt-users-modal-action="submit"
                disabled={loading}
              >
                {!loading && <span className="indicator-label">Guardar</span>}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: "block" }}
                  >
                    Espere por favor...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};
export { CreateCategoryModal };
