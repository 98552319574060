import React, { useState, useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { UserModel } from "../../../modules/auth/models/UserModel";
import { RootState } from "../../../../setup/index";
import { KTSVG } from "../../../../_metronic/helpers";
import { ListGrid } from "./ListGrid";
import { Filter } from "./Filter";
import { CreateModal } from "../_modals/CreateModal";
import { RemoveModal } from "../_modals/RemoveModal";
import { useGetAll, useGetContactosFilter } from "../hooks/ContactoHook";
import { CardOpportunities } from "./CardOpportunities";
import { AddContactModal } from "../_modals/AddContacto";
import { Link } from "react-router-dom";

const View: React.FC = () => {
  // const user: UserModel = useSelector<RootState>(
  //   ({ auth }) => auth.user,
  //   shallowEqual
  // ) as UserModel;
  // const userHasRole = (roles: string[]) => {
  //   const luser: UserModel = user;
  //   let hasRole: boolean = false;

  //   roles.forEach((element) => {
  //     if (luser?.roles?.some((e) => e.code === element)) {
  //       hasRole = true;
  //     }
  //   });
  //   return hasRole;
  // };
  const [secction, setSecction] = useState<string | "BOARD" | "LIST">("LIST");
  const [loadingGrid, setLoadingGrid] = useState(true);
  const [idRow, setIdRow] = useState(0);
  const [idDelete, setIdDelete] = useState(0);
  const [visivilityModal, setVisivilityModal] = useState(false);
  const [visivilityRemoveModal, setVisivilityRemoveModal] = useState(false);
  const [titleModal, setTitleModal] = useState("");
  const [reloadGrid, setReloadGrid] = useState(Math.random() * 40);
  const [initFilterValues, setInitFilterValues] = useState({});
  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const { data, count, loadingRequest } = useGetAll(
    reloadGrid,
    pageSize,
    currentPage,
    initFilterValues
  );
  const location_contact = 2;
  const { dataContactos } = useGetContactosFilter(
    reloadGrid,
    1,
    1,
    location_contact
  );

  const totalPages = Math.ceil(count / pageSize)
    ? Math.ceil(count / pageSize)
    : 1;

  useEffect(() => {
    if (loadingRequest > 0) {
      setLoadingGrid(false);
    }
  }, [loadingRequest]);

  const addRegister = () => {
    setTitleModal("Registro contacto");
    setVisivilityModal(!visivilityModal);
  };
  
  const editRegister = (id: number) => {
    setIdRow(id);
    setTitleModal("Editar contacto");
    setVisivilityModal(!visivilityModal);
  };
  const removeRegister = (id: number) => {
    setIdDelete(id);
    setVisivilityRemoveModal(!visivilityRemoveModal);
  };

  const onSearchFilter = (values: any) => {
    setLoadingGrid(true);
    if (values) {
      setInitFilterValues(values);
    } else {
      setInitFilterValues({});
    }
    setReloadGrid(Math.random() * 40);
  };
  const onCleanFilter = () => {
    setLoadingGrid(true);
    setInitFilterValues({});
    setReloadGrid(Math.random() * 40);
  };

  const toggleMode = (mode: "LIST" | "BOARD") => {
    localStorage.setItem("viewMode", mode);
    setSecction(mode);
  };

  useEffect(() => {
    const storedMode = localStorage.getItem("viewMode");
    if (storedMode) {
      setSecction(storedMode);
    }
  }, []);
  return (
    <>
      <div className="row gy-5 g-xl-8">
        <div className="col-xxl-12 col-xl-12 col-md-12 col-lg-12">
          <div className="card card-flush p-5">
            <div className="row">
              <div className="col-xxl-8 col-xl-8 col-md-8 col-lg-8">
                <ul className="nav nav-line-tabs nav-line-tabs-2x border-transparent fs-7 fw-bolder flex-nowrap">
                  <li className="nav-item cursor-pointer ">
                    <a
                      className={`nav-link me-6 ${
                        secction === "LIST" ? "text-active-primary active" : ""
                      } `}
                      onClick={() => {
                        toggleMode("LIST");
                      }}
                    >
                      <KTSVG
                        path="/media/icons/duotune/text/txt001.svg"
                        className="svg-icon-1 fs-7"
                      />
                      <span className="margin-left-05">Lista</span>
                    </a>
                  </li>
                  <li className="nav-item cursor-pointer">
                    <a
                      className={`nav-link me-6  ${
                        secction === "BOARD" ? "text-active-primary active" : ""
                      }`}
                      onClick={() => {
                        toggleMode("BOARD");
                      }}
                    >
                      <KTSVG
                        path="/media/icons/duotune/layouts/lay002.svg"
                        className="svg-icon-1 fs-7"
                      />
                      <span className="margin-left-05">Tarjetas</span>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-xxl-4 col-xl-4 col-md-4 col-lg-4 position-end">
                <div className="card-title center-items">
                  {/* <a
                    href="#/"
                    className="btn btn-sm btn-icon btn-active-light-primary border-btn"
                    title="Nuevo Contacto"
                    onClick={() => addRegister()}
                  >
                    <KTSVG
                      path="/media/icons/duotune/abstract/abs011.svg"
                      className="svg-icon-5 svg-icon-gray-500 "
                    />
                  </a> */}
                  <Link
                  className="btn btn-sm btn-icon btn-light-primary border-btn"
                  to={'/clientes/contactos/add/'}
                >
                  <KTSVG
                    path="/media/icons/duotune/abstract/abs011.svg"
                    className="svg-icon-5 svg-icon-gray-500 "
                  />
                </Link>
                </div>
                
                <div className="mr-05"></div>
                <Filter
                  initFilterValues={initFilterValues}
                  onCleanFilter={onCleanFilter}
                  onSearchFilter={onSearchFilter}
                  contactData={dataContactos}
                />
              </div>
            </div>
            <div className="row">
              {secction === "BOARD" ? (
                <CardOpportunities board={data}></CardOpportunities>
              ) : null}
              {secction === "LIST" ? (
                // <CardList listData={dataBoard}></CardList>
                <ListGrid
                  data={data}
                  loadingGrid={loadingGrid}
                  count={count}
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onRemoveRegister={removeRegister}
                  onPageChange={(pageNumber: number) => {
                    setLoadingGrid(true);
                    setCurrentPage(pageNumber);
                  }}
                  onEditRegister={editRegister}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>

      
      
      <RemoveModal
        show={visivilityRemoveModal}
        id_row={idDelete}
        handleClose={(updateGrid: boolean) => {
          if (updateGrid) {
            setLoadingGrid(true);
            setReloadGrid(Math.random() * 40);
            const newPage = Math.ceil(
              (count - 1 <= 0 ? 1 : count - 1) / pageSize
            );
            setCurrentPage(newPage);
          }
          setIdDelete(0);
          setVisivilityRemoveModal(!visivilityRemoveModal);
        }}
      ></RemoveModal>
    </>
  );
};

export { View };
