import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-select';
import { NumericFormat } from 'react-number-format';
import { KTSVG, formatPrice } from '../../../../../_metronic/helpers';
import {
  styleSelect,
  styleSelectPackages,
} from '../../../../../_metronic/helpers/SelectStyles';
import {
  useCreateEditSupplier,
  useSelectSupplier,
  useUpdateSupplier,
} from '../../hooks/ServiceHook';
import Swal from 'sweetalert2';
import { useSelectComponentFormat } from '../../../catalogos/generico/hooks/genericoHook';
import { ServiceModel } from '../../models/ServiceModel';
import { FormikProps } from 'formik';
import { dividir } from '../../../../../_metronic/helpers/PriceCalculations';

type Props = {
  data: any[];
  onUpdateSuppliers: () => void;
  supplierData: (data: any) => void;
  IsCheckedSupplierSecction: (isCheck: boolean) => void;
  serviceId: number;
  currencyData: any;
  measureData: any;
  isSuppPrice: boolean;
  onRefreshData: () => void;
  formik: FormikProps<ServiceModel>;
  guardarProveedor: (proveedores: any) => void;
};

const SupplierList: React.FC<Props> = ({
  data,
  onUpdateSuppliers,
  serviceId,
  supplierData,
  IsCheckedSupplierSecction,
  currencyData,
  measureData,
  isSuppPrice,
  onRefreshData,
  formik,
  guardarProveedor,
}) => {
  const [reloadCatSupp, setReloadCatSupp] = useState<number>(
    Math.random() * 50
  );
  const { catalog: suppliers, loadingRequest: loadingSupplier } =
    useSelectSupplier(reloadCatSupp);
  const { optionsSelect: UnitMeasure } = useSelectComponentFormat(
    'UNDMEDS',
    true
  );
  const { optionsSelect: UnitCurrency } = useSelectComponentFormat(
    'UMON',
    true
  );
  const [dataMemo, setDataMemo] = useState<any[]>(data);
  const [supplierDataSelected, setSupplierDataSelected] = useState<any>({});
  const [isNewSuppler, setIsNewSupplier] = useState<boolean>(false);
  const { createSupplier, errorRequest } = useCreateEditSupplier();
  const {
    updateSupplier,
    errorRequest: errUpd,
    reloadInfo,
  } = useUpdateSupplier();
  const inputNewSupplierRefs = useRef<any>([]);
  const radios = useRef<any>([]);
  const inputsNameRefs = useRef<any>([]);
  const inputsVolRefs = useRef<any>([]);
  const inputsTotRefs = useRef<any>([]);
  const [loadingRows, setLoadingRows] = useState<any>({});
  const [focusedInput, setFocusedInput] = useState<number | null>(null);

  useEffect(() => {
    setDataMemo(data);
  }, [data]);

  const AddRow = () => {
    const cleanedData = dataMemo.filter((item) => item.id !== 0);
    const newRow: any = {
      id: 0,
      proveedor: null,
      nombre: 'Nuevo proveedor',
      volumen: 1,
      precio_unitario: 0,
      proveedor_id: null,
      servicio_id: serviceId,
    };

    setDataMemo([...cleanedData, newRow]);
  };

  const addSupplier = async (selectedOption: any) => {
    if (selectedOption) {
      const {
        data: { id },
      } = selectedOption;

      if (id) {
        const updateRow = {
          id: id,
          nombre_proveedor: null,
          proveedor_id: id,
          servicio_id: serviceId,
          unidad_moneda_id: currencyData,
          unidad_medida_id: measureData,
        };

        await createSupplier(updateRow);
        onUpdateSuppliers();
        onRefreshData();
      } else {
        setIsNewSupplier(!id ? true : false);
      }
    }
  };

  const volumeChange = async (
    newVolumen: number,
    rowId: number,
    total: number
  ) => {
    let tot = total ? total : 0;
    setDataMemo((prevDataMemo) => {
      const updatedDataMemo = prevDataMemo.map((item) => {
        if (item.id === rowId) {
          return {
            ...item,
            volumen: newVolumen,
            precio_unitario: dividir(Number(tot), Number(newVolumen)),
          };
        }
        return item;
      });
      guardarProveedor(updatedDataMemo);
      return updatedDataMemo;
    });
  };

  const seleccionarProveedor = (item: any) => {
    const { id } = item;
    setDataMemo((prevDataMemo) => {
      const updatedDataMemo = prevDataMemo.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            seleccionado: true,
          };
        } else {
          return {
            ...item,
            seleccionado: false,
          };
        }
      });

      return updatedDataMemo;
    });
    IsCheckedSupplierSecction(true);
  };

  // const handleRadioChange = (item: any) => {
  //   const { precio_unitario, id } = item;

  //   let price = Number(precio_unitario);

  //   if (!price) {
  //     Swal.fire({
  //       title: '¿Estás seguro?',
  //       html: `
  //         <div className="text-gray-600">
  //           Este proveedor no tiene definido un precio unitario. ¿Aún así lo quieres seleccionar?
  //         </div>
  //         `,
  //       showCancelButton: true,
  //       padding: '2em',
  //       cancelButtonText: 'Cancelar',
  //       confirmButtonText: 'Aceptar',
  //       reverseButtons: true,
  //       customClass: {
  //         confirmButton: 'btn btn-primary',
  //         cancelButton: 'btn btn-light me-3',
  //       },
  //     }).then((result) => {
  //       if (result.isConfirmed) {
  //         updateSupplier({
  //           id: id,
  //           seleccionado: true,
  //           servicio_id: serviceId,
  //         });

  //         setDataMemo((prevDataMemo) => {
  //           const updatedDataMemo = prevDataMemo.map((item) => {
  //             if (item.id === id) {
  //               return {
  //                 ...item,
  //                 seleccionado: true,
  //               };
  //             } else {
  //               return {
  //                 ...item,
  //                 seleccionado: false,
  //               };
  //             }
  //           });

  //           return updatedDataMemo;
  //         });
  //         IsCheckedSupplierSecction(true);
  //       }
  //     });
  //   } else {
  //     updateSupplier({
  //       id: id,
  //       seleccionado: true,
  //       servicio_id: serviceId,
  //     });

  //     setDataMemo((prevDataMemo) => {
  //       const updatedDataMemo = prevDataMemo.map((item) => {
  //         if (item.id === id) {
  //           return {
  //             ...item,
  //             seleccionado: true,
  //           };
  //         } else {
  //           return {
  //             ...item,
  //             seleccionado: false,
  //           };
  //         }
  //       });

  //       return updatedDataMemo;
  //     });
  //     IsCheckedSupplierSecction(true);
  //   }
  // };

  const handleNameSupplier = async (event: any) => {
    const newName = event.target.value;
    if (isNewSuppler && newName) {
      const newRow: any = {
        nombre_proveedor: newName,
        proveedor_id: null,
        servicio_id: serviceId,
        unidad_moneda_id: currencyData,
        unidad_medida_id: measureData,
      };
      await createSupplier(newRow);

      onUpdateSuppliers();
    }
  };

  const onChangeTotal = async (
    newtotal: number,
    rowId: number,
    volumen: number
  ) => {
    let vol = volumen || 0;
    setDataMemo((prevDataMemo) => {
      const updatedDataMemo = prevDataMemo.map((item) => {
        if (item.id === rowId) {
          return {
            ...item,
            total: newtotal,
            precio_unitario: dividir(Number(newtotal), Number(vol)),
          };
        }
        return item;
      });
      guardarProveedor(updatedDataMemo);
      return updatedDataMemo;
    });
  };

  const changeUnitMeasure = async (selectOption: any, rowId: number) => {
    if (selectOption) {
      const { value } = selectOption;
      await updateSupplier({
        id: rowId,
        unidad_medida_id: value,
        servicio_id: serviceId,
      });
    } else {
      await updateSupplier({
        id: rowId,
        unidad_medida_id: null,
        servicio_id: serviceId,
      });
    }
  };

  // useEffect(() => {
  //   if (reloadInfo) {
  //     onUpdateSuppliers();
  //   }
  // }, [reloadInfo]);

  useEffect(() => {
    if (supplierDataSelected) {
      supplierData(supplierDataSelected);
    } else {
      supplierData(null);
    }
  }, [supplierDataSelected]);

  // useEffect(() => {
  //   if (currencyData) {
  //     updateSupplier({
  //       unidad_moneda_id: currencyData,
  //       servicio_id: serviceId,
  //     });
  //   } else {
  //     updateSupplier({
  //       unidad_moneda_id: null,
  //       servicio_id: serviceId,
  //     });
  //   }
  // }, [currencyData]);

  // useEffect(() => {
  //   if (measureData) {
  //     updateSupplier({
  //       unidad_medida_id: measureData,
  //       servicio_id: serviceId,
  //     });
  //   } else {
  //     updateSupplier({
  //       unidad_medida_id: null,
  //       servicio_id: serviceId,
  //     });
  //   }
  // }, [measureData]);

  useEffect(() => {
    if (errorRequest || errUpd) {
      onUpdateSuppliers();
    }
  }, [errorRequest, errUpd]);

  useEffect(() => {
    if (!isSuppPrice) {
      setDataMemo((prevDataMemo) => {
        const updatedDataMemo = prevDataMemo.map((item) => {
          return {
            ...item,
            seleccionado: false,
          };
        });

        return updatedDataMemo;
      });
    }
  }, [isSuppPrice]);

  useEffect(() => {
    if (dataMemo.length) {
      const filter = dataMemo.filter((item) => item.seleccionado);

      if (filter.length) {
        formik.setFieldValue('proveedor_id', filter[0].id);
        setSupplierDataSelected(filter[0]);
      } else {
        setSupplierDataSelected({});
      }
    }
  }, [dataMemo]);

  useEffect(() => {
    return () => {
      setDataMemo([]);
    };
  }, []);

  return (
    <>
      <div className="table-responsive min-h-300px me-n5 pe-5 scroll-y">
        <table className="table table-border table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 me-n5 pe-5">
          <thead>
            <tr className="text-gray-800 fs-7">
              <th className="w-200px">Proveedor</th>
              <th className="w-200px">Unidad de medida</th>
              <th className="w-150px">Vol/Compra</th>
              <th className="w-200px">Unidad de moneda</th>
              <th className="w-150px">Total</th>
              <th className="w-150px">Precio unitario</th>
              <th className="text-center w-120px">Seleccione</th>
            </tr>
          </thead>
          <tbody>
            {dataMemo.length > 0 ? (
              dataMemo.map((row, index) => (
                <tr key={index}>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="d-flex justify-content-start flex-column fs-012 text-gray-600">
                        {row.id === 0 ? (
                          isNewSuppler ? (
                            <>
                              <input
                                className="form-control"
                                ref={(el: any) =>
                                  (inputsNameRefs.current[index] = el)
                                }
                                type="text"
                                placeholder={row.nombre}
                                onKeyDown={async (event) => {
                                  if (event.key === 'Enter') {
                                    event.preventDefault();
                                    setLoadingRows({
                                      ...loadingRows,
                                      [`${index}-nombreN`]: true,
                                    });
                                    await handleNameSupplier(event);
                                    setIsNewSupplier(false);
                                    setReloadCatSupp(Math.random() * 50);

                                    setLoadingRows({
                                      ...loadingRows,
                                      [`${index}-nombreN`]: false,
                                    });
                                  }
                                }}
                                onFocus={() =>
                                  setFocusedInput(inputsNameRefs.current[index])
                                }
                              />
                              {loadingRows[`${index}-nombreN`] ? (
                                <span
                                  className="indicator-progress text-primary mt-3 pb-4"
                                  style={{ display: 'block' }}
                                >
                                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                              ) : (
                                <>
                                  {focusedInput ===
                                  inputsNameRefs.current[index] ? (
                                    <small className="mt-3">
                                      Presione enter para guardar
                                    </small>
                                  ) : null}
                                </>
                              )}
                              <a
                                className="btn btn-sm"
                                title="Regresar a lista de proveedores"
                                onClick={() => {
                                  setIsNewSupplier(false);
                                }}
                              >
                                <KTSVG
                                  path="/media/icons/duotune/arrows/arr015.svg"
                                  className="svg-icon-1 svg-icon-primary"
                                />
                              </a>
                            </>
                          ) : (
                            <div>
                              <Select
                                options={suppliers}
                                styles={styleSelectPackages}
                                placeholder={'Seleccione'}
                                isSearchable={true}
                                menuPortalTarget={document.body}
                                menuPosition={'fixed'}
                                noOptionsMessage={() => {
                                  return (
                                    <span>{'No se encontraron registros'}</span>
                                  );
                                }}
                                onChange={addSupplier}
                                isLoading={loadingSupplier}
                                backspaceRemovesValue
                              />
                            </div>
                          )
                        ) : (
                          <>
                            <input
                              ref={(el: any) =>
                                (inputNewSupplierRefs.current[index] = el)
                              }
                              type="text"
                              className={`${'form-control text-gray-700 fs-7'} ${
                                focusedInput ===
                                inputNewSupplierRefs.current[index]
                                  ? ''
                                  : 'border-0 '
                              }`}
                              defaultValue={row.proveedor?.nombre}
                              onFocus={() =>
                                setFocusedInput(
                                  inputNewSupplierRefs.current[index]
                                )
                              }
                              onBlur={async (event) => {
                                let nombre = event.target.value;

                                if (!nombre) {
                                  nombre = row.proveedor?.nombre;
                                }
                                await updateSupplier({
                                  id: row.id,
                                  nombre_proveedor: nombre,
                                  servicio_id: serviceId,
                                });
                                setFocusedInput(null);
                              }}
                            />
                          </>
                        )}
                      </div>
                    </div>
                  </td>
                  {row.id ? (
                    <>
                      <td>
                        <div className="d-flex align-items-center">
                          <div className="d-flex justify-content-start w-100 flex-column fs-012 font-weight-mormal text-gray-600">
                            <Select
                              options={UnitMeasure}
                              styles={styleSelect}
                              placeholder={'Seleccione'}
                              isSearchable={false}
                              isDisabled={true}
                              noOptionsMessage={() => {
                                return (
                                  <span>{'No se encontraron registros'}</span>
                                );
                              }}
                              value={
                                row?.unidadMedida
                                  ? UnitMeasure.find(
                                      (option) => option.value === measureData
                                    )
                                  : null
                              }
                              onChange={(event: any) => {
                                changeUnitMeasure(event, row.id);
                              }}
                              backspaceRemovesValue
                            />
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <div className="d-flex justify-content-start w-100 flex-column fs-012 font-weight-mormal text-gray-600">
                            <NumericFormat
                              getInputRef={(el: any) =>
                                (inputsVolRefs.current[index] = el)
                              }
                              style={{ width: '100%' }}
                              className="form-control"
                              thousandSeparator={false}
                              allowNegative={false}
                              decimalScale={2}
                              placeholder="1"
                              // disabled={!row.seleccionado}
                              value={row.volumen}
                              onBlur={async (e) => {
                                let value = Number(e.target.value);
                                if (!value || isNaN(value)) {
                                  e.target.value = '1';
                                  value = 1;
                                }
                                // await updateSupplier({
                                //   id: row.id,
                                //   volumen: value,
                                //   servicio_id: serviceId,
                                // });
                              }}
                              isAllowed={(values: any) => {
                                const { floatValue } = values;
                                const integerPart = Math.floor(floatValue);
                                return integerPart.toString().length <= 7;
                              }}
                              suffix=""
                              allowLeadingZeros={false}
                              onValueChange={(values) => {
                                const { floatValue } = values;

                                if (floatValue) {
                                  if (floatValue > 0) {
                                    volumeChange(floatValue, row.id, row.total);
                                  }
                                } else {
                                  volumeChange(1, row.id, row.total);
                                }
                              }}
                              onFocus={() =>
                                setFocusedInput(inputsVolRefs.current[index])
                              }
                            />
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <div className="d-flex justify-content-start w-100 flex-column fs-012 font-weight-mormal text-gray-600">
                            <Select
                              options={UnitCurrency}
                              styles={styleSelect}
                              placeholder={'Seleccione'}
                              isSearchable={false}
                              isDisabled={true}
                              noOptionsMessage={() => {
                                return (
                                  <span>{'No se encontraron registros'}</span>
                                );
                              }}
                              value={
                                row?.unidadMoneda
                                  ? UnitCurrency.find(
                                      (option) => option.value === currencyData
                                    )
                                  : null
                              }
                              // value={
                              //   row?.unidadMoneda
                              //     ? UnitCurrency.find(
                              //         (option) =>
                              //           option.value === row?.unidadMoneda?.id
                              //       )
                              //     : null
                              // }
                              // onChange={(event: any) => {
                              //   changeUnitMeasure(event, row.id);
                              // }}
                              backspaceRemovesValue
                            />
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <div className="d-flex justify-content-start flex-column fs-012 text-gray-600">
                            <div className="d-flex align-items-center w-100">
                              <NumericFormat
                                getInputRef={(el: any) =>
                                  (inputsTotRefs.current[index] = el)
                                }
                                style={{ minWidth: '100px' }}
                                // disabled={!row.seleccionado}
                                className="form-control"
                                thousandSeparator={true}
                                prefix="$"
                                decimalScale={2}
                                placeholder={'$0.00'}
                                fixedDecimalScale={true}
                                allowNegative={false}
                                allowLeadingZeros={false}
                                defaultValue={row.total}
                                onValueChange={(values: any) => {
                                  const { floatValue } = values;

                                  if (floatValue) {
                                    if (floatValue > 0) {
                                      onChangeTotal(
                                        floatValue,
                                        row.id,
                                        row.volumen
                                      );
                                    }
                                  } else {
                                    onChangeTotal(0, row.id, row.volumen);
                                  }
                                }}
                                onFocus={() =>
                                  setFocusedInput(inputsTotRefs.current[index])
                                }
                                onBlur={async (event: any) => {
                                  let numericValue = parseFloat(
                                    event.target.value.replace(/[^0-9.-]+/g, '')
                                  );

                                  if (isNaN(numericValue)) {
                                    numericValue = 0;
                                  }

                                  setFocusedInput(null);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </td>

                      <td>
                        <div className="d-flex align-items-center">
                          <div className="d-flex justify-content-start flex-column fs-012 text-gray-700 fw-bold">
                            {`${formatPrice(
                              row.precio_unitario,
                              'es-MX',
                              'MXN',
                              2,
                              2
                            )}`}
                            {/* / {codeCurrency} */}
                          </div>
                        </div>
                      </td>
                      <td>
                        <div
                          className="d-flex align-items-center"
                          style={{
                            justifyContent: 'center',
                          }}
                        >
                          <div className="d-flex ">
                            <label className="form-check form-check-custom form-check-solid">
                              <input
                                ref={(el: any) => (radios.current[index] = el)}
                                className="form-check-input"
                                type="radio"
                                checked={row.seleccionado}
                                // checked={index === itemSelected}
                                onClick={() => {
                                  seleccionarProveedor(row);
                                }}
                              />
                            </label>
                          </div>
                        </div>
                      </td>
                    </>
                  ) : null}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={9} className="text-center text-muted">
                  Sin proveedores
                </td>
              </tr>
            )}

            <tr>
              <td colSpan={9}>
                <div className="d-flex align-items-center">
                  <div
                    className="btn btn-light-primary btn-sm mb-10"
                    onClick={AddRow}
                  >
                    <KTSVG
                      path="/media/icons/duotune/arrows/arr075.svg"
                      className="svg-icon-5"
                    />
                    Agregar Proveedor
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export { SupplierList };
