// import CryptoJS from 'crypto-js';

export default function setupAxios(axios: any, store: any) {
  axios.interceptors.request.use(
    (config: any) => {
      const {
        auth: { accessToken },
      } = store.getState();

      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }

      // if (config.data) {
      //   const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(config.data), process.env.REACT_APP_SECRET_KEY || "").toString();
      //   config.data = { data: encryptedData }; // Encapsular los datos cifrados en un objeto
      // }

      return config;
    },
    (err: any) => Promise.reject(err)
  );

  axios.interceptors.response.use(
    (response: any) => response,
    (error: any) => {
      if (error.response && error.response.status === 401) {
        window.location.href = "/logout";
      }
      return Promise.reject(error);
    }
  );
}
