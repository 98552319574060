import React, { useState } from 'react';
import { Pagination } from '../../../../_metronic/helpers';
import { MenuComponent } from '../../../../_metronic/assets/ts/components/MenuComponent';
import { OportunidadModel } from '../../oportunidades/models/OportunidadModel';
import { shallowEqual, useSelector } from 'react-redux';
import { UserModel } from '../../../modules/auth/models/UserModel';
import { RootState } from '../../../../setup/index';
import {
  formatDate,
  dateFormatt,
} from '../../../../_metronic/helpers/FormatDate';
import { Tooltip } from '@mui/material';

type Props = {
  data: any;
  count: number;
  currentPage: number;
  totalPages: number;
  onPageChange: (pageNumber: number) => void;
  loadingGrid: boolean;
};

const ListGrid: React.FC<Props> = ({
  data,
  count,
  currentPage,
  totalPages,
  onPageChange,
  loadingGrid,
}) => {
  const Oportunidad: Array<OportunidadModel> = data;
  const [showRiesgos, setShowRiesgos] = useState<boolean>(false);
  const [showMitigaciones, setShowMitigaciones] = useState<boolean>(false);
  const [showSiguientes, setShowSiguientes] = useState<boolean>(false);

  const handleToggleContent = (code: string) => {
    switch (code) {
      case 'RIE':
        setShowRiesgos(!showRiesgos);
        break;
      case 'MIT':
        setShowMitigaciones(!showMitigaciones);
        break;
      case 'SIG':
        setShowSiguientes(!showSiguientes);
        break;

      default:
        break;
    }
  };

  const user: UserModel = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  ) as UserModel;
  const userHasRole = (roles: string[]) => {
    const luser: UserModel = user;
    let hasRole: boolean = false;

    roles.forEach((element) => {
      if (luser?.roles?.some((e) => e.code === element)) {
        hasRole = true;
      }
    });
    return hasRole;
  };

  setTimeout(() => {
    MenuComponent.reinitialization();
  }, 1000);

  return (
    <>
      <div className="card-body py-3">
        <div className="table-responsive ">
          <table
            className={`table  table-row-gray-300 align-middle gs-0 gy-4 ${Oportunidad.length ? 'table-striped' : ''
              }`}
          >
            <thead>
              <tr className="fw-bolder text-muted">
                <th className="w-50px">Folio</th>
                <th className="w-200px">Cuenta</th>
                <th className="w-200px">Subcuenta</th>
                <th className="w-200px">Oportunidad</th>
                <th className="w-300px">Vendedor comercial</th>
                <th className="w-300px">Vendedor técnico</th>
                <th className="w-100px">Fecha de creación</th>
                <th className="w-100px">Fecha estimada de aprobación</th>
                <th className="w-100px">Estatus</th>
                <th className="w-100px">Etapa</th>
                <th className="w-50px">Probabilidad de éxito</th>
                <th className="w-50px">Tareas cerradas</th>
                <th className="w-50px">Tareas abiertas</th>
                <th className="w-200px">Riesgos y dependencias</th>
                <th className="w-200px">Mitigación</th>
                <th className="w-300px">Siguientes pasos</th>
              </tr>
            </thead>
            <tbody>
              {loadingGrid ? (
                <tr>
                  <td colSpan={12}>
                    <div className="d-flex align-items-center">
                      <div className="text-muted text-center fs-6 w-100 m-5">
                        <span
                          className="indicator-progress"
                          style={{ display: 'block' }}
                        >
                          Espere por favor...
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      </div>
                    </div>
                  </td>
                </tr>
              ) : count <= 0 ? (
                <tr>
                  <td colSpan={12}>
                    <div className="d-flex align-items-center">
                      <div className="text-muted text-center fs-6 w-100 m-5">
                        Sin datos
                      </div>
                    </div>
                  </td>
                </tr>
              ) : (
                Oportunidad.map((row: any) => (
                  <tr key={row.id}>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="d-flex justify-content-start flex-column fs-6">
                          {row.id}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="d-flex justify-content-start flex-column fs-6">
                          {row.cuenta?.nombre}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="d-flex justify-content-start flex-column fs-6">
                          {row.subCuenta ? row.subCuenta?.nombre : 'S/D'}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="d-flex justify-content-start flex-column fs-6">
                          {row?.nombre}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="d-flex justify-content-start flex-column fs-6">
                          {`${row.vendedorComercial?.nombre} ${row.vendedorComercial?.apellido_paterno} ${row.vendedorComercial?.apellido_materno}`}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="d-flex justify-content-start flex-column fs-6">
                          {row.vendedorTecnico &&
                            row.vendedorTecnico.nombre &&
                            row.vendedorTecnico.apellido_paterno &&
                            row.vendedorTecnico.apellido_materno
                            ? `${row.vendedorTecnico?.nombre} ${row.vendedorTecnico?.apellido_paterno} ${row.vendedorTecnico?.apellido_materno}`
                            : 'S/D'}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex text-center">
                        <div className="d-flex justify-content-start flex-column fs-6">
                          {formatDate(
                            new Date(
                              row.created_at ? row.created_at.toString() : ''
                            )
                          )}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex text-center">
                        <div className="d-flex justify-content-start flex-column fs-6">
                          {row.fecha_aprobacion_estimada
                            ? dateFormatt(
                              row.fecha_aprobacion_estimada
                                ? row.fecha_aprobacion_estimada.toString()
                                : ''
                            )
                            : 'S/D'}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex text-center">
                        <div className="d-flex justify-content-start flex-column fs-6">
                          {row.status?.code === 'PLN' ? (
                            <span className="badge badge-light-success">
                              {row.status?.name}
                            </span>
                          ) : row.status?.code === 'RSG' ? (
                            <span className="badge badge-light-warning text-warning">
                              {row.status?.name}
                            </span>
                          ) : (
                            <span className="badge badge-light-danger text-danger">
                              {row.status?.name}
                            </span>
                          )}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex text-center">
                        <div className="d-flex justify-content-start flex-column fs-6">
                          {row.etapa?.name}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex text-center">
                        <div className="d-flex justify-content-start flex-column fs-6">
                          {`${row.probabilidad}%`}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex text-center">
                        <div className="d-flex justify-content-start flex-column fs-6">
                          {`${row?.tareasCerradas}`}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex text-center">
                        <div className="d-flex justify-content-start flex-column fs-6">
                          {`${row?.tareasAbiertas}`}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex text-center">
                        <div className="d-flex justify-content-start flex-column fs-6">
                          {row.riesgos_dependencias.length > 20 ? (
                            <Tooltip
                              title={
                                <span style={{ whiteSpace: 'pre-wrap' }}>
                                  {row.riesgos_dependencias}
                                </span>
                              }
                              arrow
                              placement="top"
                            >
                              <span
                                style={{
                                  cursor: 'pointer',
                                  textDecoration: 'underline',
                                }}
                                onClick={() => {
                                  handleToggleContent('RIE');
                                }}
                                onMouseEnter={() => {
                                  handleToggleContent('RIE');
                                }}
                                onMouseLeave={() => {
                                  handleToggleContent('RIE');
                                }}
                              >
                                {showRiesgos
                                  ? row.riesgos_dependencias
                                  : `${row.riesgos_dependencias.slice(
                                    0,
                                    10
                                  )}...`}
                              </span>
                            </Tooltip>
                          ) : row.riesgos_dependencias ? (
                            row.riesgos_dependencias
                          ) : (
                            'S/D'
                          )}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex text-center">
                        <div className="d-flex justify-content-start flex-column fs-7">
                          {row.mitigaciones.length > 20 ? (
                            <Tooltip
                              title={
                                <span style={{ whiteSpace: 'pre-wrap' }}>
                                  {row.mitigaciones}
                                </span>
                              }
                              arrow
                              placement="top"
                            >
                              <span
                                style={{
                                  cursor: 'pointer',
                                  textDecoration: 'underline',
                                }}
                                onClick={() => {
                                  handleToggleContent('MIT');
                                }}
                                onMouseEnter={() => {
                                  handleToggleContent('MIT');
                                }}
                                onMouseLeave={() => {
                                  handleToggleContent('MIT');
                                }}
                              >
                                {showMitigaciones
                                  ? row.mitigaciones
                                  : `${row.mitigaciones.slice(0, 20)}...`}
                              </span>
                            </Tooltip>
                          ) : row.mitigaciones ? (
                            row.mitigaciones
                          ) : (
                            'S/D'
                          )}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex text-center">
                        <div className="d-flex justify-content-start flex-column fs-6">
                          {row.siguentes_pasos.length > 20 ? (
                            <Tooltip
                              title={
                                <span style={{ whiteSpace: 'pre-wrap' }}>
                                  {row.siguentes_pasos}
                                </span>
                              }
                              arrow
                              placement="top"
                            >
                              <span
                                style={{
                                  cursor: 'pointer',
                                  textDecoration: 'underline',
                                }}
                                onClick={() => {
                                  handleToggleContent('SIG');
                                }}
                                onMouseEnter={() => {
                                  handleToggleContent('SIG');
                                }}
                                onMouseLeave={() => {
                                  handleToggleContent('SIG');
                                }}
                              >
                                {showSiguientes
                                  ? row.siguentes_pasos
                                  : `${row.siguentes_pasos.slice(0, 20)}...`}
                              </span>
                            </Tooltip>
                          ) : row.siguentes_pasos ? (
                            row.siguentes_pasos
                          ) : (
                            'S/D'
                          )}
                        </div>
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        <div className="d-flex flex-stack flex-wrap pt-10">
          <div className="fs-6 text-muted">Total de registros: {count}</div>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={onPageChange}
          ></Pagination>
        </div>
      </div>
    </>
  );
};

export { ListGrid };
