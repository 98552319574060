import clsx from 'clsx';
import React, { FC, useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { HeaderUserMenu } from '../../../partials';
import { HeaderNotiMenu } from '../../../partials/layout/header-menus/HeaderNotiMenu';
import { UserModel } from '../../../../app/modules/auth/models/UserModel';
import { RootState } from '../../../../setup';
import { KTSVG } from '../../../../_metronic/helpers';
import { NotificacionModel } from '../../../../app/pages/notificaciones/models/NotificacionModel';
import { useGetAll } from '../../../../app/pages/notificaciones/hooks/NotificacionesHook';
import { updateNotificacion } from '../../../../app/service/notificaciones/Notificaciones';
import { toast } from 'react-toastify';

const toolbarButtonMarginClass = '',
  toolbarUserAvatarHeightClass = 'symbol-20px symbol-md-30px';

const Topbar: FC = () => {
  const user: UserModel = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  ) as UserModel;

  const [reloadGrid, setReloadGrid] = useState(Math.random() * 40);
  const pageSize = 5;
  const [currentPage, setCurrentPage] = useState(pageSize);
  const [contador, setContador] = useState(1);

  const { data, count } = useGetAll(reloadGrid, pageSize, currentPage);

  const Notificaciones: Array<NotificacionModel> = data;

  useEffect(() => {
    const timer = setInterval(() => {
      setReloadGrid(Math.random() * 40);
    }, 300000);
  }, []);

  useEffect(() => {
    const handleStorageChange = () => {
      setReloadGrid(Math.random() * 40);
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  const handleSearch = (id: number, validate: boolean) => {
    if (!validate) {
      updateNotificacion(id)
        .then((res) => {
          const {
            data: { message },
          } = res;
          setReloadGrid(Math.random() * 40);
        })
        .catch((err) => {
          let resMessageToast: string = '';
          const {
            data: { message, details = null },
          } = err.response;
          resMessageToast = `${message}`;
          toast.error(resMessageToast, {
            position: 'top-right',
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          });
        });
    }
  };

  useEffect(() => {
    if (contador > 1) {
      setCurrentPage(contador * pageSize);
      setReloadGrid(Math.random() * 40);
    }
  }, [contador]);

  const handleNext = () => {
    setContador(contador + 1);
  };

  const handleRender = () => {
    setCurrentPage(currentPage - 1);
    setReloadGrid(Math.random() * 40);
  };

  return (
    <div className="d-flex align-items-stretch flex-shrink-0">
      <div
        className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
        id="kt_header_user_menu_toggle"
      >
        <div
          className={clsx(
            'd-flex align-items-center me-3',
            toolbarButtonMarginClass
          )}
          onMouseLeave={() => {
            setCurrentPage(pageSize);
            setContador(1);
            setReloadGrid(Math.random() * 40);
          }}
        >
          <div
            className={clsx(
              'cursor-pointer symbol  menu-dropdown',
              toolbarUserAvatarHeightClass
            )}
            data-kt-menu-trigger="hover"
            data-kt-menu-attach="parent"
            data-kt-menu-placement="bottom-end"
            data-kt-menu-flip="bottom"
            id="kt_menu_item_wow"
          >
            <span className="symbol-label fs-5 fw-bold bg-light-primary text-primary fw-bold">
              {count > 0 && (
                <span className="bullet bullet-dot bg-success h-10px w-10px position-absolute translate-middle top-0 start-50 animation-blink ms-4"></span>
              )}

              <KTSVG
                path="/media/icons/duotune/general/gen007.svg"
                className="svg-icon-3 svg-icon-primary"
              />
            </span>
          </div>
          <HeaderNotiMenu
            data={Notificaciones}
            onEditRegister={handleSearch}
            onPage={handleNext}
            currentPage={currentPage}
            onRender={handleRender}
          />
        </div>
        <div
          className={clsx(
            'cursor-pointer symbol',
            toolbarUserAvatarHeightClass
          )}
          data-kt-menu-trigger="hover"
          data-kt-menu-attach="parent"
          data-kt-menu-placement="bottom-end"
          data-kt-menu-flip="bottom"
        >
          {user.profile_photo ? (
            <img src={user.profile_photo} crossOrigin="anonymous" alt="ad" />
          ) : (
            <span className="symbol-label fs-5 fw-bold bg-light-primary text-primary fw-bold">
              {`${user.first_name ? user.first_name[0] : ''}${
                user.father_last_name ? user.father_last_name[0] : ''
              }`}
            </span>
          )}
        </div>
        <HeaderUserMenu />
      </div>
    </div>
  );
};

export { Topbar };
