import Decimal from 'decimal.js';

/**
 * Redondear decimales
 * @param {number} value - Valor en decimales a formatear .
 * @param {number} decimales - cantidad de decimales a mostrar 2 y 4.
 * @returns {number} numero formateado
 */
const formatearResultado = (value, decimales = null) => {
    let numueroFormateado = new Decimal(value).toFixed(decimales);
    return parseFloat(numueroFormateado);
};

/**
 * Suma dos valores.
 * @param {number} valor1 - Primer valor decimal (ejemplo 2.99).
 * @param {number} valor2 - Segundo valor decimal (ejemplo 18.98).
 * @returns {number} Suma de los dos valores.
 */
export const sumar = (valor1, valor2) => {
    const num1 = new Decimal(valor1);
    const num2 = new Decimal(valor2);
    const resultado = num1.plus(num2);
    return formatearResultado(resultado, 2);
};

/**
 * Resta el segundo valor del primero.
 * @param {number} valor1 - Valor decimal del minuendo (ejemplo 18.98).
 * @param {number} valor2 - Valor decimal del sustraendo (ejemplo 2.99).
 * @returns {number} Resultado de la resta.
 */
export const restar = (valor1, valor2) => {
    const num1 = new Decimal(valor1);
    const num2 = new Decimal(valor2);
    const resultado = num1.minus(num2);
    return formatearResultado(resultado, 2);
};
/**
 * Multiplica dos valores.
 * @param {number} valor1 - Primer valor decimal (ejemplo 2.99).
 * @param {number} valor2 - Segundo valor decimal (ejemplo 18.98).
 * @returns {number} Producto de los dos valores.
 */
  export const multiplicar = (valor1, valor2) => {
    const num1 = new Decimal(valor1);
    const num2 = new Decimal(valor2);
    const resultado = num1.mul(num2);
    return formatearResultado(resultado, 2);
  };
  
/**
 * Divide el primer valor por el segundo.
 * @param {number} valor1 - Valor decimal del dividendo (ejemplo 18.98).
 * @param {number} valor2 - Valor decimal del divisor (ejemplo 2.99).
 * @returns {number} Cociente de la división.
 */
  export const dividir = (valor1, valor2) => {
    const num1 = new Decimal(valor1);
    const num2 = new Decimal(valor2);
    let resultado = new Decimal(0);
    if (!num2.isZero()) {
        resultado = num1.div(num2);
    }
    return formatearResultado(resultado, 2);
  };

/**
 * Calcula el precio con el impuesto en decimales (por ejemplo, 0.16 para 16%).
 * @param {number} price - Precio sin impuestos.
 * @param {number} impuesto - Tasa de impuestos como un valor decimal (por ejemplo, 0.16 para 16%).
 * @returns {number} Precio con impuestos redondeado a dos decimales.
 */
export const calcularPrecioImpuestos = (price, impuesto) => {
    const precioConImpuestos = price * (1 + impuesto);
    return formatearResultado(precioConImpuestos, 2);
};