import { FC } from 'react';
import { PageTitle } from '../../../_metronic/layout/core';
import { View } from './components/View';

const OportunidadPage: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Oportunidades</PageTitle>
      <View />
    </>
  );
};

export { OportunidadPage };
