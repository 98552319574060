import React, { useState, useEffect, ChangeEvent } from 'react';
import { KTSVG } from '../../../../_metronic/helpers';
import { Modal } from 'react-bootstrap-v5';
import * as Yup from 'yup';
import { Form, useFormik } from 'formik';
import { toast } from 'react-toastify';
import { useGetOne } from '../hooks/PlantillaHook';
import {
  addTemplate,
  updateTemplate,
} from '../../../service/plantilla/Plantilla';

type Props = {
  show: boolean;
  handleClose: (updateGrid: boolean) => void;
  title: string;
  id_row: number;
};

const CreateModal: React.FC<Props> = ({ id_row, show, handleClose, title }) => {
  const [loading, setLoading] = useState(false);
  const { initValues } = useGetOne(id_row);

  const PlatillaSchema = Yup.object().shape({
    nombre: Yup.string().max(
      50,
      'El nombre debe tener un máximo de 50 caracteres.'
    ),
    encabezado: Yup.string()
      .required('El encabezado es requerido.')
      .max(50, 'El encabezado debe tener un máximo de 50 caracteres.'),
    cuerpo: Yup.string().required('El cuerpo de la plantilla es requerido.'),
    asunto: Yup.string()
      .required('El asunto es requerido.')
      .max(50, 'El asunto debe tener un máximo de 50 caracteres.'),
  });

  const formPlantilla = useFormik({
    initialValues: initValues,
    validationSchema: PlatillaSchema,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      setLoading(true);

      setTimeout(() => {
        if (values.id > 0) {
          updateTemplate(values)
            .then((res) => {
              const {
                data: { message },
              } = res;
              toast.success(message, {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
              });
              formPlantilla.resetForm();
              setLoading(false);
              handleClose(true);
            })
            .catch((err) => {
              let resMessageToast: string = '';
              const {
                data: { message, details = null },
              } = err.response;
              resMessageToast = `${message}`;
              setLoading(false);
              setSubmitting(false);
              toast.error(resMessageToast, {
                position: 'top-right',
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
              });
            });
        } else {
          addTemplate(values)
            .then((res) => {
              const {
                data: { message },
              } = res;
              toast.success(message, {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
              });
              formPlantilla.resetForm();
              setLoading(false);
              handleClose(true);
            })
            .catch((err) => {
              let resMessageToast: string = '';
              const {
                data: { message, details = null },
              } = err.response;
              resMessageToast = `${message}`;
              setLoading(false);
              setSubmitting(false);
              toast.error(resMessageToast, {
                position: 'top-right',
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
              });
            });
        }
      }, 1000);
    },
  });

  return (
    <Modal
      id="kt_modal_create"
      tabIndex={-1}
      backdrop="static"
      aria-hidden="true"
      dialogClassName="modal-dialog mw-900px"
      show={show}
      onHide={() => {
        formPlantilla.resetForm();
        handleClose(false);
      }}
    >
      <div className="modal-content">
        <div className="modal-header">
          <h2>{title}</h2>
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            onClick={() => {
              formPlantilla.resetForm();
              handleClose(false);
            }}
          >
            <KTSVG
              className="svg-icon-2"
              path="/media/icons/duotune/arrows/arr061.svg"
            />
          </div>
        </div>
        <div className="modal-body">
          <form
            id="kt_modal_generic_form"
            className="form"
            action="#"
            onSubmit={formPlantilla.handleSubmit}
          >
            <div id="kt_app_content_container" className="app-container ">
              <div className="d-flex flex-column " style={{ padding: '0px' }}>
                <div className="flex-lg-row-fluid ms-lg-7 ms-xl-10">
                  <div className="card">
                    <div className="card-body p-0">
                      <div className="d-block">
                        <div className="d-flex align-items-center border-bottom px-8 min-h-50px">
                          <div className="text-dark fw-bold w-10px "></div>

                          <input
                            className="form-control form-control-transparent border-0  min-h-45px"
                            {...formPlantilla.getFieldProps('nombre')}
                            name="nombre"
                            placeholder="Nombre"
                          />
                        </div>
                        {formPlantilla.touched.nombre &&
                          formPlantilla.errors.nombre && (
                            <div className="fv-plugins-message-container text-danger border-0 px-8">
                              <span role="alert">
                                {formPlantilla.errors.nombre}
                              </span>
                            </div>
                          )}
                        {/* <div className="border-bottom">
                          <input
                            className="form-control form-control-transparent border-0 px-8 min-h-45px"
                            {...formPlantilla.getFieldProps('nombre')}
                            name="nombre"
                            placeholder="Nombre"
                          />
                          {formPlantilla.touched.nombre &&
                            formPlantilla.errors.nombre && (
                              <div className="fv-plugins-message-container text-danger border-0 px-8">
                                <span role="alert">
                                  {formPlantilla.errors.nombre}
                                </span>
                              </div>
                            )}
                        </div> */}
                        {/* </div> */}

                        <div className="d-flex align-items-center border-bottom px-8 min-h-50px">
                          <div className="text-dark fw-bold w-10px required"></div>

                          <input
                            className="form-control form-control-transparent border-0  min-h-45px"
                            {...formPlantilla.getFieldProps('encabezado')}
                            name="encabezado"
                            placeholder="Encabezado"
                          />
                        </div>
                        {formPlantilla.touched.encabezado &&
                          formPlantilla.errors.encabezado && (
                            <div className="fv-plugins-message-container text-danger border-0 px-8">
                              <span role="alert">
                                {formPlantilla.errors.encabezado}
                              </span>
                            </div>
                          )}
                        {/* <div className="border-bottom">
                          <input
                            className="form-control form-control-transparent border-0 px-8 min-h-45px"
                            {...formPlantilla.getFieldProps('encabezado')}
                            name="encabezado"
                            placeholder="Encabezado"
                          />
                          {formPlantilla.touched.encabezado &&
                            formPlantilla.errors.encabezado && (
                              <div className="fv-plugins-message-container text-danger border-0 px-8">
                                <span role="alert">
                                  {formPlantilla.errors.encabezado}
                                </span>
                              </div>
                            )}
                        </div> */}
                        <div className="d-flex align-items-center border-bottom px-8 min-h-50px mt-2">
                          <div className="text-dark fw-bold w-10px required"></div>

                          <input
                            className="form-control form-control-transparent border-0  min-h-45px "
                            {...formPlantilla.getFieldProps('asunto')}
                            name="asunto"
                            placeholder="Asunto"
                          />
                        </div>
                        {formPlantilla.touched.asunto &&
                          formPlantilla.errors.asunto && (
                            <div className="fv-plugins-message-container text-danger border-0 px-8">
                              <span role="alert">
                                {formPlantilla.errors.asunto}
                              </span>
                            </div>
                          )}

                        {/* <div className="border-bottom">
                          <input
                            className="form-control form-control-transparent border-0 px-8 min-h-45px "
                            {...formPlantilla.getFieldProps('asunto')}
                            name="asunto"
                            placeholder="Asunto"
                          />
                          {formPlantilla.touched.asunto &&
                            formPlantilla.errors.asunto && (
                              <div className="fv-plugins-message-container text-danger border-0 px-8">
                                <span role="alert">
                                  {formPlantilla.errors.asunto}
                                </span>
                              </div>
                            )}
                        </div> */}

                        <div className="mt-8">
                          <div
                            id="kt_inbox_form_editor"
                            className="  border-0 h-350px px-3 ql-container ql-snow border-0 px-8 min-h-45px"
                          >
                            <textarea
                              style={{
                                width: '100%',
                                height: '100%',
                                resize: 'none',
                                outline: 'none',
                                border: 'solid 1px #D6DBDF',
                              }}
                              {...formPlantilla.getFieldProps('cuerpo')}
                              name="cuerpo"
                              placeholder="Ingresa el texto de la plantilla aquí..."
                            ></textarea>
                            {formPlantilla.touched.cuerpo &&
                              formPlantilla.errors.cuerpo && (
                                <div className="fv-plugins-message-container text-danger ">
                                  <span role="alert">
                                    {formPlantilla.errors.cuerpo}
                                  </span>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>

                      <div className="border-0  px-3 ql-container ql-snow border-0 px-8 min-h-45px mt-10">
                        {/* <input
                          className="form-control form-control-transparent border-0 px-8 min-h-45px"
                          {...formPlantilla.getFieldProps('firma')}
                          name="firma"
                          placeholder="Firma"
                        /> */}
                        <textarea
                          style={{
                            width: '100%',
                            resize: 'none',
                            outline: 'none',
                            border: 'solid 1px #D6DBDF',
                          }}
                          {...formPlantilla.getFieldProps('firma')}
                          name="firma"
                          placeholder="Ingresa la firma aquí..."
                          rows={10}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end mt-10">
              <button
                type="reset"
                className="btn btn-light me-3"
                data-kt-users-modal-action="cancel"
                onClick={() => {
                  formPlantilla.resetForm();
                  handleClose(false);
                }}
              >
                Cancelar
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                data-kt-users-modal-action="submit"
                disabled={loading}
              >
                {!loading && (
                  <span className="indicator-label">
                    {id_row > 0 ? 'Actualizar' : 'Crear'}
                  </span>
                )}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: 'block' }}
                  >
                    Espere por favor...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};
export { CreateModal };
