// vamos a copiar la estructura del formulario de otro componente y lo adecuamos a lo que ocupemos

import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import Select from "react-select";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useGetCollaborator, useGetProfileSelect, useSelectCountry } from "../hooks/CollaboratorHook";
import { useSelectGeneric, useSelectGenericPlus } from "../../catalogos/generico/hooks/genericoHook";
import { PatternFormat } from "react-number-format";
import { styleSelect } from "../../../../_metronic/helpers/SelectStyles";
import { CreateContactModal } from "../../oportunidades/_modals/quickAccess/CreateContactModal";

import { useHistory } from "react-router-dom";
import { addUpdCollaborator } from "../../../service/collaborator/Collaborator";
import { useGetContactosFilter } from "../../contacto/hooks/ContactoHook";
import { CreateProfileModal } from "./_modals/CreateProfileModal";
import { KTSVG } from "../../../../_metronic/helpers";
import { Collapse } from 'react-bootstrap-v5';
import { JobsList } from "./sections/JobsList";

// por ahora ya creamos la estructurta, ahora vamos a crear la ruta para poder ver el formulario

// por ahora ya creamos la estructura del formulario ahora vamos a adapatarlo a nuestras necesidades

type Props = {
  id_row: number;
  onRefreshData: () => void;
  section: string;
};

const CollaboratorForm: React.FC<Props> = ({
  id_row,
  onRefreshData,
  section,
}) => {
  const [loading, setLoading] = useState(false);
  const [openSection, setOpenSection] = useState<boolean>(false);
  const [isOpenProfileModal, setIsOpenProfileModal] = useState(false);
  const [selectProfileAdding, setSelectProfileAdding] =
    useState<boolean>(false);
  const [showModalContact, setShowModalContact] = useState<boolean>(false);
  const [jobsList, setJobsList] = useState<any[]>([]);
  const { catalog: departamentos } = useSelectGeneric("DEP");
  const { catalog: genres, loadingRequest: loadingGen } = useSelectGenericPlus("GEN");
  const { countries, loadingRequest: loadingCountry } = useSelectCountry()
  const { initValues } = useGetCollaborator(id_row);
  const history = useHistory();
  const [cuentaId, setCuentaId] = useState<number>(0);
  const [subcuentaId, setSubcuentaId] = useState<number>(0);
  const [reloadContact, setReloadContact] = useState<number>(
    Math.random() * 40
  );
  const [reloadProfile, setReloadProfile] = useState<number>(
    Math.random() * 40
  );
  const [contactValue, setcontactValue] = useState<any | null>(null);
  const [profileValue, setProfileValue] = useState<any | null>(null);
  const [genderValue, setGenderValue] = useState<any | null>(null);
  const [countryValue, setCountryValue]= useState<any | null>(null);

  const location_get = 1;

  const { contDataSelect } = useGetContactosFilter(
    reloadContact,
    cuentaId,
    subcuentaId,
    location_get
  );

  const { profileDataSelect } = useGetProfileSelect('PASOC', reloadProfile);


  const handleSelectContact = (selectedOption: any) => {
    setcontactValue(selectedOption);
    if (selectedOption) {
      const { value } = selectedOption;
      formCollaborator.setFieldValue("contacto_id", value);
      if (value === "new") {
        setShowModalContact(true);
        setcontactValue(null);
        formCollaborator.setFieldValue("contacto_id", 0);
      }
    } else {
      formCollaborator.setFieldValue("contacto_id", null);
    }
  };


  const changeProfile = (selectedOption: any) => {
    setProfileValue(selectedOption)
    if (selectedOption) {
      const { value } = selectedOption;
      formCollaborator.setFieldValue('perfil_id', value);
      if (value === "new") {
        setIsOpenProfileModal(true);
        setProfileValue(null);
        formCollaborator.setFieldValue("perfil_id", 0);
      }
    } else {
      formCollaborator.setFieldValue('perfil_id', null);
    }
  };

  const changeGender = (selectedOption: any) => {
    setGenderValue(selectedOption)
    if (selectedOption) {
      const { value } = selectedOption;

      formCollaborator.setFieldValue('genero_id', value);
    } else {
      formCollaborator.setFieldValue('genero_id', null);
    }
  };

  const changeCountry = (selectedOption: any) => {
    setCountryValue(selectedOption)
    if (selectedOption) {
      const { value } = selectedOption;

      formCollaborator.setFieldValue('pais_id', value);
    } else {
      formCollaborator.setFieldValue('pais_id', null);
    }
  };

  const handleJobListEvent = (data: any) => {
    // Manejar los datos recibidos del componente hijo
    formCollaborator.setFieldValue('empleos', data);
  };
  

  // en esta variable vamos a inicializar los datos que espera el formulario de colaboradores este sera el body del formulario

  const ColaboradorSchema = Yup.object().shape({
    nombre: Yup.string().required("Este campo es requerido."),
    apellido_paterno: Yup.string().required("Este campo es requerido."),
    apellido_materno: Yup.string().required("Este campo es requerido."),
    telefono: Yup.string().trim()
    .required('Este campo es requerido.') // Campo requerido
    .max(13, 'El número de teléfono no puede exceder los 10 caracteres'), // Asegúrate de que solo contenga dígitos// Limita a 10 caracteres
    email:  Yup.string().required("Este campo es requerido."),
    telefono_movil: Yup.string().trim()
    .notRequired()
    .max(13, 'El número de celular no puede exceder los 10 caracteres'),
    fecha_nacimiento: Yup.date()
      .nullable(true) // Permite que el campo sea null
      .notRequired() // Hace que el campo sea opcional
      .max(new Date(), "La fecha de nacimiento no puede ser en el futuro")
      .test(
        "is-adult",
        "Debes ser mayor de edad para registrarte y menor de 99 años",
        function (value: any) {
          if (!value) {
            return true; // Si el campo es opcional y no se proporciona, la validación pasa
          }
          const fechaNacimiento = new Date(value);
          const fechaHoy = new Date();
          const edadLimite = new Date(
            fechaHoy.getFullYear() - 18,
            fechaHoy.getMonth(),
            fechaHoy.getDate()
          );

          const edadLimite99 = new Date(
            fechaHoy.getFullYear() - 99,
            fechaHoy.getMonth(),
            fechaHoy.getDate()
          );

          return (
            fechaNacimiento <= edadLimite && fechaNacimiento >= edadLimite99
          );
        }
      ),
      fecha_ingreso: Yup.date()
      .nullable(true) // Permite que el campo sea null
      .notRequired() // Hace que el campo sea opcional
  });

  //EFFECTS
  useEffect(() => {
    if (Number(id_row) > 0 && initValues.id > 0) {

      const { empleos } = initValues;
      setJobsList(empleos);

      if (initValues.contacto?.id) {
        setcontactValue({
          value: initValues.contacto?.id,
          label: initValues.contacto
            ? `${initValues.contacto.nombre} ${initValues.contacto.apellido_paterno} ${initValues.contacto.apellido_materno}`
            : "",
        });
        formCollaborator.setFieldValue(
          "contacto_id",
          initValues.contacto?.id
        );
      }

      if(initValues.perfil?.id){
        setProfileValue({
          value: initValues.perfil?.id,
          label: initValues.perfil
            ? initValues.perfil.name
            : "",
        });
        formCollaborator.setFieldValue(
          "perfil_id",
          initValues.perfil?.id
        );
      }

      if(initValues.genero?.id){
        setGenderValue({
          value: initValues.genero?.id,
          label: initValues.genero
            ? initValues.genero.name
            : "",
        });
        formCollaborator.setFieldValue(
          "genero_id",
          initValues.genero?.id
        );
      }

      if(initValues.pais?.id){
        setCountryValue({
          value: initValues.pais?.id,
          label: initValues.pais
            ? initValues.pais.nombre
            : "",
        });
        formCollaborator.setFieldValue(
          "pais_id",
          initValues.pais?.id
        );
      }


    }
  }, [initValues]);

  useEffect(() => {
    if (selectProfileAdding) {
      const lastPositionProfile = profileDataSelect[profileDataSelect.length - 1];
      setProfileValue(lastPositionProfile);
      formCollaborator.setFieldValue('perfil_id', lastPositionProfile.id);
    }

  }, [profileDataSelect, profileValue]);


  //   inicializamos formulario con sus atributos
  const formCollaborator = useFormik({
    initialValues: initValues,
    validationSchema: ColaboradorSchema,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      setLoading(true);
      setTimeout(() => {
          addUpdCollaborator(values)
            .then((res) => {
              const {
                data: { message },
              } = res;
              history.goBack();
              toast.success(message, {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
              });
              formCollaborator.resetForm();
              setLoading(false);
            })
            .catch((err) => {
              let resMessageToast: string = '';
              const {
                data: { message, details = null },
              } = err.response;
              resMessageToast = `${message}`;
              setLoading(false);
              setSubmitting(false);
              toast.error(resMessageToast, {
                position: 'top-right',
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
              });
            });
      }, 1000);
    },
  });

  const handlePhoneNumberChange = (values: any) => {
    formCollaborator.setFieldValue("telefono_movil", values.target.value);
  };

  const handleTelephoneNumberChange = (values: any) => {
    formCollaborator.setFieldValue("telefono", values.target.value);
  };

  return (
    <>
      <form
        id="kt_modal_generic_form"
        className="form"
        action="#"
        onSubmit={formCollaborator.handleSubmit}
      >
        <div className="d-flex flex-column flex-xl-row flex-row-fluid mt-5 mb-4">
          <div className="w-100 d-flex">
            <div className="w-100 pe-5">
              <input
                className="form-control mb-2 w-100 border-0"
                style={{
                  width: "100%",
                  padding: "15px",
                  fontSize: "24px",
                  boxSizing: "border-box",
                  marginBottom: "20px",
                }}
                data-kt-element="input"
                placeholder="Nombre *"
                maxLength= {25}
                {...formCollaborator.getFieldProps('nombre')}
              ></input>
              {formCollaborator.touched.nombre && formCollaborator.errors.nombre && (
                <div className="fv-plugins-message-container text-danger w-100">
                  <span role="alert">{formCollaborator.errors.nombre}</span>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="row wh-0100 mb-3">
          <div className="col-xxl-4 col-xl-4 col-md-4 col-lg-4">
            <div className="mb-3">
              <div className="w-100 d-flex">
                <label className="fw-bold fs-6 mb-2 w-auto">
                  Seleccionar contacto/usuario
                </label>
              </div>
              <Select
                options={contDataSelect}
                styles={styleSelect}
                placeholder={"Seleccione"}
                isSearchable={true}
                noOptionsMessage={() => {
                  return <span>{"No se encontraron registros"}</span>;
                }}
                onChange={(event) => handleSelectContact(event)}
                value={contactValue}
                isClearable
                backspaceRemovesValue
              />
              {formCollaborator.touched.contacto_id &&
                formCollaborator.errors.contacto_id && (
                  <div className="fv-plugins-message-container text-danger w-100 mb-3">
                    <span role="alert">
                      {formCollaborator.errors.contacto_id}
                    </span>
                  </div>
                )}
            </div>
          </div>
          <div className="col-xxl-4 col-xl-4 col-md-4 col-lg-4">
            <div className="mb-3">
              <div className="w-100 d-flex">
                <label className="fw-bold fs-6 mb-2 w-auto">
                  Perfil asociado
                </label>
              </div>
              <Select
                value={profileValue}
                options={profileDataSelect}
                styles={styleSelect}
                placeholder={'Seleccione'}
                isSearchable={true}
                noOptionsMessage={() => {
                  return <span>{'No se encontraron registros'}</span>;
                }}
                onChange={(event) => changeProfile(event)}
                isClearable
                backspaceRemovesValue
              />
              {formCollaborator.touched.perfil_id &&
                formCollaborator.errors.perfil_id && (
                  <div className="fv-plugins-message-container text-danger w-100 mb-3">
                    <span role="alert">
                      {formCollaborator.errors.perfil_id}
                    </span>
                  </div>
                )}
            </div>
          </div>
          <div className="col-xxl-4 col-xl-4 col-md-4 col-lg-4">
            <div className="mb-3">
              <div className="w-100 d-flex">
                <label style={{ visibility: id_row ? 'visible' : 'hidden' }}
                className="fw-bold fs-6 mb-2 w-auto">Número de empleado</label>
              </div>
              <input
                className="form-control "
                data-kt-element="input"
                style={{ visibility: id_row ? 'visible' : 'hidden' }}
                disabled={ true}
                placeholder="Numero de empleado"
                {...formCollaborator.getFieldProps("numero_empleado")}
              ></input>
              {formCollaborator.touched.numero_empleado &&
                formCollaborator.errors.numero_empleado && (
                  <div className="fv-plugins-message-container text-danger w-100">
                    <span role="alert">
                      {formCollaborator.errors.numero_empleado}
                    </span>
                  </div>
                )}
            </div>
          </div>
        </div>
        <div className="row wh-0100">
          <div className="col-xxl-4 col-xl-4 col-md-4 col-lg-4">
            <div className="mb-3">
              <div className="w-100 d-flex">
                <label className="required fw-bold fs-6 mb-2 w-auto">
                  Apellido Paterno
                </label>
              </div>
              <input
                className="form-control"
                data-kt-element="input"
                placeholder="Apellido paterno"
                maxLength={15}
                {...formCollaborator.getFieldProps("apellido_paterno")}
              ></input>
              {/* esto es para que en este atributo se muestren los posibles errores que se validan en este input  */}
              {formCollaborator.touched.apellido_paterno &&
                formCollaborator.errors.apellido_paterno && (
                  <div className="fv-plugins-message-container text-danger w-100">
                    <span role="alert">
                      {formCollaborator.errors.apellido_paterno}
                    </span>
                  </div>
                )}
            </div>
          </div>
          <div className="col-xxl-4 col-xl-4 col-md-4 col-lg-4">
            <div className="mb-3">
              <div className="w-100 d-flex">
                <label className="required fw-bold fs-6 mb-2 w-auto">Apellido Materno</label>
              </div>
              <input
                className="form-control"
                data-kt-element="input"
                placeholder="Apellido materno"
                maxLength={15}
                {...formCollaborator.getFieldProps("apellido_materno")}
              ></input>
              {formCollaborator.touched.apellido_materno &&
                formCollaborator.errors.apellido_materno && (
                  <div className="fv-plugins-message-container text-danger w-100">
                    <span role="alert">
                      {formCollaborator.errors.apellido_materno}
                    </span>
                  </div>
                )}
            </div>
          </div>
          <div className="col-xxl-4 col-xl-4 col-md-4 col-lg-4">
            <div className="mb-3">
              <div className="w-100 d-flex">
                <label className="required fw-bold fs-6 mb-2 w-auto">
                  Teléfono
                </label>
              </div>
              <PatternFormat
                className="form-control mb-3"
                {...formCollaborator.getFieldProps("telefono")}
                format="## #### #### ##### ####"
                allowEmptyFormatting
                autoComplete="new-password"
              // mask="_"
              onChange={handleTelephoneNumberChange}
              />
              {formCollaborator.touched.telefono &&
                formCollaborator.errors.telefono && (
                  <div className="fv-plugins-message-container text-danger w-100">
                    <span role="alert">
                      {formCollaborator.errors.telefono}
                    </span>
                  </div>
                )}
            </div>
          </div>
        </div>
        <div className="row wh-0100">
          <div className="col-xxl-4 col-xl-4 col-md-4 col-lg-4">
            <div className="mb-3">
              <div className="w-100 d-flex">
                <label className="required fw-bold fs-6 mb-2 w-auto">
                  Correo electrónico
                </label>
              </div>
              <input
                {...formCollaborator.getFieldProps("email")}
                className="form-control"
                placeholder="Correo electrónico"
                type="email"
                name="email"
                maxLength={30}
              />
              {formCollaborator.touched.email && formCollaborator.errors.email && (
                <div className="fv-plugins-message-container text-danger w-100">
                  <span role="alert">{formCollaborator.errors.email}</span>
                </div>
              )}
            </div>
          </div>
          <div className="col-xxl-4 col-xl-4 col-md-4 col-lg-4">
            <div className="mb-3">
              <div className="w-100 d-flex">
                <label className="fw-bold fs-6 mb-2 w-auto">Celular</label>
              </div>
              <PatternFormat
                {...formCollaborator.getFieldProps("telefono_movil")}
                className="form-control"
                format="## #### #### ##### ####"
                name="telefono_movil"
                onChange={handlePhoneNumberChange}
              />
              {formCollaborator.touched.telefono_movil &&
                formCollaborator.errors.telefono_movil && (
                  <div className="fv-plugins-message-container text-danger w-100">
                    <span role="alert">
                      {formCollaborator.errors.telefono_movil}
                    </span>
                  </div>
                )}
            </div>
          </div>
          <div className="col-xxl-4 col-xl-4 col-md-4 col-lg-4">
            <div className="mb-3">
              <div className="w-100 d-flex">
                <label className="fw-bold fs-6 mb-2 w-auto">
                  Fecha de nacimiento
                </label>
              </div>
              <input
                type="date"
                 className={`form-control ${
                   formCollaborator.touched.fecha_nacimiento &&
                   formCollaborator.errors.fecha_nacimiento
                     ? 'is-invalid'
                     : ''
                 }`}
                id="fecha_nacimiento"
                name="fecha_nacimiento"
                 value={
                   formCollaborator.values.fecha_nacimiento
                     ? new Date(formCollaborator.values.fecha_nacimiento)
                         .toISOString()
                         .split('T')[0]
                     : ''
                 }
                onChange={formCollaborator.handleChange}
                onBlur={formCollaborator.handleBlur}
              />
               {formCollaborator.touched.fecha_nacimiento &&
                formCollaborator.errors.fecha_nacimiento && (
                  <div className="invalid-feedback">
                    {formCollaborator.errors.fecha_nacimiento}
                  </div>
                )}
            </div>
          </div>
        </div>
        <div className="row wh-0100">
        <div className="col-xxl-4 col-xl-4 col-md-4 col-lg-4">
            <div className="mb-3">
              <div className="w-100 d-flex">
                <label className="fw-bold fs-6 mb-2 w-auto">
                  G&eacute;nero
                </label>
              </div>
              <Select
                value={genderValue}
                options={genres}
                styles={styleSelect}
                placeholder={'Seleccione'}
                isSearchable={true}
                noOptionsMessage={() => {
                  return <span>{'No se encontraron registros'}</span>;
                }}
                onChange={changeGender}
                isLoading={loadingGen}
                isClearable
                backspaceRemovesValue
              />
              {formCollaborator.touched.genero_id &&
                formCollaborator.errors.genero_id && (
                  <div className="fv-plugins-message-container text-danger w-100">
                    <span role="alert">
                      {formCollaborator.errors.genero_id}
                    </span>
                  </div>
                )}
            </div>
          </div>
          <div className="col-xxl-4 col-xl-4 col-md-4 col-lg-4">
            <div className="mb-3">
              <div className="w-100 d-flex">
                <label className="fw-bold fs-6 mb-2 w-auto">
                  Nacionalidad
                </label>
              </div>
              <Select
                value={countryValue}
                options={countries}
                styles={styleSelect}
                placeholder={'Seleccione'}
                isSearchable={true}
                noOptionsMessage={() => {
                  return <span>{'No se encontraron registros'}</span>;
                }}
                onChange={changeCountry}
                isLoading={loadingCountry}
                isClearable
                backspaceRemovesValue
              />
              {formCollaborator.touched.pais_id &&
                formCollaborator.errors.pais_id && (
                  <div className="fv-plugins-message-container text-danger w-100">
                    <span role="alert">
                      {formCollaborator.errors.pais_id}
                    </span>
                  </div>
                )}
            </div>
          </div>
          <div className="col-xxl-4 col-xl-4 col-md-4 col-lg-4">
            <div className="mb-3">
              <div className="w-100 d-flex">
                <label className="fw-bold fs-6 mb-2 w-auto">
                  Fecha de ingreso
                </label>
              </div>
              <input
                type="date"
                 className={`form-control ${
                   formCollaborator.touched.fecha_ingreso &&
                   formCollaborator.errors.fecha_ingreso
                     ? 'is-invalid'
                     : ''
                 }`}
                id="fecha_ingreso"
                name="fecha_ingreso"
                 value={
                   formCollaborator.values.fecha_ingreso
                     ? new Date(formCollaborator.values.fecha_ingreso)
                         .toISOString()
                         .split('T')[0]
                     : new Date()
                     .toISOString()
                     .split('T')[0]
                 }
                onChange={formCollaborator.handleChange}
                onBlur={formCollaborator.handleBlur}
              />
               {formCollaborator.touched.fecha_ingreso &&
                formCollaborator.errors.fecha_ingreso && (
                  <div className="invalid-feedback">
                    {formCollaborator.errors.fecha_ingreso}
                  </div>
                )}
            </div>
          </div>
        </div>
        <div className="card mb-6 mb-xl-9">
          <div
            className="card-header cursor-pointer"
            onClick={() => {
              setOpenSection(!openSection);
              // setOpenSectionService(false);
              // setOpenSectionTotals(false);
            }}
            aria-controls="collapseProducts"
            aria-expanded={openSection}
          >
            <div className="card-title m-0">
              <h3 className="fw-bolder m-0">Empleos</h3>
            </div>
            <div className="d-flex align-items-center">

              <div
                className={`align-self-center`}
                style={{
                  transform: openSection
                    ? 'translateX(10%) rotate(-180deg)'
                    : '',
                  transition: 'transform 0.3s ease',
                }}
              >
                <KTSVG
                  path={'/media/icons/duotune/arrows/arr072.svg'}
                  className={`svg-icon-1`}
                />
              </div>
            </div>
          </div>
  
          <Collapse in={openSection}>
            <div id="collapseProducts">
              <div className="card-body border-top p-9">
                <JobsList
                  data={jobsList}
                  collabId={id_row}
                  formik={formCollaborator}
                  onUpdateData={(data: any[]) => {
                    if (data) {
                      handleJobListEvent(data)
                  }}
                }
                />
              </div>
            </div>
          </Collapse>
        </div>

        <div className="d-flex justify-content-end mt-10">
          <button
            type="reset"
            className="btn btn-light me-3"
            data-kt-users-modal-action="cancel"
            onClick={() => {
              history.goBack();
            }}
          >
            Cancelar
          </button>
          <button
            type="submit"
            className="btn btn-primary"
            data-kt-users-modal-action="submit"
            disabled={loading}
          >
            {!loading && (
              <span className="indicator-label">
                {id_row > 0 ? "Actualizar" : "Guardar"}
              </span>
            )}
            {loading && (
              <span className="indicator-progress" style={{ display: "block" }}>
                Espere por favor...
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>
        </div>
        <CreateContactModal
        show={showModalContact}
        cuenta={null}
        subcuenta={null}
        nameAcc={null}
        nameSubAcc={null}
        handleClose={(isNew: boolean, newContact: any) => {
          if (isNew && newContact) {
            setReloadContact(Math.random() * 50);
            const { value, label } = newContact;
            formCollaborator.setFieldValue("contacto_id", value);
            setcontactValue({
              value: value,
              label: label,
            });
          }
          setShowModalContact(!showModalContact);
        }}
      ></CreateContactModal>
      <CreateProfileModal
        show={isOpenProfileModal}
        handleClose={(isNew: boolean, newProfile: any) => {
          if (isNew && newProfile) {
            setReloadProfile(Math.random() * 50);
            const { value, label } = newProfile;
            formCollaborator.setFieldValue("perfil_id", value);
            setProfileValue({
              value: value,
              label: label,
            });
          }
          setIsOpenProfileModal(!isOpenProfileModal);
        }}
      >
      </CreateProfileModal>
      </form>
    </>
  );
};
export { CollaboratorForm };
