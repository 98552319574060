import axios from "axios";
import { SelectFormat } from "../../../_metronic/helpers/models/GenericModel";
const API_URL = process.env.REACT_APP_API;

export async function getCountriesSelect() {
    const response = await axios.get(`${API_URL}catalogos/pais`);
    const data = response.data.doc.data.rows;
    let select: SelectFormat[] = [];
    data.forEach((item: any) => {
      const selectCustom = {
        id: Number(item.id),
        value: item.id,
        label: `${item.nombre}`,
        data: item,
      };
      select.push(selectCustom);
    });
  

    return select;
  }