import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { KTSVG } from '../../../../_metronic/helpers';
import { useFormik } from 'formik';
import { useGetAllCuentas } from '../hooks/ContactoHook';

type Props = {
  initFilterValues: any;
  onCleanFilter: () => void;
  onSearchFilter: (values: any) => void;
  contactData: any;
};

const Filter: React.FC<Props> = ({
  initFilterValues,
  onSearchFilter,
  onCleanFilter,
  contactData,
}) => {
  const [filterValues, setFilterValues] = useState(initFilterValues);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOptionCuenta, setSelectedOptionCuenta] = useState(null);
  const { dataCuenta } = useGetAllCuentas(1);
  const [arrayData, setArrayData] = useState<
    { value: any; label: string }[] | undefined
  >();
  const [cuentaData, setCuentaData] = useState<
    { value: any; label: string }[] | undefined
  >();

  useEffect(() => {
    if (initFilterValues) {
      setFilterValues(initFilterValues);
    }
  }, [initFilterValues]);


  useEffect(() => {
    if (contactData) {
      const selectOptions = contactData.map((option: any) => ({
        value: option.id,
        label: `${option.nombre} ${option.apellido_paterno} ${option.apellido_materno}`,
      }));
      setArrayData(selectOptions);
    }
  }, [contactData]);

  useEffect(() => {
    if (dataCuenta) {
      const selectOptions = dataCuenta.map((option: any) => ({
        value: option.id,
        label: `${option.nombre} `,
      }));
      setCuentaData(selectOptions);
    }
  }, [dataCuenta]);

  const handleSelect = (selectedOption: any, code: string) => {
    const { value } = selectedOption;

    switch (code) {
      case 'CON':
        setSelectedOption(selectedOption);
        formFilter.setFieldValue('nombre', value);
        break;
      case 'CUE':
        setSelectedOptionCuenta(selectedOption);
        formFilter.setFieldValue('cuenta_id', value);
        break;
      default:
        break;
    }
  };

  const formFilter = useFormik({
    initialValues: filterValues,
    enableReinitialize: true,
    onSubmit: (values, { setStatus, setSubmitting }) => {

      onSearchFilter(values);
    },
  });

  return (
    <>
      <div className="card-title align-items-start flex-column">
        <a
          href="#/"
          className={'btn btn-sm btn-light-primary'}
          data-kt-menu-trigger="click"
          data-kt-menu-placement="bottom-end"
          data-kt-menu-flip="top-end"
        >
          <KTSVG
            path="/media/icons/duotune/general/gen031.svg"
            className="svg-icon-5 svg-icon-gray-500 me-1"
          />
          Filtrar
        </a>
        <div
          className="menu menu-sub menu-sub-dropdown w-250px w-md-400px"
          data-kt-menu="true"
        >
          <div className="px-7 py-5">
            <div className="fs-5 text-dark fw-bolder">Opciones de filtro</div>
          </div>

          <form
            id="kt_modal_generic_form"
            className="form"
            action="#"
            onSubmit={formFilter.handleSubmit}
          >
            <div className="px-7 py-5">
              <div className="mb-5">
                <label className="form-label fw-bold">Nombre: </label>
                <div onClick={(e) => e.stopPropagation()}>
                  <Select
                    options={arrayData}
                    placeholder={'Seleccione'}
                    onChange={(event) => {
                      handleSelect(event, 'CON')
                    }}
                    value={selectedOption}
                    noOptionsMessage={() => {
                      return <span>No hay contactos</span>;
                    }}
                    styles={{
                      placeholder: (provided, state) => ({
                        ...provided,
                        color: '#181c32',
                      }),
                      indicatorSeparator: (provided, state) => ({
                        ...provided,
                        display: 'none',
                      }),
                      dropdownIndicator: (provided, state) => ({
                        ...provided,
                        color: '#7E8199',
                      }),
                      control: (provided, state) => ({
                        ...provided,
                        border: '1px solid #e4e6ef',
                        borderRadius: '6px',
                        padding: '3px',
                        fontSize: '1.1rem',
                        boxShadow: 'none',
                        backgroundColor: state.isDisabled ? '#EFF2F5' : 'white',
                      }),
                      singleValue: (provided, state) => ({
                        ...provided,
                        color: state.isDisabled ? '#181c32' : 'inherit',
                        fontWeight: 500,
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        fontSize: '1.1rem',
                      }),
                    }}
                  />
                </div>
              </div>
              <div className="mb-5">
                <label className="form-label fw-bold">Cuenta: </label>
                <div onClick={(e) => e.stopPropagation()}>
                  <Select
                    options={cuentaData}
                    placeholder={'Seleccione'}
                    onChange={(event) => {
                      handleSelect(event, 'CUE')
                    }}
                    value={selectedOptionCuenta}
                    noOptionsMessage={() => {
                      return <span>No hay cuentas</span>;
                    }}
                    styles={{
                      placeholder: (provided, state) => ({
                        ...provided,
                        color: '#181c32',
                      }),
                      indicatorSeparator: (provided, state) => ({
                        ...provided,
                        display: 'none',
                      }),
                      dropdownIndicator: (provided, state) => ({
                        ...provided,
                        color: '#7E8199',
                      }),
                      control: (provided, state) => ({
                        ...provided,
                        border: '1px solid #e4e6ef',
                        borderRadius: '6px',
                        padding: '3px',
                        fontSize: '1.1rem',
                        boxShadow: 'none',
                        backgroundColor: state.isDisabled ? '#EFF2F5' : 'white',
                      }),
                      singleValue: (provided, state) => ({
                        ...provided,
                        color: state.isDisabled ? '#181c32' : 'inherit',
                        fontWeight: 500,
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        fontSize: '1.1rem',
                      }),
                    }}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-end ">
                <button
                  type="reset"
                  className="btn btn-sm btn-white btn-active-light-primary me-2"
                  data-kt-menu-dismiss="true"
                  onClick={() => {
                    formFilter.resetForm();
                    onCleanFilter();
                    setSelectedOption(null);
                    setSelectedOptionCuenta(null);
                  }}
                >
                  Limpiar
                </button>

                <button
                  type="submit"
                  className="btn btn-sm btn-primary"
                  data-kt-menu-dismiss="true"
                >
                  Buscar
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export { Filter };
