import { FC, useEffect, useState } from 'react';
import { KTSVG, encryptId } from '../../../../_metronic/helpers';
import clsx from 'clsx';
import { NotificacionModel } from '../../../../app/pages/notificaciones/models/NotificacionModel';
import { useHistory } from 'react-router-dom';
import { Tooltip } from '@mui/material';
import { VerMasTexto } from '../../../../app/pages/tasks/components/SeeMore';

type Props = {
  data: any;
  onEditRegister: (row: any, validate: any) => void;
  onPage: (row: any) => void;
  onRender: (row: any) => void;
  currentPage: number;
};

const HeaderNotiMenu: React.FC<Props> = ({
  data,
  onEditRegister,
  onPage,
  onRender,
  currentPage,
}) => {
  const Notificaciones: Array<NotificacionModel> = data;
  const history = useHistory();

  const diffMinutes = (dateNoty: Date) => {
    const today = new Date();
    const diffMs = today.getTime() - dateNoty.getTime();
    const diffDays = Math.floor(diffMs / 86400000);
    const diffHrs = Math.floor((diffMs % 86400000) / 3600000);
    const diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000);
    let timeNoty = `Justo ahora`;

    if (diffDays > 0) {
      if (diffDays > 1) {
        timeNoty = `Hace ${diffDays} días`;
      } else {
        timeNoty = `Hace ${diffDays} día`;
      }
    }
    if (diffHrs > 0 && diffDays === 0) {
      if (diffHrs > 1) {
        timeNoty = `Hace ${diffHrs} horas`;
      } else {
        timeNoty = `Hace ${diffHrs} hora`;
      }
    }
    if (diffMins > 0 && diffHrs === 0) {
      if (diffMins > 1) {
        timeNoty = `Hace ${diffMins} minutos`;
      } else {
        timeNoty = `Hace ${diffMins} minuto`;
      }
    }
    return timeNoty;
  };

  return (
    <div
      className="menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px "
      data-kt-menu="true"
      id="kt_menu_notifications"
      style={{
        zIndex: '107',
        position: 'fixed',
        inset: '0px 0px auto auto',
        margin: '0px',
      }}
      // style="z-index: 107; position: fixed; inset: 0px 0px auto auto; margin: 0px; transform: translate(-191px, 59px);"
      data-popper-placement="bottom-end"
    >
      <div
        className="d-flex flex-column bgi-no-repeat rounded-top"
        style={{
          backgroundColor: '#2683BE',
        }}
      >
        <h3 className="text-white fw-semibold px-9 mt-6 mb-6">
          Notificaciones{' '}
        </h3>
      </div>

      <div className="tab-content">
        <div
          className="tab-pane fade active show"
          id="kt_topbar_notifications_1"
          role="tabpanel"
        >
          <div className="scroll-y mh-325px my-5 px-8">
            {Notificaciones.length ? (
              Notificaciones.map((row: any) => {
                return (
                  <div
                    key={row.id}
                    className="d-flex flex-stack py-4 cursor-pointer"
                  >
                    <div className="d-flex align-items-center">
                      <div className="symbol symbol-35px me-4">
                        <span
                          className={`notification ${
                            row.vista ? 'read' : 'new'
                          }`}
                        ></span>
                      </div>

                      <div className="mb-0 me-2">
                        <div className="large-text">
                          <a
                            className="fs-7 text-gray-800 text-hover-primary fw-bold "
                            onClick={() => {
                              switch (row.modulo) {
                                case 1:
                                  history.push(
                                    `/oportunidades_edit/${encryptId(
                                      row.registro_id.toString()
                                    )}`
                                    // `/oportunidad_detalle/${row.registro_id}`
                                  );
                                  onEditRegister(row.id, row.vista);
                                  break;

                                default:
                                  break;
                              }
                            }}
                          >
                            {row.descripcion_corta}
                          </a>
                        </div>

                        <div className="text-gray-400 fs-7 ">
                          {row.descripcion.length > 50 ? (
                            <VerMasTexto
                              shortText={`${row.descripcion.slice(0, 25)}...`}
                              fullText={row.descripcion}
                            />
                          ) : (
                            row.descripcion
                          )}
                        </div>
                      </div>
                    </div>

                    <span className="badge badge-light fs-9">{`${diffMinutes(
                      new Date(row.created_at)
                    )} `}</span>
                  </div>
                );
              })
            ) : (
              <div
                className="fs-6 text-gray-600"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                Sin notificaciones
              </div>
            )}
          </div>

          <div
            className={clsx('text-center', {
              'py-3 text-center border-top': Notificaciones.length > 0,
            })}
          >
            {Notificaciones.length >= 5 ? (
              <>
                <a
                  className="btn btn-color-gray-600 btn-active-color-primary"
                  onClick={onPage}
                >
                  Ver más
                </a>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export { HeaderNotiMenu };
