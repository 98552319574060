import { useState, useEffect } from "react";
import {
  getOportunidades,
  getOportunidadById,
  getAllOportunidadesTareas,
  getAllVendedoresComerciales,
  getAllVendedoresTecnicos,
  getHistory,
  createHistoryMessage,
  createTaskOportunity,
  getTasksByIdOportunity,
  getRisksByIdOportunity,
  createRiskOportunity,
  getDocumentsByOportunityId,
  getQuotationsByIdOportunity,
} from "../../../service/oportunidad/Oportunidad";
import { getDataFilterBySelectFiled } from "../../../service/home/Home";
import {
  getCatalogCode,
  getCatFields,
} from "../../../service/catalog/Catalogs";
import {
  OportunidadModel,
  VendedorModel,
  OpportunityDocModel,
} from "../models/OportunidadModel";
import { useHistory } from "react-router-dom";
import { HistoryModel } from "../../../../_metronic/helpers/models/HistoryModel";
import { GenericoModel } from "../../catalogos/generico/models/GenericoModel";
import { getCatalog } from "../../../service/catalog/Catalogs";
import { TaskModel } from "../../tasks/models/TaskModel";
import { RiskModel } from "../../risks/models/RiskModel";
import { getNotesByOpoId, insertNoteOpp } from "../../../service/notes/Notes";
import { NoteOppModel } from "../../notes_opp/models/NoteOppModel";
import { QuotationModel } from "../models/OportunityQuotations";

type Option = {
  value: any;
  label: string;
};
export const useGetAll = (
  realoadGrid: number,
  startIndex: number,
  endIndex: number,
  filters: any
) => {
  const [data, setData] = useState<OportunidadModel[]>([]);
  const [count, setCount] = useState(1);
  const [loadingRequest, setLoadingRequest] = useState(0);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      setLoadingRequest(0);
      const res = await getOportunidades(startIndex, endIndex, filters)
        .then()
        .catch((error) => {
          history.push("/error/500");
        });
      if (res) {
        setData(res.data);
        setCount(res.count);
        setLoadingRequest(1);
      }
    };
    // fetchPost();
    if (realoadGrid) {
      fetchPost();
    }
  }, [endIndex, realoadGrid]);

  return { data, count, loadingRequest };
};
export const useGetAllOportunidades = (
  realoadGrid: number,
  cuenta: number,
  subcuenta: number
) => {
  const [dataOportunidades, setDataOportunidades] = useState<
    OportunidadModel[]
  >([]);
  const [count, setCount] = useState(1);
  const [loadingRequest, setLoadingRequest] = useState(0);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      setLoadingRequest(0);
      const res = await getAllOportunidadesTareas(cuenta, subcuenta)
        .then()
        .catch((error) => {
          history.push("/error/500");
        });
      if (res) {
        setDataOportunidades(res.data);
        setCount(res.count);
        setLoadingRequest(1);
      }
    };
    // fetchPost();
    if (realoadGrid) {
      fetchPost();
    }
  }, [realoadGrid, cuenta, subcuenta]);

  return { dataOportunidades, count, loadingRequest };
};
export const useGetOppById = (id: number, reload: number) => {
  const initOportunidadModel: OportunidadModel = {
    id: 0,
    nombre: "",
    nombre_contacto: null,
    cantidad_estimada: 0,
    fecha_aprobacion_estimada: "",
    fecha_cierre_real: "",
    created_at: new Date(),
    probabilidad: 0,
    cuenta: {
      id: 0,
      nombre: "",
    },
    subCuenta: {
      id: 0,
      nombre: "",
    },
    tipoOportunidad: {
      id: 0,
      code: "",
      name: "",
    },
    etapa: {
      id: 0,
      code: "",
      name: "",
    },
    status: {
      id: 0,
      code: "",
      name: "",
    },
    unidad: {
      id: 0,
      code: "",
      name: "",
    },
    departamento: {
      id: 0,
      code: "",
      name: "",
    },
    vendedorComercial: null,
    contacto: null,
    cuenta_id: 0,
    sub_cuenta_id: null,
    tipo_oportunidad_id: 0,
    etapa_id: 0,
    estatus_id: 0,
    status_id: 0,
    vendedor_comercial_id: 0,
    departamento_id: null,
    documentos: null,
    detalles: "",
    moneda: {
      id: 0,
      name: "",
      code: "",
    },
    company_id: 0,
    unidad_negocio_id: 0,
    moneda_id: 0,
  };
  const [initValues, setInitValues] =
    useState<OportunidadModel>(initOportunidadModel);
  const [loadingRequest, setLoadingRequest] = useState<boolean>(false);

  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      setLoadingRequest(true);

      const res = await getOportunidadById(id)
        .then()
        .catch((error) => {
          history.push("/error/500");
        });
      if (res) {
        setLoadingRequest(false);
        setInitValues(res);
      }
    };
    if (id) {
      fetchPost();
    } else {
      setInitValues(initOportunidadModel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, reload]);

  return { initValues, loadingRequest };
};
export const useGetAllVendedoresComerciales = () => {
  const [vComerciales, setVComerciales] = useState<VendedorModel[]>([]);
  const [sellerData, setSellerData] = useState<any[]>([]);
  const [count, setCount] = useState(1);
  const [loadingRequest, setLoadingRequest] = useState(0);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      setLoadingRequest(0);
      const res = await getAllVendedoresComerciales()
        .then()
        .catch((error) => {
          history.push("/error/500");
        });
      if (res) {
        setVComerciales(res.data);
        const selectOptions = res.data.map((option: any) => ({
          value: option.id,
          label: `${option.alias} `,
          nombre: option.nombre,
          apellidoP: option.apellido_paterno,
        }));
        setSellerData(selectOptions);
        setCount(res.count);
        setLoadingRequest(1);
      }
    };
    fetchPost();
  }, []);

  return { vComerciales, sellerData, count, loadingRequest };
};
export const useGetAllVendedoresComercialesByUser = () => {
  const [vComerciales, setVComerciales] = useState<VendedorModel[]>([]);
  const [sellerData, setSellerData] = useState<any[]>([]);
  const [count, setCount] = useState(1);
  const [loadingRequest, setLoadingRequest] = useState(0);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      setLoadingRequest(0);
      const res = await getAllVendedoresComerciales()
        .then()
        .catch((error) => {
          history.push("/error/500");
        });
      if (res) {
        setVComerciales(res.data);
        const selectOptions = res.data.map((option: any) => ({
          value: option.user_id,
          label: `${option.alias} `,
          nombre: option.nombre,
          apellidoP: option.apellido_paterno,
        }));
        setSellerData(selectOptions);
        setCount(res.count);
        setLoadingRequest(1);
      }
    };
    fetchPost();
  }, []);

  return { vComerciales, sellerData, count, loadingRequest };
};
export const useGetAllVendedoresTecnicos = () => {
  const [vTecnicos, setVTecnico] = useState<VendedorModel[]>([]);
  const [count, setCount] = useState(1);
  const [loadingRequest, setLoadingRequest] = useState(0);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      setLoadingRequest(0);
      const res = await getAllVendedoresTecnicos()
        .then()
        .catch((error) => {
          history.push("/error/500");
        });
      if (res) {
        setVTecnico(res.data);
        setCount(res.count);
        setLoadingRequest(1);
      }
    };
    fetchPost();
  }, []);

  return { vTecnicos, count, loadingRequest };
};
export const useGetHistory = (reaload: number, search: string, id: string) => {
  const [data, setData] = useState<HistoryModel[]>([]);
  const [loadingRequest, setLoadingRequest] = useState(0);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      setLoadingRequest(0);
      const res = await getHistory(search, id)
        .then()
        .catch((error) => {
          history.push("/error/500");
        });
      if (res) {
        setData(res);
        setLoadingRequest(1);
      }
    };
    if (reaload && Number(id) > 0) {
      fetchPost();
    }
  }, [reaload]);

  return { data, loadingRequest };
};
export const useInsertMessage = () => {
  const [loadingInsert, setLoadingInsert] = useState<boolean>(false);
  const history = useHistory();
  const insertData = async (message: string, id: string) => {
    setLoadingInsert(false);
    try {
      await createHistoryMessage(message, id);
      setLoadingInsert(true);
    } catch (error) {
      history.push("/error/500");
      setLoadingInsert(false);
    }
  };
  return { insertData, loadingInsert };
};

export const useDataFormatForSelectComponent = (code: string, id: number) => {
  const [catalog, setCatalog] = useState<GenericoModel[]>([]);
  const [dataSelect, setdataSelect] = useState<any[]>([]);

  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      const data = await getCatalog(code)
        .then()
        .catch((error) => {
          history.push("/error/500");
        });
      if (data) {
        setCatalog(data.rows);
        let selectOptions: Option[] = [];
        if (code === "ETP") {
          let permittedStages;
          if (id > 0) {
            permittedStages = data.rows;
          } else {
            permittedStages = data.rows.filter((item: any) => {
              return item.code !== "CGA" && item.code !== "CPE";
            });
          }
          selectOptions = permittedStages.map((option: any) => ({
            value: option.id,
            label: `${option.name} `,
            data: option,
          }));
        } else {
          selectOptions = data.rows.map((option: any) => ({
            value: option.id,
            label: code === "UMON" ? `${option.code} ` : `${option.name} `,
          }));
        }

        setdataSelect(selectOptions);
      }
    };
    fetchPost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  return { catalog, dataSelect };
};

export const useInsertTaskOportunity = () => {
  const [loadingInsertTask, setLoadingInsertTask] = useState<boolean>(false);
  const history = useHistory();
  const insertTask = async (taskName: string, oportunityId: string) => {
    setLoadingInsertTask(false);
    try {
      await createTaskOportunity(taskName, oportunityId);
      setLoadingInsertTask(true);
    } catch (error) {
      history.push("/error/500");
      setLoadingInsertTask(false);
    }
  };
  return { insertTask, loadingInsertTask };
};

export const useGetTasksByIdOportunity = (
  oportunityId: string,
  realoadTasks: number
) => {
  const [tasks, setTasks] = useState<TaskModel[]>([]);
  const [loadingTask, setLoadingTask] = useState<boolean>(false);
  const history = useHistory();
  useEffect(() => {
    setLoadingTask(false);
    const fetchPost = async () => {
      try {
        const res = await getTasksByIdOportunity(oportunityId);
        if (res) {
          setTasks(res);
          setLoadingTask(true);
        }
      } catch (error) {
        history.push("/error/500");
        setLoadingTask(false);
      }
    };
    fetchPost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oportunityId, realoadTasks]);

  return { tasks, loadingTask };
};

export const useGetRisksByIdOportunity = (
  oportunityId: string,
  realoadRisks: number
) => {
  const [risks, setRisks] = useState<RiskModel[]>([]);
  const [loadingRisk, setLoadingRisk] = useState<boolean>(false);
  const history = useHistory();
  useEffect(() => {
    setLoadingRisk(false);
    const fetchPost = async () => {
      try {
        const res = await getRisksByIdOportunity(oportunityId);
        if (res) {
          setRisks(res);
          setLoadingRisk(true);
        }
      } catch (error) {
        history.push("/error/500");
        setLoadingRisk(false);
      }
    };
    fetchPost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oportunityId, realoadRisks]);

  return { risks, loadingRisk };
};

export const useInsertRiskOportunity = () => {
  const [loadingInsertRisk, setLoadingInsertRisk] = useState<boolean>(false);
  const history = useHistory();
  const insertRisk = async (riskName: string, oportunityId: string) => {
    setLoadingInsertRisk(false);
    try {
      await createRiskOportunity(riskName, oportunityId);
      setLoadingInsertRisk(true);
    } catch (error) {
      history.push("/error/500");
      setLoadingInsertRisk(false);
    }
  };
  return { insertRisk, loadingInsertRisk };
};

export const useGetDptos = (code: string, reload: number) => {
  const [catalog, setCatalog] = useState<GenericoModel[]>([]);
  const [dataSelectDpto, setdataSelectDpto] = useState<any[]>([
    {
      value: "new",
      label: "Agregar Departamento",
    },
  ]);

  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      const data = await getCatalog(code)
        .then()
        .catch((error) => {
          history.push("/error/500");
        });
      if (data) {
        setCatalog(data.rows);

        const selectOptions = data.rows.map((option: any) => ({
          value: option.id,
          label: `${option.name} `,
        }));
        setdataSelectDpto((prevState) => [
          {
            value: "new",
            label: "Agregar Departamento",
          },
          ...selectOptions,
        ]);
      }
    };
    fetchPost();
  }, [reload]);

  return { catalog, dataSelectDpto };
};

export const useGetFieldsOpportunity = () => {
  const [listFields, setlistFields] = useState<GenericoModel[]>([]);
  const [loadingRequest, setLoadingRequest] = useState(0);

  const history = useHistory();

  useEffect(() => {
    const fetchPost = async () => {
      setLoadingRequest(0);
      const res = await getCatFields()
        .then()
        .catch((error) => {
          history.push("/error/500");
        });
      if (res) {
        // res.unshift({
        //   id: 0,
        //   name: 'Todas',
        //   code: 'ALL',
        // });

        const filedsFilter = res.filter(
          (item: any) => item.code != "UNI_ID" && item.code != "MON_ID"
        );

        const selectOptions = filedsFilter.map((option: any) => ({
          value: option.id,
          label: `${option.name} `,
          code: option.code,
        }));
        setlistFields(selectOptions);
        setLoadingRequest(1);
      }
    };
    fetchPost();
  }, []);

  return {
    listFields,
    loadingRequest,
  };
};

export const useGetDataSelectedByField = (code: string) => {
  const [dataOptionSelected, setDataOptionSelected] = useState<any[]>([]);
  const [loadingRequest, setLoadingRequest] = useState(0);

  const history = useHistory();

  useEffect(() => {
    const fetchPost = async () => {
      setLoadingRequest(0);
      const res = await getDataFilterBySelectFiled(code)
        .then()
        .catch((error) => {
          history.push("/error/500");
        });
      if (res) {
        const selectOptions = res.map((option: any) => ({
          value: option.id,
          label: `${
            option?.name
              ? option?.name
              : code === "VEN_COM_ID" || code === "VEN_TEC_ID"
              ? option?.alias
              : option?.nombre
          } `,
        }));
        setDataOptionSelected(selectOptions);
        setLoadingRequest(1);
      }
    };

    if (code) {
      fetchPost();
    }
  }, [code]);

  return {
    dataOptionSelected,
    loadingRequest,
  };
};

export const useGetDocumentsByOportunityId = (
  oportunityId: number,
  reload: number
) => {
  const [dataDocuments, setDataDocuments] = useState<OpportunityDocModel[]>([]);
  const [loadingDocuments, setLoadingDocuments] = useState<boolean>(false);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      setLoadingDocuments(false);
      const res = await getDocumentsByOportunityId(oportunityId)
        .then()
        .catch((error) => {
          history.push("/error/500");
        });
      if (res) {
        setDataDocuments(res);
        setLoadingDocuments(true);
      }
    };
    if (oportunityId > 0) {
      fetchPost();
    }
  }, [reload]);

  return { dataDocuments, loadingDocuments };
};

export const useGetNoteByOpoId = (id: string, reaload: number) => {
  const [notes, setNotes] = useState<NoteOppModel[]>([]);
  const [loadingNote, setLoadingNote] = useState<boolean>(false);

  const history = useHistory();
  useEffect(() => {
    setLoadingNote(false);
    const fetchPost = async () => {
      try {
        const res = await getNotesByOpoId(id);
        if (res) {
          setNotes(res);
          setLoadingNote(true);
        }
      } catch (error) {
        history.push("/error/500");
        setLoadingNote(false);
      }
    };
    fetchPost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, reaload]);

  return { notes, loadingNote };
};

export const useCreateNoteOpp = () => {
  const [loadingCreateNote, setLoadingCreateNote] = useState<boolean>(false);
  const history = useHistory();
  const createNote = async (note: NoteOppModel, oportunityId: number) => {
    setLoadingCreateNote(false);
    try {
      await insertNoteOpp(note, oportunityId);
      setLoadingCreateNote(true);
    } catch (error) {
      history.push("/error/500");
      setLoadingCreateNote(false);
    }
  };
  return { createNote, loadingCreateNote };
};

export const useGetBusinessUnit = (code: string, reload: number) => {
  const [dataSelectUnit, setdataSelectUnit] = useState<any[]>([
    {
      value: "new",
      label: "Agregar Unidad de Negocio",
    },
  ]);

  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      const data = await getCatalog(code)
        .then()
        .catch((error) => {
          history.push("/error/500");
        });
      if (data) {
        const selectOptions = data.rows.map((option: any) => ({
          value: option.id,
          label: `${option.name} `,
        }));
        setdataSelectUnit((prevState) => [
          {
            value: "new",
            label: "Agregar Unidad de Negocio",
          },
          ...selectOptions,
        ]);
      }
    };
    if (reload) {
      fetchPost();
    }
  }, [reload]);

  return { dataSelectUnit };
};

export const useGetQuotationsByIdOportunity = (oportunityId: string) => {
  const [quotations, setQuotations] = useState<QuotationModel[]>([]);
  const [loadingQuotations, setLoadingQuotations] = useState<boolean>(false);
  const history = useHistory();
  useEffect(() => {
    setLoadingQuotations(true);
    const fetchPost = async () => {
      try {
        const res = await getQuotationsByIdOportunity(oportunityId);
        if (res) {
          setQuotations(res);
          setLoadingQuotations(false);
        }
      } catch (error) {
        history.push("/error/500");
      }
    };
    fetchPost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oportunityId]);

  return { quotations, loadingQuotations };
};

export const useCatalogPerCode = (code: string) => {
  const [dataSelect, setdataSelect] = useState<any[]>([]);

  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      const data = await getCatalogCode(code)
        .then()
        .catch((error) => {
          history.push("/error/500");
        });
      if (data) {
        let selectOptions: Option[] = [];
        selectOptions = data.map((option: any) => ({
          value: option.id,
          label: `${option.name} `,
          data: option,
        }));
        setdataSelect(selectOptions);
      }
    };
    fetchPost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  return { dataSelect };
};
