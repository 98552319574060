import { useState, useEffect } from 'react';
import { TemplateModel } from '../models/PlantillaModel';
import { useHistory } from 'react-router-dom';
import { getTemplate, getTemplateById, getTemplateFilter } from '../../../service/plantilla/Plantilla';

export const useGetAllTemplate = (
    realoadGrid: number,
    startIndex: number,
    endIndex: number,
    filters: any
) => {
    const [data, setData] = useState<TemplateModel[]>([]);
    const [count, setCount] = useState(1);
    const [loadingRequest, setLoadingRequest] = useState(0);
    const history = useHistory();
    useEffect(() => {
        const fetchPost = async () => {
            setLoadingRequest(0);
            const res = await getTemplate(startIndex, endIndex, filters)
                .then()
                .catch((error) => {
                    history.push('/error/500');
                });
            if (res) {
                setData(res.data);
                setCount(res.count);
                setLoadingRequest(1);
            }
        };
        fetchPost();
    }, [endIndex, realoadGrid]);

    return { data, count, loadingRequest };
};

export const useGetOne = (id: number) => {

    const initModel = {
        id: 0,
        nombre: '',
        encabezado: '',
        cuerpo: '',
        firma: '',
        asunto: '',

    };
    const [initValues, setInitValues] = useState<TemplateModel>(initModel);

    const history = useHistory();
    useEffect(() => {
        const fetchPost = async () => {
            const res = await getTemplateById(id)
                .then()
                .catch((error) => {
                    history.push('/error/500');
                });
            if (res) {
                setInitValues(res);

            }
        };
        if (id) {
            fetchPost();
        } else {
            setInitValues(initModel);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    return { initValues };
};

export const useGetAllTemplateFilter = (
    realoadGrid: number
) => {
    const [dataFilter, setDataFilter] = useState<TemplateModel[]>([]);
    const [count, setCount] = useState(1);
    const [loadingRequest, setLoadingRequest] = useState(0);
    const history = useHistory();
    useEffect(() => {
        const fetchPost = async () => {
            setLoadingRequest(0);
            const res = await getTemplateFilter()
                .then()
                .catch((error) => {
                    history.push('/error/500');
                });
            if (res) {
                setDataFilter(res.data);
                setCount(res.count);
                setLoadingRequest(1);
            }
        };
        fetchPost();
    }, [realoadGrid]);

    return { dataFilter, count, loadingRequest };
};
