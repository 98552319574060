import React, { useState, useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { UserModel } from '../../../modules/auth/models/UserModel';
import { RootState } from '../../../../setup/index';
import { KTSVG } from '../../../../_metronic/helpers';
import { useGetAll } from '../hooks/OportunidadHook';
import { useGetAllOportunidades } from '../hooks/OportunidadHook';
import { useHistory } from 'react-router-dom';
import { useGetDataBoard } from '../../home/hooks/HomeHook';
import { HomeFilterBoard } from '../models/OportunidadModel';
import { FilterBoard } from './FilterBoard';
import { CardOpportunities } from './CardOpportunities';
import CardList from './CardList';

const View: React.FC = () => {
  const user: UserModel = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  ) as UserModel;
  const userHasRole = (roles: string[]) => {
    const luser: UserModel = user;
    let hasRole: boolean = false;

    roles.forEach((element) => {
      if (luser?.roles?.some((e) => e.code === element)) {
        hasRole = true;
      }
    });
    return hasRole;
  };

  const [loadingGrid, setLoadingGrid] = useState(true);
  const [idRow, setIdRow] = useState(0);
  const [idDelete, setIdDelete] = useState(0);
  const [visivilityModal, setVisivilityModal] = useState(false);
  const [visivilityModalDetail, setVisivilityModalDetail] = useState(false);
  const [visivilityRemoveModal, setVisivilityRemoveModal] = useState(false);
  const [titleModal, setTitleModal] = useState('');
  const [reloadGrid, setReloadGrid] = useState(Math.random() * 40);
  // const [initFilterValues, setInitFilterValues] = useState({});
  const { dataOportunidades } = useGetAllOportunidades(reloadGrid, 0, 0);
  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const history = useHistory();
  const [secction, setSecction] = useState<string | 'BOARD' | 'LIST'>('LIST');
  const [initFilterValues, setInitFilterValues] = useState<HomeFilterBoard>({
    campo_id: null,
    valor: null,
  });
  const { dataBoard, boardFormatter } = useGetDataBoard(
    initFilterValues,
    reloadGrid
  );
  const { data, count, loadingRequest } = useGetAll(
    reloadGrid,
    pageSize,
    currentPage,
    initFilterValues
  );
  const totalPages = Math.ceil(count / pageSize)
    ? Math.ceil(count / pageSize)
    : 1;

  useEffect(() => {
    if (loadingRequest > 0) {
      setLoadingGrid(false);
    }
  }, [loadingRequest]);

  const addRegister = () => {
    setTitleModal('Registro oportunidad');
    setVisivilityModal(!visivilityModal);
  };
  const toggleMode = (mode: 'LIST' | 'BOARD') => {
    localStorage.setItem('viewMode', mode);
    setSecction(mode);
  };
  const editRegister = (id: number) => {
    setIdRow(id);
    setTitleModal('Editar oportunidad');
    setVisivilityModal(!visivilityModal);
  };
  const detailOportunidad = (id: number) => {
    setIdRow(id);
    setTitleModal('Detalle oportunidad');
    setVisivilityModalDetail(!visivilityModalDetail);
  };
  const onRefreshData = () => {
    setReloadGrid(Math.random() * 40);
  };
  const removeRegister = (id: number) => {
    setIdDelete(id);
    setVisivilityRemoveModal(!visivilityRemoveModal);
  };

  const onSearchFilter = (values: any) => {
    setLoadingGrid(true);
    if (values) {
      setInitFilterValues(values);
    } else {
      setInitFilterValues({
        campo_id: null,
        valor: null,
      });
    }
    setReloadGrid(Math.random() * 40);
  };
  const onCleanFilter = () => {
    setLoadingGrid(true);
    setInitFilterValues({
      campo_id: null,
      valor: null,
    });
    setReloadGrid(Math.random() * 40);
  };

  useEffect(() => {
    const storedMode = localStorage.getItem('viewMode');
    if (storedMode) {
      setSecction(storedMode);
    }
  }, []);

  return (
    <>
      <div className="row g-6 g-xl-9">
        <div className="col-xxl-12 col-xl-12 col-md-12 col-lg-12">
          <div className="card card-flush p-5">
            <div className="row">
              <div className="col-xxl-8 col-xl-8 col-md-8 col-lg-8">
                <ul className="nav nav-line-tabs nav-line-tabs-2x border-transparent fs-7 fw-bolder flex-nowrap">
                  <li className="nav-item cursor-pointer ">
                    <a
                      className={`nav-link me-6 ${
                        secction === 'LIST' ? 'text-active-primary active' : ''
                      } `}
                      onClick={() => {
                        toggleMode('LIST');
                      }}
                    >
                      <KTSVG
                        path="/media/icons/duotune/text/txt001.svg"
                        className="svg-icon-1 fs-7"
                      />
                      <span className="margin-left-05">Lista</span>
                    </a>
                  </li>
                  <li className="nav-item cursor-pointer">
                    <a
                      className={`nav-link me-6  ${
                        secction === 'BOARD' ? 'text-active-primary active' : ''
                      }`}
                      onClick={() => {
                        toggleMode('BOARD');
                      }}
                    >
                      <KTSVG
                        path="/media/icons/duotune/layouts/lay002.svg"
                        className="svg-icon-1 fs-7"
                      />
                      <span className="margin-left-05">Tablero</span>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-xxl-4 col-xl-4 col-md-4 col-lg-4 position-end">
                <div className="card-title center-items">
                  <div
                    className={
                      'btn btn-sm btn-icon btn-active-light-primary border-btn'
                    }
                    title={'Nueva oportunidad'}
                    onClick={() => {
                      history.push(`/oportunidades_add`);
                    }}
                  >
                    <KTSVG
                      path="/media/icons/duotune/abstract/abs011.svg"
                      className="svg-icon-5 svg-icon-gray-500 "
                    />
                  </div>
                </div>
                <div className="mr-05"></div>
                <FilterBoard
                  onCleanFilter={onCleanFilter}
                  onSearchFilter={onSearchFilter}
                  initFilterValues={initFilterValues}
                ></FilterBoard>
              </div>
            </div>
            <div className="row">
              {secction === 'BOARD' ? (
                <CardOpportunities
                  board={dataBoard}
                  boardFormatter={boardFormatter}
                  onRefreshData={onRefreshData}
                ></CardOpportunities>
              ) : null}
              {secction === 'LIST' ? (
                <CardList listData={dataBoard}></CardList>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { View };
