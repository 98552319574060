import { FC } from 'react';
import { PageTitle, PageLink } from '../../../../_metronic/layout/core';
import { View } from './components/View';
import { useLocation } from 'react-router-dom';

const CategoryPackagePage: FC = () => {
  let location = useLocation();
  const lastPathSegment: any = location.pathname.split('/').pop();
  const lastSegmentTitle = lastPathSegment
    ? lastPathSegment.charAt(0).toUpperCase() + lastPathSegment.slice(1)
    : '';
  const breadCrumbs: Array<PageLink> = [
    {
      title: 'Categoría',
      path: location.pathname,
      isSeparator: false,
      isActive: true,
    },
  ];
  return (
    <>
      <PageTitle breadcrumbs={breadCrumbs}>Paquetes</PageTitle>
      <View />
    </>
  );
};

export { CategoryPackagePage };
