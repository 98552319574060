import React, { useState } from 'react';
import { toAbsoluteUrl } from '../../../../_metronic/helpers';
import { UserModel } from '../../../modules/auth/models/UserModel';
type Props = {
  user: UserModel,
  onSelectedFilter: (option: number) => void;
};

const CardUser: React.FC<Props> = ({ user, onSelectedFilter }) => {
  const valuesFilter = {
    MES: 3,
    SEMANA: 2,
    DIA: 1
  };
  const [firstReload, setFirstReload] = useState<boolean>(true);

  return (
    <div className="card card-flush h-md-100">
      <div
        className="card-body d-flex flex-column justify-content-between mt-0 bgi-no-repeat bgi-size-cover bgi-position-x-center pb-0"
        style={{
          backgroundPosition: '100% 50%',
          backgroundImage: `url('${toAbsoluteUrl(
            '/media/illustrations/sketchy-1/bg02.png'
          )}')`,
        }}
      >
        <div className="mb-10" >
          <div className="fs-1 fw-bold text-gray-800  mb-13" >
            <span className="me-2">
              <span className='fs-015'>{`${user?.first_name} ${user?.father_last_name} ${user?.mother_last_name}`}</span>
              <div className="font-weight-mormal fs-5 timeline-content text-muted pl-3">
                {user.roles && user.roles[0].name}
              </div>
              <span className="position-relative d-inline-block text-primary">
                <span className="text-primary opacity-75-hover fs-015" >
                </span>
                <span className="position-absolute opacity-15 bottom-0 start-0 border-4 border-primary border-bottom w-100"></span>
              </span>
            </span>
          </div>

          <div className="row elements-end" >
            <div className="col-md-6 col-lg-6 col-xl-8 col-xxl-12 elements-end" >
              <a
                href="#/"
                className="btn btn-sm btn-light-primary fw-bold mr-05"
                onClick={() => {
                  onSelectedFilter(valuesFilter.MES);
                  setFirstReload(false);
                }}
              >
                Mes
              </a>
              <a
                href="#/"
                className="btn btn-sm btn-light-primary fw-bold mr-05"
                onClick={() => {
                  onSelectedFilter(valuesFilter.SEMANA);
                  setFirstReload(false);
                }}
              >
                Semana
              </a>
              <a
                href="#/"
                className={`btn btn-sm fw-bold ${firstReload ? 'btn-primary' : 'btn-light-primary'}`}
                onClick={() => {
                  onSelectedFilter(valuesFilter.DIA);
                  setFirstReload(false);
                }}
              >
                Día
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { CardUser };
