import React, { useState, useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { UserModel } from '../../../modules/auth/models/UserModel';
import { RootState } from '../../../../setup/index';
import { KTSVG } from '../../../../_metronic/helpers';
import { ListGrid } from './ListGrid';
import { Filter } from './Filter';
import { ConfigModal } from '../_modals/ConfigModal';
import { useGetAll } from '../../oportunidades/hooks/OportunidadHook';
import { DownloadFileExcel } from './DownloadExcel';

let fechaActual = new Date();
let fecha_inicio_init = new Date(
  fechaActual.getTime() - 7 * 24 * 60 * 60 * 1000
);
let fecha_fin_init = new Date();

const View: React.FC = () => {
  const user: UserModel = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  ) as UserModel;
  const userHasRole = (roles: string[]) => {
    const luser: UserModel = user;
    let hasRole: boolean = false;

    roles.forEach((element) => {
      if (luser?.roles?.some((e) => e.code === element)) {
        hasRole = true;
      }
    });
    return hasRole;
  };

  const [loadingGrid, setLoadingGrid] = useState(true);
  const [visivilityModal, setVisivilityModal] = useState(false);
  const [titleModal, setTitleModal] = useState('');
  const [reloadGrid, setReloadGrid] = useState(Math.random() * 40);
  const [initFilterValues, setInitFilterValues] = useState({
    fecha_inicio: fecha_inicio_init.toISOString().split('T')[0],
    fecha_fin: fecha_fin_init.toISOString().split('T')[0],
  });
  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const { data, count, loadingRequest } = useGetAll(
    reloadGrid,
    pageSize,
    currentPage,
    initFilterValues
  );
  const totalPages = Math.ceil(count / pageSize)
    ? Math.ceil(count / pageSize)
    : 1;

  useEffect(() => {
    if (loadingRequest > 0) {
      setLoadingGrid(false);
    }
  }, [loadingRequest]);

  const onSearchFilter = (values: any) => {
    setLoadingGrid(true);
    if (values) {
      setInitFilterValues(values);
    } else {
      setInitFilterValues({
        fecha_inicio: fecha_inicio_init.toISOString().split('T')[0],
        fecha_fin: fecha_fin_init.toISOString().split('T')[0],
      });
    }
    setReloadGrid(Math.random() * 40);
  };
  const onCleanFilter = () => {
    setLoadingGrid(true);
    setInitFilterValues({
      fecha_inicio: fecha_inicio_init.toISOString().split('T')[0],
      fecha_fin: fecha_fin_init.toISOString().split('T')[0],
    });
    setReloadGrid(Math.random() * 40);
  };

  const openConfig = () => {
    setTitleModal('Configuración ScoreCard');
    setVisivilityModal(!visivilityModal);
  };

  return (
    <>
      <div className="row gy-5 g-xl-8">
        <div className="col-xl-12">
          <div className="card-title flex-column">
            <div className="d-flex justify-content-end ">
              {userHasRole(['CMA']) && (
                <a
                  href="#/"
                  className="btn btn-sm btn-light-primary"
                  id="kt_toolbar_primary_button"
                  onClick={openConfig}
                >
                  <KTSVG
                    path="/media/icons/duotune/coding/cod001.svg"
                    className="svg-icon-5 svg-icon-gray-500 me-1"
                  />
                  Configuración de envío
                </a>
              )}
            </div>
          </div>
          <div className={`card card-xxl-stretch mb-5 mb-xl-12`}>
            <div className="card-header border-0 pt-5">
              <Filter
                initFilterValues={initFilterValues}
                onCleanFilter={onCleanFilter}
                onSearchFilter={onSearchFilter}
              />
            </div>
            <div className="mt-5">
              <DownloadFileExcel data={data}></DownloadFileExcel>
              <ListGrid
                data={data}
                loadingGrid={loadingGrid}
                count={count}
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={(pageNumber: number) => {
                  setLoadingGrid(true);
                  setCurrentPage(pageNumber);
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <ConfigModal
        id_row={1}
        title={titleModal}
        show={visivilityModal}
        handleClose={(updateGrid: boolean) => {
          if (updateGrid) {
            setLoadingGrid(true);
            setVisivilityModal(true);
          }
          setVisivilityModal(!visivilityModal);
          setReloadGrid(Math.random() * 40);
        }}
      ></ConfigModal>
    </>
  );
};

export { View };
