// este componente contendra el formulario, historial, seccion de notas, etc
// nos traemos de un archivo existente la base para crear el formulario y lo demas
import React, { useState, useEffect } from "react";
import { History } from "../../../../_metronic/helpers/components/History";
// import { useGetHistory, useInsertMessage } from "../hooks/ContactoHook";
// import { ContactoForm } from "./ContactoForm";
import { Toggle } from "../../../../_metronic/partials/layout/explore/Toggle";
import { HistoryModel } from "../../../../_metronic/helpers/models/HistoryModel";
import { dateFormatt } from "../../../../_metronic/helpers/FormatDate";
import { CollaboratorForm } from "./CollaboratorForm";

// declaramos el id es te se enviara cuando quiera editar un colaborador, si vengo desde el boton de Nuevo registro el id sera 0 o null
type Props = {
  id: string;
  section: string;
};

// ya estamos en el componente que contiene el form y lo demas
const ViewAddEdit: React.FC<Props> = ({ id, section }) => {
  const [reloadGrid, setReloadGrid] = useState<number>(Math.random() * 40);
  const [search, setSearch] = useState<string>("");
  //   const { data } = useGetHistory(reloadGrid, search, id);
  //   const { insertData, loadingInsert } = useInsertMessage();
  const [showHistory, setShowHistory] = useState<boolean>(true);
  const [showTimerHistory, setShowTimerHistory] = useState<boolean>(true);

  const saveMessage = (message: string) => {
    // insertData(message, id);
  };

  //   useEffect(() => {
  //     if (loadingInsert) {
  //       setReloadGrid(Math.random() * 40);
  //     }
  //   }, [loadingInsert]);

  const onRefreshData = () => {
    setReloadGrid(Math.random() * 40);
  };

  const historyStyle: React.CSSProperties = {
    transform: showHistory ? "none" : "translateX(110%)",
    transition: "transform 0.4s ease-in-out",
  };

  useEffect(() => {
    if (!showHistory) {
      setTimeout(() => {
        setShowTimerHistory(false);
      }, 400);
    }
  }, [showHistory]);

  useEffect(() => {
    const historyMode = localStorage.getItem("isOpenHistory") || "";
    setShowHistory(historyMode ? JSON.parse(historyMode) : true);
    setShowTimerHistory(historyMode ? JSON.parse(historyMode) : true);
  }, []);

  //   useEffect(() => {
  //     if (data.length) {
  //       data.map((item: HistoryModel) => {
  //         if (item.tipo.code === "HIST") {
  //           if (item?.campo?.code == "CTO_FECHA_NAC") {
  //             item.valor_nuevo =
  //               item.valor_nuevo && item.valor_nuevo != "vacio"
  //                 ? dateFormatt(item.valor_nuevo)
  //                 : "vacio";
  //             item.valor_anterior =
  //               item.valor_anterior && item.valor_anterior != "vacio"
  //                 ? dateFormatt(item.valor_anterior)
  //                 : "vacio";
  //           }
  //         }
  //         return item;
  //       });
  //     }
  //   }, [data]);

  return (
    <>
      <div className="d-flex flex-column flex-xl-row">
        <div className="flex-lg-row-fluid ">
          <div className="card mb-6 mb-xl-9">
            <div className="card-body">
              {/* le pasamos las propiedades que necesita el formulario */}
              <CollaboratorForm
                id_row={parseInt(id)}
                section=""
                onRefreshData={() => {}}
              ></CollaboratorForm>
              {/* tenemos que importar nuestro formulario
                Co */}
              {/* aqui ira nuesto componente Formulario colaboradores */}
            </div>
          </div>
        </div>
        <div style={historyStyle}>
          {Number(id) > 0 && showTimerHistory && (
            <>
              <div className="flex-column flex-lg-row-auto w-100 w-xl-400px ms-lg-10">
                {/* este es un componente generico lo cual incluye el registro de historial */}
                <History
                  data={[]}
                  onSaveMessage={saveMessage}
                  disabled={false}
                  onShow={() => {
                    setShowHistory(!showHistory);
                    const boolean = !showHistory;
                    localStorage.setItem("isOpenHistory", boolean.toString());
                  }}
                />
              </div>
            </>
          )}
        </div>
        {!showHistory && Number(id) > 0 && (
          <Toggle
            onShow={() => {
              setShowHistory(!showHistory);
              setShowTimerHistory(true);
              const boolean = !showHistory;
              localStorage.setItem("isOpenHistory", boolean.toString());
            }}
          />
        )}
      </div>
    </>
  );
};

export { ViewAddEdit };
