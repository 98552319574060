import { useState, useEffect } from 'react';
import {
    getVendedores,
    getVendedorById,
    getAllVendedores
} from '../../../service/vendedor/Vendedor';
import { VendedorModel } from '../models/VendedorModel';
import { useHistory } from 'react-router-dom';

export const useGetAll = (
    realoadGrid: number,
    startIndex: number,
    endIndex: number,
    filters: any
) => {
    const [data, setData] = useState<VendedorModel[]>([]);
    const [count, setCount] = useState(1);
    const [loadingRequest, setLoadingRequest] = useState(0);
    const history = useHistory();
    useEffect(() => {
        const fetchPost = async () => {
            setLoadingRequest(0);
            const res = await getVendedores(startIndex, endIndex, filters)
                .then()
                .catch((error) => {
                    history.push('/error/500');
                });
            if (res) {
                setData(res.data);
                setCount(res.count);
                setLoadingRequest(1);
            }
        };
        // fetchPost();
        if (realoadGrid) {
            fetchPost();
        }
    }, [endIndex, realoadGrid]);

    return { data, count, loadingRequest };
};
export const useGetAllVendedores = (
    realoadGrid: number,
) => {
    const [dataSellers, setDataSellers] = useState<VendedorModel[]>([]);
    const [count, setCount] = useState(1);
    const [loadingRequest, setLoadingRequest] = useState(0);
    const history = useHistory();
    useEffect(() => {
        const fetchPost = async () => {
            setLoadingRequest(0);
            const res = await getAllVendedores()
                .then()
                .catch((error) => {
                    history.push('/error/500');
                });
            if (res) {
                setDataSellers(res.data);
                setCount(res.count);
                setLoadingRequest(1);
            }
        };
        // fetchPost();
        if (realoadGrid) {
            fetchPost();
        }
    }, [realoadGrid]);

    return { dataSellers, count, loadingRequest };
};


export const useGetOne = (id: number) => {

    const initModel = {
        id: 0,
        nombre: '',
        apellido_paterno: '',
        apellido_materno: '',
        email: '',
        telefono: '',
        created_at: new Date(),
        tipoVendedor: {
            id: 0,
            name: '',
            code: ''
        },
        tipoVendedorId: ''
    };
    const [initValues, setInitValues] = useState<VendedorModel>(initModel);
    const history = useHistory();

    useEffect(() => {
        const fetchPost = async () => {
            const res = await getVendedorById(id)
                .then()
                .catch((error) => {
                    history.push('/error/500');
                });
            if (res) {
                setInitValues(res);
            }
        };
        if (id) {
            fetchPost();
        } else {
            setInitValues(initModel);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    return { initValues };
};

