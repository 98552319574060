import React, { useState, useEffect } from 'react';
import { KTSVG, SimpleAlert } from '../../../../../_metronic/helpers';
import { Modal } from 'react-bootstrap-v5';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useCreateCategoryPackages } from '../../hooks/PackagesHook';

type Props = {
  show: boolean;
  handleClose: (isNew: boolean) => void;
};

const CategorySchema = Yup.object().shape({
  nombre: Yup.string().required('Nombre de la categoría es requerido.'),
});

const CategoryModal: React.FC<Props> = ({ show, handleClose }) => {
  const [loading, setLoading] = useState(false);
  const {
    create: createCategory,
    loading: loadingCategory,
    errorRequest,
  } = useCreateCategoryPackages();

  const CategoryForm = useFormik({
    initialValues: {
      nombre: '',
    },
    validationSchema: CategorySchema,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      setLoading(true);
      createCategory(values);
    },
  });

  useEffect(() => {
    if (loadingCategory) {
      if (errorRequest) {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      } else {
        setTimeout(() => {
          setLoading(false);
          handleClose(true);
          CategoryForm.resetForm();
        }, 1000);
      }
    }
  }, [loadingCategory]);

  return (
    <Modal
      id="kt_modal_create"
      tabIndex={-1}
      aria-hidden="true"
      backdrop="static"
      dialogClassName="modal-dialog mw-600px"
      show={show}
      onHide={() => {
        CategoryForm.resetForm();
        handleClose(false);
      }}
    >
      <div className="modal-content">
        <div className="modal-header">
          <h2 className="text-muted">{'Nueva categoría'}</h2>
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            onClick={() => {
              CategoryForm.resetForm();
              handleClose(false);
            }}
          >
            <KTSVG
              className="svg-icon-2"
              path="/media/icons/duotune/arrows/arr061.svg"
            />
          </div>
        </div>
        <div className="modal-body mx-5 padding-top-00">
          <form
            id="kt_modal_generic_form"
            className="form"
            action="#"
            onSubmit={CategoryForm.handleSubmit}
          >
            <div className="row wh-0100 mb-3">
              <div className="col-xxl-12 col-xl-12 col-md-12 col-lg-12 mt-5">
                <input
                  className="form-control mb-2 w-100 border-0 padd-left input-name-form"
                  data-kt-element="input"
                  placeholder="Nombre *"
                  {...CategoryForm.getFieldProps('nombre')}
                ></input>
                {CategoryForm.touched.nombre && CategoryForm.errors.nombre && (
                  <div className="fv-plugins-message-container text-danger w-100">
                    <span role="alert">{CategoryForm.errors.nombre}</span>
                  </div>
                )}
              </div>
            </div>
            <div className="d-flex justify-content-end mt-10">
              <button
                type="reset"
                className="btn btn-light me-3"
                data-kt-users-modal-action="cancel"
                onClick={() => {
                  CategoryForm.resetForm();
                  handleClose(false);
                }}
              >
                Cancelar
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                data-kt-users-modal-action="submit"
                disabled={loading}
              >
                {!loading && <span className="indicator-label">Guardar</span>}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: 'block' }}
                  >
                    Espere por favor...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};
export { CategoryModal };
