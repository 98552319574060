import React, { useState, useEffect } from 'react';
import { PreloadView } from '../../../../_metronic/helpers';
import { History } from '../../../../_metronic/helpers/components/History';
import { useGetHistory, useInsertMessage } from '../hooks/OportunidadHook';
import { OpportunityForm } from './OpportunityForm';
import { Toggle } from '../../../../_metronic/partials/layout/explore/Toggle';
import { HistoryModel } from '../../contacto/models/HistoryModel';
import { dateFormatt } from '../../../../_metronic/helpers/FormatDate';
import { formattedCurrency } from '../../../../_metronic/helpers/FormatCurrency';

type Props = {
  id: string;
  section: string;
  etapa: string;
};

const ViewAddEdit: React.FC<Props> = ({ id, section, etapa }) => {
  const [reloadGrid, setReloadGrid] = useState<number>(Math.random() * 40);
  const [search, setSearch] = useState<string>('');
  const { data } = useGetHistory(reloadGrid, search, id);
  const { insertData, loadingInsert } = useInsertMessage();
  const [historyIsEdit, setHistoryFormIsEdit] = useState<boolean>(false);
  const [showHistory, setShowHistory] = useState<boolean>(true);
  const [showTimerHistory, setShowTimerHistory] = useState<boolean>(true);

  const saveMessage = (message: string) => {
    insertData(message, id);
  };

  useEffect(() => {
    if (loadingInsert) {
      setReloadGrid(Math.random() * 40);
    }
  }, [loadingInsert]);

  const onRefreshData = () => {
    setReloadGrid(Math.random() * 40);
  };
  const onDisabledHistory = (disabled: boolean) => {
    setHistoryFormIsEdit(disabled);
  };

  const historyStyle: React.CSSProperties = {
    transform: showHistory ? 'none' : 'translateX(110%)',
    transition: 'transform 0.4s ease-in-out',
  };

  useEffect(() => {
    if (!showHistory) {
      setTimeout(() => {
        setShowTimerHistory(false);
      }, 400);
    }
  }, [showHistory]);

  useEffect(() => {
    const historyMode = localStorage.getItem('isOpenHistory') || '';
    setShowHistory(historyMode ? JSON.parse(historyMode) : true);
    setShowTimerHistory(historyMode ? JSON.parse(historyMode) : true);
  }, []);

  useEffect(() => {
    if (data.length) {
      data.map((item: HistoryModel) => {
        if (item.tipo.code === 'HIST') {
          if (
            item?.campo?.code == 'FEC_APR_EST' ||
            item.campo.code == 'FEC_CIE_REAL'
          ) {
            item.valor_nuevo =
              item.valor_nuevo && item.valor_nuevo != 'vacio'
                ? dateFormatt(item.valor_nuevo)
                : 'vacio';
            item.valor_anterior =
              item.valor_anterior && item.valor_anterior != 'vacio'
                ? dateFormatt(item.valor_anterior)
                : 'vacio';
          } else if (item?.campo?.code == 'CAN_EST') {
            item.valor_nuevo = formattedCurrency(
              item.valor_nuevo && item.valor_nuevo != 'vacio'
                ? Number(item.valor_nuevo)
                : 0
            );
            item.valor_anterior = formattedCurrency(
              item.valor_anterior && item.valor_anterior != 'vacio'
                ? Number(item.valor_anterior)
                : 0
            );
          }
        }

        return item;
      });
    }
  }, [data]);

  return (
    <>
      <div className="d-flex flex-column flex-xl-row">
        <div className="flex-lg-row-fluid ">
          <div className="card mb-6 mb-xl-9">
            <div className="card-body">
              <OpportunityForm
                id_row={parseInt(id)}
                onRefreshData={onRefreshData}
                section={section}
                etapaSelected={etapa}
                onDisabledHistory={onDisabledHistory}
              ></OpportunityForm>
            </div>
          </div>
        </div>
        <div style={historyStyle}>
          {Number(id) > 0 && showTimerHistory && (
            <>
              <div className="flex-column flex-lg-row-auto w-100 w-xl-400px ms-lg-10">
                <History
                  data={data}
                  onSaveMessage={saveMessage}
                  disabled={historyIsEdit}
                  onShow={() => {
                    setShowHistory(!showHistory);
                    const boolean = !showHistory;
                    localStorage.setItem('isOpenHistory', boolean.toString());
                  }}
                />
              </div>
            </>
          )}
        </div>
        {!showHistory && Number(id) > 0 && (
          <Toggle
            onShow={() => {
              setShowHistory(!showHistory);
              setShowTimerHistory(true);
              const boolean = !showHistory;
              localStorage.setItem('isOpenHistory', boolean.toString());
            }}
          />
        )}
      </div>
    </>
  );
};

export { ViewAddEdit };
