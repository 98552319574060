import React, { useState, useEffect, useRef } from 'react';
import { PackageModel } from '../models/QuotationModel';
import { Link } from 'react-router-dom';
import { encryptId, formatPrice, KTSVG } from '../../../../_metronic/helpers';
import { formattedCurrency } from '../../../../_metronic/helpers/FormatCurrency';

type Props = {
  items: PackageModel[];
  onRefreshData: () => void;
  active: boolean;
  isEdit: boolean;
  categoriId: number;
};

const PackageList: React.FC<Props> = ({
  items,
  active,
  isEdit,
  categoriId,
  onRefreshData,
}) => {
  const [taskMemo, setTaskMemo] = useState<PackageModel[]>(items);
  const [reloadGrid, setReloadGrid] = useState<number>(Math.random() * 40);
  const inputRef = useRef<HTMLInputElement>(null);
  const [showModalSupplier, setShowModalSupplier] = useState<boolean>(false);
  const [idSection, setIdSection] = useState<number>(0);

  useEffect(() => {
    setTaskMemo(items);
  }, [items]);

  return (
    <>
      <div className="table-responsive min-h-200px me-n5 pe-5 scroll-y">
        <table className="table table-border table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 me-n5 pe-5">
          <thead>
            <tr className="text-gray-400 fs-7">
              <th className="w-200px fw-bolder">Nombre</th>
              <th className="w-100px fw-bolder">Foto</th>
              {/* <th className="w-200px fw-bolder">Unidad de moneda</th> */}
              <th className="w-200px fw-bolder">Clasificación</th>
              <th className="w-150px fw-bolder">Precio venta</th>
              <th className="w-150px fw-bolder">Impuesto</th>
              <th className="w-150px fw-bolder">Precio venta final</th>
            </tr>
          </thead>
          <tbody className="">
            {taskMemo.map((row, index) => (
              <tr key={row.id}>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start w-100 flex-column fs-012 font-weight-mormal text-muted">
                      <Link
                        to={`/cotizaciones/paquetes/edit/${encryptId(
                          row.id.toString()
                        )}`}
                      >
                        {`${row.nombre}`}
                      </Link>
                    </div>
                  </div>
                </td>
                {/* {row.package_photo ? <>si hay imagen</> : <>no hay imagen</>} */}
                <td>
                  <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column fs-012">
                      <div className="symbol symbol-25px tooltip-soft">
                        {row.package_photo ? (
                          <img
                            src={`${process.env.REACT_APP_PUBLIC}paquete/${row?.package_photo}`}
                            title={row.nombre}
                            crossOrigin="anonymous"
                            className="symbol-label fs-5 "
                            alt="user"
                          />
                        ) : (
                          <KTSVG
                            path="/media/icons/duotune/general/gen006.svg"
                            className="symbol-label fs-5 "
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </td>
                {/* <td>
                  <div className="d-flex align-items-center">
                    <div className="d-flex text-center flex-column">
                      <div className="text-gray-700">
                        {row.moneda ? row.moneda?.code : 'Sin unidad de moneda'}
                      </div>
                    </div>
                  </div>
                </td> */}
                <td>
                  <div className="d-flex align-items-center">
                    <div className="d-flex text-center flex-column">
                      <div className="text-gray-700">
                        {row.clasificacion
                          ? row.clasificacion.name
                          : 'Sin unidad de moneda'}
                      </div>
                    </div>
                  </div>
                </td>
                <td style={{ minWidth: '150px', overflowX: 'auto' }}>
                  <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column">
                      <div>
                        <span className="text-gray-700 fw-bolder">
                          {`${formatPrice(
                            Number(row.precio_sin_impuestos) || 0,
                            'es-MX',
                            'MXN',
                            2,
                            2
                          )}`}
                        </span>
                        <span className="text-gray-600 ms-3 fs-8">
                          {row.moneda
                            ? `/ ${row.moneda.code}`
                            : 'Sin unidad de moneda'}
                        </span>
                      </div>
                    </div>
                  </div>
                </td>
                <td style={{ minWidth: '150px', overflowX: 'auto' }}>
                  <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column">
                      <div>
                        <span className="text-gray-700 fw-bolder">
                          {`${formatPrice(
                            Number(row.impuestos) || 0,
                            'es-MX',
                            'MXN',
                            2,
                            2
                          )}`}
                        </span>
                        <span className="text-gray-600 ms-3 fs-8">
                          {row.moneda
                            ? `/ ${row.moneda.code}`
                            : 'Sin unidad de moneda'}
                        </span>
                      </div>
                    </div>
                  </div>
                </td>
                <td style={{ minWidth: '150px', overflowX: 'auto' }}>
                  <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column">
                      <div>
                        <span className="text-gray-700 fw-bolder">
                          {`${formatPrice(
                            Number(row.precio_unitario) || 0,
                            'es-MX',
                            'MXN',
                            2,
                            2
                          )}`}
                        </span>
                        <span className="text-gray-600 ms-3 fs-8">
                          {row.moneda
                            ? `/ ${row.moneda.code}`
                            : 'Sin unidad de moneda'}
                        </span>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export { PackageList };
