import { FC } from "react";
import { useParams } from "react-router-dom";
import { ViewAddEdit } from "./components/ViewAddEdit";
import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import { decryptId } from "../../../_metronic/helpers";

const CollaboratorAddEdit: FC = () => {
  const { id, section } = useParams<{ id: string; section?: string }>();
  const idRegister = decryptId(id);
  // estas son las migajas para navegar cuando estemos en el form y le demos click a colaboradores en las migajas nos lleva ala vista principal
  // esta es la vista principal para el formulario y lo demas componentes, el view contiene el FilterQuotationData, grid y cards
  const breadCrumbs: Array<PageLink> = [
    {
      title: "Colaboradores",
      path: "/recursos_humanos/colaboradores",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
  ];
  return (
    <>
      <PageTitle breadcrumbs={breadCrumbs}>
        {idRegister ? "Editar colaborador" : "Nuevo colaborador"}
      </PageTitle>
      <ViewAddEdit
        id={idRegister ? idRegister : "0"}
        section={section ? section : ""}
      />
    </>
  );
};

export { CollaboratorAddEdit };
