import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { formattedCurrency } from '../FormatCurrency';

interface BarChartProps {
  series: any[];
  categories: string[];
  title: string;
  description: string;
  position: boolean;
  montos: any[];
  showSubtitle: boolean;
  isPrice: boolean;
}

const BarChart: React.FC<BarChartProps> = ({
  series,
  categories,
  title,
  description,
  position,
  montos,
  showSubtitle,
  isPrice,
}) => {
  const options = {
    chart: {
      type: 'bar' as const,
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val: any, opts: any) {
        return isPrice ? formattedCurrency(val) : val;
      },
    },
    xaxis: {
      categories: categories,
      labels: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        distributed: true,
        horizontal: position,
        dataLabels: {
          total: {
            enabled: true,
            offsetX: 0,
            style: {
              fontSize: '13px',
              fontWeight: 900,
            },
            formatter: function (val: any, opts: any) {
              return String(
                montos[opts.dataPointIndex] ? montos[opts.dataPointIndex] : ''
              );
            },
          },
        },
      },
    },
    legend: {
      show: false,
    },
    // colors: [
    //   "#58D68D",
    //   "#F4D03F"],
    theme: {
      monochrome: {
        enabled: true,
        color: '#2983FF',
        shadeTo: 'light' as const,
        shadeIntensity: 0.65,
      },
    },
    tooltip: {
      enabled: true,
      // x: {
      //   formatter: function (val) {
      //     return 'Valor X: ' + val;
      //   },
      // },
      y: {
        formatter: function (val: any) {
          return isPrice ? formattedCurrency(val) : val;
        },
      },
    },
    grid: {
      show: false,
    },
    yaxis: {
      labels: {
        show: true,
      },
    },
  };

  return (
    <>
      <div className={`card mb-0 mb-xxl-8 h-100`}>
        <div className="card-header border-0 pt-5">
          <div className="card-title align-items-start w-100 d-flex">
            <span
              className={
                description !== ''
                  ? 'fw-bolder fs-3 mb-1 w-50 d-flex'
                  : 'fw-bolder fs-3 mb-1 w-100 d-flex'
              }
            >
              {title}
            </span>
            {description !== '' && (
              <span className="text-primary fw-bolder fs-3 mb-1 w-50 text-end ">
                <span className="position-relative d-inline-block text-primary">
                  <span className="text-primary opacity-75-hover">
                    {' '}
                    {description}
                  </span>
                  <span className="position-absolute opacity-15 bottom-0 start-0 border-4 border-primary border-bottom w-100"></span>
                </span>
              </span>
            )}
          </div>
        </div>
        <div className="card-body">
          {showSubtitle ? (
            <div className="row">
              <div
                className="col-md-6 col-xs-6 col-lg-6"
                style={{ display: 'flex', justifyContent: 'end' }}
              >
                Monto
              </div>
              <div
                className="col-md-6 col-xs-6 col-lg-6"
                style={{ display: 'flex', justifyContent: 'end' }}
              >
                Total de ventas
              </div>
            </div>
          ) : null}
          {/* begin::Chart */}
          <ReactApexChart
            options={options}
            type="bar"
            height={200}
            series={series}
          />
          {/* end::Chart */}
        </div>
        {/* end::Body */}
      </div>
    </>
  );
};

export default BarChart;
