import React, { useState } from 'react';
import { KTSVG } from '../../../../../_metronic/helpers';
import { Modal } from 'react-bootstrap-v5';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { createBusinessOportunity } from '../../../../service/oportunidad/Oportunidad';

type Props = {
    show: boolean;
    handleClose: (isNew: boolean, newDpto: any) => void;
};

const UnitSchema = Yup.object().shape({
    name: Yup.string().required('Este campo es requerido.'),
});

const BussinesUnitModal: React.FC<Props> = ({ show, handleClose }) => {
    const [loading, setLoading] = useState(false);

    const formUnit = useFormik({
        initialValues: {
            name: '',
        },
        validationSchema: UnitSchema,
        enableReinitialize: true,
        onSubmit: (values, { setSubmitting }) => {
            setLoading(true);
            setTimeout(() => {
                createBusinessOportunity(values)
                    .then((res) => {
                        const {
                            data: { message, doc: {
                                data: {
                                    id,
                                    name
                                }
                            } },
                        } = res;
                        toast.success(message, {
                            position: 'top-right',
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: 'light',
                        });
                        setLoading(false);
                        formUnit.resetForm();
                        handleClose(true, {
                            value: id,
                            label: name
                        });
                    })
                    .catch((err) => {
                        let resMessageToast: string = '';
                        const {
                            data: { message, details = null },
                        } = err.response;
                        resMessageToast = `${message}`;
                        setLoading(false);
                        setSubmitting(false);
                        toast.error(resMessageToast, {
                            position: 'top-right',
                            autoClose: 4000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: 'light',
                        });
                    });

            }, 1000);
        },
    });

    return (
        <Modal
            id="kt_modal_create"
            tabIndex={-1}
            aria-hidden="true"
            backdrop="static"
            dialogClassName="modal-dialog mw-600px"
            show={show}
            onHide={() => {
                formUnit.resetForm();
                handleClose(false, 0);
            }}
        >
            <div className="modal-content">
                <div className="modal-header">
                    <h2 className='text-muted'>{'Datos de la Unidad de negocio'}</h2>
                    <div
                        className="btn btn-sm btn-icon btn-active-color-primary"
                        onClick={() => {
                            formUnit.resetForm();
                            handleClose(false, 0);
                        }}
                    >
                        <KTSVG
                            className="svg-icon-2"
                            path="/media/icons/duotune/arrows/arr061.svg"
                        />
                    </div>
                </div>
                <div className="modal-body scroll-y mx-5 padding-top-00">
                    <form
                        id="kt_modal_generic_form"
                        className="form"
                        action="#"
                        onSubmit={formUnit.handleSubmit}
                    >

                        <div className="row wh-0100 mb-3">
                            <div className="col-xxl-12 col-xl-12 col-md-12 col-lg-12">
                                <input
                                    className="form-control mb-2 w-100 border-0 padd-left input-name-form"
                                    data-kt-element="input"
                                    placeholder="Nombre *"
                                    {...formUnit.getFieldProps('name')}
                                ></input>
                                {formUnit.touched.name &&
                                    formUnit.errors.name && (
                                        <div className="fv-plugins-message-container text-danger w-100">
                                            <span role="alert">
                                                {formUnit.errors.name}
                                            </span>
                                        </div>
                                    )}
                            </div>
                        </div>
                        <div className="d-flex justify-content-end mt-10">
                            <button
                                type="reset"
                                className="btn btn-light me-3"
                                data-kt-users-modal-action="cancel"
                                onClick={() => {
                                    formUnit.resetForm();
                                    handleClose(false, 0);
                                }}
                            >
                                Cancelar
                            </button>
                            <button
                                type="submit"
                                className="btn btn-primary"
                                data-kt-users-modal-action="submit"
                                disabled={loading}
                            >
                                {!loading && (
                                    <span className="indicator-label">
                                        Guardar
                                    </span>
                                )}
                                {loading && (
                                    <span
                                        className="indicator-progress"
                                        style={{ display: 'block' }}
                                    >
                                        Espere por favor...
                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                )}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </Modal>
    );
};
export { BussinesUnitModal };
