import { FC } from 'react';
import { PageTitle } from '../../../../_metronic/layout/core';
import { View } from './components/View';
import { useParams } from 'react-router-dom';
import { decryptId } from '../../../../_metronic/helpers';

const CotizacionPreviewPdf: FC = () => {
  const { id } = useParams<{ id: string }>();
  const { nombre } = useParams<{ nombre: string }>();
  const quotationId = decryptId(id);

  return (
    <>
      <PageTitle breadcrumbs={[]}>Generar PDF</PageTitle>
      <View 
      id={quotationId ? Number(quotationId) : 0} 
      />
    </>
  );
};

export { CotizacionPreviewPdf };
