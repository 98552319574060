/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef } from 'react';
import ApexCharts, { ApexOptions } from 'apexcharts';

type Props = {
  type: string;
  className: string;
  title: string;
  description: string;
  height: number;
  series: any[];
  labels: string[];
  legend: boolean;
};

const GenericPieChart: React.FC<Props> = ({
  className,
  title,
  description,
  height,
  series,
  labels,
  type,
  legend,
}) => {
  const chartRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!chartRef.current) {
      return;
    }

    const chart = new ApexCharts(
      chartRef.current,
      getChartOptions(height, series, labels, type, legend)
    );
    if (chart) {
      chart.render();
    }

    return () => {
      if (chart) {
        chart.destroy();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef, series]);

  return (
    <div className={`card ${className} h-100`}>
      <div className="card-header border-0 pt-5">
        <div className="card-title align-items-start w-100 d-flex">
          <span
            className={
              description !== ''
                ? 'fw-bolder fs-3 mb-1 w-50 d-flex'
                : 'fw-bolder fs-3 mb-1 w-100 d-flex'
            }
          >
            {title}
          </span>
          {description !== '' && (
            <span className="text-primary fw-bolder fs-3 mb-1 w-50 text-end ">
              <span className="position-relative d-inline-block text-primary">
                <span className="text-primary opacity-75-hover">
                  {' '}
                  {description}
                </span>
                <span className="position-absolute opacity-15 bottom-0 start-0 border-4 border-primary border-bottom w-100"></span>
              </span>
            </span>
          )}
        </div>
      </div>
      <div className="card-body">
        <div ref={chartRef} id="kt_charts_widget_6_chart"></div>
      </div>
    </div>
  );
};

export { GenericPieChart };

function getChartOptions(
  height: number,
  _series: any | null,
  _labels: string[],
  _type: any,
  _legend: boolean
): ApexOptions {
  return {
    series: _series,
    chart: {
      height: height,
      type: _type,
    },
    labels: _labels,
    theme: {
      monochrome: {
        enabled: true,
        color: '#2983FF',
        shadeTo: 'light',
        shadeIntensity: 0.9,
      },
    },
    stroke: {
      show: false,
    },
    plotOptions: {
      pie: {
        donut: {
          size: '45%',
        },
        // dataLabels: {
        //   offset: -5,
        // },
      },
    },
    dataLabels: {
      dropShadow: {
        enabled: false,
      },
    },
    legend: {
      show: _legend,
      onItemClick: {
        toggleDataSeries: false,
      },
    },
    tooltip: {
      enabled: true,
      // x: {
      //   formatter: function (val) {
      //     return 'Valor X: ' + val;
      //   },
      // },
      y: {
        formatter: function (val) {
          return val + '%';
        },
      },
    },
  };
}
