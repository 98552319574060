import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  encryptId,
  formatPrice,
  formatPriceNew,
} from '../../../../../_metronic/helpers';

type Props = {
  board: any;
  onRefreshData: () => void;
};

const CardsPackages: FC<Props> = ({ board, onRefreshData }) => {
  const history = useHistory();
  const itemsPerPage = 9;
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(board.length / itemsPerPage);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, board.length);

  const onPageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  useEffect(()=>{
    
    setCurrentPage(1)
  
}, [onRefreshData])

  return (
    <>
      <div className="container mt-5">
        <div className="row">
          {board.slice(startIndex, endIndex).map((item: any, index: number) => {
            return (
              <div
                key={index}
                className="col-md-6 col-lg-6 col-xxl-4 col-xl-6 mb-3"
              >
                <div
                  className="card border h-100 cursor-pointer"
                  style={{ minHeight: '100px' }}
                  onClick={() => {
                    history.push(
                      `/cotizaciones/paquetes/edit/${encryptId(
                        item.id.toString()
                      )}`
                    );
                  }}
                >
                  <div className="card-body d-flex  align-items-center  pt-1 pb-0">
                    <div className="me-5 w-25">
                      {item.package_photo ? (
                        <img
                          src={`${process.env.REACT_APP_PUBLIC}paquete/${item?.package_photo}`}
                          title={item.nombre}
                          crossOrigin="anonymous"
                          className="w-100"
                          alt="paquete"
                          style={{ borderRadius: '3px' }}
                        />
                      ) : (
                        <img
                          src={
                            process.env.PUBLIC_URL + '/media/avatars/photo.png'
                          }
                          alt=""
                          className="w-100"
                        />
                      )}
                    </div>

                    <div className="min-h-150px w-75">
                      <div className="fw-bolder text-gray-700 fs-4 mt-2 cursor-pointer w-100  large-text">
                        {item.nombre}
                      </div>
                      <div className="">
                        <div className="text-gray-500 fw-bolder fs-7 mt-2">
                          Precio venta
                        </div>
                        <div className="text-gray-500 fs-5">
                          <span
                            className="fs-6 fw-bold"
                            style={{ color: '#1B84FF' }}
                          >
                            {`${formatPrice(
                              item.precio_sin_impuestos || 0,
                              'es-MX',
                              'MXN',
                              2,
                              2
                            )}`}
                          </span>
                          <span className="fs-8 mt-3 ms-2">
                            {item.moneda && (
                              <>
                                /{' '}
                                <span data-kt-element="period">
                                  {item.moneda.code}
                                </span>
                              </>
                            )}
                          </span>
                        </div>
                        <div className="text-gray-500  fs-7 mt-2">
                          <span className="fw-bolder">Precio venta final</span>
                          <span className="fs-8 mt-3 ms-2">
                            {Number(item.impuestos) ? (
                              <>{'( c/impuesto)'}</>
                            ) : (
                              <>{''}</>
                            )}
                          </span>
                        </div>
                        <div className="text-gray-500  fs-5">
                          <span
                            className="fs-6 fw-bold"
                            style={{ color: '#1B84FF' }}
                          >
                            {`${formatPrice(
                              item.precio_unitario || 0,
                              'es-MX',
                              'MXN',
                              2,
                              2
                            )}`}
                          </span>
                          <span className="fs-8 mt-3 ms-2">
                            {item.moneda && (
                              <>
                                /{' '}
                                <span data-kt-element="period">
                                  {item.moneda.code}
                                </span>
                              </>
                            )}
                          </span>
                        </div>
                        <div style={{ minHeight: '57px' }}></div>
                      </div>
                    </div>
                  </div>
                  <span className="position-absolute opacity-15 bottom-0 start-0 border-4 border-primary border-bottom w-100"></span>
                </div>
              </div>
              // <div
              //   key={index}
              //   className="col-md-6 col-lg-6 col-xxl-4 col-xl-6 mb-3"
              // >
              //   <div
              //     className="card border h-100 borders-card-board cursor-pointer"
              //     style={{ minHeight: "100px" }}
              //     onClick={() =>
              //       history.push(
              //         `/cotizaciones/paquetes/edit/${encryptId(
              //           item.id.toString()
              //         )}`
              //       )
              //     }
              //   >
              //     <div className="card-body d-flex align-items-center pt-1 pb-0">
              //       <div style={{ minWidth: "100px" }}>
              //         {item.package_photo ? (
              //           <img
              //             src={`${process.env.REACT_APP_PUBLIC}paquete/${item?.package_photo}`}
              //             title={item.nombre}
              //             crossOrigin="anonymous"
              //             className="h-100px me-4"
              //             alt="paquete"
              //             style={{ borderRadius: "3px" }}
              //           />
              //         ) : (
              //           <img
              //             src={
              //               process.env.PUBLIC_URL + "/media/avatars/photo.png"
              //             }
              //             alt=""
              //             className="h-100px me-4"
              //           />
              //         )}
              //       </div>
              //       <div className="d-flex flex-column flex-grow-1 py-2 py-lg-13 large-text">
              //         <a className="fw-bolder text-dark fs-4 mb-2 text-hover-primary large-text cursor-pointer">
              //           {item.nombre}
              //         </a>
              //         <span className="fw-bold text-success fs-5">{`${formatPriceNew(
              //           item.precio_unitario ?? 0,
              //           "es-MX",
              //           "MXN",
              //           2,
              //           2
              //         )}`}</span>
              //       </div>
              //     </div>
              //     <span className="position-absolute opacity-15 bottom-0 start-0 border-4 border-primary border-bottom w-100"></span>
              //   </div>
              // </div>
            );
          })}
        </div>
        <div className="row justify-content-center">
          <nav aria-label="Navegación de página">
            <ul className="pagination">
              {Array.from({ length: totalPages }, (_, i) => (
                <li
                  key={i}
                  className={`page-item ${
                    currentPage === i + 1 ? 'active' : ''
                  }`}
                >
                  <button
                    className="page-link"
                    onClick={() => onPageChange(i + 1)}
                  >
                    {i + 1}
                  </button>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
};

export { CardsPackages };
