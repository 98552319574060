import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useFormik } from 'formik';
// import { MultiSelect } from 'primereact/multiselect';
import { MultiSelect } from 'react-multi-select-component';

type Props = {
  initFilterValues: any;
  onCleanFilter: () => void;
  onSearchFilter: (values: any) => void;
  dataOportunidades: any;
  cuentas: any;
  subcuentas: any;
  filterOpo: (v: [], t: number) => void;
};

const Filter: React.FC<Props> = ({
  initFilterValues,
  onSearchFilter,
  onCleanFilter,
  dataOportunidades,
  cuentas,
  subcuentas,
  filterOpo,
}) => {
  const [filterValues, setFilterValues] = useState(initFilterValues);
  const [selectedOption, setSelectedOption] = useState<[]>([]);
  const [selectedOptionCuenta, setSelectedOptionCuenta] = useState<[]>([]);
  const [selectedOptionSubCuenta, setSelectedOptionSubCuenta] = useState<[]>(
    []
  );

  interface Option {
    value: number;
    label: string;
  }
  // const [oportunidadesData, setOportunidadesData] = useState<
  //   { value: any; label: string }[] | undefined
  // >();
  const [cuentaData, setCuentaData] = useState<Option[]>([]);
  const [subcuentaData, setSubCuentaData] = useState<Option[]>([]);
  const [oportunidadesData, setOportunidadesData] = useState<Option[]>([]);
  // const [subcuentaData, setSubCuentaData] = useState<
  //   { value: any; label: string }[] | undefined
  // >();

  useEffect(() => {
    if (initFilterValues) {
      setFilterValues(initFilterValues);
    }
  }, [initFilterValues]);

  useEffect(() => {
    if (dataOportunidades) {
      const selectOptions = dataOportunidades.map((option: any) => ({
        value: option?.contacto?.id,
        label: `${option.nombre} `,
      }));
      setOportunidadesData(selectOptions);
    }
  }, [dataOportunidades]);

  useEffect(() => {
    if (cuentas) {
      const selectOptions = cuentas.map((option: any) => ({
        value: option.id,
        label: `${option.nombre} `,
      }));
      setCuentaData(selectOptions);
    }
  }, [cuentas]);

  useEffect(() => {
    if (subcuentas) {
      const selectOptions = subcuentas.map((option: any) => ({
        value: option.id,
        label: `${option.nombre} `,
      }));
      setSubCuentaData(selectOptions);
    }
  }, [subcuentas]);

  // const handleSelect = (selectedOption: any, code: string) => {
  //   switch (code) {
  //     case 'CUE':
  //       setSelectedOptionCuenta(selectedOption);
  //       setSelectedOptionSubCuenta(null);
  //       setSelectedOption(null);
  //       filterOpo(selectedOption, 1);
  //       break;
  //     case 'SUB':
  //       setSelectedOptionSubCuenta(selectedOption);
  //       filterOpo(selectedOption, 2);
  //       setSelectedOption(null);

  //       break;
  //     case 'OPO':
  //       setSelectedOption(selectedOption);
  //       filterOpo(selectedOption, 3);
  //       break;

  //     default:
  //       break;
  //   }
  // };

  const formFilter = useFormik({
    initialValues: filterValues,
    enableReinitialize: true,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      onSearchFilter(values);
    },
  });

  const handleSelect = (selectedOption: any, code: string) => {
    switch (code) {
      case 'CUE':
        setSelectedOptionCuenta(selectedOption);
        setSelectedOptionSubCuenta([]);
        setSelectedOption([]);
        filterOpo(selectedOption, 1);
        break;
      case 'SUB':
        setSelectedOptionSubCuenta(selectedOption);
        filterOpo(selectedOption, 2);
        setSelectedOption([]);

        break;
      case 'OPO':
        setSelectedOption(selectedOption);
        filterOpo(selectedOption, 3);
        break;

      default:
        break;
    }
  };

  return (
    <>
      <div className="mt-5" style={{ width: '100%' }}>
        <form
          style={{ width: '100%' }}
          id="kt_modal_generic_form"
          className="form"
          action="#"
          onSubmit={formFilter.handleSubmit}
        >
          <div className="row">
            <div className="col-md-4">
              <label className="form-label fw-bold">Cuenta </label>

              <div>
                <MultiSelect
                  options={cuentaData}
                  value={selectedOptionCuenta}
                  onChange={(event: any) => {
                    handleSelect(event, 'CUE');
                  }}
                  labelledBy="Seleccione"
                  overrideStrings={{
                    selectAll: 'Selecionar todo',
                    search: 'Buscar',
                    noOptions: 'Sin resultados',
                    allItemsAreSelected:
                      'Todos los elementos están seleccionados',
                    selectSomeItems: 'Seleccione',
                  }}
                />
                {/* <Select
                  placeholder={'Seleccione'}
                  isMulti
                  options={cuentaData}
                  value={selectedOptionCuenta}
                  onChange={(event) => {
                    handleSelect(event, 'CUE');
                  }}
                  noOptionsMessage={() => {
                    return <span>No hay cuentas</span>;
                  }}
                /> */}
                {/* <MultiSelect
                  placeholder="Seleccione"
                  value={selectedOptionCuenta}
                  options={cuentaData}
                  onChange={(e) => handleSelect(e.value, 'CUE')}
                  style={{ width: '100%' }} // Estilo opcional
                  multiple
                /> */}
              </div>
            </div>
            <div className="col-md-4 ">
              <label className="form-label fw-bold">Subcuenta </label>

              <div>
                {/* <MultiSelect
                  placeholder="Seleccione"
                  value={selectedOptionSubCuenta}
                  options={subcuentaData}
                  onChange={(e) => handleSelect(e.value, 'SUB')}
                  style={{ width: '100%' }} // Estilo opcional
                  multiple
                /> */}

                <MultiSelect
                  options={subcuentaData}
                  value={selectedOptionSubCuenta}
                  onChange={(event: any) => {
                    handleSelect(event, 'SUB');
                  }}
                  labelledBy="Seleccione"
                  overrideStrings={{
                    selectAll: 'Selecionar todo',
                    search: 'Buscar',
                    noOptions: 'Sin resultados',
                    allItemsAreSelected:
                      'Todos los elementos están seleccionados',
                    selectSomeItems: 'Seleccione',
                  }}
                />
                {/* <Select
                  placeholder={'Seleccione'}
                  isMulti
                  options={subcuentaData}
                  value={selectedOptionSubCuenta}
                  onChange={(event) => {
                    handleSelect(event, 'SUB');
                  }}
                  noOptionsMessage={() => {
                    return <span>No hay subcuentas</span>;
                  }}
                /> */}
              </div>
            </div>
            <div className="col-md-4">
              <label className="form-label fw-bold">Oportunidad </label>

              <div>
                {/* <Select
                  placeholder={'Seleccione'}
                  isMulti
                  options={oportunidadesData}
                  value={selectedOption}
                  onChange={(event) => {
                    handleSelect(event, 'OPO');
                  }}
                  noOptionsMessage={() => {
                    return <span>No hay oportunidades</span>;
                  }}
                /> */}
                <MultiSelect
                  options={oportunidadesData}
                  value={selectedOption}
                  onChange={(event: any) => {
                    handleSelect(event, 'OPO');
                  }}
                  labelledBy="Seleccione"
                  overrideStrings={{
                    selectAll: 'Selecionar todo',
                    search: 'Buscar',
                    noOptions: 'Sin resultados',
                    allItemsAreSelected:
                      'Todos los elementos están seleccionados',
                    selectSomeItems: 'Seleccione',
                  }}
                />
                {/* <MultiSelect
                  placeholder="Seleccione"
                  value={selectedOption}
                  options={oportunidadesData}
                  onChange={(e) => handleSelect(e.value, 'OPO')}
                  style={{ width: '100%' }} // Estilo opcional
                  multiple
                /> */}
              </div>
            </div>
            {/* <div className="col-md-12">
              <div className="center-data">
                <div>
                  <button
                    type="reset"
                    className="btn btn-sm btn-light-primary mt-3"
                    data-kt-menu-dismiss="true"
                    onClick={() => {
                      formFilter.resetForm();
                      onCleanFilter();
                      setSelectedOption(null);
                      setSelectedOptionCuenta(null);
                      setSelectedOptionSubCuenta(null);
                    }}
                  >
                    Limpiar
                  </button>
                </div>
              </div>
            </div> */}
          </div>
        </form>
      </div>
    </>
  );
};

export { Filter };
