import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  getNoteServiceById,
  updateNoteService,
} from "../../../service/notes/Notes";
import { NoteModel } from "../models/NoteModel";

export const useGetNoteServiceId = (id: string, reload: number) => {
  const note: NoteModel = {
    id: 0,
    nombre: "",
    descripcion: "",
  };
  const [noteInit, setNoteInit] = useState<NoteModel>(note);

  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      const res = await getNoteServiceById(id)
        .then()
        .catch((error) => {
          history.push("/error/500");
        });
      if (res) {
        setNoteInit(res);
      }
    };
    if (id) {
      fetchPost();
    } else {
      setNoteInit(note);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, reload]);

  return { noteInit };
};

export const useEditNoteService = () => {
  const [loadingEditNote, setLoadingEditNote] = useState<boolean>(false);
  const history = useHistory();
  const editNote = async (note: NoteModel) => {
    setLoadingEditNote(false);
    try {
      await updateNoteService(note);
      setLoadingEditNote(true);
    } catch (error) {
      history.push("/error/500");
      setLoadingEditNote(false);
    }
  };
  return { editNote, loadingEditNote };
};
