import { FC } from 'react';
import { PageTitle, PageLink } from '../../../_metronic/layout/core';
import { ViewAE } from './components/ViewAE';
import { useParams } from 'react-router-dom';
import { decryptId } from '../../../_metronic/helpers';

const QuotationPage: FC = () => {
  const { id ,oportunidad} = useParams<{ id: string, oportunidad?:string }>();
  const quotationId = decryptId(id);
  const oportunityId = decryptId(oportunidad || "");

  const breadCrumbs: Array<PageLink> = [
    {
      title: 'Cotizaciones',
      path: '/cotizaciones',
      isSeparator: false,
      isActive: false,
    },

    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ];
  return (
    <>
      <PageTitle breadcrumbs={breadCrumbs}>
        {quotationId ? 'Editar cotización' : 'Nuevo cotización'}
      </PageTitle>

      <ViewAE id={quotationId ? Number(quotationId) : 0} oportunidad={oportunityId ? Number(oportunityId): 0} />
    </>
  );
};

export { QuotationPage };
