import React from 'react';
import DataTable from 'react-data-table-component';
import { MenuComponent } from '../../assets/ts/components';

type Props = {
  data: any;
  columns: [];
};

const TableList: React.FC<Props> = ({ data, columns }) => {
  return (
    <>
      <div className="card-body py-3 h-auto">
        <DataTable
          columns={columns}
          data={data}
          expandableRowsHideExpander
          pagination
          responsive
          onChangePage={() => {
            MenuComponent.reinitialization();
          }}
          customStyles={{
            table: {
              style: {
                minHeight: '450px',
              },
            },
            rows: {
              style: {
                borderBottom: '1px dashed #e4e6ef !important',
                paddingTop: '5px',
                paddingBottom: '5px',
              },
            },
            headRow: {
              style: {
                borderBottom: '1px dashed  #e4e6ef',
              },
            },
            pagination: {
              style: {
                borderTopStyle: 'none',
              },
            },
          }}
          paginationComponentOptions={{
            rowsPerPageText: 'Registros por página',
            rangeSeparatorText: 'de',
          }}
          noDataComponent={
            <>
              <div className="d-flex align-items-center">
                <div className="text-muted text-center fs-6 w-100 m-5">
                  Sin registros
                </div>
              </div>
            </>
          }
          persistTableHead
        ></DataTable>
      </div>
    </>
  );
};

export { TableList };
