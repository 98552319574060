import { useState, useEffect } from 'react';
import {
  getAccount,
  getAccountById,
  getEstados,
  getMunicipios,
  getInformationByCode,
  getCountries,
  getHistoryAccount,
  createHistoryMessage,
  getDataFilterBySelectFiledAccount,
  getFilterFieldsByCode,
  insertAccount,
  updateAccount,
  createHistoryMessageAccount,
  getNotesByAccountId,
  insertNoteAccount,
  getSubByAccountId,
  getAddressByAccountId,
  getDocumentsByAccountId,
  getContactByAccountId,
} from '../../../service/account/Account';
import { AccountDocModel, AccountModel } from '../models/AccountModel';
import { useHistory } from 'react-router-dom';
import { HistoryModel } from '../../../../_metronic/helpers/models/HistoryModel';
import { GenericoModel } from '../../catalogos/generico/models/GenericoModel';
import { NoteModel } from '../../notes/models/NoteModel';
import { SubAccountModel } from '../../subaccount/models/SubAccountModel';
import { SimpleAlert } from '../../../../_metronic/helpers';
import {
  AddressModel,
  PostalCodeModel,
} from '../../../../_metronic/helpers/models/AddressModel';
import { ContactoModel } from '../../contacto/models/ContactoModel';
import { addIdContactInAcount } from '../../../service/contacto/Contacto';

export const useGetAll = (
  realoadGrid: number,
  startIndex: number,
  endIndex: number,
  filters: any
) => {
  const [data, setData] = useState<AccountModel[]>([]);
  const [count, setCount] = useState(1);
  const [loadingRequest, setLoadingRequest] = useState(0);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      setLoadingRequest(0);
      const res = await getAccount(startIndex, endIndex, filters)
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        setData(res.data);
        setCount(res.count);
        setLoadingRequest(1);
      }
    };
    // fetchPost();
    if (realoadGrid) {
      fetchPost();
    }
  }, [endIndex, realoadGrid]);

  return { data, count, loadingRequest };
};

export const useGetOne = (id: number) => {
  const initModel: AccountModel = {
    id: 0,
    nombre: '',
    telefono: '',
    sitio_web: '',
    proveedor_id: '',
    company_id: 0,
    num_empleados: '',
    tipo_id: 0,
    vendedor_comercial_id: 0,
    industria_id: 0,
    tamano_id: 0,
    referido_id: 0,
    contactoPrincipal_id: 0,
    regimen_id: 0,
  };

  const [initValues, setInitValues] = useState<AccountModel>(initModel);
  const [loadingRequest, setLoadingRequest] = useState<boolean>(true);

  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      setLoadingRequest(true);
      const res = await getAccountById(id)
        .then()
        .catch((error) => {
          history.push('/error/500');
        });

      if (res) {
        setInitValues(res);
        setLoadingRequest(false);
      }
    };

    if (id > 0) {
      fetchPost();
    } else {
      setInitValues(initModel);
      setLoadingRequest(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return { initValues, loadingRequest };
};

export const useGetState = () => {
  const [estadosList, setEstadosList] = useState<any>([]);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      const res = await getEstados()
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        setEstadosList(res.data);
      }
    };
    fetchPost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { estadosList };
};

export const useGetMunicipality = (stateId: number) => {
  const [municipalityList, setMunicipalityList] = useState<any>([]);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      const res = await getMunicipios(stateId)
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        setMunicipalityList(res.data);
      }
    };

    if (stateId > 0) {
      fetchPost();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateId]);

  return { municipalityList };
};
export const useGetInformationByCode = (code: number) => {
  const [dataLocation, setDataLocation] = useState<PostalCodeModel | null>();
  const [loadingPostalCodeFetch, setloadingPostalCodeFetch] =
    useState<boolean>(false);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      setloadingPostalCodeFetch(true);
      const res = await getInformationByCode(code)
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        setDataLocation(res.data);
        setloadingPostalCodeFetch(false);
      }
    };
    if (code > 0) {
      fetchPost();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);
  return { dataLocation, loadingPostalCodeFetch };
};

export const useGetCountries = () => {
  const [dataCountry, setDataCountry] = useState<any>([]);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      const res = await getCountries()
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        const selectOptions = res.data.map((option: any) => ({
          value: option.nombre,
          label: option.nombre,
        }));
        setDataCountry(selectOptions);
      }
    };

    fetchPost();
  }, []);

  return { dataCountry };
};

export const useGetHistory = (reaload: number, search: string, id: string) => {
  const [data, setData] = useState<HistoryModel[]>([]);
  const [loadingRequest, setLoadingRequest] = useState(0);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      setLoadingRequest(0);
      const res = await getHistoryAccount(search, id)
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        setData(res);
        setLoadingRequest(1);
      }
    };
    if (reaload && Number(id) > 0) {
      fetchPost();
    }
  }, [reaload]);

  return { data, loadingRequest };
};

export const useInsertMessage = () => {
  const [loadingInsert, setLoadingInsert] = useState<boolean>(false);
  const history = useHistory();
  const insertData = async (message: string, id: string) => {
    setLoadingInsert(false);
    try {
      await createHistoryMessage(message, id);
      setLoadingInsert(true);
    } catch (error) {
      history.push('/error/500');
      setLoadingInsert(false);
    }
  };
  return { insertData, loadingInsert };
};

export const useGetDataSelectedByField = (code: string) => {
  const [dataOptionSelected, setDataOptionSelected] = useState<any[]>([]);
  const [loadingRequest, setLoadingRequest] = useState(0);

  const history = useHistory();

  useEffect(() => {
    const fetchPost = async () => {
      setLoadingRequest(0);
      const res = await getDataFilterBySelectFiledAccount(code)
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        const selectOptions = res.map((option: any) => ({
          value: option.id,
          label: `${
            option?.name
              ? option?.name
              : code === 'CNTA_VC_ID'
              ? option?.alias
              : option?.nombre
          } `,
        }));
        setDataOptionSelected(selectOptions);
        setLoadingRequest(1);
      }
    };

    if (code) {
      fetchPost();
    }
  }, [code]);

  return {
    dataOptionSelected,
    loadingRequest,
  };
};

export const useGetFields = (code: string) => {
  const [listFields, setlistFields] = useState<GenericoModel[]>([]);
  const [loadingRequest, setLoadingRequest] = useState(0);

  const history = useHistory();

  useEffect(() => {
    const fetchPost = async () => {
      setLoadingRequest(0);
      const res = await getFilterFieldsByCode(code)
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        res.unshift({
          id: 0,
          name: 'Todas',
          code: 'ALL',
        });
        const selectOptions = res.map((option: any) => ({
          value: option.id,
          label: `${option.name} `,
          code: option.code,
        }));
        setlistFields(selectOptions);
        setLoadingRequest(1);
      }
    };
    fetchPost();
  }, []);

  return {
    listFields,
    loadingRequest,
  };
};

export const useCreateAccount = () => {
  const [loadingCreateAccount, setLoadingCreateAccount] =
    useState<boolean>(false);
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);

  const history = useHistory();
  const createAccount = async (account: AccountModel) => {
    setLoadingCreateAccount(false);
    setIsSuccess(false);
    setIsError(false);
    try {
      const res = await insertAccount(account);
      setLoadingCreateAccount(true);
      SimpleAlert(res.message, 3000, 'success');
      setIsSuccess(true);
    } catch (error: any) {
      if (error && error.response) {
        const { message } = error.response.data;
        SimpleAlert(message, 3000, 'error');
        setLoadingCreateAccount(false);
        setIsError(true);
      } else {
        history.push('/error/500');
      }
    }
  };
  return { createAccount, loadingCreateAccount, isSuccess, isError };
};


// export const useCreateAccount = () => {
//   const [loadingCreateAccount, setLoadingCreateAccount] =
//     useState<boolean>(false);

//   const history = useHistory();
//   const createAccount = async (account: AccountModel, accountId: number) => {
//     setLoadingCreateAccount(false);
//     try {
//       await insertAccount(account);
//       setLoadingCreateAccount(true);
//     } catch (error) {
//       history.push('/error/500');
//       setLoadingCreateAccount(false);
//     }
//   };
//   return { createAccount, loadingCreateAccount };
// };




export const useEditAccount = () => {
  const [loadingEditAccount, setLoadingEditAccount] =
    useState<boolean>(false);
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);

  const history = useHistory();
  const editAccount = async (account: AccountModel, accountId: number) => {
    setLoadingEditAccount(false);
    setIsSuccess(false);
    setIsError(false);
    try {
      const res = await updateAccount(account, accountId);
      setLoadingEditAccount(true);
      SimpleAlert(res.message, 3000, 'success');
      setIsSuccess(true);
    } catch (error: any) {
      if (error && error.response) {
        const { message } = error.response.data;
        SimpleAlert(message, 3000, 'error');
        setLoadingEditAccount(false);
        setIsError(true);
      } else {
        history.push('/error/500');
      }
    }
  };
  return { editAccount, loadingEditAccount, isSuccess, isError  };
};

// export const useEditAccount = () => {
//   const [loadingEditAccount, setLoadingEditAccount] = useState<boolean>(false);
//   const history = useHistory();
//   const editAccount = async (account: AccountModel, accountId: number) => {
//     setLoadingEditAccount(false);
//     try {
//       await updateAccount(account, accountId);
//       setLoadingEditAccount(true);
//     } catch (error) {
//       history.push('/error/500');
//       setLoadingEditAccount(false);
//     }
//   };
//   return { editAccount, loadingEditAccount };
// };

export const useAddContactInAccount = () => {
  const [loadingAddContactInAcount, setLoadingAddContactInAcount] =
    useState<boolean>(false);

  const history = useHistory();
  const addContactinAcount = async (account: AccountModel) => {
    setLoadingAddContactInAcount(false);
    try {
      await addIdContactInAcount(account);
      setLoadingAddContactInAcount(true);
    } catch (error) {
      history.push('/error/500');
      setLoadingAddContactInAcount(false);
    }
  };
  return { addContactinAcount, loadingAddContactInAcount };
};

export const useInsertMessageAccount = () => {
  const [loadingInsert, setLoadingInsert] = useState<boolean>(false);
  const history = useHistory();
  const insertData = async (message: string, accountId: string) => {
    setLoadingInsert(false);
    try {
      await createHistoryMessageAccount(message, accountId);
      setLoadingInsert(true);
    } catch (error) {
      history.push('/error/500');
      setLoadingInsert(false);
    }
  };
  return { insertData, loadingInsert };
};

export const useGetNoteByAccountId = (id: string, reaload: number) => {
  const [notes, setNotes] = useState<NoteModel[]>([]);
  const [loadingNote, setLoadingNote] = useState<boolean>(false);

  const history = useHistory();
  useEffect(() => {
    setLoadingNote(false);
    const fetchPost = async () => {
      try {
        const res = await getNotesByAccountId(id);
        if (res) {
          setNotes(res);
          setLoadingNote(true);
        }
      } catch (error) {
        history.push('/error/500');
        setLoadingNote(false);
      }
    };
    if (Number(id) > 0) {
      fetchPost();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, reaload]);

  return { notes, loadingNote };
};

export const useCreateNote = () => {
  const [loadingCreateNote, setLoadingCreateNote] = useState<boolean>(false);
  const history = useHistory();
  const createNote = async (note: NoteModel, accountId: number) => {
    setLoadingCreateNote(false);
    try {
      await insertNoteAccount(note, accountId);
      setLoadingCreateNote(true);
    } catch (error) {
      history.push('/error/500');
      setLoadingCreateNote(false);
    }
  };
  return { createNote, loadingCreateNote };
};

export const useGetSubByAccountId = (id: string, reaload: number) => {
  const [subaccount, setSubaccount] = useState<SubAccountModel[]>([]);
  const [loadingSub, setLoadingSub] = useState<boolean>(false);

  const history = useHistory();
  useEffect(() => {
    setLoadingSub(false);
    const fetchPost = async () => {
      try {
        const res = await getSubByAccountId(id);
        if (res) {
          setSubaccount(res);
          setLoadingSub(true);
        }
      } catch (error) {
        history.push('/error/500');
        setLoadingSub(false);
      }
    };
    if (Number(id) > 0) {
      fetchPost();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, reaload]);

  return { subaccount, loadingSub };
};

export const useGetAddressByAccountId = (id: string, reaload: number) => {
  const [address, setAddress] = useState<AddressModel[]>([]);
  const [loadingAddress, setLoadingAddress] = useState<boolean>(false);

  const history = useHistory();
  useEffect(() => {
    setLoadingAddress(false);
    const fetchPost = async () => {
      try {
        const res = await getAddressByAccountId(id);
        if (res) {
          setAddress(res);
          setLoadingAddress(true);
        }
      } catch (error) {
        history.push('/error/500');
        setLoadingAddress(false);
      }
    };
    if (Number(id) > 0) {
      fetchPost();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, reaload]);

  return { address, loadingAddress };
};

export const useGetDocumentsByAccountId = (
  accountId: number,
  reload: number
) => {
  const [dataDocuments, setDataDocuments] = useState<AccountDocModel[]>([]);
  const [loadingDocuments, setLoadingDocuments] = useState<boolean>(false);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      setLoadingDocuments(false);
      const res = await getDocumentsByAccountId(accountId)
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        setDataDocuments(res);
        setLoadingDocuments(true);
      }
    };
    if (accountId > 0) {
      fetchPost();
    }
  }, [reload]);

  return { dataDocuments, loadingDocuments };
};

export const useGetContactsByAccountId = (id: string, reaload: number) => {
  const [contacts, setContacts] = useState<ContactoModel[]>([]);
  const [loadingContacts, setLoadingContacts] = useState<boolean>(false);

  const history = useHistory();
  useEffect(() => {
    setLoadingContacts(false);
    const fetchPost = async () => {
      try {
        const res = await getContactByAccountId(id);
        if (res) {
          setContacts(res);
          setLoadingContacts(true);
        }
      } catch (error) {
        history.push('/error/500');
        setLoadingContacts(false);
      }
    };
    if (Number(id) > 0) {
      fetchPost();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, reaload]);

  return { contacts, loadingContacts };
};
