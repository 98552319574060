import React, { useState, useEffect } from 'react';
import { NumericFormat } from 'react-number-format';
import CountUp from 'react-countup';
import { styleSelect } from '../../../../../_metronic/helpers/SelectStyles';
import { FormikProps } from 'formik';
import Select from 'react-select';
import {
  useSelectComponentFormat,
  useSelectGeneric,
  useSelectGenericPlus,
} from '../../../catalogos/generico/hooks/genericoHook';
import { ServiceModel } from '../../models/ServiceModel';
import { formatTotalSingle } from '../../../../../_metronic/helpers';
import {
  calcularPrecioImpuestos,
  multiplicar,
} from '../../../../../_metronic/helpers/PriceCalculations';

type Props = {
  currentUnitMeasureSaleProduct: (measure: any) => void;
  currentUnitCurrencyProduct: (currency: any) => void;
  currentProfit: (profit: any) => void;
  updatePriceProduct: (
    total: number,
    priceSale: number,
    priceType: number
  ) => void;
  currentTypePriceToSale: (type: number) => void;
  taxValue: number;
  calculatePrice: number;
  isSupp: boolean;
  appTax: (apply: boolean) => void;
  initVal: any;
  formik: FormikProps<ServiceModel>;
};

const SaleDataSection: React.FC<Props> = ({
  currentUnitMeasureSaleProduct,
  currentUnitCurrencyProduct,
  currentProfit,
  taxValue,
  calculatePrice,
  updatePriceProduct,
  appTax,
  initVal,
  formik,
}) => {
  const { catalog: SaleType, loadingRequest: loadingST } =
    useSelectGenericPlus('TPVENS');

  const { catalog: PriceSale } = useSelectGeneric('TPVEN');

  const { optionsSelect: UnitMeasure } = useSelectComponentFormat(
    'UNDMEDS',
    true
  );
  const { optionsSelect: UnitCurrency } = useSelectComponentFormat(
    'UMON',
    true
  );
  const [selectedOptionUnitMeasure, setSelectedOptionUnitMeasure] = useState<
    any | null
  >(null);
  const [selectedOptionSaleType, setSelectedOptionSaleType] = useState<
    any | null
  >(null);
  const [selectedOptionCurrency, setSelectedOptionCurrency] = useState<
    any | null
  >(null);
  const priceTypeOption = {
    FIJO: 1,
    OBJETIVO: 2,
  };
  const [priceType, setPriceType] = useState<number>(0);
  const [applyTax, setApplyTax] = useState<boolean>(true);
  const [tax, setTax] = useState<number>(0);
  const [fixedSalesPrice, setFixedSalesPrice] = useState<number>(0);
  const [profit, setProfit] = useState<number>(0);
  const [priceObj, setPriceObj] = useState<number>(0);
  const [typePriceValue, setTypePriceValue] = useState<number>(0);
  const [totalSecction, setTotalSecction] = useState<number>(0);
  const [codeCurrency, setCodeCurrency] = useState<string>('');
  const [codeMeasure, setCodeMeasure] = useState<string>('');

  const changeSaleType = (selectOption: any) => {
    if (selectOption) {
      const { value } = selectOption;
      formik.setFieldValue('tipo_venta_id', value);
      setSelectedOptionSaleType(selectOption);
    } else {
      setSelectedOptionSaleType(selectOption);
      formik.setFieldValue('tipo_venta_id', null);
    }
  };

  const changeUnitMeasure = (selectOption: any) => {
    if (selectOption) {
      const { value } = selectOption;
      formik.setFieldValue('unidad_medida_venta_id', value);
      setSelectedOptionUnitMeasure(selectOption);
    } else {
      formik.setFieldValue('unidad_medida_venta_id', null);

      setSelectedOptionUnitMeasure(selectOption);
    }
    currentUnitMeasureSaleProduct(selectOption);
  };

  const changeCurrency = (selectOption: any) => {
    if (selectOption) {
      setSelectedOptionCurrency(selectOption);
    } else {
      setSelectedOptionCurrency(null);
    }
    currentUnitCurrencyProduct(selectOption);
  };

  useEffect(() => {
    if (initVal && initVal.id > 0) {
      const {
        precio_fijo_venta,
        unidadMedidaVenta,
        unidadMonedaVenta,
        tipoVenta,
        tipoPrecioVenta,
        aplica_impuesto_precio_venta,
        porcentaje_beneficio_precio_venta,
        precio_objetivo_calculado_venta,
        impuesto,
        precio_venta,
      } = initVal;

      setPriceObj(precio_objetivo_calculado_venta);
      setProfit(porcentaje_beneficio_precio_venta);

      if (unidadMedidaVenta) {
        const measure = UnitMeasure.filter(
          (item) => item.value === unidadMedidaVenta?.id
        );
        if (measure.length) {
          setSelectedOptionUnitMeasure(measure[0]);
        }
      }

      if (unidadMonedaVenta) {
        const currency = UnitCurrency.filter(
          (item) => item.value === unidadMonedaVenta?.id
        );

        if (currency.length) {
          setSelectedOptionCurrency(currency[0]);
        }
      }

      if (tipoVenta) {
        const defaultaValueSaleType = SaleType.filter(
          (option: any) => option.data.code == tipoVenta.code
        );

        if (defaultaValueSaleType.length) {
          setSelectedOptionSaleType(defaultaValueSaleType[0]);

          const { id } = defaultaValueSaleType[0];

          formik.setFieldValue('tipo_venta_id', id);
        }
      } else {
        const defaultaValueSaleType = SaleType.filter(
          (option: any) => option.data.code == 'TPVENTSEDIR'
        );
        if (defaultaValueSaleType.length) {
          setSelectedOptionSaleType(defaultaValueSaleType);

          const { id } = defaultaValueSaleType[0];

          formik.setFieldValue('tipo_venta_id', id);
        }
      }

      if (tipoPrecioVenta) {
        const { code, id } = tipoPrecioVenta;
        setTypePriceValue(id);
        formik.setFieldValue('tipo_precio_venta_id', id);

        if (code === 'TPVF') {
          setPriceType(priceTypeOption.FIJO);
          setFixedSalesPrice(Number(precio_fijo_venta));
          if (impuesto && aplica_impuesto_precio_venta) {
            const { porcentaje } = impuesto;
            let impuestos = multiplicar(
              Number(precio_fijo_venta),
              Number(porcentaje)
            );
            setTax(impuestos);
          }
        } else if (code === 'TPVO') {
          setPriceType(priceTypeOption.OBJETIVO);
          if (impuesto && aplica_impuesto_precio_venta) {
            const { porcentaje } = impuesto;
            let impuestos = multiplicar(
              Number(precio_objetivo_calculado_venta),
              Number(porcentaje)
            );
            setTax(impuestos);
          }
        }
      }

      setApplyTax(aplica_impuesto_precio_venta);
      setTotalSecction(precio_venta);
    }
  }, [initVal, UnitMeasure, UnitCurrency, SaleType]);

  useEffect(() => {
    if (PriceSale && priceType === priceTypeOption.FIJO) {
      const typePrice = PriceSale.filter((item) => item.code === 'TPVF');
      if (typePrice.length) {
        const { id } = typePrice[0];
        setTypePriceValue(id);
      }
    } else if (PriceSale && priceType === priceTypeOption.OBJETIVO) {
      const typePrice = PriceSale.filter((item) => item.code === 'TPVO');
      if (typePrice.length) {
        const { id } = typePrice[0];
        setTypePriceValue(id);
      }
    } else {
      setTypePriceValue(0);
    }
  }, [PriceSale, priceType]);

  useEffect(() => {
    appTax(applyTax);
  }, [applyTax]);

  useEffect(() => {
    if (!taxValue) {
      setApplyTax(false);
    }
  }, [taxValue]);

  useEffect(() => {
    if (priceType === priceTypeOption.FIJO) {
      let precio: number = Number(fixedSalesPrice);

      const impuestos = multiplicar(Number(fixedSalesPrice), Number(taxValue));
      // INCLUYE IMPUESTOS
      if (applyTax) {
        precio = calcularPrecioImpuestos(
          Number(fixedSalesPrice),
          Number(taxValue)
        );
      }
      setTax(impuestos);
      setTotalSecction(precio);
    }
  }, [fixedSalesPrice, taxValue, applyTax, priceType]);

  useEffect(() => {
    if (profit) {
      formik.setFieldValue('porcentaje_beneficio_precio_venta', profit);
      currentProfit(profit);
    } else {
      formik.setFieldValue('porcentaje_beneficio_precio_venta', 0);
      currentProfit(0);
    }
  }, [profit]);

  useEffect(() => {
    if (priceType === priceTypeOption.OBJETIVO) {
      setPriceObj(calculatePrice);
    } else {
      formik.setFieldValue('porcentaje_beneficio_precio_venta', 0);
      setPriceObj(0);
      setProfit(0);
    }
  }, [calculatePrice, priceType]);

  useEffect(() => {
    if (priceType === priceTypeOption.OBJETIVO) {
      setFixedSalesPrice(0);
      let precioCompraFijjo = priceObj;
      let porcentajeDecimal = taxValue;
      let aplicaImpuesto = applyTax;
      let total = Number(precioCompraFijjo);
      let impuestos = multiplicar(
        Number(precioCompraFijjo),
        Number(porcentajeDecimal)
      );
      if (aplicaImpuesto) {
        total = calcularPrecioImpuestos(
          Number(precioCompraFijjo),
          Number(porcentajeDecimal)
        );
      }
      setTotalSecction(total);
      setTax(impuestos);
    }
  }, [priceObj, taxValue, applyTax, priceType]);

  useEffect(() => {
    if (priceType === priceTypeOption.FIJO) {
      updatePriceProduct(totalSecction, fixedSalesPrice, priceTypeOption.FIJO);
    } else if (priceType === priceTypeOption.OBJETIVO) {
      updatePriceProduct(totalSecction, priceObj, priceTypeOption.OBJETIVO);
    }
  }, [fixedSalesPrice, priceObj, totalSecction, priceType]);

  useEffect(() => {
    formik.setFieldValue('tipo_precio_venta_id', typePriceValue);
  }, [typePriceValue]);

  useEffect(() => {
    if (selectedOptionCurrency) {
      const {
        data: { code },
      } = selectedOptionCurrency;
      setCodeCurrency(code);
    }
  }, [selectedOptionCurrency]);

  useEffect(() => {
    if (selectedOptionUnitMeasure) {
      const {
        data: { code },
      } = selectedOptionUnitMeasure;
      setCodeMeasure(code);
    }
  }, [selectedOptionUnitMeasure]);

  return (
    <>
      <div className="row">
        <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 w50">
          <div className="w-100 pe-5">
            <div className="w-100 d-flex">
              <label className="form-label w-auto">Tipo de venta</label>
            </div>
            <Select
              options={SaleType}
              styles={styleSelect}
              placeholder={'Seleccione'}
              isSearchable={true}
              noOptionsMessage={() => {
                return <span>{'No se encontraron registros'}</span>;
              }}
              isLoading={loadingST}
              onChange={changeSaleType}
              value={selectedOptionSaleType}
              backspaceRemovesValue
              isClearable
            />
          </div>
        </div>
        <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 w50">
          <div className="w-100 pe-5">
            <div className="w-100 d-flex">
              <label className="form-label w-auto">Unidad de medida</label>
            </div>
            <Select
              options={UnitMeasure}
              styles={styleSelect}
              placeholder={'Seleccione'}
              isSearchable={true}
              noOptionsMessage={() => {
                return <span>{'No se encontraron registros'}</span>;
              }}
              onChange={changeUnitMeasure}
              value={selectedOptionUnitMeasure}
              backspaceRemovesValue
              isClearable={false}
            />
          </div>
        </div>
        <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mb-5 w50">
          <div className="w-100 pe-5">
            <div className="w-100 d-flex">
              <label className="form-label w-auto">Unidad moneda</label>
            </div>
            <Select
              options={UnitCurrency}
              styles={styleSelect}
              placeholder={'Seleccione'}
              isSearchable={true}
              noOptionsMessage={() => {
                return <span>{'No se encontraron registros'}</span>;
              }}
              onChange={changeCurrency}
              value={selectedOptionCurrency}
              backspaceRemovesValue
              isClearable
            />
          </div>
        </div>
        <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mT-15 w100">
          <div className="w-100 pe-5 mb-3">
            <div className="w-100 d-flex">
              <label className="form-label w-auto fw-bolder">
                Precio de venta
              </label>
            </div>
          </div>
        </div>

        <div className="row mb-5 row-scroll">
          <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
            <div className="w-100 pe-5 mb-5">
              <div className="w-100 d-flex">
                <label className="form-check form-check-custom form-check-solid">
                  <input
                    className="form-check-input  mr-10"
                    type="radio"
                    checked={priceType === priceTypeOption.FIJO}
                    onClick={() => {
                      updatePriceProduct(0, 0, 0);
                      setPriceType(priceTypeOption.FIJO);
                    }}
                  />
                  Precio fijo
                </label>
              </div>
            </div>
            <div className="w-100">
              <label className="form-label w-auto">Precio venta fijo</label>
              <div className="w-100 d-flex">
                <NumericFormat
                  disabled={priceType != priceTypeOption.FIJO}
                  className="form-control fs-4"
                  thousandSeparator={true}
                  prefix="$"
                  placeholder={'$0.00'}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  allowNegative={false}
                  allowLeadingZeros={false}
                  isAllowed={(values: any) => {
                    const { floatValue } = values;
                    const integerPart = Math.floor(floatValue);
                    return integerPart.toString().length <= 9;
                  }}
                  value={fixedSalesPrice}
                  onValueChange={(values: any) => {
                    const { floatValue } = values;
                    setFixedSalesPrice(floatValue ? floatValue : 0);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
            <div className="w-100 pe-5 mb-5">
              <div className="w-100 d-flex">
                <label className="form-check form-check-custom form-check-solid">
                  <input
                    className="form-check-input  mr-10"
                    type="radio"
                    checked={priceType === priceTypeOption.OBJETIVO}
                    onClick={() => {
                      updatePriceProduct(0, 0, 0);
                      setPriceType(priceTypeOption.OBJETIVO);
                    }}
                  />
                  Precio objetivo
                </label>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                <div className="w-100 pe-5 ">
                  <label className="form-label w-auto">Beneficio</label>
                  <div className="w-100 d-flex">
                    <NumericFormat
                      disabled={priceType != priceTypeOption.OBJETIVO}
                      className="form-control fs-4"
                      thousandSeparator={false}
                      allowNegative={false}
                      suffix="%"
                      placeholder={'0%'}
                      allowLeadingZeros={false}
                      isAllowed={({ value }) => {
                        // Verificar que el valor esté entre 0 y 100
                        if (parseInt(value) < 0 || parseInt(value) > 100) {
                          return false;
                        }
                        // Verificar que el valor tenga entre 1 y 3 dígitos enteros
                        const integerValue = parseInt(value);
                        if (integerValue < 10 && value.length > 1) {
                          return false;
                        }
                        if (integerValue < 100 && value.length > 2) {
                          return false;
                        }
                        return true;
                      }}
                      value={profit}
                      onValueChange={(values) => {
                        const { floatValue } = values;
                        setProfit(floatValue ? floatValue : 0);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-6 ">
                <div className="w-100 pe-5">
                  <label
                    className="form-label w-auto large-text"
                    style={{ marginBottom: '1px' }}
                  >
                    Precio calculado
                  </label>
                  <div className="w-100 d-flex">
                    <NumericFormat
                      disabled
                      className="form-control fs-4"
                      thousandSeparator={true}
                      prefix="$"
                      decimalScale={2}
                      fixedDecimalScale={true}
                      allowNegative={false}
                      allowLeadingZeros={false}
                      value={priceObj}
                      isAllowed={(values: any) => {
                        const { floatValue } = values;
                        const integerPart = Math.floor(floatValue);
                        return integerPart.toString().length <= 9;
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
            <div className="w-100 pe-5 mb-5">
              <div className="w-100 d-flex">
                <label className="form-check form-check-custom form-check-solid">
                  <input
                    className="form-check-input mr-10"
                    type="checkbox"
                    disabled={taxValue ? false : true}
                    checked={applyTax}
                    onChange={(event: any) => {
                      setApplyTax(event.target.checked);
                    }}
                  />
                  Impuesto
                </label>
              </div>
            </div>

            <div className="w-100 pe-5">
              <label className="form-label w-auto">Impuesto</label>
              <div className="w-100 d-flex">
                <NumericFormat
                  style={{ width: '100%' }}
                  disabled
                  className="form-control fs-4"
                  value={tax}
                  prefix="$"
                  thousandSeparator={false}
                  allowNegative={false}
                  allowLeadingZeros={false}
                  decimalScale={2}
                  isAllowed={(values: any) => {
                    const { floatValue } = values;
                    const integerPart = Math.floor(floatValue);
                    return integerPart.toString().length <= 9;
                  }}
                />
              </div>
            </div>
          </div>
          <div
            className="col-sm-12 col-md-12 col-lg-2 col-xl-2 col-xxl-2 mt-md-5 price-total"
            style={{ alignSelf: 'flex-end' }}
          >
            <div className=" me-5 fw-bold w-100">Precio unitario</div>
            <div>
              <span
                className="fs-1 fw-bold"
                style={{ color: '#1B84FF', fontSize: '2px' }}
              >
                <CountUp
                  end={totalSecction}
                  duration={1}
                  prefix="$"
                  decimals={2}
                />{' '}
              </span>
            </div>
            <div>
              <span className="fs-7 fw-semibold opacity-50">
                <span data-kt-element="period">
                  {codeCurrency ? codeCurrency : ''}
                </span>
                /{' '}
                <span data-kt-element="period">
                  {codeMeasure ? codeMeasure : ''}
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { SaleDataSection };
