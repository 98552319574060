import React, { useState, useEffect } from 'react';
import { KTSVG } from '../../../../_metronic/helpers';
import { Modal } from 'react-bootstrap-v5';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { useGetOne } from '../hooks/VendedorHook';
import { useSelectGeneric } from '../../catalogos/generico/hooks/genericoHook';
import { NumericFormat, PatternFormat } from 'react-number-format';
import {
  addVendedor,
  updateVendedor,
} from '../../../service/vendedor/Vendedor';
type Props = {
  show: boolean;
  handleClose: (updateGrid: boolean) => void;
  title: string;
  id_row: number;
};

const VendedorSchema = Yup.object().shape({
  telefono: Yup.string().required('Este campo es requerido.'),
  // .test(
  //   'min-length',
  //   'Ingresa un número de teléfono móvil válido.',
  //   (value: any) => {
  //     const v = value
  //       ? value.replace('(', '').replace(')', '').replace('+', '')
  //       : '';

  //     return v.trim().length >= 12;
  //   }
  // ),
  nombre: Yup.string().required('Este campo es requerido.'),
  apellido_paterno: Yup.string().required('Este campo es requerido.'),
  apellido_materno: Yup.string().required('Este campo es requerido.'),
  email: Yup.string()
    .email('Formato de correo inválido.')
    .min(3, 'Mínimo 3 caracteres.')
    .max(50, 'Máximo 50 caracteres.')
    .required('Este campo es requerido.'),
  tipoVendedorId: Yup.number()
    .min(1, 'Seleccione el tipo de vendedor.')
    .required('Este campo es requerido.'),
});

const CreateModal: React.FC<Props> = ({ id_row, show, handleClose, title }) => {
  const [loading, setLoading] = useState(false);
  const { catalog: tipoVendedor } = useSelectGeneric('TVE');
  const { initValues } = useGetOne(id_row);

  // useEffect(() => {
  //   formVendedor.setFieldValue('telefono', '52');
  // }, [show]);

  const formVendedor = useFormik({
    initialValues: initValues,
    validationSchema: VendedorSchema,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      setLoading(true);
      setTimeout(() => {
        if (values.id > 0) {
          updateVendedor(values)
            .then((res) => {
              const {
                data: { message },
              } = res;
              toast.success(message, {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
              });
              formVendedor.resetForm();
              setLoading(false);
              handleClose(true);
            })
            .catch((err) => {
              let resMessageToast: string = '';
              const {
                data: { message, details = null },
              } = err.response;
              resMessageToast = `${message}`;
              setLoading(false);
              setSubmitting(false);
              toast.error(resMessageToast, {
                position: 'top-right',
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
              });
            });
        } else {
          addVendedor(values)
            .then((res) => {
              const {
                data: { message },
              } = res;
              toast.success(message, {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
              });
              formVendedor.resetForm();
              setLoading(false);
              handleClose(true);
            })
            .catch((err) => {
              let resMessageToast: string = '';
              const {
                data: { message, details = null },
              } = err.response;
              resMessageToast = `${message}`;
              setLoading(false);
              setSubmitting(false);
              toast.error(resMessageToast, {
                position: 'top-right',
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
              });
            });
        }
      }, 1000);
    },
  });

  const handlePhoneNumberChange = (values: any) => {
    formVendedor.setFieldValue('telefono', values.target.value);
  };

  return (
    <Modal
      id="kt_modal_create"
      tabIndex={-1}
      aria-hidden="true"
      backdrop="static"
      dialogClassName="modal-dialog mw-600px"
      show={show}
      onHide={() => {
        formVendedor.resetForm();
        handleClose(false);
      }}
    >
      <div className="modal-content">
        <div className="modal-header">
          <h2>{title}</h2>
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            onClick={() => {
              formVendedor.resetForm();
              handleClose(false);
            }}
          >
            <KTSVG
              className="svg-icon-2"
              path="/media/icons/duotune/arrows/arr061.svg"
            />
          </div>
        </div>
        <div className="modal-body scroll-y mx-5">
          <form
            id="kt_modal_generic_form"
            className="form"
            action="#"
            onSubmit={formVendedor.handleSubmit}
          >
            <div className="d-flex flex-column flex-xl-row flex-row-fluid">
              <div className="w-100 d-flex">
                <div className="w-100 pe-5">
                  <div className="w-100 d-flex">
                    <label className="required fw-bold fs-6 mb-2 w-auto">
                      Nombre
                    </label>
                  </div>
                  <input
                    className="form-control"
                    data-kt-element="input"
                    placeholder="Nombre"
                    {...formVendedor.getFieldProps('nombre')}
                  ></input>
                  {formVendedor.touched.nombre && formVendedor.errors.nombre && (
                    <div className="fv-plugins-message-container text-danger w-100">
                      <span role="alert">{formVendedor.errors.nombre}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="d-flex flex-column flex-xl-row flex-row-fluid mt-3">
              <div className="w-100 d-flex">
                <div className="w-100 pe-5">
                  <div className="w-100 d-flex">
                    <label className="required fw-bold fs-6 mb-2 w-auto">
                      Apellido paterno
                    </label>
                  </div>
                  <input
                    {...formVendedor.getFieldProps('apellido_paterno')}
                    className="form-control"
                    placeholder="Apellido paterno"
                    name="apellido_paterno"
                  />
                  {formVendedor.touched.apellido_paterno &&
                    formVendedor.errors.apellido_paterno && (
                      <div className="fv-plugins-message-container text-danger w-100">
                        <span role="alert">
                          {formVendedor.errors.apellido_paterno}
                        </span>
                      </div>
                    )}
                </div>
              </div>
            </div>
            <div className="d-flex flex-column flex-xl-row flex-row-fluid mt-3">
              <div className="w-100 d-flex">
                <div className="w-100 pe-5">
                  <div className="w-100 d-flex">
                    <label className="required fw-bold fs-6 mb-2 w-auto">
                      Apellido materno
                    </label>
                  </div>
                  <input
                    {...formVendedor.getFieldProps('apellido_materno')}
                    className="form-control"
                    placeholder="Apellido materno"
                    name="apellido_materno"
                  />
                  {formVendedor.touched.apellido_materno &&
                    formVendedor.errors.apellido_materno && (
                      <div className="fv-plugins-message-container text-danger w-100">
                        <span role="alert">
                          {formVendedor.errors.apellido_materno}
                        </span>
                      </div>
                    )}
                </div>
              </div>
            </div>
            <div className="d-flex flex-column flex-xl-row flex-row-fluid mt-3">
              <div className="w-100 d-flex">
                <div className="w-100 pe-5">
                  <div className="w-100 d-flex">
                    <label className="required fw-bold fs-6 mb-2 w-auto">
                      Correo electrónico
                    </label>
                  </div>
                  <input
                    {...formVendedor.getFieldProps('email')}
                    className="form-control"
                    placeholder="Correo electrónico"
                    type="email"
                    name="email"
                  />
                  {formVendedor.touched.email && formVendedor.errors.email && (
                    <div className="fv-plugins-message-container text-danger w-100">
                      <span role="alert">{formVendedor.errors.email}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="d-flex flex-column flex-xl-row flex-row-fluid mt-3">
              <div className="w-100 d-flex">
                <div className="w-100 pe-5">
                  <div className="w-100 d-flex">
                    <label className="required fw-bold fs-6 mb-2 w-auto">
                      Teléfono
                    </label>
                  </div>
                  <PatternFormat
                    className="form-control"
                    {...formVendedor.getFieldProps('telefono')}
                    format="## #### #### ##### ####"
                    allowEmptyFormatting
                    onChange={handlePhoneNumberChange}
                  />
                  {/* <NumericFormat
                    className="form-control mb-3"
                    {...formVendedor.getFieldProps('telefono')}
                    decimalScale={0}
                    fixedDecimalScale
                    placeholder=""
                    prefix=""
                    isAllowed={(values: any) => {
                      const { floatValue = 0 } = values;
                      return floatValue <= 9999999999;
                    }}
                    onValueChange={(values: any) => {
                      const { floatValue } = values;
                      formVendedor.setFieldValue('telefono', floatValue);
                    }}
                  /> */}
                  {formVendedor.touched.telefono &&
                    formVendedor.errors.telefono && (
                      <div className="fv-plugins-message-container text-danger w-100">
                        <span role="alert">{formVendedor.errors.telefono}</span>
                      </div>
                    )}
                </div>
              </div>
            </div>
            <div className="d-flex flex-column flex-xl-row flex-row-fluid mt-3">
              <div className="w-100 d-flex">
                <div className="w-100 pe-5">
                  <div className="w-100 d-flex">
                    <label className="required fw-bold fs-6 mb-2 w-auto">
                      Tipo de vendedor
                    </label>
                  </div>
                  <select
                    className="form-select"
                    data-kt-select2="true"
                    data-placeholder="Select option"
                    data-allow-clear="true"
                    {...formVendedor.getFieldProps('tipoVendedorId')}
                  >
                    <option value={0}>Seleccione</option>
                    {tipoVendedor.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                  {formVendedor.touched.tipoVendedorId &&
                    formVendedor.errors.tipoVendedorId && (
                      <div className="fv-plugins-message-container text-danger w-100">
                        <span role="alert">
                          {formVendedor.errors.tipoVendedorId}
                        </span>
                      </div>
                    )}
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end mt-10">
              <button
                type="reset"
                className="btn btn-light me-3"
                data-kt-users-modal-action="cancel"
                onClick={() => {
                  formVendedor.resetForm();
                  handleClose(false);
                }}
              >
                Cancelar
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                data-kt-users-modal-action="submit"
                disabled={loading}
              >
                {!loading && (
                  <span className="indicator-label">
                    {id_row > 0 ? 'Actualizar' : 'Crear'}
                  </span>
                )}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: 'block' }}
                  >
                    Espere por favor...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};
export { CreateModal };
