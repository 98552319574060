import axios from 'axios'
const API_URL = process.env.REACT_APP_API


export async function getNotificaciones(startIndex: number, endIndex: number) {

    const response = await axios.get(`${API_URL}notificaciones/get/myNotification?limit=${endIndex}&page=1`)
    const data = response.data.doc.data?.rows;
    const count = response.data.doc.data?.count
    return {
        data: data,
        count: count
    }

}

export async function updateNotificacion(id: number) {

    const response = await axios.put(`${API_URL}/notificaciones/${id}`);
    return response;
}