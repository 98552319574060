import React, { useRef, useState, useEffect } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import { MenuComponent } from "../../../../_metronic/assets/ts/components/MenuComponent";
import { PackageList } from "./PackageList";
import { randomClassList } from "../../../../_metronic/helpers/CustomElements";

type Props = {
  listData: any;
  isInfo: boolean;
  onRefreshData: () => void;
};
interface RowDataList {
  code: string;
  color: string;
  etapaId: number;
  nombre: string;
  items: any[];
}

const CardListPackage: React.FC<Props> = ({
  listData,
  isInfo,
  onRefreshData,
}) => {
  const [sendCatId, setSendcatId] = useState<number>(0);
  const [data, setData] = useState<RowDataList[]>(listData);
  const [addPackName, setaddPacktName] = useState<boolean>(false);
  const [realod, setReload] = useState(Math.random() * 150);

  useEffect(() => {
    if (listData) {
      listData.map((item: RowDataList) => {
        const randomClass = randomClassList();
        item.color = randomClass;
      });

      setData(listData);
    }
  }, [listData]);

  const columns: TableColumn<RowDataList>[] = [
    {
      name: "",
      selector: (row: RowDataList) => row.nombre,
      cell: (row: any) => (
        <>
          <span
            className="badge fs-7 fw-bold mr-15 min-w-150px"
            style={{
              color: "#fff",
              backgroundColor: row.color ? row.color : "#9096EC",
            }}
          >
            {row.nombre}
          </span>
          <div
            className="badge badge-light-primary fs-7 fw-bold mr-15"
            title="Total de productos"
          >
            {row.items.length}
          </div>
          {/* <a
            className="cursor-pointer badge badge-light-primary"
            title="Agregar producto"
            onClick={() => {
              setaddProductName(true)
              setSendcatId(row.categoriaId)
            }}
            role="button"
          >
            + Agregar 
          </a> */}
        </>
      ),

      sortable: false,
      width: "250px",
    },
  ];

  const listGral = (d: any) => {
    return (
      <>
        <PackageList
          items={d.data.items}
          onRefreshData={onRefreshData}
          categoriId={sendCatId}
          active={addPackName}
          isEdit={isInfo}
        />
      </>
    );
  };

  setTimeout(() => {
    MenuComponent.reinitialization();
  }, 1000);

  return (
    <>
      <DataTable
        columns={columns}
        data={data}
        responsive
        expandableRows
        expandableRowsComponent={listGral}
        noDataComponent={
          <>
            <div className="d-flex align-items-center">
              <div className="text-muted text-center fs-6 w-100 m-5">
                Sin registros
              </div>
            </div>
          </>
        }
        onRowExpandToggled={(event) => {
          if (event) setReload(Math.random() * 100);
        }}
      />
    </>
  );
};

export default CardListPackage;
