import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { KTSVG } from '../../../../_metronic/helpers';
import { ListGrid } from './ListGrid';
import { FilterBoard } from './Filter';
import { useGetAll } from '../hooks/AccountHook';
import { CardAccount } from './CardGrid';
// import { useGetAllCuentas } from '../../contacto/hooks/ContactoHook';

const View: React.FC = () => {
  const [secction, setSecction] = useState<string | "BOARD" | "LIST">("LIST");
  const [loadingGrid, setLoadingGrid] = useState(true);
  const [reloadGrid, setReloadGrid] = useState(Math.random() * 40);
  const [initFilterValues, setInitFilterValues] = useState({});
  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const { data, count, loadingRequest } = useGetAll(
    reloadGrid,
    pageSize,
    currentPage,
    initFilterValues
  );

  // const { dataCuenta } = useGetAllCuentas(reloadGrid);

  const totalPages = Math.ceil(count / pageSize)
    ? Math.ceil(count / pageSize)
    : 1;

  useEffect(() => {
    if (loadingRequest > 0) {
      setLoadingGrid(false);
    }
  }, [loadingRequest]);

  const onSearchFilter = (values: any) => {
    setLoadingGrid(true);
    if (values) {
      setInitFilterValues(values);
    } else {
      setInitFilterValues({
        campo_id: null,
        valor: null,
      });
    }
    setReloadGrid(Math.random() * 40);
  };

  const onCleanFilter = () => {
    setLoadingGrid(true);
    setInitFilterValues({
      campo_id: null,
      valor: null,
    });
    setReloadGrid(Math.random() * 40);
  };
  const toggleMode = (mode: "LIST" | "BOARD") => {
    localStorage.setItem("viewMode", mode);
    setSecction(mode);
  };

  useEffect(() => {
    const storedMode = localStorage.getItem("viewMode");
    if (storedMode) {
      setSecction(storedMode);
    }
  }, []);

  return (
    <>

      <div className="row gy-5 g-xl-8">
        <div className="col-xxl-12 col-xl-12 col-md-12 col-lg-12">
          <div className="card card-flush p-5">
            <div className="row">
              <div className="col-xxl-8 col-xl-8 col-md-8 col-lg-8">
                <ul className="nav nav-line-tabs nav-line-tabs-2x border-transparent fs-7 fw-bolder flex-nowrap">
                  <li className="nav-item cursor-pointer ">
                    <a
                      className={`nav-link me-6 ${secction === "LIST" ? "text-active-primary active" : ""
                        } `}
                      onClick={() => {
                        toggleMode("LIST");
                      }}
                    >
                      <KTSVG
                        path="/media/icons/duotune/text/txt001.svg"
                        className="svg-icon-1 fs-7"
                      />
                      <span className="margin-left-05">Lista</span>
                    </a>
                  </li>
                  <li className="nav-item cursor-pointer">
                    <a
                      className={`nav-link me-6  ${secction === "BOARD" ? "text-active-primary active" : ""
                        }`}
                      onClick={() => {
                        toggleMode("BOARD");
                      }}
                    >
                      <KTSVG
                        path="/media/icons/duotune/layouts/lay002.svg"
                        className="svg-icon-1 fs-7"
                      />
                      <span className="margin-left-05">Tarjetas</span>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-xxl-4 col-xl-4 col-md-4 col-lg-4 position-end">
                <div className="card-title center-items">
                  <Link
                    className="btn btn-sm btn-icon btn-light-primary border-btn"
                    to={'/clientes/cuentas/add'}
                  >
                    <KTSVG
                      path="/media/icons/duotune/abstract/abs011.svg"
                      className="svg-icon-5 svg-icon-gray-500 "
                    />
                  </Link>
                </div>

                <div className="mr-05"></div>
                <FilterBoard
                  onCleanFilter={onCleanFilter}
                  onSearchFilter={onSearchFilter}
                  initFilterValues={initFilterValues}
                ></FilterBoard>
              </div>
            </div>
            <div className="row">
              {secction === "BOARD" ? (
                <CardAccount board={data}></CardAccount>
              ) : null}
              {secction === "LIST" ? (
                // <CardList listData={dataBoard}></CardList>
                <ListGrid
                  data={data}
                  loadingGrid={loadingGrid}
                  count={count}
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onRemoveRegister={() => { }}
                  onPageChange={(pageNumber: number) => {
                    setLoadingGrid(true);
                    setCurrentPage(pageNumber);
                  }}
                  onEditRegister={() => { }}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export { View };
