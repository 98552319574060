import React, { useState, useEffect, useRef } from 'react';
import { ProductModel } from '../models/QuotationModel';
import { Link } from 'react-router-dom';
import { KTSVG, encryptId, formatPrice } from '../../../../_metronic/helpers';
import Swal from 'sweetalert2';
import { useRemoveProduct } from '../hooks/ProductsHook';
import { CreateSupplierModal } from '../_modals/CreateSupplierModal';

type Props = {
  items: ProductModel[];
  onRefreshData: () => void;
  onRefreshDataSupplier: () => void;
  isNewProduct: boolean;
  categoriId: number;
  catUnidadMoneda: any;
  catUnidadMedida: any;
  catTipoImpuesto: any;
  catProveedores: any;
};

const ProductList: React.FC<Props> = ({
  items,
  isNewProduct,
  onRefreshData,
  onRefreshDataSupplier,
}) => {
  const [taskMemo, setTaskMemo] = useState<ProductModel[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);

  const [showModalSupplier, setShowModalSupplier] = useState<boolean>(false);
  const [idSection, setIdSection] = useState<number>(0);
  const { removeProducts, isDeletedCorrect } = useRemoveProduct();
  const [removePesoSign, setRemovePesoSign] = useState<boolean>(false);

  useEffect(() => {
    if (isDeletedCorrect) {
      onRefreshData();
    }
  }, [isDeletedCorrect]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [isNewProduct]);

  useEffect(() => {
    if (inputRef.current && document.activeElement === inputRef.current) {
      inputRef.current.classList.add('input-scroll-animation');
      inputRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });

      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.classList.remove('input-scroll-animation');
        }
      }, 500);
    }
  }, [inputRef.current]);

  useEffect(() => {
    setTaskMemo(items);
  }, [items]);

  return (
    <>
      <div className="table-responsive min-h-200px me-n5 pe-5">
        <table className="table table-border table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 me-n5 pe-5">
          <thead>
            <tr className="text-gray-400 fs-7">
              <th className="w-200px fw-bolder">Nombre</th>
              <th className="w-100px fw-bolder">Foto</th>
              <th className="w-150px fw-bolder">Precio de venta</th>
              <th className="w-150px fw-bolder">Tipo de impuesto</th>
              <th className="w-150px fw-bolder">Impuesto</th>
              <th className="w-150px fw-bolder">Precio venta final</th>
              <th className="w-100px fw-bolder">Inventario</th>
              <th className="w-100px fw-bolder">Acciones</th>
            </tr>
          </thead>
          <tbody>
            {taskMemo.map((row: ProductModel, index: number) => (
              <tr key={index}>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start w-100 flex-column fs-012 font-weight-mormal text-muted">
                      <Link
                        to={`/cotizaciones/productos/edit/${encryptId(
                          row.id.toString()
                        )}`}
                      >
                        {row.nombre}
                      </Link>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column fs-012">
                      <div className="symbol symbol-25px tooltip-soft">
                        {row.imagen ? (
                          <img
                            src={`${process.env.REACT_APP_PUBLIC}producto/${row?.imagen}`}
                            title={row.nombre}
                            crossOrigin="anonymous"
                            className="symbol-label fs-5 "
                            alt="user"
                          />
                        ) : (
                          <KTSVG
                            path="/media/icons/duotune/general/gen006.svg"
                            className="symbol-label fs-5 "
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </td>

                <td style={{ minWidth: '150px', overflowX: 'auto' }}>
                  <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column">
                      <div>
                        <span className="text-gray-700 fw-bolder">
                          {row.tipoPrecioVenta?.code === 'TPVF' ? (
                            <>
                              {' '}
                              {`${formatPrice(
                                row.precio_fijo_venta || 0,
                                'es-MX',
                                'MXN',
                                2,
                                2
                              )}`}
                            </>
                          ) : (
                            <>
                              {' '}
                              {`${formatPrice(
                                row.precio_objetivo_calculado_venta || 0,
                                'es-MX',
                                'MXN',
                                2,
                                2
                              )}`}
                            </>
                          )}
                        </span>
                        <span className="text-gray-600 ms-3 fs-8">
                          {row.unidad_moneda_venta
                            ? `/ ${row.unidad_moneda_venta}`
                            : 'Sin unidad de moneda'}
                        </span>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column">
                      <div className="text-gray-700 fw-bolder">
                        {row.aplica_impuesto_precio_venta ? (
                          <>
                            {row.impuesto_siglas && row.impuesto_siglas}

                            {row.impuesto_porcentaje &&
                              ` ${row.impuesto_porcentaje * 100} %`}
                          </>
                        ) : (
                          <>
                            <div className="badge badge-light">
                              Sin impuesto
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column">
                      <div>
                        <span className="text-gray-700 fw-bolder">
                          {`${formatPrice(
                            Number(row.monto_impuesto_precio_venta) || 0,
                            'es-MX',
                            'MXN',
                            2,
                            2
                          )}`}
                        </span>
                        <span className="text-gray-600 ms-3 fs-8">
                          {row.unidad_moneda_venta
                            ? `/ ${row.unidad_moneda_venta}`
                            : 'Sin unidad de moneda'}
                        </span>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column">
                      <div>
                        <span className="text-gray-700 fw-bolder">
                          {`${formatPrice(
                            Number(row.precio_venta) || 0,
                            'es-MX',
                            'MXN',
                            2,
                            2
                          )}`}
                        </span>
                        <span className="text-gray-600 ms-3 fs-8">
                          {row.unidad_moneda_venta
                            ? `/ ${row.unidad_moneda_venta}`
                            : 'Sin unidad de moneda'}
                        </span>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column ">
                      <div>
                        <span className="text-gray-700 fw-bolder">
                          {row.inventario}
                        </span>
                        <span className="text-gray-600 ms-3 fs-8">
                          {row.unidadMedidaVenta
                            ? `/ ${row.unidadMedidaVenta.code}`
                            : 'Sin unidad de medida'}
                        </span>
                      </div>
                    </div>
                  </div>
                </td>

                <td>
                  <div className="center-items">
                    <div>
                      {/* <a
                        className="btn btn-sm btn-active-light-primary btn-icon btn-light-primary"
                        onClick={(event) => {
                          Swal.fire({
                            title: '¿Estas seguro?',
                            html: `
                                <div className="text-gray-600">
                                  Se va a eliminar el producto con nombre <b>${row.nombre}</b>, una vez
                                  eliminado ya no se podrá modificar ningún dato de este
                                  producto.
                                </div>
                                `,
                            showCancelButton: true,
                            padding: '2em',
                            cancelButtonText: 'Cancelar',
                            confirmButtonText: 'Aceptar',
                            reverseButtons: true,
                            customClass: {
                              confirmButton: 'btn btn-primary',
                              cancelButton: 'btn btn-light me-3',
                            },
                          }).then((result) => {
                            if (result.isConfirmed) {
                              removeProducts(Number(row.id));
                            }
                          });
                        }}
                        title="Eliminar producto"
                      >
                        <KTSVG path="/media/icons/duotune/general/gen027.svg" />
                      </a> */}
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <CreateSupplierModal
        show={showModalSupplier}
        typeProveedor={1}
        handleClose={(refresh?: boolean) => {
          setShowModalSupplier(!showModalSupplier);
          if (refresh) {
            onRefreshData();
            onRefreshDataSupplier();
          }
        }}
        itemSelected={idSection}
      ></CreateSupplierModal>
    </>
  );
};

export { ProductList };
