/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { UserModel } from '../../../../app/modules/auth/models/UserModel';
import { RootState } from '../../../../setup';
import { Tooltip } from '@mui/material';

const HeaderUserMenu: FC = () => {
  const user: UserModel = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  ) as UserModel;

  const [showEmail, setShowEmail] = useState<boolean>(false);

  const handleToggleContent = () => {
    setShowEmail(!showEmail);
  };

  return (
    <div
      className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px"
      data-kt-menu="true"
    >
      <div className="menu-item px-3">
        <div className="menu-content d-flex align-items-center px-3">
          <div className="symbol symbol-50px me-5">
            <img alt="Logo" src={user.profile_photo} crossOrigin="anonymous" />
          </div>

          <div className="d-flex  flex-column">
            <div className="fw-bolder d-flex align-items-center fs-5">
              {user.first_name}
              <span className="badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2">
                {user.company}
              </span>
            </div>
            <a href="#" className="fw-bold text-muted text-hover-primary fs-7">
              {user.email && user?.email?.length > 25 ? (
                <Tooltip
                  title={
                    <span style={{ whiteSpace: 'pre-wrap', fontSize: '12px' }}>
                      {user.email}
                    </span>
                  }
                  arrow
                  placement="top"
                >
                  <span
                    style={{
                      cursor: 'pointer',
                    }}
                    onClick={handleToggleContent}
                    // onMouseEnter={handleToggleContent}
                    // onMouseLeave={handleToggleContent}
                  >
                    {showEmail
                      ? user.email
                      : `${user.email ? user.email.slice(0, 22) : ''}...`}
                  </span>
                </Tooltip>
              ) : (
                user.email
              )}
            </a>
          </div>
        </div>
      </div>

      <div className="separator my-2"></div>

      <div className="menu-item px-5">
        <Link to={'/perfil'} className="menu-link px-5">
          Mi Perfil
        </Link>
      </div>
      {/* <div className='menu-item px-5 my-1'>
        <Link to='/crafted/account/settings' className='menu-link px-5'>
          Cpmfiguracion
        </Link>
      </div> */}
      <div className="menu-item px-5">
        <Link to="/logout" className="menu-link px-5">
          Cerrar Sesión
        </Link>
      </div>
    </div>
  );
};

export { HeaderUserMenu };
