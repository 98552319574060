import axios from 'axios'
const API_URL = process.env.REACT_APP_API


export async function getAllComerciales() {
    const response = await axios.get(`${API_URL}catalogos/vendedores/list/comerciales`);
    const data = response.data.doc.data;
    let dataSelect: any = data.rows;
    dataSelect.forEach(( row:any ) => {
        row.label = `${row.nombre} ${row.apellido_paterno} ${row.apellido_materno}`
        row.value = row.id;
    });
    return dataSelect;
}

export async function getDataSelectMultiple( code: string ) {
    const response = await axios.get(`${API_URL}catalogs/${code}`);
    const data = response.data.doc.data;
    let dataSelect: any = data.rows;
    dataSelect.forEach(( row:any ) => {
        row.label = `${row.name}`
        row.value = row.id;
    });
    return dataSelect;

}

export async function getAccountSelectMultiple() {
    const response = await axios.post(`${API_URL}catalogos/cuentas?sort=nombre`,{
        field_id: null,
        field_value: null
    });
    const data = response.data.doc.data;
    let dataSelect: any = data.rows;
    dataSelect.forEach(( row:any ) => {
        row.label = `${row.nombre}`
        row.value = row.id;
    });
    return dataSelect;

}

export async function getProjectionsDashborad(body: any) {

    const { tipo_oportunidad, cuenta, agente } = body;

    const filter = {
        tipoOportunidadId: tipo_oportunidad,
        vendedorId: agente,
        cuentaId: cuenta,
    }
    const response = await axios.post(`${API_URL}dashboard/proyecciones`, filter)
    const data = response.data.doc.data;
    return data;
}
