import React, { useState, useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { UserModel } from '../../../modules/auth/models/UserModel';
import { RootState } from '../../../../setup/index';
import { useParams } from 'react-router-dom';
import { useGetOppById } from '../../oportunidades/hooks/OportunidadHook';
import { formatDate } from '../../../../_metronic/helpers/FormatDate';

const View: React.FC = () => {
  const user: UserModel = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  ) as UserModel;
  const userHasRole = (roles: string[]) => {
    const luser: UserModel = user;
    let hasRole: boolean = false;

    roles.forEach((element) => {
      if (luser?.roles?.some((e) => e.code === element)) {
        hasRole = true;
      }
    });
    return hasRole;
  };
  let { id }: { id: string } = useParams();
  const { initValues } = useGetOppById(parseInt(id), 1);

  const formattedCurrency = (currencyValue: number) =>
    new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN',
    }).format(currencyValue);

  return (
    <>
      <div className="row gy-5 g-xl-8">
        <div className="col-xl-12">
          <div className={`card card-xxl-stretch mb-5 mb-xl-12`}>
            {initValues.id > 0 ? (
              <>
                <div className="card-body pt-9 pb-0">
                  <div className="d-flex flex-wrap flex-sm-nowrap mb-6">
                    <div className="flex-grow-1">
                      <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                        <div className="d-flex flex-column">
                          <div className="d-flex align-items-center mb-1">
                            <a
                              href="#"
                              className="text-gray-800 text-hover-primary fs-2 fw-bold me-3"
                            >
                              {initValues?.nombre}
                            </a>
                            <span
                              className={`badge me-auto ${initValues?.status?.code == 'PLN'
                                ? 'badge-light-success'
                                : initValues?.status?.code == 'RSG'
                                  ? 'badge-light-warning'
                                  : 'badge-light-danger'
                                }`}
                            >
                              {initValues?.status?.name}
                            </span>
                          </div>
                        </div>

                        <div className="d-flex mb-4">
                          <span className="badge badge-light-primary me-auto">
                            {`Etapa ${initValues?.etapa?.name}`}
                          </span>
                        </div>
                      </div>

                      <div className="d-flex flex-wrap justify-content-start">
                        <div className="d-flex flex-wrap">
                          <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                            <div className="d-flex align-items-center">
                              <div className="fs-4 fw-bold">
                                {` ${initValues?.cuenta?.nombre}`}
                              </div>
                            </div>

                            <div className="fw-semibold fs-6 text-gray-400">
                              Cuenta
                            </div>
                          </div>
                          <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                            <div className="d-flex align-items-center">
                              <div className="fs-4 fw-bold">
                                {` ${initValues?.subCuenta?.nombre}`}
                              </div>
                            </div>

                            <div className="fw-semibold fs-6 text-gray-400">
                              Sub cuenta
                            </div>
                          </div>
                          <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                            <div className="d-flex align-items-center">
                              <div className="fs-4 fw-bold">
                                {formatDate(
                                  new Date(
                                    initValues?.fecha_aprobacion_estimada
                                      ? initValues?.fecha_aprobacion_estimada
                                      : ''
                                  )
                                )}
                              </div>
                            </div>

                            <div className="fw-semibold fs-6 text-gray-400">
                              Fecha aprobación
                            </div>
                          </div>
                          <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                            <div className="d-flex align-items-center">
                              <div className="fs-4 fw-bold">
                                {formatDate(
                                  new Date(
                                    initValues?.fecha_cierre_real
                                      ? initValues?.fecha_cierre_real
                                      : ''
                                  )
                                )}
                              </div>
                            </div>

                            <div className="fw-semibold fs-6 text-gray-400">
                              Fecha cierre
                            </div>
                          </div>
                          <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                            <div className="d-flex align-items-center">
                              <div className="fs-4 fw-bold">
                                {formattedCurrency(
                                  initValues?.cantidad_estimada
                                )}
                              </div>
                            </div>

                            <div className="fw-semibold fs-6 text-gray-400">
                              Cantidad estimada
                            </div>
                          </div>
                        </div>

                        <div className="symbol-group symbol-hover mb-3">
                          <div
                            className="symbol symbol-35px symbol-circle"
                            title={`Vendedor Comercial ${initValues?.vendedorComercial?.nombre} ${initValues?.vendedorComercial?.apellido_paterno} ${initValues?.vendedorComercial?.apellido_materno}`}
                          >
                            <span className="symbol-label bg-warning text-inverse-warning fw-bold">
                              {initValues.vendedorComercial?.nombre
                                ? initValues.vendedorComercial?.nombre[0]
                                : ''}
                            </span>
                          </div>
                          {/* {initValues.vendedorTecnico?.nombre ? (
                            <div
                              className="symbol symbol-35px symbol-circle"
                              data-bs-toggle="tooltip"
                              title={`Vendedor Técnico ${initValues?.vendedorTecnico?.nombre} ${initValues?.vendedorTecnico?.apellido_paterno} ${initValues?.vendedorTecnico?.apellido_materno}`}
                              data-kt-initialized="1"
                            >
                              <span className="symbol-label bg-primary text-inverse-primary fw-bold">
                                {initValues.vendedorTecnico?.nombre
                                  ? initValues.vendedorTecnico?.nombre[0]
                                  : '-'}
                              </span>
                            </div>
                          ) : null} */}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="separator"></div>
                </div>
                <div className="card-body">
                  <div className="row mb-8">
                    <div className="col-xl-12">
                      <div className="d-flex flex-column">
                        <div className="d-flex justify-content-between w-100 fs-4 fw-bold mb-3">
                          <span>{`Probabilidad de éxito ${initValues.probabilidad}%`}</span>
                        </div>

                        <div className="h-8px bg-light rounded mb-3">
                          <div
                            className="bg-success rounded h-8px"
                            role="progressbar"
                            style={{ width: `${initValues.probabilidad}%` }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row mb-8">
                    <div className="col-xl-12">
                      <div
                        className="row g-9"
                        data-kt-buttons="true"
                        data-kt-buttons-target="[data-kt-button]"
                        data-kt-initialized="1"
                      >
                        <div className="col-md-4 col-lg-12 col-xxl-4">
                          <label
                            className="btn btn-outline btn-outline-dashed btn-active-light-primary  d-flex text-start p-6"
                            data-kt-button="true"
                          >
                            <span className="ms-5">
                              <span className="fs-4 fw-bold mb-1 d-block">
                                Riesgos y dependencias
                              </span>
                              <span className="fw-semibold fs-7 text-gray-600">
                                {initValues.riesgos_dependencias}
                              </span>
                            </span>
                          </label>
                        </div>

                        <div className="col-md-4 col-lg-12 col-xxl-4">
                          <label
                            className="btn btn-outline btn-outline-dashed btn-active-light-primary d-flex text-start p-6"
                            data-kt-button="true"
                          >
                            <span className="ms-5">
                              <span className="fs-4 fw-bold mb-1 d-block">
                                Mitigaciones
                              </span>
                              <span className="fw-semibold fs-7 text-gray-600">
                                {initValues.mitigaciones}
                              </span>
                            </span>
                          </label>
                        </div>

                        <div className="col-md-4 col-lg-12 col-xxl-4">
                          <label
                            className="btn btn-outline btn-outline-dashed btn-active-light-primary d-flex text-start p-6"
                            data-kt-button="true"
                          >
                            <span className="ms-5">
                              <span className="fs-4 fw-bold mb-1 d-block">
                                Siguientes pasos
                              </span>
                              <span className="fw-semibold fs-7 text-gray-600">
                                {initValues.siguentes_pasos}
                              </span>
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export { View };
