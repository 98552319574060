import axios from 'axios';
import { HomeFilterBoard } from '../../pages/home/models/HomeModel';
const API_URL = process.env.REACT_APP_API;


export async function getCountDetailByUser(filter: number) {

    const data_filter = {
        time: filter
    }
    const response = await axios.post(`${API_URL}homePage/count`, data_filter);

    const data = response.data.doc?.data;

    return {
        data: data,
    };

}

export async function getDataBoard(filter: HomeFilterBoard) {

    const { campo_id, valor } = filter
    const data_filter = {
        campo_id, valor
    }
    const response = await axios.post(`${API_URL}homePage/oportunidades`, data_filter);

    const data = response.data.doc?.data;

    return {
        data: data,
    };
}

export async function getDataTask() {

    const response = await axios.get(`${API_URL}homePage/task`);

    const data = response.data.doc?.data;

    return {
        data: data,
    };
};

export async function getDataFilterBySelectFiled(code: string) {

    let response;
    let data;

    switch (code) {
        case 'NOM':
            response = await axios.get(`${API_URL}oportunidades`);
            data = response.data.doc.data.rows;
            break;
        case 'ETA_ID':
            response = await axios.get(`${API_URL}catalogs/ETP?sort=name`);
            data = response.data.doc.data.rows;
            break;
        case 'STA_ID':
            response = await axios.get(`${API_URL}catalogs/STS?sort=name`);
            data = response.data.doc.data.rows;
            break;
        case 'CUE_ID':
            response = await axios.post(`${API_URL}catalogos/cuentas`,{
                field_id: null,
                field_value: null
            });
            data = response.data.doc.data.rows;
            break;
        case 'SUB_CUE_ID':
            response = await axios.get(`${API_URL}catalogos/subcuentas`);
            data = response.data.doc.data.rows;
            break;
        case 'TIP_ID':
            response = await axios.get(`${API_URL}catalogs/TOP?sort=name`);
            data = response.data.doc.data.rows;
            break;
        case 'DEP_ID':
            response = await axios.get(`${API_URL}catalogs/DEP?sort=name`);
            data = response.data.doc.data.rows;
            break;
        case 'CON_ID':
            response = await axios.get(`${API_URL}catalogos/contactos`);
            data = response.data.doc.data.rows;
            break;
        case 'VEN_COM_ID':
            response = await axios.get(`${API_URL}catalogos/vendedores/list/comerciales`);
            data = response.data.doc.data.rows;
            break;
        case 'VEN_TEC_ID':
            response = await axios.get(`${API_URL}catalogos/vendedores/list/tecnicos`);
            data = response.data.doc.data.rows;
            break;

        default:
            break;
    }

    return data;

}


export async function getDataFilterBySelectFiledProducts(code: string) {

    let response;
    let data;

    switch (code) {
        case 'PROD_NOM':
            response = await axios.post(`${API_URL}productos`,{
                campo_id: null,
                valor: null
            });
            data = response.data.doc.data;
            break;
        case 'PROD_UNEG':
            response = await axios.get(`${API_URL}catalogs/UNEG?sort=name`);
            data = response.data.doc.data.rows;
            break;
        case 'PROD_TIPO':
            response = await axios.get(`${API_URL}catalogs/TPROD?sort=name`);
            data = response.data.doc.data.rows;
            break;

        default:
            break;
    }
    return data;

}