import React, { useState, useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { UserModel } from '../../../modules/auth/models/UserModel';
import { RootState } from '../../../../setup/index';
import { ListGrid } from './ListGrid';
import { Filter } from './Filter';
import { useGetAllCuentas } from '../../contacto/hooks/ContactoHook';
import {
  useGetAllSubCuentas,
  useGetAllOportunidades,
  useGetAllContactos,
} from '../hooks/EmailHook';

import { useGetAllTemplateFilter } from '../../plantillas/hooks/PlantillaHook';
const View: React.FC = () => {
  const user: UserModel = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  ) as UserModel;
  const userHasRole = (roles: string[]) => {
    const luser: UserModel = user;
    let hasRole: boolean = false;

    roles.forEach((element) => {
      if (luser?.roles?.some((e) => e.code === element)) {
        hasRole = true;
      }
    });
    return hasRole;
  };

  const [loadingGrid, setLoadingGrid] = useState(true);
  const [reloadGrid, setReloadGrid] = useState(Math.random() * 40);
  const { dataFilter } = useGetAllTemplateFilter(reloadGrid);
  const { dataCuenta } = useGetAllCuentas(1);
  const [cuentaId, setCuentaId] = useState<[]>([]);
  const [subcuentaId, setSubCuentaId] = useState<[]>([]);
  const [contactoId, setContactoId] = useState<[]>([]);
  const { dataSub } = useGetAllSubCuentas(cuentaId);
  const { dataOportunidades } = useGetAllOportunidades(reloadGrid, subcuentaId);
  const [initFilterValues, setInitFilterValues] = useState({});
  const [indicator, setIndicator] = useState<boolean>(true);
  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const { dataContactos, count, loadingRequest } = useGetAllContactos(
    reloadGrid,
    pageSize,
    currentPage,
    cuentaId,
    subcuentaId,
    contactoId,
    indicator
  );

  const totalPages = Math.ceil(count / pageSize)
    ? Math.ceil(count / pageSize)
    : 1;

  useEffect(() => {
    if (loadingRequest > 0) {
      setLoadingGrid(false);
    }
  }, [loadingRequest]);

  const onSearchFilter = (values: any) => {
    setLoadingGrid(true);
    if (values) {
      setInitFilterValues(values);
    } else {
      setInitFilterValues({});
    }
    setReloadGrid(Math.random() * 40);
  };
  const onCleanFilter = () => {
    setLoadingGrid(true);
    setIndicator(false);
    setInitFilterValues({});
    setCuentaId([]);
    setContactoId([]);
    setSubCuentaId([]);

    setReloadGrid(Math.random() * 40);
  };

  const handleGetFilter = (value: [], type: number) => {
    setIndicator(true);
    if (type === 1) {
      setCuentaId(value);
      setSubCuentaId([]);
      setContactoId([]);
    } else if (type === 2) {
      setSubCuentaId(value);
      setContactoId([]);
    } else if (type == 3) {
      setContactoId(value);
    }
  };

  return (
    <>
      <div className="row gy-5 g-xl-8">
        <div className="col-xl-12">
          <div className={`card card-xxl-stretch mb-5 mb-xl-12`}>
            <div className="card-header border-0 pt-5">
              <Filter
                key={reloadGrid}
                initFilterValues={initFilterValues}
                onCleanFilter={onCleanFilter}
                onSearchFilter={onSearchFilter}
                cuentas={dataCuenta}
                subcuentas={dataSub}
                dataOportunidades={dataOportunidades}
                filterOpo={handleGetFilter}
              />
            </div>
            <ListGrid
              data={dataContactos}
              loadingGrid={loadingGrid}
              count={count}
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={(pageNumber: number) => {
                setLoadingGrid(true);
                setCurrentPage(pageNumber);
              }}
              onCleanFilter={onCleanFilter}
              template={dataFilter}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export { View };
