export const toAbsoluteUrl = (pathname: string) =>
  process.env.PUBLIC_URL + pathname;

export const maskPhoneNumber = (inputPhoneNumber: string): string => {
  const numericValue = inputPhoneNumber.replace(/[^\d]/g, '');
  let maskedValue = '';
  for (let i = 0; i < numericValue.length && i < 10; i++) {
    if (i === 0) maskedValue += `${numericValue[i]}`;
    else if (i === 2) maskedValue += ` ${numericValue[i]}`;
    else if (i === 6) maskedValue += ` ${numericValue[i]}`;
    else maskedValue += numericValue[i];
  }
  return maskedValue;
};
export const cleanPhoneNumber = (phoneNumber: string): string => {
  const phone = phoneNumber.replace(/\D/g, '');
  return phone;
};

export const maskPhoneNumberNineteen = (inputPhoneNumber: string): string => {
  const numericValue = inputPhoneNumber.replace(/[^\d]/g, ''); // Eliminar caracteres no numéricos
  let maskedValue = '';
  for (let i = 0; i < numericValue.length && i < 19; i++) {
    if (i === 0) maskedValue += `${numericValue[i]}`;
    else if (i === 2 || i === 6 || i === 10 || i === 15)
      maskedValue += ` ${numericValue[i]}`;
    // Insertar espacios en posiciones específicas
    else maskedValue += numericValue[i];
  }
  return maskedValue;
};
