import { FC } from 'react';
import { PageTitle, PageLink } from '../../../_metronic/layout/core';
import { ViewAddEdit } from './components/ViewAddEdit';
import { useParams } from 'react-router-dom';
import { decryptId } from '../../../_metronic/helpers';

const SubaccountCreateEditPage: FC = () => {
  const { id_account, id } = useParams<{ id_account: string; id: string }>();
  const idRegister = decryptId(id);
  const accountId = decryptId(id_account);

  const breadCrumbs: Array<PageLink> = [
    {
      title: 'Cuentas',
      path: '/clientes/cuentas/',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
    {
      title: 'Editar',
      path: `/clientes/cuentas/edit/${id_account}`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
    {
      title: 'Subcuenta',
      path: '#/',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ];
  return (
    <>
      <PageTitle breadcrumbs={breadCrumbs}>
        {idRegister ? 'Editar subcuenta' : 'Nueva subcuenta'}
      </PageTitle>
      <ViewAddEdit
        id={idRegister ? idRegister : '0'}
        accountId={accountId ? accountId : '0'}
      />
    </>
  );
};

export { SubaccountCreateEditPage };
