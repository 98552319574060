import { FC } from 'react';
import { PageTitle, PageLink } from '../../../_metronic/layout/core';
import { ViewAddEdit } from './components/ViewAddEdit';
import { useParams } from 'react-router-dom';
import { decryptId } from '../../../_metronic/helpers';

const AccountCreateEditPage: FC = () => {
  const { id } = useParams<{ id: string }>();
  const idRegister = decryptId(id);
  const breadCrumbs: Array<PageLink> = [
    {
      title: 'Clientes',
      path: '/clientes/cuentas/',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
    {
      title: 'Cuentas',
      path: '/clientes/cuentas/',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ];
  return (
    <>
      <PageTitle breadcrumbs={breadCrumbs}>
        {idRegister ? 'Editar cuenta' : 'Nueva cuenta'}
      </PageTitle>
      <ViewAddEdit id={idRegister ? idRegister : '0'} />
    </>
  );
};

export { AccountCreateEditPage };
