import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  createHistoryMessage,
  getHistory,
  getMitigateByID,
  updateMitigate,
  getDocumentsByMitigateId
} from '../../../service/mitigate/Mitigate';
import { MitigateModel, MitigationDocModel } from '../models/MitigateModel';
import { HistoryModel } from '../../../../_metronic/helpers/models/HistoryModel';

export const useGetMitigateByID = (id: string, reload: number) => {
  const mitigate: MitigateModel = {
    id: 0,
    nombre: '',
    descripcion: '',
    fecha_inicio: new Date().toISOString().split('T')[0],
    fecha_fin: new Date().toISOString().split('T')[0],
    statusMitigacion: null,
    etapaMitigacion: null,
    prioridadMitigacion: null,
    riesgo: null,
    vendedorComercial: {
      user_asignado: null,
    },
    user_creador: null,
    status_id: 0,
    etapa_id: 0,
    prioridad_id: 0,
    user_asignado_id: 0,
    documentos: null,
  };
  const [mitigateInit, setMitigateInit] = useState<MitigateModel>(mitigate);

  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      const res = await getMitigateByID(id)
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        setMitigateInit(res);
      }
    };
    if (id) {
      fetchPost();
    } else {
      setMitigateInit(mitigate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, reload]);

  return { mitigateInit };
};

export const useEditMitigate = () => {
  const [loadingEditMitigate, setLoadingEditMitigate] =
    useState<boolean>(false);
  const history = useHistory();
  const editMitigate = async (mitigate: MitigateModel, riskId: number) => {
    setLoadingEditMitigate(false);
    try {
      await updateMitigate(mitigate, riskId);
      setLoadingEditMitigate(true);
    } catch (error) {
      history.push('/error/500');
      setLoadingEditMitigate(false);
    }
  };
  return { editMitigate, loadingEditMitigate };
};

export const useGetHistory = (reaload: number, search: string, id: string) => {
  const [data, setData] = useState<HistoryModel[]>([]);
  const [loadingRequest, setLoadingRequest] = useState(0);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      setLoadingRequest(0);
      const res = await getHistory(search, id)
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        setData(res);
        setLoadingRequest(1);
      }
    };
    if (reaload && Number(id) > 0) {
      fetchPost();
    }
  }, [reaload]);

  return { data, loadingRequest };
};

export const useInsertMessage = () => {
  const [loadingInsert, setLoadingInsert] = useState<boolean>(false);
  const history = useHistory();
  const insertData = async (message: string, id: string) => {
    setLoadingInsert(false);
    try {
      await createHistoryMessage(message, id);
      setLoadingInsert(true);
    } catch (error) {
      history.push('/error/500');
      setLoadingInsert(false);
    }
  };
  return { insertData, loadingInsert };
};

export const useGetDocumentsByTaskId = (mitigateId: string, reload: number) => {
  const [dataDocuments, setDataDocuments] = useState<MitigationDocModel[]>([]);
  const [loadingDocuments, setLoadingDocuments] = useState<boolean>(false);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      setLoadingDocuments(false);
      const res = await getDocumentsByMitigateId(mitigateId)
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        setDataDocuments(res);
        setLoadingDocuments(true);
      }
    };
    fetchPost();
  }, [reload]);

  return { dataDocuments, loadingDocuments };
};