import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { formattedCurrency } from '../FormatCurrency';

type Props = {
  title: string;
  description: string;
  // height: number;
  series: any[];
  categories: string[];
  porcentajes: any[];
};

const StackedBarChart: React.FC<Props> = ({
  title,
  description,
  series,
  categories,
  porcentajes,
}) => {
  const options = {
    chart: {
      type: 'bar' as const,
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: categories,
      labels: {
        style: {
          fontSize: '10px',
        },
      },
    },
    theme: {
      monochrome: {
        enabled: true,
        color: '#2983FF',
        shadeTo: 'light' as const,
        shadeIntensity: 0.65,
      },
    },
    grid: {
      show: false,
    },
    legend: {
      show: true,
      position: 'right' as const,
      onItemClick: {
        toggleDataSeries: false,
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
    tooltip: {
      enabled: true,
      // x: {
      //   formatter: function (val: any) {
      //     return 'Valor X: ' + val;
      //   },
      // },
      y: {
        formatter: function (val: any) {
          return formattedCurrency(val);
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        dataLabels: {
          total: {
            enabled: true,
            offsetX: 0,
            style: {
              fontSize: '13px',
              fontWeight: 900,
            },
            formatter: function (val: any, opts: any) {
              return String(
                porcentajes[opts.dataPointIndex]
                  ? porcentajes[opts.dataPointIndex] + '%'
                  : ''
              );
            },
          },
        },
      },
    },
  };

  return (
    <>
      <div className={`card mb-0 mb-xxl-8 h-100`}>
        <div className="card-header border-0 pt-5">
          <div className="card-title flex-column w-100">
            <span className="card-label text-primary fw-bolder fs-1 mb-1 w-100 text-end">
              <span className="position-relative d-inline-block text-primary">
                <span className="text-primary opacity-75-hover"> {title}</span>
                <span className="position-absolute opacity-15 bottom-0 start-0 border-4 border-primary border-bottom w-100"></span>
              </span>
            </span>
          </div>
        </div>

        <div className="card-body">
          <ReactApexChart
            options={options}
            series={series}
            type="bar"
            height={250}
          />
        </div>
      </div>
    </>
  );
};

export default StackedBarChart;
