import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { encryptId } from '../../../../_metronic/helpers';

type Props = {
  board: any;
};

const CardAccount: FC<Props> = ({ board }) => {
  const history = useHistory();
  const itemsPerPage = 9;
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(board.length / itemsPerPage);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, board.length);

  const onPageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  return (
    <>
      <div className="container">
        <div className="row">
          {board.slice(startIndex, endIndex).map((item: any, index: number) => {
            return (
              <div
                key={index}
                className="col-md-6 col-lg-6 col-xxl-4 col-xl-6 mb-4 mt-4"
              >
                <div
                  className="card borders-card-board h-100 cursor-pointer"
                  onClick={() =>
                    history.push(
                      `/clientes/cuentas/edit/${encryptId(item.id.toString())}`
                    )
                  }
                >
                  <div className="card-body p-9" style={{ overflow: 'hidden' }}>
                    <div className="d-flex justify-content-between w-100">
                      <div>
                        <div className="fs-4 text-hover-primary large-text fw-bolder mb-0">
                          {item?.nombre}
                        </div>
                        <div className="fw-bold text-gray-700 mb-6">
                          {item.tipoCuenta?.name}
                        </div>
                        <div className="fs-6 fw-normal text-gray-600 mt-6">
                          {item?.referido
                            ? item.referido.nombre_completo
                            : 'Sin referido'}
                        </div>
                      </div>
                      <div className="symbol symbol-40px">
                        {item.vendedorComercial?.nombre ? (
                          <span
                            className={`symbol-label bg-light-primary text-primary fs-5 fw-bolder`}
                          >
                            {`${item.vendedorComercial?.nombre.substring(
                              0,
                              1
                            )}${item.vendedorComercial?.apellido_paterno.substring(
                              0,
                              1
                            )}`}
                          </span>
                        ) : null}
                      </div>
                    </div>
                    <span className="position-absolute opacity-15 bottom-0 start-0 border-4 border-primary border-bottom w-100"></span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="row justify-content-center">
          <nav aria-label="Navegación de página">
            <ul className="pagination">
              {Array.from({ length: totalPages }, (_, i) => (
                <li
                  key={i}
                  className={`page-item ${
                    currentPage === i + 1 ? 'active' : ''
                  }`}
                >
                  <button
                    className="page-link"
                    onClick={() => onPageChange(i + 1)}
                  >
                    {i + 1}
                  </button>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
};

export { CardAccount };
