import React, { FC, useEffect, useRef, useState } from "react";
import { Formik, Form, FormikValues, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Modal } from "react-bootstrap-v5";
import { StepperComponent } from "../../../../../_metronic/assets/ts/components";
import { KTSVG } from "../../../../../_metronic/helpers";
import Select from "react-select";
import { useGetAll } from "../../../oportunidades/hooks/OportunidadHook";

type Props = {
  show: boolean;
};
interface ICreateAccount {
  appName: string;
  category: string;
  framework: string;
  dbName: string;
  dbType: string;
  nameOnCard: string;
  cardNumber: string;
  cardExpiryMonth: string;
  cardExpiryYear: string;
  cardCvv: string;
  saveCard: string;
}

const inits: ICreateAccount = {
  appName: "",
  category: "1",
  framework: "1",
  dbName: "",
  dbType: "1",
  nameOnCard: "Max Doe",
  cardNumber: "4111 1111 1111 1111",
  cardExpiryMonth: "1",
  cardExpiryYear: "2025",
  cardCvv: "123",
  saveCard: "1",
};

const createAppSchema = [
  Yup.object({
    appName: Yup.string().required().label("App name"),
    category: Yup.string().required().label("Category"),
  }),
  Yup.object({
    framework: Yup.string().required().label("Framework"),
  }),
  Yup.object({
    dbName: Yup.string().required().label("Database name"),
    dbType: Yup.string().required().label("Database engine"),
  }),
  Yup.object({
    nameOnCard: Yup.string().required().label("Name"),
    cardNumber: Yup.string().required().label("Card Number"),
    cardExpiryMonth: Yup.string().required().label("Expiration Month"),
    cardExpiryYear: Yup.string().required().label("Expiration Year"),
    cardCvv: Yup.string().required().label("CVV"),
  }),
];

const QuotationWizard: React.FC<Props> = ({ show }) => {
  const stepperRef = useRef<HTMLDivElement | null>(null);
  const stepper = useRef<StepperComponent | null>(null);
  const [currentSchema, setCurrentSchema] = useState(createAppSchema[0]);
  const [initValues] = useState<ICreateAccount>(inits);

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(
      stepperRef.current as HTMLDivElement
    );
  };

  const prevStep = () => {
    if (!stepper.current) {
      return;
    }

    stepper.current.goPrev();

    setCurrentSchema(createAppSchema[stepper.current.currentStepIndex - 1]);
  };

  const submitStep = (values: ICreateAccount, actions: FormikValues) => {
    if (!stepper.current) {
      return;
    }

    setCurrentSchema(createAppSchema[stepper.current.currentStepIndex]);

    if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
      stepper.current.goNext();
    } else {
      stepper.current.goto(1);
      actions.resetForm();
    }
  };

  useEffect(() => {
    if (!stepperRef.current) {
      return;
    }

    loadStepper();
  }, [stepperRef]);

  return (
    <Modal
      id="kt_modal_create"
      tabIndex={-1}
      aria-hidden="true"
      backdrop="static"
      dialogClassName="modal-dialog modal-dialog-centered mw-800px"
      show={show}
      onHide={() => {
        // formContact.resetForm();
        // handleClose(false, 0);
      }}
    >
      <div className="modal-content">
        <div className="modal-header">
          <h2>¿Deseas ligar la cotización a una oportunidad existente?</h2>

          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
          >
            <KTSVG
              path="/media/icons/duotune/arrows/arr061.svg"
              className="svg-icon-1"
            />
          </div>
        </div>

        <div
          className="modal-body py-lg-10 px-lg-10"
          // style={{ border: "solid 1px red" }}
        >
          <div
            ref={stepperRef}
            className="stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid"
            id="kt_modal_create_app_stepper"
          >
            <div className="d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px">
              <div className="stepper-nav ps-lg-10">
                <div
                  className="stepper-item current"
                  data-kt-stepper-element="nav"
                >
                  <div className="stepper-line w-40px"></div>

                  <div className="stepper-icon w-40px h-40px">
                    <i className="stepper-check fas fa-check"></i>
                    <span className="stepper-number">1</span>
                  </div>

                  <div className="stepper-label">
                    <h3 className="stepper-title">Oportunidad</h3>

                    <div className="stepper-desc">Elije la oportunidad</div>
                  </div>
                </div>

                <div className="stepper-item" data-kt-stepper-element="nav">
                  <div className="stepper-line w-40px"></div>

                  <div className="stepper-icon w-40px h-40px">
                    <i className="stepper-check fas fa-check"></i>
                    <span className="stepper-number">2</span>
                  </div>

                  <div className="stepper-label">
                    <h3 className="stepper-title">Template</h3>

                    <div className="stepper-desc">Define tu template</div>
                  </div>
                </div>

                <div className="stepper-item" data-kt-stepper-element="nav">
                  <div className="stepper-line w-40px"></div>

                  <div className="stepper-icon w-40px h-40px">
                    <i className="stepper-check fas fa-check"></i>
                    <span className="stepper-number">3</span>
                  </div>

                  <div className="stepper-label">
                    <h3 className="stepper-title">Más información</h3>

                    <div className="stepper-desc">Informcación adicional</div>
                  </div>
                </div>

                <div className="stepper-item" data-kt-stepper-element="nav">
                  <div className="stepper-line w-40px"></div>

                  <div className="stepper-icon w-40px h-40px">
                    <i className="stepper-check fas fa-check"></i>
                    <span className="stepper-number">4</span>
                  </div>

                  <div className="stepper-label">
                    <h3 className="stepper-title">Más información</h3>

                    <div className="stepper-desc">Informcación adicional</div>
                  </div>
                </div>

                <div className="stepper-item" data-kt-stepper-element="nav">
                  <div className="stepper-line w-40px"></div>

                  <div className="stepper-icon w-40px h-40px">
                    <i className="stepper-check fas fa-check"></i>
                    <span className="stepper-number">5</span>
                  </div>

                  <div className="stepper-label">
                    <h3 className="stepper-title">Más información</h3>

                    <div className="stepper-desc">Informcación adicional</div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex-row-fluid py-lg-5 px-lg-15">
              <Formik
                validationSchema={currentSchema}
                initialValues={initValues}
                onSubmit={submitStep}
              >
                {() => (
                  <Form
                    className="form"
                    noValidate
                    id="kt_modal_create_app_form"
                  >
                    <div className="current" data-kt-stepper-element="content">
                      <div className="w-100">
                        <div className="fv-row mb-10">
                          <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                            <span className="required">Oportunidad</span>
                            <i
                              className="fas fa-exclamation-circle ms-2 fs-7"
                              data-bs-toggle="tooltip"
                              title="Especifica el nombre de la campaña"
                            ></i>
                          </label>

                          <Select
                            // options={account}
                            // value={selectedOptionAccount}
                            // styles={styleSelectPackages}
                            placeholder={"Seleccione"}
                            isSearchable={true}
                            noOptionsMessage={() => {
                              return (
                                <span>{"No se encontraron registros"}</span>
                              );
                            }}
                            // onChange={changeAccount}
                            // isLoading={loadingAccount}
                            backspaceRemovesValue
                          />
                          <div className="fv-plugins-message-container invalid-feedback">
                            <ErrorMessage name="appName" />
                          </div>
                        </div>

                        <div className="fv-row">
                          <label className="d-flex align-items-center fs-5 fw-bold mb-4">
                            <span className="required">Categoría</span>

                            <i
                              className="fas fa-exclamation-circle ms-2 fs-7"
                              data-bs-toggle="tooltip"
                              title="Select your app category"
                            ></i>
                          </label>

                          <div className="fv-row">
                            <label className="d-flex flex-stack mb-5 cursor-pointer">
                              <span className="d-flex align-items-center me-2">
                                <span className="symbol symbol-50px me-6">
                                  <span className="symbol-label bg-light-primary">
                                    <KTSVG
                                      path="/media/icons/duotune/maps/map004.svg"
                                      className="svg-icon-1 svg-icon-primary"
                                    />
                                  </span>
                                </span>

                                <span className="d-flex flex-column">
                                  <span className="fw-bolder fs-6">
                                    Categoría 1
                                  </span>

                                  <span className="fs-7 text-muted">
                                    Información de la categoría 1
                                  </span>
                                </span>
                              </span>

                              <span className="form-check form-check-custom form-check-solid">
                                <Field
                                  className="form-check-input"
                                  type="radio"
                                  name="category"
                                  value="1"
                                />
                              </span>
                            </label>

                            <label className="d-flex flex-stack mb-5 cursor-pointer">
                              <span className="d-flex align-items-center me-2">
                                <span className="symbol symbol-50px me-6">
                                  <span className="symbol-label bg-light-danger  ">
                                    <KTSVG
                                      path="/media/icons/duotune/general/gen024.svg"
                                      className="svg-icon-1 svg-icon-danger"
                                    />
                                  </span>
                                </span>

                                <span className="d-flex flex-column">
                                  <span className="fw-bolder fs-6">
                                    Categoría 1
                                  </span>

                                  <span className="fs-7 text-muted">
                                    Información de la categoría 2
                                  </span>
                                </span>
                              </span>

                              <span className="form-check form-check-custom form-check-solid">
                                <Field
                                  className="form-check-input"
                                  type="radio"
                                  name="category"
                                  value="2"
                                />
                              </span>
                            </label>

                            <label className="d-flex flex-stack cursor-pointer">
                              <span className="d-flex align-items-center me-2">
                                <span className="symbol symbol-50px me-6">
                                  <span className="symbol-label bg-light-success">
                                    <KTSVG
                                      path="/media/icons/duotune/general/gen013.svg"
                                      className="svg-icon-1 svg-icon-success"
                                    />
                                  </span>
                                </span>

                                <span className="d-flex flex-column">
                                  <span className="fw-bolder fs-6">
                                    Categoría 1
                                  </span>

                                  <span className="fs-7 text-muted">
                                    Información de la categoría 3
                                  </span>
                                </span>
                              </span>

                              <span className="form-check form-check-custom form-check-solid">
                                <Field
                                  className="form-check-input"
                                  type="radio"
                                  name="category"
                                  value="3"
                                />
                              </span>
                            </label>
                          </div>

                          <div className="fv-plugins-message-container invalid-feedback">
                            <ErrorMessage name="category" />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div data-kt-stepper-element="content">
                      <div className="w-100">
                        <div className="fv-row">
                          <label className="d-flex align-items-center fs-5 fw-bold mb-4">
                            <span className="required">
                              Selecciona el template
                            </span>
                            <i
                              className="fas fa-exclamation-circle ms-2 fs-7"
                              data-bs-toggle="tooltip"
                              title="Selecciona el template"
                            ></i>
                          </label>

                          <label className="d-flex flex-stack cursor-pointer mb-5">
                            <span className="d-flex align-items-center me-2">
                              <span className="symbol symbol-50px me-6">
                                <span className="symbol-label bg-light-warning">
                                  <i className="fab fa-html5 text-warning fs-2x"></i>
                                </span>
                              </span>

                              <span className="d-flex flex-column">
                                <span className="fw-bolder fs-6">
                                  Template 1
                                </span>

                                <span className="fs-7 text-muted">
                                  Informcación template
                                </span>
                              </span>
                            </span>

                            <span className="form-check form-check-custom form-check-solid">
                              <Field
                                className="form-check-input"
                                type="radio"
                                name="framework"
                                value="1"
                              />
                            </span>
                          </label>

                          <label className="d-flex flex-stack cursor-pointer mb-5">
                            <span className="d-flex align-items-center me-2">
                              <span className="symbol symbol-50px me-6">
                                <span className="symbol-label bg-light-success">
                                  <i className="fab fa-react text-success fs-2x"></i>
                                </span>
                              </span>

                              <span className="d-flex flex-column">
                                <span className="fw-bolder fs-6">
                                  Template 2
                                </span>
                                <span className="fs-7 text-muted">
                                  Informcación template
                                </span>
                              </span>
                            </span>

                            <span className="form-check form-check-custom form-check-solid">
                              <Field
                                className="form-check-input"
                                type="radio"
                                name="framework"
                                value="2"
                              />
                            </span>
                          </label>

                          <label className="d-flex flex-stack cursor-pointer mb-5">
                            <span className="d-flex align-items-center me-2">
                              <span className="symbol symbol-50px me-6">
                                <span className="symbol-label bg-light-danger">
                                  <i className="fab fa-angular text-danger fs-2x"></i>
                                </span>
                              </span>

                              <span className="d-flex flex-column">
                                <span className="fw-bolder fs-6">
                                  Template 3
                                </span>
                                <span className="fs-7 text-muted">
                                  Informcación template
                                </span>
                              </span>
                            </span>

                            <span className="form-check form-check-custom form-check-solid">
                              <Field
                                className="form-check-input"
                                type="radio"
                                name="framework"
                                value="3"
                              />
                            </span>
                          </label>

                          <label className="d-flex flex-stack cursor-pointer">
                            <span className="d-flex align-items-center me-2">
                              <span className="symbol symbol-50px me-6">
                                <span className="symbol-label bg-light-primary">
                                  <i className="fab fa-vuejs text-primary fs-2x"></i>
                                </span>
                              </span>

                              <span className="d-flex flex-column">
                                <span className="fw-bolder fs-6">
                                  Template 4
                                </span>
                                <span className="fs-7 text-muted">
                                  Informcación template
                                </span>
                              </span>
                            </span>

                            <span className="form-check form-check-custom form-check-solid">
                              <Field
                                className="form-check-input"
                                type="radio"
                                name="framework"
                                value="4"
                              />
                            </span>
                          </label>
                        </div>
                        <div className="fv-plugins-message-container invalid-feedback">
                          <ErrorMessage name="framework" />
                        </div>
                      </div>
                    </div>

                    <div data-kt-stepper-element="content">
                      <div className="w-100">
                        <div className="fv-row mb-10">
                          <label className="required fs-5 fw-bold mb-2">
                            Cliente
                          </label>

                          <Field
                            type="text"
                            className="form-control form-control-lg form-control-solid"
                            name="dbName"
                            placeholder=""
                          />
                          <div className="fv-plugins-message-container invalid-feedback">
                            <ErrorMessage name="dbName" />
                          </div>
                        </div>

                        <div className="fv-row">
                          <label className="d-flex align-items-center fs-5 fw-bold mb-4">
                            <span className="required">
                              Seleccione el cliente
                            </span>

                            <i
                              className="fas fa-exclamation-circle ms-2 fs-7"
                              data-bs-toggle="tooltip"
                              title="Seleccione el cliente"
                            ></i>
                          </label>

                          <label className="d-flex flex-stack cursor-pointer mb-5">
                            <span className="d-flex align-items-center me-2">
                              <span className="symbol symbol-50px me-6">
                                <span className="symbol-label bg-light-success">
                                  <i className="fas fa-database text-success fs-2x"></i>
                                </span>
                              </span>

                              <span className="d-flex flex-column">
                                <span className="fw-bolder fs-6">
                                  Cliente 1
                                </span>

                                <span className="fs-7 text-muted">
                                  Información cliente
                                </span>
                              </span>
                            </span>

                            <span className="form-check form-check-custom form-check-solid">
                              <Field
                                className="form-check-input"
                                type="radio"
                                name="dbType"
                                value="1"
                              />
                            </span>
                          </label>

                          <label className="d-flex flex-stack cursor-pointer mb-5">
                            <span className="d-flex align-items-center me-2">
                              <span className="symbol symbol-50px me-6">
                                <span className="symbol-label bg-light-danger">
                                  <i className="fab fa-google text-danger fs-2x"></i>
                                </span>
                              </span>

                              <span className="d-flex flex-column">
                                <span className="fw-bolder fs-6">
                                  Cliente 2
                                </span>

                                <span className="fs-7 text-muted">
                                  Información cliente
                                </span>
                              </span>
                            </span>

                            <span className="form-check form-check-custom form-check-solid">
                              <Field
                                className="form-check-input"
                                type="radio"
                                name="dbType"
                                value="2"
                              />
                            </span>
                          </label>

                          <label className="d-flex flex-stack cursor-pointer">
                            <span className="d-flex align-items-center me-2">
                              <span className="symbol symbol-50px me-6">
                                <span className="symbol-label bg-light-warning">
                                  <i className="fab fa-amazon text-warning fs-2x"></i>
                                </span>
                              </span>

                              <span className="d-flex flex-column">
                                <span className="fw-bolder fs-6">
                                  Cliente 3
                                </span>

                                <span className="fs-7 text-muted">
                                  Información cliente
                                </span>
                              </span>
                            </span>

                            <span className="form-check form-check-custom form-check-solid">
                              <Field
                                className="form-check-input"
                                type="radio"
                                name="dbType"
                                value="3"
                              />
                            </span>
                          </label>
                        </div>

                        <div className="fv-plugins-message-container invalid-feedback">
                          <ErrorMessage name="dbType" />
                        </div>
                      </div>
                    </div>

                    <div className="d-flex flex-stack pt-10">
                      <div className="me-2">
                        <button
                          onClick={prevStep}
                          type="button"
                          className="btn btn-lg btn-light-primary me-3"
                          data-kt-stepper-action="previous"
                        >
                          <KTSVG
                            path="/media/icons/duotune/arrows/arr063.svg"
                            className="svg-icon-4 me-1"
                          />
                          Back
                        </button>
                      </div>

                      <div>
                        <button
                          type="submit"
                          className="btn btn-lg btn-primary me-3"
                        >
                          <span className="indicator-label">
                            {stepper.current?.currentStepIndex !==
                              stepper.current?.totatStepsNumber! - 1 &&
                              "Continue"}
                            {stepper.current?.currentStepIndex ===
                              stepper.current?.totatStepsNumber! - 1 &&
                              "Submit"}
                            <KTSVG
                              path="/media/icons/duotune/arrows/arr064.svg"
                              className="svg-icon-3 ms-2 me-0"
                            />
                          </span>
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
      {/* </div> */}
    </Modal>
  );
};

export { QuotationWizard };
