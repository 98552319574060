import React, { useState, useEffect } from "react";
import { KTSVG, encryptId } from "../../../../../_metronic/helpers";
import { Link } from "react-router-dom";
import { dateFormatt } from "../../../../../_metronic/helpers/FormatDate";
import { useHistory } from "react-router-dom";
import { QuotationModel } from "../../models/OportunityQuotations";
import { formattedCurrency } from "../../../../../_metronic/helpers/FormatCurrency";

type Props = {
  data: any[];
  isEdit: boolean;
  oportunty: number;
};

const QuotationList: React.FC<Props> = ({ data, isEdit, oportunty }) => {
  const [dataMemo, setDataMemo] = useState<QuotationModel[]>(data);
  const history = useHistory();

  useEffect(() => {
    setDataMemo(data);
  }, [data]);

  return (
    <>
      <div className="table-responsive min-h-200px me-n5 pe-5 scroll-y">
        <table className="table table-border table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 me-n5 pe-5">
          <thead>
            <tr className="text-gray-400 fs-7">
              <th className="w-100px">Nombre</th>
              <th className="w-100px text-center">Asignado a</th>
              <th className="w-100px">Fecha elaboración</th>
              <th className="w-100px">Tipo de cotización</th>
              <th className="w-100px">Tipo de moneda</th>
              <th className="w-100px">Monto</th>
            </tr>
          </thead>
          <tbody className="">
            {dataMemo.map((row: QuotationModel) => (
              <tr key={row.id}>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start w-100 flex-column fs-012 font-weight-mormal text-muted">
                      {!isEdit ? (
                        <Link
                          className="text-primary"
                          to={`/cotizaciones/edit/${encryptId(
                            row.id.toString()
                          )}`}
                        >
                          {row.nombre}
                        </Link>
                      ) : (
                        row.nombre
                      )}
                    </div>
                  </div>
                </td>
                <td>
                  <div
                    className="d-flex align-items-center"
                    style={{ justifyContent: "center", alignContent: "center" }}
                  >
                    <div className="d-flex  flex-column fs-012">
                      <div className="symbol symbol-25px tooltip-soft">
                        {row.vendedorAsignado && (
                          <>
                            {row.vendedorAsignado.user.profile_photo ? (
                              <>
                                <img
                                  src={`${process.env.REACT_APP_PUBLIC}users/${row.vendedorAsignado.user.profile_photo}`}
                                  crossOrigin="anonymous"
                                  className="symbol-label fs-7 "
                                  alt="user"
                                />
                                <span className="tooltiptext">{`${row.vendedorAsignado.nombre} ${row.vendedorAsignado.apellido_paterno} ${row.vendedorAsignado.apellido_materno}`}</span>
                              </>
                            ) : (
                              <>
                                <span className="symbol-label fs-7 fw-bold bg-light-primary text-primary fw-bold">
                                  {row.vendedorAsignado.nombre &&
                                  row.vendedorAsignado.apellido_paterno ? (
                                    <>{`${row.vendedorAsignado.nombre[0]}${row.vendedorAsignado.apellido_paterno[0]}`}</>
                                  ) : (
                                    <></>
                                  )}
                                </span>
                                <span className="tooltiptext">{`${row.vendedorAsignado.nombre} ${row.vendedorAsignado.apellido_paterno} ${row.vendedorAsignado.apellido_materno}`}</span>
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column fs-012 text-muted">
                      {row.created_at
                        ? dateFormatt(row.created_at)
                        : row.created_at}
                    </div>
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column fs-012 text-muted">
                      {row.tipo ? row.tipo.name : "No registrado."}
                    </div>
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column fs-012 text-muted">
                      {row.unidadMoneda
                        ? `${row.unidadMoneda.code} - ${row.unidadMoneda.name}`
                        : "No registrado."}
                    </div>
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column fs-012 text-muted">
                      {formattedCurrency(Number(row.total))}
                    </div>
                  </div>
                </td>
              </tr>
            ))}
            {!isEdit && (
              <tr>
                <td colSpan={4}>
                  <div className="d-flex align-items-center">
                    <div
                      className="btn btn-light-primary btn-sm mb-10"
                      onClick={() => {
                        history.push(
                          `/cotizaciones/add/${encryptId(oportunty.toString())}`
                        );
                      }}
                    >
                      <KTSVG
                        path="/media/icons/duotune/arrows/arr075.svg"
                        className="svg-icon-5"
                      />
                      Agregar cotización
                    </div>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export { QuotationList };
