import { useState, useEffect, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import {
    getOpportunitiesByStage, getAllComerciales, getSalesDashborad, getYearsOpportunities
} from '../../../service/dashboard/Dashboard'
import { ResourceInfo, ResultDataChart } from '../models/ResultModel'
import { VendedorModel } from '../../vendedor/models/VendedorModel'
import { getCatalog } from '../../../service/catalog/Catalogs'
import { DetailGenericoModel } from '../../catalogos/generico/models/GenericoModel'
function generateArrays(data: Array<ResourceInfo>) {
    let categories = new Array<string>();
    let cantidad: any = [];
    let series: any = [];

    data.forEach((item) => {
        categories.push(item?.name.toUpperCase());
        cantidad.push(item?.cantidad);

    });

    series = [
        {
            name: 'Oportunidades',
            data: cantidad,
        },
    ];

    return { series, categories }
}

export const useGetOpportunitiesByStage = (realoadGrid: number, filters: any) => {

    const [series, setSeries] = useState<any[]>([]);
    const [categories, setCategories] = useState<string[]>([]);
    const [data, setData] = useState<string[]>([]);


    const history = useHistory()
    useEffect(() => {
        const fetchPost = async () => {
            const data = await getOpportunitiesByStage(filters).then().catch((error) => {
                history.push('/error/500')
            });
            if (data) {
                setData(data)
                const { series, categories } = generateArrays(data)
                setSeries(series);
                setCategories(categories);
            }
        };
        fetchPost();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [realoadGrid, filters]);

    return { data, series, categories }

}

export const useGetAllVendedores = (
    realoadGrid: number,
) => {
    const [dataSellers, setDataSellers] = useState<VendedorModel[]>([]);
    const [count, setCount] = useState(1);
    const [loadingRequest, setLoadingRequest] = useState(0);
    const history = useHistory();
    useEffect(() => {
        const fetchPost = async () => {
            setLoadingRequest(0);
            const res = await getAllComerciales()
                .then()
                .catch((error) => {
                    history.push('/error/500');
                });
            if (res) {
                setDataSellers(res.data);
                setCount(res.count);
                setLoadingRequest(1);
            }
        };
        // fetchPost();
        if (realoadGrid) {
            fetchPost();
        }
    }, [realoadGrid]);

    return { dataSellers, count, loadingRequest };
};

export const useGetSales = (filter: any, realoadGrid: number) => {
    // const [isLoading, setIsLoading] = useState<boolean>(true);
    const [loadingRequest, setLoadingRequest] = useState(0);
    const [dataChart, setDataChart] = useState<ResultDataChart>();
    const history = useHistory();
    useEffect(() => {
        const fetchPost = async () => {
            setLoadingRequest(0);
            const res = await getSalesDashborad(filter)
                .then()
                .catch((error) => {
                    history.push('/error/500');
                });
            if (res) {
                setDataChart(res)
                setLoadingRequest(1);

            }
        };
        fetchPost();

    }, [filter]);

    return { dataChart, loadingRequest };
}

export const useGetTypeOfOpportunities = () => {

    const [types, setTypes] = useState<DetailGenericoModel[]>([]);
    const history = useHistory();
    useEffect(() => {
        const fetchPost = async () => {

            const res = await getCatalog('TOP')
                .then()
                .catch((error) => {
                    history.push('/error/500');
                });
            if (res) {
                setTypes(res.rows)
            }
        };
        fetchPost();
    }, []);

    return { types };
}

export const useGetStagesOfOpportunities = () => {

    const [stages, setStages] = useState<DetailGenericoModel[]>([]);
    const history = useHistory();
    useEffect(() => {
        const fetchPost = async () => {

            const res = await getCatalog('ETP')
                .then()
                .catch((error) => {
                    history.push('/error/500');
                });
            if (res) {
                setStages(res.rows)
            }
        };
        fetchPost();
    }, []);

    return { stages };
}

export const useGetYearsOpportunities = (etapa: any) => {

    const [years, setYears] = useState<any[]>([]);
    const history = useHistory();
    useEffect(() => {
        const fetchPost = async () => {

            const res = await getYearsOpportunities(etapa)
                .then()
                .catch((error) => {
                    history.push('/error/500');
                });
            if (res) {
                setYears(res)
            }
        };
        fetchPost();
    }, [etapa]);

    return { years };
}