import React, { useState, useEffect } from "react";
import { NoteForm } from "./NoteForm";

type Props = {
  id: string;
};

const View: React.FC<Props> = ({ id }) => {
  return (
    <>
      <div className="d-flex flex-column flex-xl-row">
        <div className="flex-lg-row-fluid ">
          <NoteForm id={id} />
        </div>
      </div>
    </>
  );
};

export { View };
