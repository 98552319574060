import React from 'react';

type Props = {
  title: string;
  loading: boolean;
};

const EmptyResult: React.FC<Props> = ({ title, loading }) => {
  return (
    <>
      <div
        className="card card-xl-stretch mb-xl-8"
        style={{ minHeight: '415px' }}
      >
        <div className="card-body d-flex align-items-center pt-3 pb-0">
          <div className="d-flex flex-column flex-grow-1 py-2 py-lg-13 me-2">
            <div className="text-center">
              {loading && (
                <span
                  className="indicator-progress text-primary"
                  style={{ display: 'block' }}
                >
                  <div className="text-center mt-3">
                    <span className="spinner-border spinner-border-lg align-middle ms-2"></span>
                  </div>
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { EmptyResult };
