import { FC } from 'react';
import { PageTitle, PageLink } from '../../../_metronic/layout/core';
import { View } from './components/View';

const SurchargePage: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Recargos</PageTitle>
      <View />
    </>
  );
};

export { SurchargePage };
