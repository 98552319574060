import { FC } from 'react';
// import { PageTitle, PageLink } from '../../../_metronic/layout/core';
// import { ViewAddEdit } from './components/ViewAddEdit';
import { useParams } from 'react-router-dom';
// import { decryptId } from '../../../_metronic/helpers';
import { PageTitle, PageLink } from '../../../../_metronic/layout/core';
import { ViewAddEdit } from './ViewAddEdit';
import { decryptId } from '../../../../_metronic/helpers';

const ContactoCreateEditPage: FC = () => {
  const { id, section } = useParams<{ id: string; section?: string }>();
  const idRegister = decryptId(id);
  const breadCrumbs: Array<PageLink> = [
    {
      title: 'Contacto',
      path: '/clientes/contactos/',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ];
  return (
    <>
      <PageTitle breadcrumbs={breadCrumbs}>
        {idRegister ? 'Editar contacto' : 'Nueva contacto'}
      </PageTitle>
      <ViewAddEdit
        id={idRegister ? idRegister : '0'}
        section={section ? section : ''}
      />
    </>
  );
};

export { ContactoCreateEditPage };
