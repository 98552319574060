import { FormikProps } from "formik";
import { KTSVG } from "../../../../../_metronic/helpers";
import Select from 'react-select';
import React, { useState, useEffect } from 'react';
import { formatDate } from "../../../../../_metronic/helpers/FormatDate";
import { useGetDepartmentSelect, useGetPositionSelect } from "../../hooks/CollaboratorHook";
import { styleSelect } from "../../../../../_metronic/helpers/SelectStyles";
import { NumericFormat } from "react-number-format";
import { CreatePositionModal } from "../_modals/CreatePositionModal";
import { toast } from "react-toastify";


type Props = {
    data: any[];
    formik: FormikProps<any>;
    collabId: number,
    onUpdateData: (newData: any[]) => void;
};

const JobsList: React.FC<Props> = ({
    data,
    collabId,
    onUpdateData
}) => {

    const [dataMemo, setDataMemo] = useState<any[]>(data);
    const [reloadPosition, setReloadPosition] = useState<number>(
        Math.random() * 40
    );
    const [reloadDepartment, setReloadDepartment] = useState<number>(
        Math.random() * 40
    );
    const [isOpenPositionModal, setIsOpenPositionModal] = useState(false);
    const [positionValue, setPositionValue] = useState<any | null>(null);
    const [departmentValue, setDepartmentValue] = useState<any | null>(null);
    const [tarifaValue, setTarifaValue] = useState<any | null>(null);
    const [showJobError, setShowJobError] = useState(false);
    const [fechaInicioValue, setFechaInicioValue] = useState<any | null>(null);
    const [fechaTerminoValue, setFechaTerminoValue] = useState<any | null>(null);
    const { positionDataSelect } = useGetPositionSelect('PUCOL', reloadPosition);
    const { departmentDataSelect } = useGetDepartmentSelect('DEP', reloadDepartment);


    const handleSelectPosition = (selectedOption: any, jobId: any) => {
        if (selectedOption) {
            setPositionValue(selectedOption)
            const { label, value } = selectedOption;
            if (value === "new") {
                setIsOpenPositionModal(true);
                localStorage.setItem('actualRow', jobId);
              }
              
            setDataMemo((prevDataMemo) => {
                const updatedDataMemo = prevDataMemo.map((item) => {
                    if(item.id === jobId){
                        return {
                            ...item,
                            puesto: {id: value, name: label},
                        };
                    }

                    return item;
                    
                });
                return updatedDataMemo;
            });
        }
    };

    const handleSelectDepartment = (selectedOption: any, jobId: any) => {
        if (selectedOption) {
            setDepartmentValue(selectedOption)
            const { value, label } = selectedOption;
            setDataMemo((prevDataMemo) => {
                const updatedDataMemo = prevDataMemo.map((item) => {
                    if(item.id === jobId){
                        return {
                            ...item,
                            departamento: {id: value, name: label},
                        };
                    }

                    return item;
                    
                });
                return updatedDataMemo;
            });
        }
    };


    useEffect(() => {
        if (data.length) {
            const cleanedData = data.filter((item) => item.id !== 0);
            setDataMemo(cleanedData);
        }
    }, [data]);

    useEffect(() => {
        if (data && collabId > 0) {
            localStorage.setItem('memo', JSON.stringify(data));
        }
    }, [data]);

    useEffect(() => {
        // Actualiza el estado de Formik cuando dataMemo cambia
        onUpdateData(dataMemo);
    }, [dataMemo]);

    const AddRow = () => {
        let newElement;
        const cleanedData = dataMemo.filter((item) => item.key !== 0);
        const lastAdded = dataMemo.filter((item) => item.key === 0);
        
        if(lastAdded.length > 0){
            const allValid = dataMemo.every(item => 
                item.fecha_inicio && item.fecha_termino && item.puesto?.id);

                if(!allValid){
                    const messageErrorToast = 'Complete los campos requeridos de empleo.'
                    return toast.error(messageErrorToast, {
                        position: 'top-right',
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'light',
                      });
                }else{
   
            newElement = lastAdded[0];
            newElement.key = dataMemo.length;
            newElement.id = `N/${dataMemo.length}`
            cleanedData.push(newElement);

                }
        }

        setPositionValue('')
                    setDepartmentValue('')
                    setTarifaValue('')
                    
        const newRow: any = {
            id: 0,
            fecha_inicio: '',
            fecha_termino: '',
            puesto: 0,
            departamento: 0,
            tarifa_unidad_esfuerzo: '',
            key: 0
        };

        setDataMemo([...cleanedData, newRow]);
    };

    const handleFechaInicioChange = (fechaInicio: any, jobId: number) =>{
        const newValue = fechaInicio ? new Date(fechaInicio).toISOString().split('T')[0] : null;
        
       setFechaInicioValue(newValue)
        setDataMemo((prevDataMemo) => {
            const updatedDataMemo = prevDataMemo.map((item) => {
                if (item.id === jobId) {
                    return {
                        ...item,
                        fecha_inicio: newValue,

                    };
                }
                return item;
            });
            return updatedDataMemo;
        });
    
    }

    const handleFechaTerminoChange = (fechaTermino: any, jobId: number) =>{
        const newValue = fechaTermino ? new Date(fechaTermino).toISOString().split('T')[0] : null;

        const today = new Date();

        const todaySixMonths = new Date(today);

        todaySixMonths.setMonth(today.getMonth()+7); //Se suman 7 debido a que toma enero como 0

        let dateFechaTermino;

        if(fechaTermino){

                dateFechaTermino = new Date(fechaTermino)

                if (dateFechaTermino.getTime() > todaySixMonths.getTime()) {
                    const messageErrorToast = 'La fecha termino del empleo no puede ser mayor a 6 meses.'
                    return toast.error(messageErrorToast, {
                        position: 'top-right',
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'light',
                    });
                }
            }
        
        setFechaTerminoValue(newValue)
        setDataMemo((prevDataMemo) => {
            const updatedDataMemo = prevDataMemo.map((item) => {
                if (item.id === jobId) {
                    return {
                        ...item,
                        fecha_termino: fechaTermino,

                    };
                }
                return item;
            });
            return updatedDataMemo;
        });

    }



    const handleTarifaChange = (
        newTarifa: number,
        jobId: number,
    ) => {
        setTarifaValue(newTarifa)
        setDataMemo((prevDataMemo) => {
            const updatedDataMemo = prevDataMemo.map((item) => {
                if (item.id === jobId) {
                    return {
                        ...item,
                        tarifa_unidad_esfuerzo: newTarifa,

                    };
                }
                return item;
            });
            return updatedDataMemo;
        });
    };

    return (
        <>
            <div className="table-responsive min-h-300px me-n5 pe-5 scroll-y">
                <table className="table table-border table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 me-n5 pe-5">
                    <thead>
                        <tr className="text-gray-800 fs-7">
                            <th className="required w-150px">Fecha inicio</th>
                            <th className="required w-200px">Fecha término</th>
                            <th className="required w-350px">Puesto</th>
                            <th className="w-350px">Departamento</th>
                            <th className="w-150px">Tarifa/hh</th>
                        </tr>
                    </thead>
                    <tbody className="">
                        {dataMemo.length > 0 ? (
                            dataMemo.map((row, index) => (
                                <tr key={row.id}>
                                    <td>
                                        {row.id === 0 ? (
                                            <input
                                                type="date"
                                                className={`form-control`}
                                                id="fecha_inicio"
                                                name="fecha_inicio"
                                                onChange={(event) => handleFechaInicioChange(event.target.value, row.id)}
                                                value={row.fecha_inicio ? 
                                                    new Date(row.fecha_inicio)
                                                        .toISOString()
                                                        .split('T')[0]
                                                        :
                                                        ''
                                                }
                                            />
                                        ) : (
                                            <>
                                                {index+1 === (dataMemo.length) ? (
                                                    <input
                                                        type="date"
                                                        className={`form-control`}
                                                        id="fecha_inicio"
                                                        name="fecha_inicio"
                                                        onChange={(event) => handleFechaInicioChange(event.target.value, row.id)}
                                                        value={
                                                            fechaInicioValue ?
                                                            new Date(fechaInicioValue)
                                                                .toISOString()
                                                                .split('T')[0]
                                                                :
                                                                row.fecha_inicio ?
                                                            new Date(row.fecha_inicio)
                                                                .toISOString()
                                                                .split('T')[0]
                                                                : ''
                                                        }
                                                    />
                                                ) : (
                                                    <div className="d-flex align-items-center">
                                                        <div className="d-flex justify-content-start flex-column fs-012 text-gray-600">
                                                            {formatDate(new Date(row.fecha_inicio))}
                                                        </div>
                                                    </div>
                                                )}
                                            </>
                                        )}

                                    </td>
                                    <td>
                                        {row.id === 0 ? (
                                            <input
                                                type="date"
                                                className={`form-control`}
                                                id="fecha_termino"
                                                name="fecha_termino"
                                                onChange={(event) => handleFechaTerminoChange(event.target.value, row.id)}
                                                value={row.fecha_termino ? 
                                                    new Date(row.fecha_termino)
                                                        .toISOString()
                                                        .split('T')[0]
                                                        :
                                                        ''
                                                }
                                            />
                                        ) : (
                                            <>
                                                {index+1 === (dataMemo.length) ? (
                                                    <input
                                                        type="date"
                                                        className={`form-control`}
                                                        id="fecha_termino"
                                                        name="fecha_termino"
                                                        onChange={(event) => handleFechaTerminoChange(event.target.value, row.id)}
                                                        value={
                                                            fechaTerminoValue ?
                                                            new Date(fechaTerminoValue)
                                                                .toISOString()
                                                                .split('T')[0]
                                                                :
                                                                row.fecha_termino ?
                                                            new Date(row.fecha_termino)
                                                                .toISOString()
                                                                .split('T')[0]
                                                                : ''
                                                        }
                                                    />
                                                ) : (
                                                    <div className="d-flex align-items-center">
                                                        <div className="d-flex justify-content-start flex-column fs-012 text-gray-600">
                                                            {formatDate(new Date(row.fecha_termino))}
                                                        </div>
                                                    </div>
                                                )}
                                            </>
                                        )}

                                    </td>
                                    <td>
                                        <div className="d-flex align-items-center">
                                            <div className="d-flex justify-content-start w-100 flex-column fs-012 font-weight-mormal text-gray-600">
                                                {row.id === 0 ? (
                                                    <Select
                                                        key={row.id}
                                                        value={positionValue}
                                                        options={positionDataSelect}
                                                        menuPortalTarget={document.body}
                                                        menuPosition={"fixed"}
                                                        styles={styleSelect}
                                                        placeholder={"Seleccione"}
                                                        isSearchable={true}
                                                        noOptionsMessage={() => {
                                                            return (
                                                                <span>{"No se encontraron registros"}</span>
                                                            );
                                                        }}
                                                        onChange={(event) => handleSelectPosition(event, row.id)}
                                                        backspaceRemovesValue
                                                    />

                                                ) : (
                                                    <>
                                                        {index+1 === (dataMemo.length) ? (
                                                            <Select
                                                                key={row.id}
                                                                value={ 
                                                                    row.puesto?.id ?
                                                                {value: row.puesto.id, label: row.puesto.name} : ''                                                               }
                                                                options={positionDataSelect}
                                                                menuPortalTarget={document.body}
                                                                menuPosition={"fixed"}
                                                                styles={styleSelect}
                                                                placeholder={"Seleccione"}
                                                                isSearchable={true}
                                                                noOptionsMessage={() => {
                                                                    return (
                                                                        <span>{"No se encontraron registros"}</span>
                                                                    );
                                                                }}
                                                                onChange={(event) => handleSelectPosition(event, row.id)}
                                                                backspaceRemovesValue
                                                            />

                                                        ) : (
                                                            <div className="d-flex align-items-center">
                                                                <div className="d-flex justify-content-start flex-column fs-012 text-gray-600">
                                                                    {row.puesto.name}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex align-items-center">
                                            <div className="d-flex justify-content-start w-100 flex-column fs-012 font-weight-mormal text-gray-600">
                                                {row.id === 0 ? (
                                                    <Select
                                                        key={row.id}
                                                        value={departmentValue}
                                                        options={departmentDataSelect}
                                                        menuPortalTarget={document.body}
                                                        menuPosition={"fixed"}
                                                        styles={styleSelect}
                                                        placeholder={"Seleccione"}
                                                        isSearchable={true}
                                                        noOptionsMessage={() => {
                                                            return (
                                                                <span>{"No se encontraron registros"}</span>
                                                            );
                                                        }}
                                                        onChange={(event) => handleSelectDepartment(event, row.id)}
                                                        backspaceRemovesValue
                                                    />
                                                ) : (
                                                    <>
                                                        {index+1 === (dataMemo.length) ? (
                                                            <Select
                                                                key={row.id}
                                                                value={row.departamento?.id ?
                                                                    { value: row.departamento.id, label: row.departamento.name }
                                                                    : ''}
                                                                options={departmentDataSelect}
                                                                menuPortalTarget={document.body}
                                                                menuPosition={"fixed"}
                                                                styles={styleSelect}
                                                                placeholder={"Seleccione"}
                                                                isSearchable={true}
                                                                noOptionsMessage={() => {
                                                                    return (
                                                                        <span>{"No se encontraron registros"}</span>
                                                                    );
                                                                }}
                                                                onChange={(event) => handleSelectDepartment(event, row.id)}
                                                                backspaceRemovesValue
                                                            />
                                                        ) : (
                                                            <div className="d-flex align-items-center">
                                                                <div className="d-flex justify-content-start flex-column fs-012 text-gray-600">
                                                                    {row.departamento?.id ? row.departamento.name : ''}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex align-items-center">
                                            <div className="d-flex justify-content-start flex-column fs-012 text-gray-600">
                                                {row.id == 0 ? (
                                                    <NumericFormat
                                                        className="form-control w-100px"
                                                        value={tarifaValue}
                                                        thousandSeparator={false}
                                                        allowNegative={false}
                                                        decimalScale={2}
                                                        onBlur={(e) => {
                                                            const value = e.target.value;
                                                            if (!value) {
                                                                e.target.value = "1";
                                                            }
                                                        }}
                                                        isAllowed={(values: any) => {
                                                            const { floatValue } = values;
                                                            const integerPart = Math.floor(floatValue);
                                                            return integerPart.toString().length <= 7;
                                                        }}
                                                        suffix=""
                                                        allowLeadingZeros={false}
                                                        onValueChange={(values) => {
                                                            const { floatValue } = values;
                                                            if (floatValue) {
                                                                if (floatValue > 0) {
                                                                    handleTarifaChange(floatValue, row.id);
                                                                }
                                                            } else {
                                                                handleTarifaChange(0, row.id);
                                                            }
                                                        }}
                                                    />
                                                ) : (
                                                    <>
                                                        {index+1 === (dataMemo.length) ? (
                                                            <NumericFormat
                                                                className="form-control w-100px"
                                                                thousandSeparator={true}
                                                                allowNegative={false}
                                                                fixedDecimalScale={true}
                                                                decimalScale={2}
                                                                value={tarifaValue ? 
                                                                    tarifaValue: 
                                                                    row.tarifa_unidad_esfuerzo ?? ''}
                                                                onBlur={(e) => {
                                                                    const value = e.target.value;
                                                                    if (!value) {
                                                                        e.target.value = "";
                                                                    }
                                                                }}
                                                                isAllowed={(values: any) => {
                                                                    const { floatValue } = values;
                                                                    const integerPart = Math.floor(floatValue);
                                                                    return integerPart.toString().length <= 7;
                                                                }}
                                                                suffix=""
                                                                allowLeadingZeros={false}
                                                                onValueChange={(values) => {
                                                                    const { floatValue } = values;
                                                                    if (floatValue) {
                                                                        if (floatValue > 0) {
                                                                            handleTarifaChange(floatValue, row.id);
                                                                        }
                                                                    } else {
                                                                        handleTarifaChange(0, row.id);
                                                                    }
                                                                }}
                                                            />
                                                        ) : (
                                                            <div className="d-flex align-items-center">
                                                                <div className="d-flex justify-content-start flex-column fs-012 text-gray-600">
                                                                    {row.tarifa_unidad_esfuerzo}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={9} className="text-center text-muted">
                                    Sin registros
                                </td>
                            </tr>
                        )}

                        <tr>
                            <td colSpan={9}>
                                <div className="d-flex align-items-center">
                                    <div
                                        className="btn btn-light-primary btn-sm mb-10"
                                        onClick={AddRow}
                                    >
                                        <KTSVG
                                            path="/media/icons/duotune/arrows/arr075.svg"
                                            className="svg-icon-5"
                                        />
                                        Agregar Empleo
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <CreatePositionModal
        show={isOpenPositionModal}
        handleClose={(isNew: boolean, newPosition: any) => {
          if (isNew && newPosition) {
            setReloadPosition(Math.random() * 50);
            const actualRow = localStorage.getItem('actualRow');
            handleSelectPosition(newPosition, Number(actualRow));
          }
          setIsOpenPositionModal(!isOpenPositionModal);
        }}
      >
      </CreatePositionModal>
                </table>
            </div>
        </>
    )

}

export { JobsList };