import axios from 'axios'
const API_URL = process.env.REACT_APP_API


export async function getVendedores(startIndex: number, endIndex: number, filters: any) {

    let search: string = '';

    if (filters.nombre) {
        search = `&id=${filters.nombre}`;
    }

    const response = await axios.get(`${API_URL}catalogos/vendedores?limit=${startIndex}&page=${endIndex}${search}`);

    const data = response.data.doc.data.rows;
    const count = response.data.doc.data.count;
    return {
        data: data,
        count: count
    };

}

export async function getAllVendedores() {


    const response = await axios.get(`${API_URL}catalogos/vendedores`);

    const data = response.data.doc.data.rows;
    const count = response.data.doc.data.count;
    return {
        data: data,
        count: count
    };

}

export async function getVendedorById(id_row: number) {

    const response = await axios.get(`${API_URL}catalogos/vendedores/${id_row}`);
    const { id, nombre, apellido_paterno, apellido_materno, email, telefono, created_at, tipoVendedor } = response.data.doc.data;
    return {
        id: id,
        nombre: nombre,
        apellido_paterno: apellido_paterno,
        apellido_materno: apellido_materno,
        email: email,
        telefono: telefono,
        created_at: created_at,
        tipoVendedor: {
            id: tipoVendedor.id,
            name: tipoVendedor.name,
            code: tipoVendedor.code,

        },
        tipoVendedorId: tipoVendedor.id,
    };

}

export async function addVendedor(body: any) {

    const { nombre, apellido_paterno, apellido_materno, email, telefono, tipoVendedorId } = body;

    const data = {
        nombre: nombre,
        apellido_paterno: apellido_paterno,
        apellido_materno: apellido_materno,
        email: email,
        telefono: telefono,
        tipo_vendedor_id: tipoVendedorId
    }

    const response = await axios.post(`${API_URL}catalogos/vendedores/`, data);
    return response;

}

export async function updateVendedor(body: any) {
    const { id, nombre, apellido_paterno, apellido_materno, email, telefono, tipoVendedorId } = body;

    const data = {
        nombre: nombre,
        apellido_paterno: apellido_paterno,
        apellido_materno: apellido_materno,
        email: email,
        telefono: telefono,
        tipoVendedorId: tipoVendedorId,
    }

    const response = await axios.put(`${API_URL}catalogos/vendedores/${id}`, data);
    return response;

}

export async function removeVendedor(id: number) {
    const response = await axios.delete(
        `${API_URL}catalogos/vendedores/${id}`
    );
    return response;
}